<template>
  <div>
    <span v-if="quantity == 0">-</span>
    <span v-else @click="quantityPressed" 
          class="tdclickable" 
          :class="{ negative: quantity < 0 || quantity <= Number(object.min_stock) }">
    {{ quantity | formatNumber }} {{ object.unit.title }}
  </span>
  </div>
</template>

<script>
import {mapState} from "vuex"

export default {
  name: "WarehouseDatatableTDMaterialQuantities",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      filters: state => state.datatable.filters
    }),
    quantity() {
      if(this.filters && this.filters.warehouse_id && this.object.quantities) {
        return Number(this.object.quantities[this.filters.warehouse_id.condition])
      }
      return Number(this.object.total_quantity)
    }
  },
  methods: {
    quantityPressed() {
      this.$store.commit('setSelectedModel', JSON.parse(JSON.stringify(this.object)))
      this.$store.dispatch('getWarehouses').then(() => {
        this.$bvModal.show('stocks-modal')
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
