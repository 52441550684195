<template>
  <div>
    <span v-if="object.age || object.age === 0">{{ $t('years') }}: {{ object.age }}</span>&nbsp;
    <span class="text-muted" v-if="object.date_of_birth">({{ object.date_of_birth | formatDate }})</span>
  </div>
</template>

<script>
export default {
  name: "PatientsDatatableTDAge",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>