<template>
  <a class="btn btn-plain position-relative" v-if="wazzupEnabled && userHasAccessToWazzup" @click="wazzupChat">
    <WazzupSvg class="svg-wazzup"/>
    <span class="notification-bubble" v-b-tooltip.hover :title="counter" v-if="counter > 0">{{ counter }}</span>
    <WazzupIframeModal/>
  </a>
</template>

<script>

import WazzupSvg from '@/assets/svg/crm/whatsapp.svg'
import {WhatsappService} from "@/services/api.service"
// import ApiService, {WhatsappService} from "@/services/api.service"
import WazzupIframeModal from "@/components/parts/settings/WazzupIframeModal"
import {mapState} from "vuex"

export default {
  name: "HeaderWazzup",
  components: {
    WazzupIframeModal,
    WazzupSvg
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      wazzupEnabled: state => state.crm.wazzupEnabled,
      user: state => state.auth.user,
      superadmin:  state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : { wazzupUsers: [] }
    },
    wazzupUsers() {
      return this.ppoSettings.wazzupUsers ?? []
    },
    userHasAccessToWazzup() {
      return (this.wazzupUsers.includes(this.user.id))
    }
  },
  data() {
      return {
        counter: 0
      }
  },
  methods: {
    async wazzupChat() {
      let res = await WhatsappService.createIframe();
      if (res.data.url) {
        this.$store.commit('setWazzupIframeUrl', res.data.url)
        this.$nextTick(() => {
          this.$bvModal.show('wazzup-iframe-modal')
        })
        return
      }
      if (res.data.error) {
        this.$noty.error(res.data.error)
      }
    },
    getWazzupStatus() {
      WhatsappService.getWazzupStatus(this.superadmin).then(res => {
        this.$store.commit('setWazzupEnabled', res.data)
        if(res.data) {
          setTimeout(() => {
            this.socket()
          }, 4000)
        }
      })
    },
    async socket() { // used to get unread messages through socket
      let res = await WhatsappService.getWazzupSocketCredentials()
      if(res && res.data && res.data.user && res.data.key) {
        const wsHost = 'ws-counters2.wazzup24.com';
        const connectOptions = {
          path: '/ws-counters/',
          transports: ['websocket', 'polling']
        };
        const apiKey = res.data.key;
        const userId = res.data.user.id;
        const client = window.io(`https://${wsHost}`, connectOptions);
        client.on('connect', () => {
          console.log('socket connected')
          client.emit('counterConnecting', {
            type: 'api_v3',
            apiKey,
            userId
          });
        })

        client.on('counterConnected', () => console.log('Connected to Wazzup notifications!'));

        client.on('counterUpdate', (data) => {
          console.log('wazzup counter updated', data)
          this.counter = data.counter;
        });  
      }
    },
  },
  mounted() {
    if(this.userHasAccessToWazzup) {
      this.getWazzupStatus()  
    }
  }
}
</script>

<style scoped>
.svg-wazzup {
  fill: #BAC0C6;
  width: 18px;
}
.notification-bubble {
  top: -2px;
}
</style>
