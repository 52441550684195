<template>
  <div>
    {{  object.doctors | oneLinerFromProperty('full_name') }}
  </div>
</template>

<script>

export default {
  name: "StaffDatatableTDChairDoctors",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
