<template>
  <div>
    <DataTableHtml :columns="columns"
                   v-if="loaded"
                   primaryKey="title"
                   initialOrder="created_at"
                   initialSort="desc"
                   :rows="cheques"/>
  </div>
</template>

<script>

import { mapState } from "vuex"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import {CashdeskService} from "@/services/api.service"

export default {
  name: "CashdeskCheques",
  components: {
    DataTableHtml
  },
  metaInfo() {
    return {
      title: this.$t('cheques'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      ppoData: state => state.ppo.ppoData
    }),
    cheques() {
      if(!this.ppoData || !this.ppoData.cheques) return []
      if(this.ppoData.vendor === 'aqsi') return this.ppoData.cheques
      return this.ppoData.cheques.results
    },
    columns() {
      if(this.ppoData.vendor === 'checkbox') return this.checkboxColumns
      if(this.ppoData.vendor === 'webkassa') return this.webkassaColumns
      if(this.ppoData.vendor === 'aqsi') return this.aqsiColumns
      return []
    }
  },
  data() {
    return {
      loaded: false,
      checkboxColumns: [
        {
          id: 'id',
          field: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          id: 'total_sum',
          field: 'total_sum',
          label: this.$t('sum'),
          format_currency: true,
          sortable: true
        },
        {
          id: 'created_at',
          field: 'created_at',
          label: this.$t('date'),
          sortable: true,
          format_datetime: true
        },
        {
          tdcomponent: 'CashdeskDatatableTDView',
          label: this.$t('actions'),
        }
      ],
      webkassaColumns: [
        {
          id: 'Number',
          field: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          id: 'RegistratedOn',
          field: 'created_at',
          label: this.$t('date'),
          sortable: true,
        },
        {
          id: 'Total',
          field: 'total',
          label: this.$t('sum'),
          format_currency: true,
          sortable: true
        },
        {
          id: 'OperationTypeText',
          field: 'type',
          label: this.$t('type'),
          sortable: true,
        },
        {
          id: 'ShiftNumber',
          field: 'shift',
          label: this.$t('shift'),
          sortable: true
        },
        {
          tdcomponent: 'CashdeskDatatableTDView',
          label: this.$t('actions'),
        }
      ],
      aqsiColumns: [
        {
          id: 'id',
          field: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          id: 'fiscalizedAt',
          field: 'created_at',
          label: this.$t('date'),
          sortable: true,
        },
        {
          id: 'amount',
          field: 'total',
          label: this.$t('sum'),
          format_currency: true,
          sortable: true
        },
        {
          id: 'shiftId',
          field: 'shift',
          label: this.$t('shift'),
          sortable: true
        }
      ]
    }
  },
  async mounted() {
    if(this.ppoData.vendor === 'webkassa') {
      const res = await CashdeskService.webkassaData('cheques')
      this.$store.commit('setCheques', res.data)
    }
    if(this.ppoData.vendor === 'aqsi') {
      const res = await CashdeskService.aqsiData('cheques')
      this.$store.commit('setCheques', res.data.rows)
    }
    this.loaded = true
  }
}
</script>
