<template>
  <a class="sidebar__menutoggler pointer" :class="`view-${view}`">
    <div class="select-wrapper select-branch" v-if="view === 'sidebar'">
      <select v-if="superadmin" class="form-control" disabled>
        <option>superadmin</option>
      </select>
      <select v-if="!superadmin" 
              class="form-control"
              :disabled="!rights.includes('branches')"
              v-model="tmpBranch">
        <option v-for="branch in branches"
                :value="branch.id"
                :key="`branch-${branch.id}`">
          {{ branch.title }}
        </option>
      </select>
    </div>

    <a @click="$store.commit('toggleCollapsed')" class="svg-a d-inline-block">
      <MenuSvg class="d-md-inline d-none"/>
      <MobileMenuSvg class="d-md-none d-inline"/>
    </a>
  </a>
</template>

<script>

import { mapState } from "vuex"
import MenuSvg from '@/assets/svg/sidebar/menu.svg'
import MobileMenuSvg from '@/assets/svg/header/menu.svg'
import CalendarSettingsService from "@/services/calendarSettings.helper"

export default {
  name: "MenuToggler",
  components: {
    MenuSvg,
    MobileMenuSvg
  },
  props: {
    view: {
      type: String
    }
  },
  computed: {
    ...mapState({
      branches: state => state.auth.branches,
      branch: state => state.auth.branch,
      user: state => state.auth.user,
      superadmin: state => state.auth.superadmin,
      rights: state => state.auth.rights,
    })
  },
  data() {
    return {
      tmpBranch: ''
    }
  },
  mounted() {
    if(!this.rights.includes('branches') && this.user.branch_id) {
      this.tmpBranch = this.user.branch_id
      return
    }
    this.tmpBranch = this.branch
  },
  watch: {
    tmpBranch(val, oldval) {
      this.$store.commit('setBranch', val)
      if(oldval) {
        CalendarSettingsService.setSelectedDoctors([])
        location.reload()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .sidebar__menutoggler {
    svg {
      top: 3px;
      position: relative;
    }
  }
}
</style>
