<template>
  <section class="discount-wrap">
  <b-row>
    <b-col lg="6" class="mb-3 mb-lg-0">
      <label>{{ $t('discount') }}</label>
      <div>
        <div class="d-inline-block">
          <input type="number"
                 @keyup="(($event.target.value > 100 && object.discount_type === 'percent') ? object.discount_value = 100 : $event.target.value < 0 ? object.discount_value = 0 : '')"
                 :max="object.discount_type === 'percent' ? 100 : beforeDiscount"
                 v-model="object.discount_value"
                 class="form-control sm"/>
        </div>
        <div class="d-inline-block pl-3">
          <select class="form-control sm discount-type-select" v-model="object.discount_type">
            <option value="percent">%</option>
<!--            <option value="absolute">{{ $store.getters.getCurrencyName }}</option>-->
          </select>
        </div>
      </div>
    </b-col>
    <b-col lg="6">
      <div class="text-right">
      <div>{{ $t('total') }}: <span class="total-num">{{ costOriginal | formatCurrency }}</span></div>
      <div>{{ $t('discount') }}: <span class="total-num">{{ (costOriginal - beforeDiscount) | formatCurrency }}</span></div>
      <div>{{ $t('additional_discount') }}: <span class="total-num">{{ (beforeDiscount - afterDiscount) | formatCurrency }}</span></div>
      <div>{{ $t('total_with_discount') }}: <span class="total-num">{{ afterDiscount | formatCurrency }}</span></div>
      <div>{{ $t('total_paid_for_treatment_plan') }}: <span class="total-num">{{ paid | formatCurrency }}</span></div>
      </div>
    </b-col>
  </b-row>
  </section>
</template>

<script>

export default {
  name: "PlanTreatmentDiscount",
  props: {
    object: {},
    cost: {},
    costOriginal: {}
  },
  computed: {
    beforeDiscount() {
      return this.cost
    },
    discount() {
      if(!this.object || !this.object.discount_type) return 0
      return this.object.discount_type === 'percent' ?
          ((this.object.discount_value * this.beforeDiscount) / 100)
          : this.object.discount_value
    },
    afterDiscount() {
      return (this.beforeDiscount - this.discount)
    },
    paid() {
      return this.object.paid_sum
    }
  },
}
</script>

<style scoped lang="scss">
.discount-wrap {
  color: $label-color;
  .total-num {
    color: #000;
    font-size: 110%;
  }
}
.discount-type-select {
  width: 80px;
}
</style>
