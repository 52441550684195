<template>
  <div class="doctor-hover-calendar bg-white" v-if="doctor">
    <b-row class="align-items-center no-gutters">
      <b-col cols="auto">
        <img class="round-img mr-2" :src="doctor.photo"/>    
      </b-col>
      <b-col>
        <div class="doctor-title">{{ doctor.full_name }}</div>
        <div class="text-muted">{{ times }}</div>
      </b-col>
    </b-row>
    <div class="position-relative">
    <b-spinner v-if="loading" class="position-absolute" style="left: 50%; top: 50%; margin-left: -23px; margin-top: -23px; z-index: 5"></b-spinner>
    <DatePicker :locale="{ id: 'ru', firstDayOfWeek: 2, masks: { L: 'DD.MM.YYYY' } }"
                v-model="date"
                :selected-attribute="{ highlight: false }"
                @update:from-page="pageChange"
                class="date-picker border-0">
      <template #day-content="{day}">
        <span class="vc-day-content position-relative" :class="{ 'text-muted': !fillData[day.label] }" @click="dayClicked(day)">
          <span class="position-relative day-title">{{ day.label }}</span>
          <div class="availability-column" v-if="fillData[day.label]" :style="{ height: fillData[day.label]+'%' }"></div>
        </span>
      </template>
    </DatePicker>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex"
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from "moment"
import {DoctorService} from "@/services/api.service"

export default {
  name: "DoctorHoverCalendar",
  components: {
    DatePicker
  },
  props: {
    doctor: {
      type: Object
    },
    chair: {
      type: Object
    },
    day: {}
  },
  data() {
    return {
      date: moment(this.day).format('YYYY-MM'),
      fillData: {},
      loading: false,
      dayLoaded: moment(this.day).format('YYYY-MM')
    }
  },
  computed: {
    ...mapState({
      doctorSchedules: state => state.calendar.doctorSchedules,
      branchId:  state => state.auth.branch,
    }),
    times() {
      const day = moment(this.day).format('YYYY-MM-DD')
      return this.doctorSchedules[this.doctor.id]?.filter(s => (this.chair ? Number(s.chair_id) === this.chair.id : true) && s.day === day)
          .map(s => `${s.time_from.slice(0, -3)} - ${s.time_to.slice(0, -3)}`)
          .join(', ')
    },
  },
  methods: {
    ...mapActions([
      'changeCalendarDays'
    ]),
    load(day) {
      if(!this.doctor) return
      this.loading = true
      this.dayLoaded = day
      this.fillData = {}
      DoctorService.monthAvailability(this.doctor.id, this.branchId, day).then(res => {
        this.fillData = res.data
        this.loading = false
      })
    },
    pageChange(e) {
      const day = `${e.year}-${e.month.toString().padStart(2, '0')}`
      if(this.dayLoaded !== day) this.load(day)
    },
    dayClicked(e) {
      this.$store.commit('goToDate', moment(e.date))
    }
  },
  watch: {
    // date(v) {
    //   console.log(v)
    // }
  //   doctorId(val) {
  //     console.log(val)
  //   }
  },
  mounted() {
    this.fillData = {}
    this.load(this.dayLoaded)
  }
}
</script>

<style scoped lang="scss">

.doctor-hover-calendar {
  img {
    width: 64px;
  }
  .doctor-title {
    font-weight: 700;
    font-size: 14px;
  }
  .day-title {
    z-index: 2;
  }
  .availability-column {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    background: #C1ECA6;
  }
}
::v-deep .vc-highlights {
  display: none;
}
::v-deep .vc-day-content:hover {
  background-color: transparent;
}
</style>