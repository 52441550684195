<template>
  <div>
    <DataTable :options="options"
               :params="{ record_type: ['treatment', 'consultation', 'checkup', 'diagnostics'] }"
               module="planning"/>
    <PatientSMSModal/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/planning/record"
import {mapState} from "vuex"
import PatientSMSModal from "@/components/parts/patients/PatientSMSModal"

export default {
  name: "CallVisits",
  metaInfo() {
    return {
      title: this.$t('visits'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      branch: state => state.auth.branch
    })
  },
  components: {PatientSMSModal, DataTable },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
