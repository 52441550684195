<template>
  <section id="payments" class="payments">
    <transition name="fade" mode="out-in">
      <div class="section-top-row" >
        <div class="row row-narrow align-items-center no-print position-relative">
          <div class="col-md col-12 order-md-0 order-0">
            <h1>{{ $t('notifications') }}</h1>
          </div>
          <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator">
            <DataTableFilterButton/>
          </div>
          <div class="mt-md-0 mt-2 col-auto order-md-5 order-2 table-view-navigator">
            <a class="btn btn-themed d-md-inline-flex d-block align-items-center"
               @click="createNotif">
              <MarketingSvg class="svg-white mr-md-2"/>
              Создать уведомление
            </a>
          </div>
        </div>
      </div>
    </transition>
    <div>
      <div class="alert alert-warning">Здесь указаны только пользователи, у которых есть токен для пуш-уведомлений</div>
      <DataTable :options="options"
                 ref="dataTable"
                 @totalRows="totalRows"
                 @allParams="allParams"
                 module="superadmin"/>
    </div>

    <b-modal id="superadmin-notification-modal"
             :title="`Уведомление`"
             @show="message = ''"
             centered>
      <div>
        <div class="mb-3">{{ $t('users_selected') }}: <b>{{ selected }}</b></div>
        <AutosizeTextArea v-model="message"
                          :label="$t('message')"/>
      </div>
      <template #modal-footer="{ close }">
        <div class="modal-footer-buttons">
          <b-button class="ml-auto ml-sm-0 cancel d-inline-block d-sm-inline-block align-middle"
                    variant="themed"
                    @click="close()">
            {{ $t('cancel') }}
          </b-button>
          <button :disabled="loading"
                  @click="send"
                  class="ml-3 btn-themed align-middle d-inline-block d-sm-inline-block btn-themed-squared">
            {{ $t('save') }}
          </button>
        </div>
      </template>


    </b-modal>


  </section>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/superadmin/staff"
import {mapState} from "vuex"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
// import AddObject from "@/components/parts/datatable/AddObject"
// import SearchTable from "@/components/parts/datatable/SearchTable"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import {SuperadminService} from "@/services/api.service"
import MarketingSvg from '@/assets/svg/sidebar/marketing.svg'

export default {
  name: "SuperAdminNotifications",
  components: {
    AutosizeTextArea,
    // AddObject,
    // SearchTable,
    DataTableFilterButton,
    DataTable,
    MarketingSvg
  },
  metaInfo() {
    return {
      title: this.$t('notifications'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
    })
  },
  data() {
    return {
      options: options,
      message: '',
      selected: 0
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  },
  methods: {
    createNotif() {
      this.$bvModal.show('superadmin-notification-modal')
    },
    allParams(params) {
      if(params.filters) params.filters = JSON.stringify(params.filters)
      SuperadminService.sendPush(params, this.message).then(res => {
        if(res.data) {
          this.$noty.info(this.$t('success_message'))
          this.$bvModal.hide('superadmin-notification-modal')
        }
      })
    },
    send() {
      this.$refs.dataTable.emitAllParams()
    },
    totalRows(rows) {
      this.selected = rows
    }
  }
}
</script>

<style scoped lang="scss">
.btn-themed {
  svg {
    width: 16px;
    height: 16px;
  }
}
</style>
