<template>
  <div class="mt-4 patient-notifications">

    <b-row>
      <b-col lg="12" class="mb-5">
        <div v-if="!notifications || !notifications.length && loaded">
          {{ $t('no_data_available') }}
        </div>
        <DataTable :options="options"
                   :params="{ recipient_id: patient.id }"
                   module="patients"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapState } from "vuex"
// import { EloquentService } from "@/services/api.service"
import DataTable from "@/components/parts/general/DataTable.vue"
import options from "../../../data/patients/notification"

export default {
  name: "PatientNotifications",
  components: {
    DataTable
    // CheckboxGroup,
    // PatientCommentTeaser,
    // AutosizeTextArea,
    // ClipSvg,
    // CloseSvg
  },
  metaInfo() {
    return {
      title: this.$t("notifications"),
      meta: [],
    }
  },
  data() {
    return {
      options: options,
      // checks: [
      //   { id: 'all', title: 'all' },
      //   { id: 'manual', title: 'comments' },
      //   { id: 'record', title: 'visits' },
      //   { id: 'notification', title: 'notifications' },
      //   { id: 'payment', title: 'payments' },
      //   { id: 'advance', title: 'deposit' },
      //   { id: 'treatment_plan', title: 'plan_treatments' },
      //   { id: 'task', title: 'tasks' },
      //   { id: 'bonus', title: 'bonuses' },
      //   { id: 'call', title: 'calls' },
      //   { id: 'visit_timing', title: 'visit_time' },
      // ],
      checked: [],
      notifications: [],
      loaded: false,
      paginator: null,
      page: 1,
      keyCounter: 1,
      dataTableWatcher: true
    }
  },
  computed: {
    ...mapState({
      patient: state => state.patient.patient,
      loading: state => state.dom.loading,
      counter: state => state.datatable.dataTableCounter,
    }),
    // hasMore() {
    //   if(!this.paginator) return false
    //   return this.page < this.paginator.last_page
    // }
  },
  // created() {
  //   window.addEventListener('scroll', this.handleScroll)
  // },
  mounted() {
    // this.getNotifications()
  //   this.checked = this.checks.map(x => x.id)
  },
  methods: {
    // getNotifications(flush = false) {
    //   if(this.patient && this.patient.id) {
    //     if(flush) this.page = 1
    //     EloquentService.dataTable('notification', this.page, {
    //       pagination: 10,
    //       order: 'created_at',
    //       sort: 'desc',
    //       condition: { recipient_id: this.patient.id },
    //     }).then(res => {
    //       if(flush) this.notifications = []
    //       // this.comments = res.data.result.data.reverse().concat(this.comments)
    //       this.notifications = this.notifications.concat(res.data.result.data)
    //       this.paginator = res.data.result
    //       this.loaded = true
    //       this.dataTableWatcher = false
    //       this.$nextTick(() => {
    //         this.$store.commit('incrementDataTableCounter')
    //         setTimeout(() => {
    //           this.dataTableWatcher = true
    //         }, 100)
    //       })
    //     })
    //   }
    // },
    // loadMore() {
    //   this.page++
    //   this.getNotifications()
    // },
    // checkboxInput(clicked, all) {
    //   if(clicked === 'all' && all.includes('all')) {
    //     this.checked = this.copyObject(this.checks.map(x => x.id))
    //     return
    //   }
    //   if(clicked === 'all' && !all.includes('all')) {
    //     this.checked = []
    //     return
    //   }
    //   this.checked = all.filter(x => x !== 'all')
    //   if(this.checked.length === (this.checks.length - 1)) {
    //     this.checked.push('all')
    //   }
    // },
    // handleScroll() {
    //   if(!this.loaded || this.loading || !this.hasMore) return
    //   let bottomOfWindow = (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight) > (document.documentElement.offsetHeight - 40)
    //   if (bottomOfWindow) {
    //     this.loadMore()
    //   }
    // }
  },
  watch: {
    // checked: {
    //   handler() {
    //     this.getComments(true)
    //   },
    // },
    counter() {
      // if(this.dataTableWatcher) this.getComments(true)
    }
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>>
