export default {
    primaryKey: "id",
    model: 'pay_method',
    modaltitle: 'payment_type',
    order: 'custom_data_sort_order',
    sort: 'desc',
    showTotalRecords: true,
    actions: [
        "add",
        "edit",
        "delete"
    ],
    deleteCondition(object) {
        return !!object.clinic_id
    },
    deletemessage: 'are_you_sure_you_want_to_delete_payment',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table_show: 'translatedTitle',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true,
                component: "SettingsDatatableFormPayMethodTitle",
            },
        },
        {
            id: 'custom_data_acquiring_fee',
            title: 'acquiring_fee',
            table: true,
            sortable: true,
            form: {
                type: "number",
            },
            format_number: true
        },
        {
            id: 'custom_data_sort_order',
            title: 'sort_order',
            table: true,
            sortable: true,
            form: {
                type: "number",
                note: "higher_number_higher_priority"
            },
            format_number: true
        },
        {
            id: 'custom_data_active',
            title: 'active',
            table: true,
            sortable: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: 'ActiveTD'
        },
    ]
}
