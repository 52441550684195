<template>
  <span>
  <router-link
      v-if="!isPatient && isActive && belongsToCurrentBranch"
      :title="$t('edit')"
      v-b-tooltip.hover
      class="data-table-action"
      :to="{name: 'PatientVisitPage', params: { visitid: object.id, id: object.client_id, page: 'description' } }">
    <EditSvg/><span class="action-button-mob-title">{{ $t('edit') }}</span>
  </router-link>
    <a class="data-table-action"
       v-if="!isPatient && rights.includes('accept_payments') && isActive && belongsToCurrentBranch"
       :title="$t('payment')"
       v-b-tooltip.hover
       @click="payment">
      <DollarSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('payment') }}</span>
    </a>
    <a class="data-table-action"
       v-if="!isPatient && rights.includes('agreements') && isActive && belongsToCurrentBranch"
       @click="agreementPressed"
       :title="$t('agreement')"
       v-b-tooltip.hover>
      <ContractSvg/><span class="action-button-mob-title">{{ $t('agreement') }}</span>
    </a>
    <a class="data-table-action"
       :title="$t('print')"
       v-if="isActive"
       v-b-tooltip.hover
       @click="printVisit">
      <PrintSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('print') }}</span>
    </a>
    <b-dropdown right
                v-if="object.id && isActive && belongsToCurrentBranch"
                variant="link"
                no-caret
                toggle-tag="span"
                toggle-class="action-0"
                class="data-table-action">
      <template #button-content>
        <a v-b-tooltip.hover :title="$t('akt_print')">
          <NoteSvg/><span class="action-button-mob-title">{{ $t('akt_print') }}</span>
        </a>
      </template>
          <b-dropdown-item @click="printAkt('admin')">{{ $t('manager') }}</b-dropdown-item>
          <b-dropdown-item @click="printAkt('doctor')">{{ $t('doctor') }}</b-dropdown-item>
    </b-dropdown>
    <a class="data-table-action"
       :title="isActive ? $t('cancel') : $t('restore')"
       v-b-tooltip.hover
       v-if="belongsToCurrentBranch && canDelete"
       @click="cancelRecoverVisit">
      <DeleteSvg v-if="isActive" class="svg-light"/><RestoreSvg v-if="!isActive" class="svg-light"/><span class="action-button-mob-title">{{ isActive ? $t('cancel') : $t('restore') }}</span>
    </a>
    </span>
</template>

<script>

import EditSvg from '@/assets/svg/general/edit.svg'
import PrintSvg from '@/assets/svg/general/print2.svg'
import ContractSvg from '@/assets/svg/patients/contract.svg'
import DollarSvg from '@/assets/svg/patients/dollar.svg'
import NoteSvg from '@/assets/svg/header/note.svg'
import DeleteSvg from '@/assets/svg/general/delete.svg'
import RestoreSvg from '@/assets/svg/accounting/back.svg'
import JwtService from "@/services/jwt.service"
import {mapState} from "vuex"
import {CalendarService} from "@/services/api.service"

export default {
  name: "PatientDatatableRecordActions",
  components: {
    EditSvg,
    ContractSvg,
    DollarSvg,
    PrintSvg,
    NoteSvg,
    DeleteSvg,
    RestoreSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      patient: state => state.patient.patient,
      rights: state => state.auth.rights,
      branchId: state => state.auth.branch
    }),
    isPatient() {
      return this.$store.getters.isPatient
    },
    isActive() {
      return this.object.status != 2
    },
    belongsToCurrentBranch() {
      if(!this.object) return false
      if(!this.object.branch_id) return true
      return this.object.branch_id == this.branchId
    },
    canDelete() {
      return this.rights.includes('recording_status')
    }
  },
  methods: {
    agreementPressed() {
      // let link = JwtService.getPrintPrelink(this.clinic)
      // let visit_id = '?visit_id='+this.object.id
      // window.open(link+'/document/'+this.patient.id+'/agreement'+ visit_id, '_blank')
      this.$store.commit('setPrintAgreements', true)
      this.$store.commit('setVisit', this.copyObject(this.object))
      this.$bvModal.show('contract-modal')
    },
    payment() {
      this.$store.commit('setVisit', this.copyObject(this.object))
      this.$nextTick(() => {
        this.$bvModal.show('visit-pay-modal')
      })
    },
    printVisit() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/document/'+this.object.client_id+'/disease_history?visit_id='+this.object.id, '_blank')
    },
    printAkt(signer) {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/akt/'+this.object.id+'?signer='+signer, '_blank')
    },
    cancelRecoverVisit() {
      if(this.isActive) {
        let reason = prompt(this.$t('write_reason_for_cancellation'))
        if(!reason) return
        CalendarService.cancelRecord(this.object.id, reason).then(() => {
          this.$noty.info(this.$t('success_message'))
          this.$store.commit('incrementDataTableCounter')
        })
      }
      if(!this.isActive) {
        CalendarService.recoverRecord(this.object.id).then(() => {
          this.$noty.info(this.$t('success_message'))
          this.$store.commit('incrementDataTableCounter')
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
