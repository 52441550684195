<template>
  <div>
    {{ perHour | formatNumber(true) }}
  </div>
</template>

<script>

export default {
  name: "ReportStatDoctorsDatatableHtmlPerHourTotal",
  components: {
  },
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    },
  },
  computed: {
    perHour() {
        return this.object.hours > 0 ? Math.round(this.object.revenue / this.object.hours) : 0
      }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
