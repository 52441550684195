export default {
    add_task: "Add task",
    add_patient: "Add patient",
    calls: "Calls",
    clinic_id: "Clinic Id",
    expenses: "Expenses",
    high: "High",
    low: "Low",
    medium: "Medium",
    no_notifications: "No notifications",
    tasks: "Tasks"
}
