export default {
    cashdesk: "Cashdesk",
    cash_operation: "Cash operation",
    cheque: "Cheque",
    cheques: "Cheques",
    closing_balance: "Closing balance",
    close_shift: "Close shift",
    data_is_invalid: "Data is invalid",
    data_is_valid: "Data is valid",
    injection: "Injection",
    name_of_service_in_cheque: "Name of service in cheque",
    not_subject_to_vat: "Not subject to VAT",
    opening_balance: "Opening balance",
    open_shift: "Open shift",
    opened_at: "Opening date",
    sales: "Sales",
    send_services_in_cheque: "Send services in cheque",
    shift: "Shift",
    shifts: "Shifts",
    shift_number: "Shift number",
    vat_rate: "VAT rate",
    withdrawal: "Withdrawal",
    xreport: "X-report",
    xreports: "X-reports"
}
