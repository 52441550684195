<template>
  <div>
    <DataTable :options="options" module="warehouse"/>
  </div>
</template>

<script>

import DataTable from "../../parts/general/DataTable"
import options from "../../../data/warehouse/supplier"

export default {
  name: "Suppliers",
  metaInfo() {
    return {
      title: "Поставщики",
      meta: []
    }
  },
  components: { DataTable },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>