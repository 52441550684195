<template>
  <div class="form-group" ref="multiselect">
    <label class="form-label" v-if="label" :data-content="label">
      <span>{{ label }}</span>
      <span v-if="required"> *</span>
    </label>
    <multiselect :options="options"
                 :searchable="true"
                 v-model="f"
                 :label="title"
                 :placeholder="$t(placeholder)"
                 selectLabel=""
                 selectedLabel=""
                 deselectLabel=""
                 :internalSearch="internalSearch"
                 :track-by="title"
                 @remove="remove"
                 :disabled="readonly"
                 @select="select"
                 :group-select="false"
                 :[groupLabel]="'category'"
                 :[groupValues]="'services'"
                 @open="serviceFind"
                 @search-change="serviceFind"
                 ref="multi"
                 :multiple="multiple">
      <span slot="noResult">{{ $t('results_not_found') }}</span>
      <span slot="noOptions">{{ $t('results_not_found') }}</span>
    </multiselect>
  </div>
</template>

<script>

import Multiselect from 'vue-multiselect'
import { EloquentService } from "@/services/api.service"
import {mapState} from "vuex"

export default {
  name: "ServiceMultiSelect",
  components: {
    Multiselect
  },
  props: {
    value: {},
    label: {},
    prepopulatedOptions: {},
    dbsearch: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    internalSearch: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'name'
    },
    model: {
      type: String,
      default: 'service'
    },
    dblclick: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    ignoreIds: {
      type: Array,
      default() {
        return []
      }
    },
    placeholder: {
      type: String,
      default: 'search'
    },
    onlyId: {
      type: Boolean,
      default: false
    },
    valueId: {
      type: String,
      default: 'id'
    }
  },
  computed: {
    ...mapState({
      superadmin: state => state.auth.superadmin,
      clinic: state => state.auth.clinic,
      branch: state => state.auth.branch
    }),
    groupLabel() {
      return this.model == 'service' ? 'group-label' : ''
    },
    groupValues() {
      return this.model == 'service' ? 'group-values' : ''
    }
  },
  data() {
    return {
      options: [],
      timer: null,
      f: []
    }
  },
  methods: {
    serviceFind(query) {
      if(!this.dbsearch) return
      if(this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        EloquentService.autocomplete(this.model,
            query,
            40,
            true,
            this.superadmin,
            this.superadmin && this.clinic ? this.clinic.id : null,
            this.branch
        ).then(res => {
          let set = res.data.result
          if(this.ignoreIds && this.ignoreIds.length) {
            set = set.filter(x => x && (!x.id || !this.ignoreIds.includes(x.id)))
          }
          this.options = set
        })
      }, 250)
    },
    tagClick(e) {
      let node = e.target
      if(node && node.parentNode && node.parentNode.classList.contains('multiselect__tag')) {
        node = node.parentNode
        let parent = node.parentNode
        let index = Array.from(parent.children).indexOf(node)
        this.f.push(this.f[index])
        this.$emit('select', this.f[index])
      }
    },
    remove(e) {
      this.$emit('remove', e)
    },
    select(e) {
      this.$emit('select', e)
    },
    openDropdown() {
      setTimeout(() => {
        this.$refs.multi.activate()
      }, 100)
    }
  },
  mounted() {
    this.f = this.value
    if(this.prepopulatedOptions) {
      this.options = this.prepopulatedOptions
      if(this.onlyId && this.value) {
        this.f = this.options.find(o => o[this.valueId].toString() === this.value.toString())
      }
    }
  },
  watch: {
    f(val) {
      if(this.onlyId) {
        this.$emit('input', val ? val[this.valueId] : '')
        return
      }
      this.$emit('input', val)
    },
    value(val) {
      this.f = val
      if(this.onlyId) {
        this.f = this.options.find(o => o[this.valueId].toString() === val.toString())
      }
    }
  },
  created() {
    if(this.dblclick) {
      // document.body.addEventListener('dblclick', this.tagClick)
      setTimeout(() => {
        if(this.$refs.multiselect) {
          this.$refs.multiselect.addEventListener('dblclick', this.tagClick)
        }
      }, 20)
    }
  },
  beforeDestroy() {
    if(this.dblclick) {
      if(this.$refs.multiselect) {
        // document.body.removeEventListener('dblclick', this.tagClick)
        this.$refs.multiselect.removeEventListener('dblclick', this.tagClick)
      }
    }
  }
}
</script>
