<template>
  <div>
    <b-button class="btn-collapse"
              :aria-expanded="expandedCollapse === 'application' ? 'true' : 'false'"
              @click="switchCollapse('application')">
      {{ $t('applications_and_appeals') }}
    </b-button>
<!--    <b-collapse :visible="expandedCollapse === 'application'">-->
<!--      <ApplicationsFunnelTab v-if="expandedCollapse === 'application'"/>-->
<!--    </b-collapse>-->

    <b-button class="btn-collapse mt-3"
              :aria-expanded="expandedCollapse === 'plan' ? 'true' : 'false'"
              @click="switchCollapse('plan')">
      {{ $t('plan_treatments') }}
    </b-button>
<!--    <b-collapse :visible="expandedCollapse === 'plan'">-->
<!--      <PlanTreatmentsFunnelTab v-if="expandedCollapse === 'plan'"/>-->
<!--    </b-collapse>-->

    <b-button class="btn-collapse mt-3"
              :aria-expanded="expandedCollapse === 'visit' ? 'true' : 'false'"
              @click="switchCollapse('visit')">
      {{ $t('visit_confirm') }}
    </b-button>
    <b-collapse :visible="expandedCollapse === 'visit'">
      <VisitConfirmFunnelTab v-if="expandedCollapse === 'visit'"/>
    </b-collapse>

  </div>
</template>

<script>

import { mapState } from "vuex"
import options from "@/data/crm/task"
import VisitConfirmFunnelTab from "@/components/parts/crm/VisitConfirmFunnelTab"

export default {
  name: "CRMSalesFunnel",
  components: {
    VisitConfirmFunnelTab,
  },
  metaInfo() {
    return {
      title: this.$t('sales_funnel'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    }),
  },
  data() {
    return {
      options: options,
      // expandedCollapse: 'application',
      expandedCollapse: 'plan',
    }
  },
  methods: {
    switchCollapse(view) {
      this.$router.replace({'query': null }).catch(() => {})
      if(this.expandedCollapse === view) {
        this.expandedCollapse = null
        return
      }
      this.$store.commit("setViewModel", null)
      this.expandedCollapse = view
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
