<template>
  <DataTable :options="options"
             :params="{ client_id: patient.id, branch_id: branch }"
             module="patients"/>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/patients/advance"
import { mapState } from "vuex"

export default {
  name: "PatientDepositTransactions",
  metaInfo() {
    return {
      title: this.$t("deposit"),
      meta: []
    }
  },
  components: { DataTable },
  data() {
    return {
      options: options
    }
  },
  computed: {
    ...mapState({
      patient:  state => state.patient.patient,
      branch: state => state.auth.branch,
    })
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    this.$store.commit('setAdditionalParams', { client_id: this.patient.id })
  },
  beforeDestroy() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>

<style scoped>

</style>
