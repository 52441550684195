import { render, staticRenderFns } from "./ServicesDatatableTDCategory.vue?vue&type=template&id=51f67e32&scoped=true&"
import script from "./ServicesDatatableTDCategory.vue?vue&type=script&lang=js&"
export * from "./ServicesDatatableTDCategory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f67e32",
  null
  
)

export default component.exports