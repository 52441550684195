<template>
  <span>
  <a @click="toggleDoctor"
     :title="object.blocked == 0 ? $t('block') : $t('activate')"
     v-b-tooltip.hover
     class="data-table-action">
    <BlockSvg v-if="object.blocked == 0"/>
    <ActivateSvg class="svg-light" v-if="object.blocked == 1"/>
    <span class="action-button-mob-title">{{ object.blocked == 0 ? $t('block') : $t('activate') }}</span>
  </a>
    </span>
</template>

<script>

import BlockSvg from '@/assets/svg/general/block.svg'
import ActivateSvg from '@/assets/svg/form/tick.svg'
import { EloquentService } from "@/services/api.service"
import { doctorAdapter } from '@/adapters/staff/doctor'

export default {
  name: "StaffDatatableDoctorActions",
  components: {
    BlockSvg,
    ActivateSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  methods: {
    toggleDoctor() {
      let formData = new FormData()
      let data = doctorAdapter(this.object)
      delete data.full
      data.blocked = this.object.blocked == 1 ? 0 : 1
      formData = this.appendFormdata(formData, data)
      EloquentService.update('administrator', this.object.id, formData).then(() => {
        this.$store.commit('incrementDataTableCounter')
      })
    }
  }
}
</script>

<style scoped>

</style>
