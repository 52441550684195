import { render, staticRenderFns } from "./SuperAdminClinics.vue?vue&type=template&id=71b31371&scoped=true&"
import script from "./SuperAdminClinics.vue?vue&type=script&lang=js&"
export * from "./SuperAdminClinics.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71b31371",
  null
  
)

export default component.exports