<template>
  <div>
    <SettingsIntegrations/>
  </div>
</template>

<script>

import SettingsIntegrations from "@/components/pages/settings/SettingsIntegrations"

export default {
  name: "SuperAdminClinicIntegrations",
  components: {
    SettingsIntegrations 
    
  },
  metaInfo() {
    return {
      title: this.$t('integrations'),
      meta: []
    }
  },
  // computed: {
  //   ...mapState({
  //     clinic: state => state.auth.clinic
  //   })
  // },
  // data() {
  //   return {}
  // },
}
</script>