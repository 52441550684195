<template>
  <div class="form-group position-relative">
    <label class="form-label" v-if="showlabel && label">
      <span>{{ label }}</span>
      <span v-if="required"> *</span>
    </label>
    <div class="position-relative">
      <input v-if="multiple"
             class="form-control bg-white overflow-ellipsis"
             v-model="valueText"
             @focus="focus"
             readonly
      />
      <the-mask mask="##.##.####"
                v-else
                class="form-control"
                v-model="contentStringMasked"
                @keyup.native="manualInput"
                @focus.native="focus"
                :readonly="readonly"
                placeholder="__.__.____"
                :masked="true"/>
      <CalendarSvg class="position-absolute calendar-icon" v-if="calendarIcon"/>
    </div>
    <DatePicker :locale="{ id: 'ru', firstDayOfWeek: 2, masks: { L: 'DD.MM.YYYY' } }"
                v-if="show"
                v-click-outside="clickedOutside"
                class="date-picker"
                :class="{ labeled: !!label }"
                :readonly="readonly"
                @input="handleInput"
                @dayclick="dayClicked"
                v-model="content"
                :attributes='attrs'/>
    <ValidationError class="mt-1" v-if="showErrors" :validationId="validationId"/>
  </div>
</template>

<script>

import moment from 'moment'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import ValidationError from "@/components/form/ValidationError"
import CalendarSvg from '@/assets/svg/calendar/calendar.svg'
import { TheMask } from 'vue-the-mask'

export default {
  name: "DateGroup",
  components: {
    ValidationError,
    DatePicker,
    CalendarSvg,
    TheMask
  },
  data() {
    return {
      show: false,
      date: new Date(),
      content: new Date(moment(this.value)),
      timer: null,
      contentString: this.value,
      contentStringMasked: this.value ? moment(this.value).format('DD.MM.YYYY') : null,
    }
  },
  props: {
    label: {},
    value: {},
    showErrors: {
      type: Boolean,
      default: false
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    attrs: {
      type: Array,
      default: () => [{}]
    },
    showlabel: {
      type: Boolean,
      default: true
    },
    calendarIcon: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    validationId: {},
    required: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    valueText: {
      type: String,
      default: null
    }
  },
  mounted() {
  },
  methods: {
    handleInput(e) {
      let val = moment(e).format('YYYY-MM-DD')
      this.contentString = val
      if(this.closeOnClick) this.show = false
    },
    dayClicked(e) {
      this.$emit('dayClicked', e.id)
    },
    focus() {
      if(this.readonly) return
      this.show = true
      this.timer = setTimeout(() => {
        this.timer = null
      }, 300)
    },
    clickedOutside() {
      if(this.timer) return
      this.show = false
    },
    manualInput() {
      if(!this.contentStringMasked || this.contentStringMasked.length === 0) {
        this.contentString = null
        return
      }
      if(this.contentStringMasked.length === 10) {
        this.contentString = moment(this.contentStringMasked, 'DD.MM.YYYY').format('YYYY-MM-DD')
        this.show = false
      }
    },
    refresh() {
      this.contentString = this.value
      this.contentStringMasked = this.value ? moment(this.value).format('DD.MM.YYYY') : null
    }
  },
  watch: {
    contentString(val) {
      this.$emit('input', val === 'Invalid date' ? null : val)
      this.content = new Date(moment(val))
      this.contentStringMasked = moment(val).format('DD.MM.YYYY')
    },
    value(val) {
      if(val != this.contentString) this.refresh()
    }
  }
}
</script>

<style scoped lang="scss">
.date-picker {
  position: absolute;
  top: 45px;
  left: 0;
  &.labeled {
    top: 73px;
  }
}
.right-popup {
  .date-picker {
    left: auto;
    right: 0;
  }
}
.calendar-icon {
  top: 15px;
  right: 15px;
  width: 16px;
  height: 16px;
  pointer-events: none;
}
.overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 50px;
}
@media screen and (max-width: 768px) {
  .calendar-icon {
    top: 10px;
    right: 13px;
    width: 12px;
    height: 12px;
  }
  .date-picker {
    top: 31px;
    &.labeled {
      top: 51px;
    }
  }
}
</style>
