<template>
  <b-dropdown right variant="link" no-caret>
    <template #button-content>
      <a class="btn btn-themed warehouse-filter-btn gray d-md-inline-flex align-items-center position-relative">
        <BoxSvg class="svg-gray mr-md-2"/>
        <span>{{ $t('warehouse') }}</span>
        <span class="notification-bubble" v-if="selected.length > 0">{{ selected.length }}</span>
      </a>
    </template>
    <div class="pt-3">
      <template v-for="w in warehouses">
        <div :key="`warehouse-filter-opt-${w.id}`" class="px-3" style="width: 200px;">
          <CheckboxGroup :label="w.title"
                         :checkboxvalue="w.id"
                         :fid="`checkbox-w-${w.id}`"
                         v-model="selected"/>
        </div>
      </template>
    </div>
  </b-dropdown>
</template>

<script>

import BoxSvg from '@/assets/svg/sidebar/box.svg'
import {mapActions, mapState} from "vuex"
import CheckboxGroup from "@/components/form/CheckboxGroup"

export default {
  name: "WarehouseHeaderWarehouseFilter",
  components: {
    BoxSvg,
    CheckboxGroup
  },
  methods: {
    ...mapActions(['getWarehouses']),
    parseRoute() {
      if(this.$route.query.filters) {
        let js = JSON.parse(this.$route.query.filters)
        if(js.warehouse_id) {
          let f = js.warehouse_id
          if(f.type === 'contains') {
            this.selected = f.condition
          } else {
            if(f.type === 'equals') {
              this.selected = [f.condition]
            }
          }
        } else {
          this.selected = []
        }
      }
      this.$nextTick(() => {
        this.watcher = true
      })
    }
  },
  computed: {
    ...mapState({
      warehouses: state => state.datatable.warehouses
    }),
  },
  data() {
    return {
      selected: [],
      watcher: false
    }
  },
  mounted() {
    this.getWarehouses()
    this.parseRoute()
  },
  watch: {
    async $route() {
      if(this.watcher) {
        this.watcher = false
        await this.$nextTick()
        this.parseRoute()
      }
    },
    selected(val) {
      if(!this.watcher) return
      let arr = []
      for(const v in val) {
        arr.push(val[v])
      }
      const currentFilters = this.$route.query.filters ? JSON.parse(this.$route.query.filters) : {}
      if(arr.length) {
        currentFilters.warehouse_id = {
          id:         'warehouse_id',
          condition:  arr,
          type:       'contains',
        }
      } else {
        delete currentFilters.warehouse_id
      }
      this.$store.commit('setFilters', currentFilters)
      const query = { filters: JSON.stringify(currentFilters) }
      if(query.filters && this.$route.query && this.$route.query.filters && query.filters == this.$route.query.filters) {
        return
      }
      this.$router.push({ path: this.$route.path, query }).catch(()=>{})
    }
  }
}
</script>

<style scoped lang="scss">
.notification-bubble {
  right: 14px;
}
.warehouse-filter-btn {
  svg {
    height: 16px;
    width: 16px;
  }
}
</style>
