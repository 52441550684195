export default {
    add_to_egisz: "Додати до ЕГИСЗ",
    download_all: "Завантажити все",
    egisz: "ЕГИСЗ",
    main_doctor: "Головний лікар",
    patient_rejected_to_send_to_egisz: "Пацієнт відмовився відправляти дані в ЕГИСЗ",
    requires_attention: "Потребує уваги",
    representative_relation: "Ким є представник пацієнту",
    send_to_egisz: "Відправити в ЕГИСЗ",
    sent_to_egisz: "Відправлено до ЕГИСЗ",
    sign: "Підписати",
    sign_all: "Підписати все",
    signed_by_doctor: "Підписано лікарем",
    signed_by_main_doctor: "Підписано глав. лікарем",
    to_egisz_page: "На сторінку ЕГИСЗ",
    to_rework: "На доопрацювання",
    to_sign: "На підпис",
}
