<template>
  <span class="">
    <template v-for="(p, pind) in object.payments">
      <span :key="`object-cash-${object.id}-${pind}`" v-if="p.type == 'CASH'">{{ (p.sell_sum / 100) | formatCurrency }}</span>
    </template>
    <span v-if="object.payments.length == 0">0</span>
  </span>
</template>

<script>

export default {
  name: "CashdeskDatatableTDXReportCash",
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
