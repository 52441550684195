<template>
  <div class="alert alert-warning">{{  $t('in_development')  }}</div>
</template>

<script>

export default {
  name: "PatientClientService",
  metaInfo() {
    return {
      title: this.$t('client_service'),
      meta: []
    }
  },
}
</script>

<style scoped>

</style>
