<template>
  <section id="users" class="users">
    <transition name="fade" mode="out-in" >
      <div class="section-top-row" >
        <div class="row row-narrow align-items-center no-print position-relative">
          <div class="col order-md-0 order-0">
            <h1>{{ $t('users') }}</h1>
          </div>
          <div class="col-auto order-md-5 order-2 table-view-navigator">
            <AddObject module="superadmin" svg-passed="UserSvg"/>
          </div>
        </div>
      </div>
    </transition>
    <div>
      <DataTable :options="options"
                 module="superadmin"/>
    </div>
  </section>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/superadmin/super_user"
import { mapState } from "vuex"
import AddObject from "@/components/parts/datatable/AddObject"

export default {
  name: "SuperAdminUsers",
  components: {
    AddObject,
    DataTable
  },
  metaInfo() {
    return {
      title: this.$t('users'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
