const TOKEN_KEY = "calendar-settings"
import { ViewRange, ViewType } from "@/components/pages/calendar/calendar/const/CalendarConst";

export const getCalendarSettings = () => {
  let def = {
    curView: ViewType.DOCTOR,
    curRange: ViewRange.WEEK,
    showDoctorColor: true,
    showServiceColor: true,
    showStatusColor: true,
    showOverflowScreen: true,
    showClientTags: true,
    showPlannedServices: false,
    fillDoctorColor: false,
    showRecordTagColor: false,
    hideDoctorsWithoutSchedule: false,
    hideChairsWithoutSchedule: false,
    calendarDays: 1,
  }
  return JSON.parse(window.localStorage.getItem(TOKEN_KEY) ?? JSON.stringify(def))
  // return JSON.parse(JSON.stringify(def))
}

export const saveCalendarSettings = (settings) => {
  window.localStorage.setItem(TOKEN_KEY, JSON.stringify(settings))
}

export const saveCalendarSetting = (key, value) => {
  let settings = getCalendarSettings()
  settings[key] = value
  saveCalendarSettings(settings)
}

export const getSelectedDoctors = () => {
  let doctors = window.localStorage.getItem('selectedDoctors')
  let r = doctors ? JSON.parse(doctors) : []
  return r
}

export const setSelectedDoctors = (doctors) => {
  window.localStorage.setItem('selectedDoctors', JSON.stringify(doctors))
}

export default { getCalendarSettings, saveCalendarSetting, saveCalendarSettings, setSelectedDoctors, getSelectedDoctors }
