<template>
  <span v-if="object.balance">
   {{ (object.balance.cash_sales / 100) | formatCurrency }}
  </span>
</template>

<script>

export default {
  name: "CashdeskDatatableTDSalesCash",
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
}
</script>
