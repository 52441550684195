<template>
  <section id="expenses" class="expenses">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print position-relative">
        <div class="col order-md-0 order-0">
          <h1>{{ $t('expenses') }}</h1>
<!--          <h1 v-else>-->
<!--        <span class="d-md-inline d-none">-->
<!--        <router-link :to="{ name: 'AccountingMain' }">{{ $t('accounting') }}</router-link>-->
<!--          / </span> <span class="text-capitalize" v-if="month">{{ month.date | formatDate('MMMM, YYYY') }}</span>-->
<!--          </h1>-->
        </div>
        <div class="col-auto table-view-navigator order-md-4 order-1">
          <DataTableExcel/>
        </div>
        <div class="col-auto order-md-5 order-2 table-view-navigator">
          <AddObject module="expenses"/>
        </div>
      </div>
    </div>
    <DataTable :options="options"
               module="expenses"/>
  </section>
</template>

<script>

import AddObject from "@/components/parts/datatable/AddObject"
import DataTable from "@/components/parts/general/DataTable"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import options from "@/data/expenses/other_expense"
import { mapState } from "vuex"

export default {
  name: "Expenses",
  components: {
    AddObject,
    DataTableExcel,
    DataTable
  },
  metaInfo() {
    return {
      title: this.$t('expenses'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      month: state => state.accounting.accountingmonth
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  },
  beforeDestroy() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>

<style scoped>

</style>
