<template>
  <div>
    {{ averageCheque | formatNumber(true) }}
  </div>
</template>

<script>

export default {
  name: "ReportStatDoctorsDatatableHtmlChequeTotal",
  components: {
  },
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    },
  },
  computed: {
      averageCheque() {
        return this.object.visits > 0 ? Math.round(this.object.revenue / this.object.visits) : 0
      }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
