export default {
    accountingmonth: "Бухгалтерский месяц",
    accrual: "Начисление",
    accruals: "Начисления",
    AQSI_account: "AQSI счет",
    balance_end: "Баланс на конец периода",
    balance_start: "Баланс на начало периода",
    bonus_movement: "Движение бонусов",
    cash_movements: "Движение ДС",
    cash_received: "Получено ДС",
    closed: "Закрыт",
    communal: "Коммунальные платежи",
    deduction: "Удержание",
    deposit_balance: "Остаток депозита",
    deposit_balance_end: "Остаток депозита на конец периода",
    deposit_balance_start: "Остаток депозита на начало периода",
    deposit_movement: "Движения по депозиту",
    employee: "Сотрудник",
    expense: "Расход",
    left_to_pay: "Осталось выплатить",
    manual_adjustments: "Ручные корректировки",
    materials_expense: "Расходные материалы",
    open: "Открыть",
    other_expense: "Прочие расходы",
    other_income: "Прочие доходы",
    pay_out: "Выплатить",
    payment_sum: "Сумма для выплаты",
    profit: "Доход",
    recalculate: "Пересчитать",
    rent: "Аренда",
    salaries: "Зарплаты",
    unclosed: "Не закрыт",
}
