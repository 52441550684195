<template>
  <div v-if="loaded">
    <b-row>
      <b-col md="4">
        <h5 class="mb-4">Визит</h5>
        <ServiceMultiSelect label="Исход заболевания"
                            model="egiszDictionary"
                            title="title"
                            valueId="egisz_id"
                            :onlyId="true"
                            :multiple="false"
                            :dblclick="false"
                            :dbsearch="false"
                            :readonly="disabled"
                            :prepopulatedOptions="dictionaries.getCaseResults"
                            v-model="record.result_id"/>
        <ServiceMultiSelect label="Цель посещения"
                            model="egiszDictionary"
                            title="title"
                            valueId="egisz_id"
                            :onlyId="true"
                            :multiple="false"
                            :dblclick="false"
                            :dbsearch="false"
                            :readonly="disabled"
                            :prepopulatedOptions="dictionaries.getVisitPurposes"
                            v-model="record.purpose_id"/>
        <AutosizeTextArea v-model="record.epikriz"
                          :readonly="disabled"
                          label="Текст заключения из эпикриза"/>
        <AutosizeTextArea v-model="record.diagnosis"
                          :readonly="disabled"
                          label="Комментарий к диагнозу"/>
        <ServiceMultiSelect label="МКБ Код"
                            model="egiszDictionary"
                            title="title"
                            valueId="egisz_id"
                            :onlyId="true"
                            :multiple="false"
                            :dblclick="false"
                            :dbsearch="false"
                            :readonly="disabled"
                            :prepopulatedOptions="dictionaries.getMKBCodes"
                            v-model="record.mkb"/>
        <ServiceMultiSelect label="Источник финансирования"
                            model="egiszDictionary"
                            title="title"
                            valueId="egisz_id"
                            :onlyId="true"
                            :multiple="false"
                            :dblclick="false"
                            :dbsearch="false"
                            :readonly="disabled"
                            :prepopulatedOptions="dictionaries.getPaymentTypes"
                            v-model="record.paytype_id"/>
      </b-col>
      <b-col md="4">
        <h5 class="mb-4">Врач / пациент</h5>
        <InputGroup v-model="record.snils_doctor"
                    :readonly="disabled"
                    label="СНИЛС доктора"/>
        <ServiceMultiSelect label="Специальность врача"
                            model="egiszDictionary"
                            title="title"
                            valueId="egisz_id"
                            :onlyId="true"
                            :multiple="false"
                            :dblclick="false"
                            :dbsearch="false"
                            :readonly="disabled"
                            :prepopulatedOptions="dictionaries.getSpecialties"
                            v-model="record.specialty_id"/>
        <ServiceMultiSelect label="Должность врача"
                            model="egiszDictionary"
                            title="title"
                            valueId="egisz_id"
                            :onlyId="true"
                            :multiple="false"
                            :dblclick="false"
                            :dbsearch="false"
                            :readonly="disabled"
                            :prepopulatedOptions="dictionaries.getDoctorPositions"
                            v-model="record.position_id"/>
        <InputGroup v-model="record.snils_patient"
                    :readonly="disabled"
                    label="СНИЛС пациента"/>
      </b-col>
      <b-col md="4">
        <h5 class="mb-4">В случае наличия законного представителя / опекуна пациента:</h5>
        <ServiceMultiSelect label="Официальный представитель пациента"
                            model="patient"
                            title="full_name"
                            :prepopulated-options="record.representative_user ? [record.representative_user] : null"
                            :onlyId="true"
                            :multiple="false"
                            :dblclick="false"
                            :dbsearch="true"
                            :readonly="disabled"
                            v-model="record.representative_id"/>
        <ServiceMultiSelect label="Отношение к пациенту"
                            model="egiszDictionary"
                            title="title"
                            valueId="egisz_id"
                            :onlyId="true"
                            :multiple="false"
                            :dblclick="false"
                            :dbsearch="false"
                            :readonly="disabled"
                            :prepopulatedOptions="dictionaries.getGuardianRelationships"
                            v-model="record.representative_type"/>
        <AutosizeTextArea v-model="record.representative_document"
                          :readonly="disabled"
                          label="Реквизиты документа, удостоверяющего право представлять пациента"/>
      </b-col>
    </b-row>
    <div v-if="disabled"
         class="alert text-center mt-4"
         :class="{
          'alert-warning': record.status !== 'sent_to_egisz',
          'alert-info': record.status === 'sent_to_egisz'
        }">
      {{ $t(record.status) }}
    </div>
    <div class="text-right" v-if="!disabled">
      <button :disabled="loading"
              class="btn-themed btn-themed-squared mt-4"
              @click="save">
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import InputGroup from "@/components/form/InputGroup.vue"
import { CalendarService, EloquentService } from "@/services/api.service"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"
import AutosizeTextArea from "@/components/form/AutosizeTextArea.vue"

export default {
  name: "EGISZForm",
  components: {
    AutosizeTextArea,
    ServiceMultiSelect,
    InputGroup,
  },
  props: {
    recordId: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loaded: false,
      loading: false,
      dictionaries: {},
      record: null
    }
  },
  async mounted() {
    await this.loadDictionaries()
    await this.loadEGISZRecord()
    this.loaded = true
  },
  methods: {
    async loadDictionaries() {
      const res = await CalendarService.getEGISZDictionaries()
      this.dictionaries = res.data
    },
    async loadEGISZRecord() {
      const res = await EloquentService.show('egiszRecord', this.recordId)
      this.record = res.data
    },
    async save() {
      this.loading = true
      try {
        let formData = new FormData()
        formData = this.appendFormdata(formData, this.record)
        const res = await EloquentService.update('egiszRecord', this.record.id, formData)
        if(res.data.id) {
          this.$noty.info(this.$t('success_message'))
          this.$emit('success')
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
