export const doctorAdapter =
    ({
         id,
         fname,
         mname,
         lname,
         color,
         email,
         gender,
         mphone,
         mphone2,
         date_of_birth,
         percent,
         rate,
         rate_per_day,
         photo,
         image,
         doctors_profession_id,
         doctors_second_profession_id,
         doctors_third_profession_id,
         userFunctions,
         username,
         password,
         password_confirmation,
         chairs,
         branches,
         prices,
         warehouse_id,
         branch_id,
         role_id,
         clinic_id,
         sendPassword,
         egisz_position,
         egisz_specialty,
         snils,
        fields
     }) => ({
        id: id ?? null,
        fname: fname ?? null,
        mname: mname ?? null,
        lname: lname ?? null,
        color: color ?? null,
        email: email ?? null,
        gender: gender ?? null,
        mphone: mphone ?? null,
        mphone2: mphone2 ?? null,
        date_of_birth: date_of_birth ?? null,
        percent: percent ?? null,
        rate: rate ?? null,
        rate_per_day: rate_per_day ?? null,
        snils: snils ?? null,
        photo: photo ?? null,
        image: image ?? null,
        doctors_profession_id: doctors_profession_id ?? null,
        doctors_second_profession_id: doctors_second_profession_id ?? null,
        doctors_third_profession_id: doctors_third_profession_id ?? null,
        userFunctions: userFunctions ?? null,
        username: username ?? null,
        password: password ?? null,
        password_confirmation: password_confirmation ?? null,
        chairs: minimizeChairs(chairs),
        branches: minimizeChairs(branches),
        prices: minimizeChairs(prices),
        warehouse_id: warehouse_id ?? null,
        branch_id: branch_id ?? null,
        full: role_id != 2,
        clinic_id: clinic_id ? clinic_id : null,
        egisz_position: egisz_position ?? null,
        egisz_specialty: egisz_specialty ?? null,
        sendPassword: sendPassword ? sendPassword : false,
        fields: fields ? fields.map(field => field.id) : null
    })

function minimizeChairs(chairs) {
    let arr = chairs ? [...new Set(chairs.map(({id}) => id))] : []
    return arr.filter(item => item !== undefined)
}

