<template>
  <b-modal :id="modalId"
           size="md"
           @show="onShow"
           @hide="onHide"
           :title="$t('plan_notification')"
           centered>
    <DateTimeGroup :label="$t('send_time')"
                   v-model="form.datetime"/>
    <ValidationError validationId="datetime"/>
    <label class="form-label">{{ $t('message') }}</label>
    <AutosizeTextArea
        :label="$t('message')"
        v-model="form.message"
        :showlabel="false"
        :dictation="true"
        :minH="46"
    />
    <ValidationError validationId="message"/>
    <div>
      <template v-for="n in notificationChannels">
        <CheckboxGroup class="d-inline-block mr-4"
                       :fid="`tag-${n.id}`"
                       :key="`ch-${n.id}`"
                       :checkboxvalue="n.id"
                       v-model="form.notification_channel"
                       :label="n.title"/>
      </template>
      <ValidationError validationId="notification_channel"/>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="ml-auto cancel d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('close') }}
        </b-button>

        <button :disabled="loading || !form.message"
                @click="send"
                class="ml-3 btn-themed align-middle d-inline-block btn-themed-squared">
          {{ $t('send') }}
        </button>

      </div>
    </template>
  </b-modal>
</template>

<script>

import {mapState} from "vuex"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import DateTimeGroup from "@/components/form/DateTimeGroup"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import {MarketingService} from "@/services/api.service"
import ValidationError from "@/components/form/ValidationError"

export default {
  name: "DeferredMessageModal",
  components: {
    ValidationError,
    DateTimeGroup,
    AutosizeTextArea,
    CheckboxGroup
  },
  computed: {
    ...mapState({
      // clinic: state => state.auth.clinic,
      // user: state => state.auth.user,
      patient: state => state.patient.patient,
    })
  },
  data() {
    return {
      form: {
        datetime: null,
        message: null,
        notification_channel: null
      },
      loading: false,
      notificationChannels: [],
      modalId: 'deferred-message-modal'
    }
  },
  methods: {
    onShow() {
      this.getNotificationChannels()
    },
    onHide() {
      this.$store.commit('cleanValidationErrors')
      this.form = {
        datetime: null,
        message: null,
        notification_channel: null
      }
    },
    getNotificationChannels() {
      MarketingService.getNotificationChannels().then(res => {
        this.notificationChannels = res.data
      })
    },
    async send() {
      this.loading = true
      try {
        let data = this.copyObject(this.form)
        data.patient_id = this.patient.id
        await MarketingService.sendDeferredMessage(data)
        this.$noty.info(this.$t('success_message'))
        this.$bvModal.hide(this.modalId)
      } catch (e) {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>

</style>
