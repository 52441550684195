import Vue from "vue"
import { doctorAdapter } from "@/adapters/staff/doctor"

import options from "@/data/staff/doctor"
let assistant_options = Vue.prototype.copyObject(options)
assistant_options.model = 'assistant'
assistant_options.fields.forEach(item => {
    if(['warehouse_id','doctors_profession_id','color'].includes(item.id)) { delete item.form }
    if(['full_name'].includes(item.id)) { item.tdcomponent = 'StaffDatatableTDStaffName' }
    if(item.form && item.form.type === 'separator') { delete item.form }
})
assistant_options.fields.splice(12, 0 , {
    id: 'profession_string',
    title: 'profession',
    table: false,
    form: {
        classes: 'col-md-4',
        readonly: true,
        type: "text"
    },
})
assistant_options.fields.push({
    id: 'rate_per_day',
    title: 'rate_per_day',
    table: false,
    form: {
        type: "text",
        classes: 'col-md-4',
    },
},)
delete assistant_options.actionscomponent
assistant_options.adapter = doctorAdapter
export default assistant_options