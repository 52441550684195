export default {
    after_scanning_qr: "Після сканування qr коду",
    application_reason: "Причина звернення",
    are_you_sure_you_want_to_delete_lead: "Ви впевнені, що хочете видалити заявку?",
    check_status: 'Перевірити статус',
    click_here: "натисніть сюди",
    connect: "Підключити",
    connection_is_right_can_be_used: "Підключення вірно - можна використовувати",
    'crm-form': "CRM-форма",
    forms: "Форми",
    lead: "Заявка",
    not_reached: "Чи не додзвонилися",
    site_form: "Форма",
    stale: "Неліквід",
    telegram: "Telegram",
    thinking: "Думає",
    unpaid_message: 'У ваш обліковий запис прострочена оплата. Будь ласка, поповніть баланс або зверніться в тих підтримку.',
    urgent: "Терміново",
    whatsapp: "Whatsapp",
    whatsapp_qr_instructions: "На телефоні в налаштуваннях whatsapp виберіть 'прив'язати пристрій' і вважайте qr код",
}
