export default {
    absent: "Отсутствует",
    abonplata: "Абонентская плата",
    accountant_fio: "ФИО главного бухгалтера",
    accrue_bonuses_on_bonus_payments: "Начислять бонусы на оплату бонусами",
    activate_bonus_system: "Активировать бонусную систему",
    acquiring_fee: "% эквайринга",
    add_field: "Добавить направление",
    add_user: "Добавить пользователя",
    additional_doctors: "Дополнительно врачей",
    additional_modules: "Дополнительные модули",
    additional_space: "Дополнительное место",
    admins_limit: "Лимит администраторов",
    Advance: "Депозит",
    advance_payment: "Единоразовый платеж",
    after_discounts: "После учета скидок",
    after_technic_deduction: "После вычета техника",
    after_visit: "Напоминание после визита",
    all_visits: "Все записи",
    api: "API",
    api_token: "API токен",
    are_you_sure_you_want_to_delete_branch: "Вы уверены, что хотите удалить филиал?",
    are_you_sure_you_want_to_delete_company: "Вы уверены, что хотите удалить компанию?",
    are_you_sure_you_want_to_delete_notification: "Вы уверены, что хотите удалить уведомление?",
    are_you_sure_you_want_to_delete_source: "Вы уверены, что хотите удалить источник?",
    are_you_sure_you_want_to_delete_tag: "Вы уверены, что хотите удалить метку?",
    ask_for_more: "Запросить еще",
    assistants_connected: "Подключен модуль Ассистенты",
    available_disk_space: "Доступное место на диске, МБ (0 - безлимит)",
    automatic_conflict_task_creation: "Автоматическое создание задач при конфликте расписания",
    before_discounts: "До учета скидок",
    before_technic_deduction: "До вычета техника",
    before_visit: "Напоминание перед визитом",
    billing: "Биллинг",
    billing_payment: "Оплата",
    bonus_accrued: "Начислено бонусов",
    bonus_adjustment: "Корректировка бонусов",
    bonus_payment: "Оплата бонусом",
    bonus_percent_can_be_used: "Максимальная доля продажи, которую можно оплатить бонусами",
    bonus_percent_of_cheque: "Процент бонусов от продажи",
    bonus_system: "Бонусная система",
    bonus: "Бонус",
    bonuses: "Бонусы",
    branch_address: "Адрес филиала",
    branch_contacts: "Контакты филиала",
    branches: "Филиалы",
    branches_connected: "Подключен платный модуль Филиалы",
    by_chairs: "По креслам",
    calendar_text_color: "Цвет текста в календаре",
    calendar_view_by_default: "Вид календаря по умолчанию",
    change_tariff: "Сменить тариф",
    ClientNote: "Примечание",
    client_tag: "Метка",
    clinic: "Клиника",
    clinics: "Клиники",
    clinic_data: "Данные клиники",
    clinic_inn: "ИНН клиники",
    clinic_is_blocked: "Клиника заблокирована",
    clinic_name: "Название клиники",
    close: "Закрыть",
    cloud_space: "Место в облаке",
    city: "Город",
    communications: "Коммуникации",
    connected_successfully: "Подключено успешно",
    connection_failed: "Ошибка связи",
    control_system: "Система контроля",
    country: "Страна",
    crm_application_created: "Создание заявки CRM",
    crm_connected: "Подключен платный модуль CRM",
    currency_rates: "Курсы валют",
    data: "Данные",
    date_of_registration: "Дата регистрации",
    day: "День",
    deduct_acquiring_from_salary: "Вычитать эквайринг из зарплат",
    deleted: "Удаление",
    demo: "Демо",
    director_fio: "ФИО директора",
    disk_space_limit: "Лимит дискового места (Мб)",
    do_not_send_notifications_if_period_passed: "Не отправлять напоминание о визите, если срок уже прошёл",
    Doctor: "Врач",
    DoctorAssistant: "Ассистент",
    doctors_limit: "Лимит врачей",
    error_code: "Код ошибки",
    finance: "Финансы",
    free: "Свободно",
    free_day: "Бесплатный день",
    general: "Общее",
    has_additional_doctors: "Есть дополнительные врачи",
    higher_number_higher_priority: "Чем выше номер, тем выше опция в списке",
    hour: "час",
    hours: "часы",
    in_development: "В разработке",
    insurance_company: "Страховая компания",
    integrations: "Интеграции",
    interface: "Интерфейс",
    ioka: "Ioka",
    jetapi_connected: "Подключен модуль JET API",
    'kit materials sync': "Привязка материалов к комплекту",
    last_login: "Последний вход",
    license: "Лицензия",
    license_issue_body: "Кем выдана лицензия",
    license_issue_date: "Дата выдачи лицензии",
    license_num: "Лицензия #",
    location_on_map: "Местоположение на карте",
    logs: "Логи",
    logs_journal: "Журнал действий",
    logo: "Логотип",
    logout_interval_timeout: "Интервал выхода из программы при отсутствии активности (для учётных записей администраторов)",
    max_doctor_amount: "Максимальное кол-во докторов",
    Manager: "Администратор",
    manual: "Ручная",
    min: "минут",
    min_symbols: "Минимум символов",
    minute: "минута",
    minutes: "минут",
    model_id: "Id модели",
    model_type: "Тип модели",
    month: "Месяц",
    monthly_payment: "Месячный платеж",
    months_to_consider_patient_lost: "Кол-во месяцев, после которых пациент считается утерянным",
    more_than_one_day: "Более одного дня",
    more_than_one_visit: "Более одного визита",
    next_payment: "Следующий платеж",
    not_connected: "Не подключено",
    notification_channel: "Тип доставки",
    notification_setting: "Уведомление",
    NotificationSetting: "Уведомление",
    notification_type: "Тип уведомления",
    notifications: "Уведомления",
    occupied: "Занято",
    ogrn: "ОГРН",
    oid_ministry_rf: "OID Министерства здравоохранения РФ",
    on: "Включено",
    on_pay_page: "На страницу оплаты",
    online_booking: "Онлайн-запись",
    online_booking_activate: "Активировать онлайн-запись",
    online_booking_categories: "Отображать категории услуг",
    online_booking_feedbacks: "Активировать функционал отзывов",
    online_booking_map: "Отображать карту филиала",
    online_booking_max_days: "Максимальный период записи в днях",
    online_booking_time: "Бронировать время в календаре или нет",
    only_after_payment_received: "Только при получении оплаты от пациента",
    paid_functionality: "Оплачиваемый функционал",
    paid_till: "Оплачено до",
    Patient: "Пациент",
    patient_conditions: "Состояния пациентов",
    patient_sources: "Источники пациентов",
    patients_import: "Импорт пациентов",
    pay: "Оплатить",
    Payment: "Оплата",
    payment_id: "Id оплаты",
    pay_methods: "Способы оплаты",
    pay_methods_cash: "Способы оплаты, отображающиеся как наличные",
    pay_system: "Платежная система",
    percent_accrual_for_administrator: "Начисление процента администраторам",
    percent_accrual_for_personnel: "Начисление процента персоналу",
    permanent_patients_hint: "Считать ли пациента постоянным при а) наличии у него минимум двух визитов со статусом пришел или услугами в счете, даже в течение одного дня  б) наличии у него минимум двух разных дней с визитами",
    period_by_default: "Период по умолчанию",
    plan_treatment_text: "Пометка в плане лечения",
    PlanTreatment: "План лечения",
    popup: "Попап",
    price_per_additional_doctor: "Цена за дополнительного врача",
    price_per_additional_space: "Цена за дополнительное место (100 Мб)",
    program_language: "Язык программы",
    prolong: "Продлить",
    purchase: "Покупка",
    record_tag: "Ярлык для визитов",
    record_tags: "Ярлыки для визитов",
    record_timing_enabled: "Система контроля 'Начало и завершения визитов'",
    Record: "Запись",
    RecordTooth: "Зуб",
    recipient: "Получатель",
    required_fields_to_close_visit: "Обязательные поля для закрытия визита",
    requires_payment: "Требует доплаты",
    right: "Право",
    rights: "Права",
    salary_accrual: "Логика начисления процентов врачам / техникам / администраторам",
    salary_accrual_for_personnel: "Начисление зарплаты врачам и администраторам",
    salary_accrual_for_technics: "Начисление зарплаты техникам",
    send_time: "Время отправки",
    sendouts_only_in_day_time: "Отправлять массовые рассылки  в дневное время (09.00 - 22.00)",
    server_error: "Системе не удалось выполнить ваш запрос. Пожалуйста, повторите попытку или обратитесь в службу поддержки",
    services_import: "Импорт услуг",
    show_complex_materials_for_teeth: "Отображать комплекты и материалы во вкладке \"Зубы\" в Карточке лечения",
    show_description_in_forms: "Отображать описание визита в формах 043, 058",
    show_preinstalled_templates: "Показывать предустановленные шаблоны",
    show_teeth_general: "Отображать общие поля во вкладке \"Зубы\" в Карточке лечения",
    sort_order: "Порядок отображения",
    source: "Источник",
    superadmin: "Суперадминистратор",
    superadministrators: "Суперадминистраторы",
    supermanager: "Суперменеджер",
    super_user: "Суперпользователь",
    switched_to_version_2: "Перешла на версию 2.0",
    sync_data: "Синхронизировать данные",
    tag: "Ярлык",
    tags: "Метки",
    tags_for_custom_notifications: "Тэги для автоматического вывода информации:\n" +
        "<span class='tag-code'>{DATE}</span> – Дата визита\n" +
        "<span class='tag-code'>{CLINIC}</span> – Название клиники\n" +
        "<span class='tag-code'>{BRANCH_ADDRESS}</span> – Адрес филиала\n" +
        "<span class='tag-code'>{CLIENT}</span> – Ф.И.О. клиента\n" +
        "<span class='tag-code'>{CLIENT_NAME}</span> – имя клиента\n" +
        "<span class='tag-code'>{CLIENT_BONUSES}</span> – Бонусы клиента\n" +
        "<span class='tag-code'>{DOCTOR}</span> – Ф.И.О. доктора\n" +
        "<span class='tag-code'>{URL_VIZIT_CONFIRMATION}</span> – Ссылка на подтверждение визита\n" +
        "<span class='tag-code'>{URL_VIZIT_REVIEW}</span> – Ссылка на отзыв о визите",
    tags_for_notifications: "Тэги для автоматического вывода информации:\n" +
        "<span class='tag-code'>{DATE}</span> – Дата визита\n" +
        "<span class='tag-code'>{CLINIC}</span> – Название клиники\n" +
        "<span class='tag-code'>{BRANCH_ADDRESS}</span> – Адрес филиала\n" +
        "<span class='tag-code'>{CLIENT}</span> – Ф.И.О. клиента\n" +
        "<span class='tag-code'>{CLIENT_NAME}</span> – имя клиента\n" +
        "<span class='tag-code'>{CLIENT_BONUSES}</span> – Бонусы клиента\n" +
        "<span class='tag-code'>{DOCTOR}</span> – Ф.И.О. доктора\n" +
        "<span class='tag-code'>{TASK}</span> – Текст задачи\n" +
        "<span class='tag-code'>{PHONE}</span> – Телефон клиента\n" +
        "<span class='tag-code'>{PLAN_TITLE}</span> – Название плана лечения\n" +
        "<span class='tag-code'>{PLAN_STATUS}</span> – Статус плана лечения\n" +
        "<span class='tag-code'>{COMPLEX_STATUS}</span> – Статус комплекса плана лечения\n" +
        "<span class='tag-code'>{URL_VIZIT_CONFIRMATION}</span> – Ссылка на подтверждение визита\n" +
        "<span class='tag-code'>{URL_VIZIT_REVIEW}</span> – Ссылка на отзыв о визите",
    tariff: "Тариф",
    tariff_payment_expires_in_days: " | Оплата по тарифу заканчивается через {day} день| Оплата по тарифу заканчивается через {day} дня",
    tariffs: "Тарифы",
    task_created: "Создание задачи",
    templates: "Шаблоны",
    templates_agreements: "Шаблоны согласий",
    templates_categories: "Стандартные категории",
    templates_contracts: "Шаблоны договоров",
    templates_text: "Текстовые шаблоны",
    templates_treatments: "Шаблоны лечений",
    techsupport_panel: "Панель техподдержки",
    test: "Тест",
    time_after_visit: "после визита",
    time_before_visit: "до визита",
    time_interval_value: "Величина интервалов времени",
    time_zone: "Часовой пояс",
    to_billing: "К настройкам биллинга",
    to_the_one_who_accepted_payment: "Тому, кто принимает платеж",
    to_the_one_who_made_record: "Тому, кто делает запись",
    treatment_finished: "Лечение завершено",
    turn_off: "Отключить",
    turned_off: "Выключена",
    turned_on: "Включена",
    'visit services sync': "Привязка услуг",
    ukrainian: "Украинский",
    unblock_for_3_days: "Разблокировать на 3 дня",
    updated: "Изменено",
    User: "Пользователь",
    use_bonuses_on_salary: "Начисление зарплаты с оплаты бонусами",
    use_proxy: "Использовать прокси",
    user_id: "Id пользователя",
    uzbek: "Узбекский",
    visit_cancel: "Отмена визита",
    visit_created: "Создание визита",
    warehouse_connected: "Подключен платный модуль склад",
    week: "Неделя",
    when_changing_tariff_all_payments_will_nullify: "При смене тарифа все платежи будут аннулированы",
    which_branches_use_online_booking: "Какие филиалы используют онлайн-запись",
    which_clients_to_consider_permanent: "Каких клиентов считать постоянными",
    work_time_from: "Время работы с",
    work_time_to: "Время работы до",
    your_tariff_plan_is_not_paid: "Ваш тарифный план не оплачен",
    zero_unlimited: "0 - неограниченно",
}
