
export default {
    primaryKey: 'id',
    model: 'payment',
    order: 'date',
    sort: 'desc',
    actionscomponent: 'PaymentDatatableActions',
    deletemessage: 'are_you_sure_you_want_to_delete_payment',
    showTotalRecords: true,
    actions: [
        "add",
        "edit",
        "delete"
    ],
    rights: {
        edit: "remove_payment",
        delete: "remove_payment",
    },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'date_timezone',
            title: 'date',
            table: true,
            sortable: 'date',
            tabletdclass: "order-mob-0",
            form: {
                type: 'datetime',
                required: true
            },
            format_datetime: true
        },
        {
            id: 'treatment_id',
            title: 'visit',
            table: true,
            sortable: true,
            tdcomponent: "PatientsDatatableTDPaymentVisit"
        },
        {
            id: 'forma',
            title: 'payment_type',
            table: true,
            sortable: true,
            format_translate: true,
            form: {
                component: "PaymentsDatatableFormPaymentType",
            },
            table_show: 'payment_type',
        },
        {
            id: 'paid',
            title: 'sum',
            table: true,
            sortable: true,
            format_currency: true
        },
        {
            id: 'comment',
            title: 'comment',
            table: true,
            filter: true
        },
    ]
}
