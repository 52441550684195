<template>
  <div>
    <DataTable :options="options"
               class="mt-4"
               @checkboxSelected="checkboxSelected"
               ref="datatable"
               :params="{ group_id: groupId, branch_id: branch, blocked: 0 }"
               module="marketing"/>
    <SelectedBottomPanel :selected="selected"
                         @cancel="cancel"
                         view="delete"
                         @actionWithSelected="removeFromGroup"
                         :group="group"/>
  </div>
</template>

<script>

import {mapState} from "vuex"
import DataTable from "@/components/parts/general/DataTable"
import { MarketingService, EloquentService } from "@/services/api.service"
import options from "@/data/marketing/patient"
import SelectedBottomPanel from "@/components/parts/datatable/SelectedBottomPanel"

export default {
  name: "MarketingGroup",
  components: {
    SelectedBottomPanel,
    DataTable,
  },
  metaInfo() {
    return {
      title: this.$t('group'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      branch: state => state.auth.branch,
      clinic: state => state.auth.clinic,
      filters: state => state.datatable.filters,
      saveFilterCounter: state => state.datatable.saveFilterCounter
    }),
    groupId() {
      return this.$route.params.id
    }
  },
  data() {
    return {
      group: null,
      options: options,
      selected: []
    }
  },
  methods: {
    checkboxSelected(val) {
      this.selected = val
    },
    cancel() {
      this.$refs.datatable.cancelSelection()
    },
    removeFromGroup() {
      if(!this.group) return
      MarketingService.removeFromGroup(this.group.id, this.selected).then(res => {
        if(res.data) {
          this.$noty.info(this.$t('success_message'))
          this.cancel()
          this.$store.commit('incrementDataTableCounter')
        }
      })
    }
  },
  mounted() {
    EloquentService.show('marketing_group', this.groupId).then(res => {
      this.$store.commit('setMarketingGroup', res.data)
      this.group = res.data
    })
    this.$store.commit('setEloquentOptions', options)
    this.$store.commit('setAdditionalParams', {
      group_id: this.groupId,
      branch_id: this.branch,
      blocked: 0
    })
  },
  watch: {
    saveFilterCounter() {
      if(!this.group || this.group.filters == this.filters) return
      let formData = new FormData()
      formData.append('filters', JSON.stringify(this.filters))
      formData.append('title', this.group.title)
      EloquentService.update('marketing_group', this.groupId, formData).then(() => {
        this.$noty.info(this.$t('success_message'))
      })
    }
  },
  beforeDestroy() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>

<style scoped>

</style>
