import { taskAdapter } from '@/adapters/crm/task'
import {taskImportances, taskTypes} from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'task',
    order: 'id',
    sort: 'desc',
    modalsize: 'lg',
    adapter: taskAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_task',
    trclass: [{ class: 'done', condition: 'finished' }],
    actionscomponent: 'CRMTaskDatatableActions',
    formComponent: "CRMDatatableTaskForm",
    showTotalRecords: true,
    checks: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    rights: {
        edit: "crmtask_update",
        delete: "crmtask_delete",
    },
    fields: [
        // {
        //     id: 'id',
        //     title: '',
        //     table: true,
        //     sortable: true,
        //     tdcomponent: "CRMDatatableTDTaskCheckbox"
        // },
        {
            id: 'title',
            title: 'name',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            },
            tdcomponent: "CRMDatatableTDTaskTitle"
        },
        {
            id: 'task_type',
            title: 'type',
            table: true,
            sortable: true,
            filter: true,
            filter_list_select: true,
            form: {
                type: "text",
                required: true,
                options: taskTypes,
            },
            tdcomponent: "CRMDatatableTDTaskType"
        },
        {
            id: 'phone',
            title: 'phone',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
                required: true
            },
        },
        {
            id: 'patient_id',
            title: 'patient',
            table: true,
            sortable: true,
            table_show: 'patient.full_name',
            filter: true,
            // filter_model_select: true,
        },
        {
            id: 'deadline',
            title: 'deadline',
            table: true,
            sortable: true,
            format_datetime: true,
            filter: true,
            filter_date: true,
            form: {
                type: "date",
                required: true
            },
        },
        {
            id: 'description',
            title: 'description',
            table: true,
            filter: true,
            form: {
                type: "textarea",
                required: true
            }
        },
        {
            id: 'user_id',
            title: 'responsible',
            table: true,
            sortable: true,
            table_show: 'user.full_name',
            // table_show: 'causer_id',
            filter: true,
            filter_model_select: true,
        },
        {
            id: 'finished',
            title: 'status',
            table_show: 'status',
            table: true,
            sortable: true,
            tdcomponent: 'CRMDatatableTDTaskStatus'
        },
        {
            id: 'finished',
            title: 'done',
            filter: true,
            filter_boolean: true,
        },
        {
            id: 'importance',
            title: 'importance',
            filter: true,
            filter_list_select: true,
            form: {
                options: taskImportances,
            },
        },
        {
            id: 'my_tasks',
            title: 'my_tasks',
            filter: true,
            filter_hidden: true,
            filter_boolean: true,
        },
        {
            id: 'overdue',
            title: 'overdue',
            filter: true,
            filter_hidden: true,
            filter_boolean: true,
        },
        {
            id: 'created_by',
            title: 'author',
            table: true,
            sortable: true,
            table_show: 'author.full_name',
            filter: true,
            filter_model_select: true,
        },
        {
            id: 'created_at',
            title: 'created',
            table: true,
            sortable: true,
            format_datetime: true,
            filter: true,
        },
        {
            id: 'date_from',
            title: 'date_from',
            filter: true,
            filter_date: true,
        },
        {
            id: 'date_to',
            title: 'date_to',
            filter: true,
            filter_date: true,
        }
    ]

}
