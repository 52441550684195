import Vue from "vue"

import options from "@/data/superadmin/administrator"
import {doctorAdapter} from "@/adapters/staff/doctor"

let administrator_options = Vue.prototype.copyObject(options)
administrator_options.model = 'superadministrator'
administrator_options.adapter = doctorAdapter
administrator_options.actionscomponent = 'SuperAdminUsersActions'

export default administrator_options
