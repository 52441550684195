<template>
  <a @click="showDetails" class="pointer text-primary">
    {{ object.title }}
  </a>
</template>

<script>
export default {
  name: "CRMDatatableTDTaskTitle",
  props: {
    object: {
      type: Object
    }
  },
  computed: {

  },
  methods: {
    showDetails() {
      this.$store.commit('setTask', this.copyObject(this.object))
      this.$bvModal.show('task-show-modal')
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

</style>
