<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="debt"
                   initialSort="asc"
                   :rows="rows"/>
    <PatientToPayModal/>
    <VisitPayModal/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import VisitPayModal from "@/components/parts/patients/VisitPayModal"
import PatientToPayModal from "@/components/parts/patients/PatientToPayModal"
import {mapState} from "vuex"

export default {
  name: "ReportsDebtors",
  components: {
    PatientToPayModal,
    VisitPayModal,
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    selectedBranches: {}
  },
  metaInfo() {
    return {
      title: this.$t('debtors'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      counter: state => state.datatable.dataTableCounter,
      branchId: state => state.auth.branch,
    }),
  },
  data() {
    return {
      columns: [
        {
          id: 'full_name',
          field: 'full_name',
          label: this.$t('name'),
          tdcomponent: 'PatientsDatatableTDName',
          sortable: true,
        },
        {
          id: 'phone',
          field: 'phone',
          label: this.$t('phone'),
          sortable: true,
          right_excludes: 'hide_patient_phone',
        },
        {
          id: 'doctor_name',
          field: 'doctor_name',
          format_html: true,
          label: this.$t('doctor'),
          search_select: true,
          search_doctor_one: true,
          sortable: true
        },
        {
          id: 'sum',
          field: 'sum',
          label: this.$t('sum'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'paid',
          field: 'paid',
          label: this.$t('paid'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'debt',
          field: 'debt',
          label: this.$t('debt'),
          sortable: true,
          format_number: true,
          totals: true
        },
      ],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('debtors', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
      }).then(res => {
        this.rows = res.data
      })
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    if(this.rights.includes('payments')) {
      this.columns.push({
        id: 'id',
        field: 'id',
        label: this.$t('payment'),
        tdcomponent: 'PatientsDatatableTDDebtorPay',
        sortable: true,
      })
    }
    this.loadReport()
  },
  watch: {
    counter() {
      this.loadReport()
    }
  }
}
</script>

<style scoped>

</style>
