<template>
  <div v-if="clinicFull" class="mb-3">
    <SelectGroup :options="months"
                 :showlabel="true"
                 v-model="object.months"
                 variant="white"
                 :translate="false"
                 :label="$t('months')"
                 :required="true"/>
    <div>Валюта: {{ clinicFull.currency.title }}</div>
    <div>Тариф: {{ clinicFull.tariffInfo ? clinicFull.tariffInfo.tariff.title : '-' }}</div>
    <div>Месячный платеж: {{ amountPerMonth | formatNumber }}</div>
  </div>
</template>

<script>
import {EloquentService} from "@/services/api.service"
import SelectGroup from "@/components/form/SelectGroup.vue"

export default {
  name: "BillingPaymentFormCard",
  components: {
    SelectGroup
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
    options: {}
  },
  data() {
    return {
      clinicFull: null,
      currencyMapping: {
        1: 'uah',
        2: 'rub',
        3: 'usd',
        5: 'kzt'
      },
      months: [ ...Array(12).keys() ].map( i => ({ id: (i+1), title: (i+1)}))
    }

  },
  computed: {
    clinicId() {
      return this.object.clinic_id || null
    },
    amountPerMonth() {
      return this.clinicFull.tariffPrices === 0 ? 0 : this.clinicFull.tariffPrices.total[this.object.currency]
    },
  },
  watch: {
    async clinicId(val) {
      this.clinicFull = (await EloquentService.show('clinic', val.code, true)).data
      this.object.currency = this.currencyMapping[this.clinicFull.currency.id] || 'usd'
      this.recountSum()
      // this.$emit('clinic-id-changed', this.clinicId)
    },
    'object.months'() {
      this.recountSum()
    }
  },
  methods: {
    recountSum() {
      this.object.sum = this.object.months * this.amountPerMonth
    }
  }
}
</script>
