<template>
  <b-modal id="contract-modal"
           :size="loaded ? 'xl' : 'xl'"
           no-enforce-focus
           no-close-on-backdrop
           @show="onShow"
           @hide="onHide"
           :title="printAgreements ? $t('agreement_print') : $t('contract_print')"
           centered>
    <div v-if="!loaded">
      <div class="mb-3">{{ $t('select_document_type') }}</div>
      <div v-if="!initialLoading">
        <div v-for="ct in list"
             class="py-1"
             :class="{ 'border-bottom': !printAgreements }"
             :key="`ct-${ct.id}`">
          <b-row class="align-items-center">
            <b-col :md="printAgreements ? 12 : 7" cols="12">
              <a @click="selectContract(ct)"
                 class="d-block pointer py-1 text-decoration-none">- {{ ct.title }}</a>
            </b-col>
            <b-col md="2" cols="6" v-if="!printAgreements">
              <div v-if="!ct.generated">{{ $t('not_generated') }}</div>
              <div v-else-if="ct.generated && ct.generated.signed_at" class="text-success">{{ $t('signed') }}</div>
              <div v-else-if="ct.generated && ct.generated.fileManualUrl" class="text-primary">{{ $t('uploaded') }}</div>
              <div v-else  class="text-info">{{ $t('generated') }}</div>
            </b-col>
            <b-col md="3" cols="6" v-if="!printAgreements">
              <b-button :title="$t('generate')"
                        variant="link"
                        :disabled="loading"
                        class="pointer outline-none"
                        v-if="!ct.generated"
                        @click="generateDoc(ct)"
                        v-b-tooltip.hover><GenerateSvg class="svg-light"/>
              </b-button>
              <b-button :title="`${$t('file')} (pdf)`"
                        variant="link"
                        :disabled="loading"
                        class="pointer outline-none position-relative"
                        v-if="ct.generated && ct.generated.filePdfUrl"
                        @click="loadDoc(ct.generated.filePdfUrl)"
                        v-b-tooltip.hover><FilePdfSvg class="svg-light"/>
              </b-button>
              <b-button :title="`${$t('file')} (word)`"
                        variant="link"
                        :disabled="loading"
                        class="pointer outline-none position-relative"
                        v-if="ct.generated && ct.generated.fileWordUrl"
                        @click="loadWordDoc(ct.generated.fileWordUrl)"
                        v-b-tooltip.hover><FileWordSvg class="svg-light"/>
              </b-button>
              <b-button :title="`${$t('file')}${ct.generated.signed_at ? ' - '+$t('signed') : ''}`"
                        variant="link"
                        :disabled="loading"
                        class="pointer outline-none position-relative"
                        v-if="ct.generated && ct.generated.fileManualUrl"
                        @click="loadDoc(ct.generated.fileManualUrl)"
                        v-b-tooltip.hover><FilePdfSvg class="svg-light"/><SignedSvg class="signed-svg" v-if="ct.generated.signed_at"/>
              </b-button>
                <label :for="`manual_doc_${ct.id}`"
                       class="btn btn-link pointer mb-0"
                       :class="{ disabled: loading }"
                       :title="$t('upload_signed')"
                       v-if="ct.generated && !ct.generated.fileManualUrl && !ct.generated.signed_at"
                       v-b-tooltip.hover>
                  <UploadSvg class="svg-stroke-light"/>
                  <input type="file"
                         accept="application/pdf"
                         class="d-none"
                         :multiple="false"
                         :key="`input-${ct.id}-${counter}`"
                         :id="`manual_doc_${ct.id}`"
                         @change="processFile($event, ct)"/>
                </label>
              <b-button :title="$t('sign')"
                        variant="link"
                        :disabled="loading"
                        class="pointer outline-none"
                        v-if="ct.generated && !ct.generated.signed_at"
                        @click="signDoc(ct.generated)"
                        v-b-tooltip.hover><SignSvg class="svg-light"/>
              </b-button>
              <b-button :title="$t('delete')"
                        variant="link"
                        :disabled="loading"
                        class="pointer outline-none"
                        v-if="ct.generated"
                        @click="deleteDoc(ct.generated)"
                        v-b-tooltip.hover><TrashSvg class="svg-light"/>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div v-if="loaded">
      <label class="form-label mt-0">{{ $t('document') }}</label>
      <ckeditor class=""
                @namespaceloaded="onNamespaceLoaded"
                :config="ckConfig"
                v-model="template">
      </ckeditor>
      <div class="mt-4 tags-note"
           v-html="printAgreements ? $t('tags_for_document') : $t('tags_for_contract')"></div>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel d-inline-block align-middle"
                  variant="themed"
                  @click="loaded ? loaded = false : close()">
          {{ $t('cancel') }}
        </b-button>
        <button :disabled="loading || !selectedContract"
                v-if="loaded"
                @click="next"
                class="ml-3 btn-themed align-middle btn-themed-squared">
          {{ loaded ? $t('print_noun') : $t('continue') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { DocumentsService, EloquentService } from "@/services/api.service"
import CKEditor from 'ckeditor4-vue'
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"
import { ckeditorConfig } from "@/dictionaries/ckeditorConfig"
import GenerateSvg from '@/assets/svg/general/refresh2.svg'
import SignSvg from '@/assets/svg/form/tick.svg'
import SignedSvg from '@/assets/svg/form/tick.svg'
// import FileSvg from '@/assets/svg/patients/file.svg'
import FilePdfSvg from '@/assets/svg/patients/file_pdf.svg'
import FileWordSvg from '@/assets/svg/patients/file_word.svg'
import TrashSvg from '@/assets/svg/general/trash_tr.svg'
import UploadSvg from '@/assets/svg/patients/upload.svg'

export default {
  name: "PatientContractSelectModal",
  components: {
    // RadioGroup,
    ckeditor: CKEditor.component,
    GenerateSvg,
    SignSvg,
    SignedSvg,
    // FileSvg,
    FilePdfSvg,
    FileWordSvg,
    TrashSvg,
    UploadSvg
  },
  data() {
    return {
      contractTypes: [
        { id: 'services', title: 'contract_services', type: 'services' },
        // { id: 'agreement', title: 'contract_agreement'},
        // { id: 'general', title: 'contract_general'},
      ],
      loaded: false,
      ckConfig: ckeditorConfig,
      // selectedContract: 'services',
      selectedContract: null,
      selectedContractObj: null,
      template: '',
      loading: false,
      initialLoading: true,
      counter: 0,
      customAgreements: [],
      generatedDocuments: []
    }
  },
  methods: {
    async selectContract(ct) {
      // if(this.loading) return
      // if(!ct.generated) {
      //   await this.generateDoc(ct)
      //   return
      // }
      // if(ct.generated.fileManualUrl) {
      //   this.loadDoc(ct.generated.fileManualUrl)
      //   return
      // }
      // if(ct.generated.filePdfUrl) {
      //   this.loadDoc(ct.generated.filePdfUrl)
      //   return
      // }
      // if(ct.generated.fileWordUrl) {
      //   this.loadDoc(ct.generated.fileWordUrl)
      // }
        this.selectedContract = ct.id
        this.selectedContractObj = ct
        await this.next()
    },
    async next() {
      let isWord = false
      if(!this.loaded) {
        const templateRes = await DocumentsService.getDocumentTemplate(
            this.documentType,
            this.documentType === 'custom' ? this.selectedContract : null
        )
        this.template = templateRes.data.text || templateRes.data // for compatibility purpose, res.data can be deleted later on
        this.loaded = true
        isWord = templateRes.data.word
        if(!isWord) return
        // return
      }
      if(this.loaded && this.template) {
        const res = await DocumentsService.saveDocumentTemplate(
            this.documentType,
            this.template,
            this.documentType === 'custom' ? this.selectedContract : null
        )
        if(!this.printAgreements && this.selectedContractObj && !this.selectedContractObj?.generated?.signed_at) {
          await this.generateDoc(this.selectedContractObj)
        }
        let link = JwtService.getPrintPrelink(this.clinic)
        let visit_id = '?visit_id=' + (this.visit ? this.visit.id : '')
        let doc_type = this.selectedContract
        if(this.documentType === 'custom') {
          doc_type = 'custom'
          visit_id += '&custom_id='+res.data.id
        }
        window.open(link+'/document/'+this.patient.id+'/'+doc_type + visit_id, '_blank')
        if(isWord) this.$bvModal.hide('contract-modal')
      }
    },
    async loadCustomAgreements(type) {
      const res = await DocumentsService.getAgreementsList(type)
      this.customAgreements = res.data
      if(this.customAgreements.length === 0) {
        this.$store.commit('setPrintAgreements', false)
      }
    },
    async loadGeneratedDocuments() {
      const res = await DocumentsService.getGeneratedDocuments(this.patient.id)
      this.generatedDocuments = res.data
    },
    onNamespaceLoaded(CKEDITOR) {
      CKEDITOR.plugins.addExternal('lineheight', '/js/ckeditor/lineheight/', 'plugin.js' );
    },
    async onShow() {
      this.template = ''
      this.loaded = false
      this.initialLoading = true
      this.loading = false
      await this.loadCustomAgreements(this.printAgreements ? 'agreement' : 'contract')
      await this.loadGeneratedDocuments()
      this.initialLoading = false
    },
    onHide() {
      this.$store.commit('setPrintAgreements', false)
      this.template = ''
      this.loaded = false
      this.customAgreements = []
      this.selectedContract = null
      this.selectedContractObj = null
    },
    async generateDoc(doc) {
      this.loading = true
      try {
        await DocumentsService.generateDocument(this.patient.id, doc)
        await this.loadGeneratedDocuments()
      } finally {
        this.loading = false
      }
    },
    async signDoc(generatedDoc) {
      this.loading = true
      try {
        await DocumentsService.signGeneratedDocument(this.patient.id, generatedDoc.id)
        await this.loadGeneratedDocuments()
      } finally {
        this.loading = false
      }
    },
    async deleteDoc(generatedDoc) {
      this.loading = true
      try {
        await EloquentService.delete('generated_document', generatedDoc.id)
        await this.loadGeneratedDocuments()
      } finally {
        this.loading = false
      }
    },
    async processFile(event, doc) {
      const file = event.target.files[0]
      this.counter++
      if(file.size > (2 * 1024 * 1024)) { // 2 MB
        this.$noty.error(this.$t('file_size_is_too_big'))
        return
      }
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        file,
        generatedDocumentId: doc.generated?.id || null,
        docType: doc.type,
        docId: doc.id
      })
      await DocumentsService.uploadToGeneratedDocument(this.patient.id, formData)
      await this.loadGeneratedDocuments()
    },
    loadDoc(url) {
      window.open(url,"_blank")
    },
    loadWordDoc(url) {
      window.open(url,"_blank")
      // window.open(`https://view.officeapps.live.com/op/embed.aspx?src=${url}`,"_blank")
    }
  },
  computed: {
    ...mapState({
      patient: state => state.patient.patient,
      visit: state => state.patient.visit,
      clinic: state => state.auth.clinic,
      printAgreements: state => state.patient.printAgreements,
    }),
    documentType() {
      if(this.printAgreements) return 'custom'
      if(this.selectedContract && !isNaN(this.selectedContract)) return 'custom'
      return this.selectedContract
    },
    list() {
      let arr = [];
      if(!this.printAgreements) {
        arr = this.contractTypes.map(ca => ({
          ...ca, title: this.$t(ca.title), generated: this.generatedDocuments.find(gd => gd.document_type === 'services')
        }))
      }
      arr = arr.concat(this.customAgreements.map(ca => ({
        ...ca, type: 'custom', generated: this.generatedDocuments.find(gd => gd.document_type === 'custom' && gd.document_id === ca.id)
      })))
      return arr
    }
  },
}
</script>

<style scoped lang="scss">
.signed-svg {
  fill: $green-color;
  position: absolute;
  //left: 50%;
  //transform: translateX(-50%);
  right: 7px;
  top: 10px;
}
</style>
