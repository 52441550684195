<template>
  <div>
    <a class="tdclickable" v-b-toggle="'kit-collapse-'+object.package_id">
      {{ object.name }}
    </a>
    <b-collapse :id="`kit-collapse-${object.package_id}`">
      <div v-for="m in object.materials" :key="`kit-${object.package_id}-mat-${m.material_id}`">
        {{ m.name }}: <b>{{ m.pivot.amount }}</b>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "WarehouseDatatableTDKitTitle",
  props: {
    object: {
      type: Object
    }
  }
}
</script>
