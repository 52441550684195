<template>
  <b-dropdown right
              variant="link"
              no-caret
              class="d-inline-block align-middle">
    <template #button-content>
      <a class="ml-3 pointer position-relative"
         style="top: -3px"
         v-b-tooltip.hover
         :title="$t('write_in_messenger')">
        <WhatsappSvg class="svg-primary" style="width: 16px;"/></a>
    </template>
    <b-dropdown-item v-if="wazzupEnabled && patientId" @click="wazzupIframe('whatsapp')">Whatsapp</b-dropdown-item>
    <b-dropdown-item v-if="wazzupEnabled && patientId" @click="wazzupIframe('telegram')">Telegram</b-dropdown-item>
    <b-dropdown-item v-if="!wazzupEnabled || !patientId" target="_blank" :href="`https://wa.me/${$options.filters.formatPhone(phone, false)}`">Whatsapp</b-dropdown-item>
    <b-dropdown-item v-if="!wazzupEnabled || !patientId" target="_blank" :href="`https://t.me/${$options.filters.formatPhone(phone)}`">Telegram</b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { WhatsappService } from "@/services/api.service"
import WhatsappSvg from "@/assets/svg/crm/whatsapp.svg"
import {mapState} from "vuex"

export default {
  name: "WhatsappTelegramIcon",
  components: {
    WhatsappSvg
  },
  props: {
    patientId: {

    },
    phone: {

    }
  },
  computed: {
    ...mapState({
      wazzupEnabled: state => state.crm.wazzupEnabled,
    })
  },
  methods: {
    async wazzupIframe(type = 'whatsapp') {
      let res = await WhatsappService.createIframe(this.patientId, type)
      if(res.data.url) {
        this.$store.commit('setWazzupIframeUrl', res.data.url)
        this.$nextTick(() => {
          this.$bvModal.show('wazzup-iframe-modal')
        })
        return
      }
      if(res.data.error) {
        this.$noty.error(res.data.error)
      }
    },
  }
}
</script>
