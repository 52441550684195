import { superUserRoles } from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'super_user',
    order: 'id',
    sort: 'desc',
    // adapter: clinicAdapter,
    // actionscomponent: 'SuperAdminClinicsActions',
    showTotalRecords: true,
    // deletemessage: 'are_you_sure_you_want_to_delete_service',
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    // rights: {
    //     edit: "clinics_update",
    //     delete: "clinics_delete",
    // },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'full_name',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            // form: {
            //     type: "text",
            //     required: true,
            // },
            // tdcomponent: 'SuperAdminDatatableTDClinicTitle',
        },
        {
            id: 'role',
            title: 'role',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "select",
                select_type: "list",
                options: superUserRoles,
                hideNullOption: true,
                required: true,
            }
        },
        {
            id: 'fname',
            title: 'name',
            table: false,
            filter: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'lname',
            title: 'surname',
            table: false,
            filter: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'mname',
            title: 'middle_name',
            table: false,
            filter: true,
            form: {
                type: "text"
            }
        },
        {
            id: 'login',
            title: 'login',
            table: false,
            filter: false,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'password',
            title: 'password',
            table: false,
            filter: true,
            form: {
                type: "password"
            }
        },
        {
            id: 'blocked',
            title: 'blocked',
            table: true,
            sortable: true,
            filter: true,
            filter_boolean: true,
            tdcomponent: 'SuperAdminDatatableTDUserBlocked',
            form: {
                type: "checkbox"
            }
        },
    ]

}
