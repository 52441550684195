import { render, staticRenderFns } from "./PatientsHeaderDoctorsFilter.vue?vue&type=template&id=2dc5ff69&scoped=true&"
import script from "./PatientsHeaderDoctorsFilter.vue?vue&type=script&lang=js&"
export * from "./PatientsHeaderDoctorsFilter.vue?vue&type=script&lang=js&"
import style0 from "./PatientsHeaderDoctorsFilter.vue?vue&type=style&index=0&id=2dc5ff69&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc5ff69",
  null
  
)

export default component.exports