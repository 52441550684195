<template>
  <div class="position-relative">
    <a ref="rangeBtn" href="#" @click="showPopup" class="d-inline-block mr-3 current-view-range text-capitalize">
      {{ professionString }} <Arrow />
    </a>
    <div v-if="popupShowed" class="view-range-selector view-dd-selector position-absolute"
         v-closable="{
            exclude: ['rangeBtn'],
            handler: 'onClose'
        }">
      <a href="#"
         class="text-capitalize"
         @click="professionSelected([])">
        {{ $t('all') }}
      </a>
      <a href="#"
         size="sm"
         class="text-capitalize"
         v-for="p in professions"
         :key="`prof-${p.doctors_profession_id}`"
         @click="professionSelected([p])">
        {{ p.title }}
      </a>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex"
import Arrow from '@/assets/svg/calendar/arrow.svg'
import {Closable} from "@/components/pages/calendar/calendar/directives/CloseOutside";

export default {
  name: "DProfessionSwitcher",
  directives: {
    Closable
  },
  components: {
    Arrow
  },
  computed: {
    ...mapState({
      professions: state => state.auth.professions,
      selectedProfessions: state => state.calendar.selectedProfessions
    }),
    professionString() {
      if(!this.selectedProfessions || !this.selectedProfessions.length) return this.$t('all')
      return this.selectedProfessions[0].title
    }
  },
  data() {
    return {
      popupShowed: false,
    }
  },
  methods: {
    showPopup() {
      this.popupShowed = !this.popupShowed
    },
    onClose() {
      this.popupShowed = false
    },
    professionSelected(professions) {
      this.popupShowed = false
      this.$store.commit('setSelectedProfessions', professions)
    }
  }
}
</script>

<style scoped lang="scss">
.view-range-selector {
  top:30px;
  min-width: 150px;
  padding:10px 0;
}
.view-range-selector a {
  font-size: 16px;
  display: block;
  padding: 9px 20px;
  color: #5D6D7C;
}
.view-range-selector a:hover {
  background-color: #F3F3F3;
  text-decoration: none;
}
a {
  text-transform: lowercase;
}
@media screen and (max-width: 768px) {
  .view-range-selector {
    top: 25px;
    padding: 7px 0;
    a {
      font-size: 13px;
      padding: 5px 15px;
    }
  }
}
</style>