<template>
  <div>
    <InputGroup :label="$t(field.title)"
                type="text"
                :required="true"
                @keyup="changed"
                v-model="object[field.id]"/>
    <div class="validation-error small" v-if="userWithSamePhone">{{ $t('this_phone_is_in_system') }}: {{ userWithSamePhone.full_name }}</div>
  </div>
</template>

<script>

import InputGroup from "@/components/form/InputGroup"
import {PatientService} from "@/services/api.service"

export default {
  name: "PatientsDatatableFormMPhone",
  components: {
    InputGroup
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  data() {
    return {
      userWithSamePhone: null,
      timer: null,
    }
  },
  mounted() {
  },
  methods: {
    changed(e) {
      if(this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        const val = e.target.value
        if(val) {
          PatientService.checkIfPhoneExists(this.object.id, val).then(res => {
            this.userWithSamePhone = res.data.user
          })
        }
      }, 250)
    }
  }
}
</script>

<style scoped>

</style>
