<template>
  <div class="d-record overflow-hidden noselect dbg"
       tabindex="0"
       ref="drecref"
       :class="[
           showStatusColor && !fillDoctorColor ? 'dbg-'+record.iconStatus : '',
           elHeight < 15 ? 'superShort' : '',
          'record-'+record.id,
          isOnlineBooking ? 'online-booking' : '',
          isOpenedNow ? 'in-progress' : ''
       ]"
       :style="[position, doctorFill, recordTagFill, textColor]">
    <div v-if="showDoctorColor"
         class="d-record__doctor"
         :style="{ backgroundColor: doctorColor }"/>
    <div class="d-record__menu d-flex align-items-center"><MenuSvg /></div>
    <div class="d-record__body d-flex align-items-stretch position-relative w-100 h-100">
      <div class="d-record__main"
           :class="[defineBorderColor, smallWidth, smallHeight, hideIcon]">
        <DRecordStatusIcon class="d-record__status-icon"
                           :record="record"
                           v-click-outside="clickedOutside"
                           @touchstart.native="touchStart"
                           @click.native="stopClick"
                           :id="`popover-tgt-${record.id}`"/>
        <!--        <div class="d-record__content">-->
        <span class="d-record__popover" style="pointer-events: none"><InfoSvg/></span>
        <div class="d-record__time" :style="[textColor]" :class="{ 'd-inline': elWidth < 200 }">
          <span class="align-middle">{{ time }}</span>
          <span class="d-inline-block align-middle ml-2 position-relative"
                style="top: -1px;"
                v-if="patientNotes && showClientTags"
                v-b-tooltip.hover
                :title="patientNotes">
            <WarnSvg/>
          </span>
          <div v-if="patient && patient.age && patient.age < 18"
               v-b-tooltip.hover
               style="top: -1px;"
               :title="$t('child_age')"
               class="d-inline-block align-middle ml-1 position-relative">
            <ChildSvg class="child-svg"/>
          </div>
          <div v-if="patient && patient.debt_calculated && patient.debt_calculated < 0"
               v-b-tooltip.hover
               style="top: -1px;"
               :title="$t('debtor')"
               class="d-inline-block align-middle ml-1 position-relative">
            <DebtSvg class="debtor-svg"/>
          </div>
          <div class="d-inline-block align-middle ml-1 position-relative"
               style="top: -0.5px"
               v-b-tooltip.hover
               :title="$t('new_patient')"
               v-if="firstVisitPatient">
            <OneSvg class="svg-first-visit"/>
          </div>
          <div class="d-inline-block align-middle ml-1 position-relative"
               v-if="firstVisit"
               :class="{ 'text-danger': firstVisit }"
               style="font-size:10px"
                v-b-tooltip.hover
                :title="firstVisit ? $t('first_visit') : $t('return_visit')">
            {{ firstVisit ? '1В' : 'ПВ' }}
          </div>
          <div v-if="showClientTags && patient" class="d-record__services d-inline-flex">
            <div class="d-record__service"
                 v-b-tooltip.hover
                 v-for="(item, iind) in patient.tags"
                 :key="`patient-tag-${record.id}-${iind}`"
                 :alt="item.title"
                 :title="item.title"
                 :style="`background-color:${item.color}; border: 1px solid ${item.color == '#FFFFFF' ? '#ddd' : 'transparent'};`">
            </div>
          </div>
          <div v-if="record.tags && record.tags.length" class="d-record__services d-inline-flex">
            <div class="d-record__tag"
                 v-b-tooltip.hover
                 v-for="(item, iind) in record.tags"
                 :key="`record-tag-${record.id}-${iind}`"
                 :alt="item.title"
                 :title="item.title"
                 :style="`border-bottom-color:${item.color};`">
            </div>
          </div>
          <div v-if="isOnlineBooking" class="d-inline-block ml-1 requires-confirmation small text-danger">{{ $t('online_booking') }} - <span class="text-lowercase">{{ $t('requires_attention') }}</span></div>
        </div>
        <b-popover :target="`popover-tgt-${record.id}`"
                   ref="popover"
                   triggers="hover"
                   placement="top"
                   custom-class="record-popover">
          <div v-if="record.record_type"><b>{{ $t('record_type') }}</b>: {{ $t(record.record_type) }}</div>
          <div v-if="patient">
            <b>{{$t('patient')}}:</b> {{ patientName }}
            <span v-if="patient.phone && !rights.includes('hide_patient_phone')">({{ patient.phone }})</span>
            <span class="records-count ml-2 circle text-center circle-primary" v-if="patient.client_records && patient.client_records.length > 1">{{ patient.client_records.length }}</span>
            <div v-if="patient.date_of_birth">
              <span><b>{{ $t('age') }}:</b> {{ patient.age }} ({{ patient.date_of_birth | formatDate }})</span>
            </div>
          </div>
          <div v-if="doctor"><b>{{$t('doctor')}}:</b> {{ doctor.full_name }}</div>
          <div v-if="record.author"><b>{{$t('added')}}:</b> {{ record.author.full_name }} </div>
          <div><b>{{ $t('status')}}:</b> {{ $t(record.iconStatus) }}</div>
          <div v-if="patient && patient.card"><b>{{ $t('card_number')}}:</b> {{ patient.card }}</div>
          <hr v-if="record.description">
          <div v-if="record.description">{{ record.description }}</div>
          <div class="mt-3" v-if="showPlannedServices && record.planned_services && record.planned_services.length">
            <b>{{ $t('planned_services') }}: </b>
            <span v-for="(ps, psind) in record.planned_services" :key="`plan-sp-${psind}`">{{ psind > 0 ? ', ' : ''}}{{ ps.name }}</span>
          </div>
          <div v-if="record.tags && record.tags.length">
            <span><b>{{ $t('record_tags') }}: </b></span>
            <span>{{ record.tags.map(tag => tag.title).join(', ') }}</span>
          </div>
          <div v-if="patient && patient.tags && patient.tags.length">
            <span><b>{{ $t('tags') }}: </b></span>
            <span>{{ patient.tags.map(tag => tag.title).join(', ') }}</span>
          </div>
        </b-popover>
        <div class="d-record__patient d-inline" :style="[textColor]">
          {{ patientName }}
          <div class="planned-block" v-if="showPlannedServices && record.planned_services && record.planned_services.length">
            <span v-for="(ps, psind) in record.planned_services" :key="`plan-s-${psind}`">{{ psind > 0 ? ', ' : ''}}{{ ps.name }}</span>
          </div>
        </div>
        <div v-if="showServiceColor" class="d-record__services d-flex">
          <template v-for="(item, iind) in record.planned_services">
            <div class="d-record__service"
                 v-b-tooltip.hover
                 v-if="item.category"
                 :key="`plan-circle-${record.id}-${iind}`"
                 :alt="item.name"
                 :title="item.name"
                 :style="`background-color:${item.category.color}; border: 1px solid ${item.category.color == '#FFFFFF' ? '#ddd' : 'transparent'};`">
            </div>
          </template>
        </div>
        <div class="small descr" v-if="record.description">{{ record.description }}</div>
        <!--        </div>-->
      </div>
    </div>
    <DRecordResize @mouseleave.native="onMouseHover(false)"
                   @mouseover.native="onMouseHover(true)" />
  </div>
</template>

<script>

import moment from "moment/moment"
import RecordPosition from "@/components/pages/calendar/calendar/classes/RecordPosition"
import DRecordResize from "@/components/pages/calendar/calendar/DRecordResize"
import DRecordStatusIcon from "@/components/pages/calendar/calendar/DRecordStatusIcon"
import MenuSvg from '@/assets/svg/calendar/more.svg'
import InfoSvg from '@/assets/svg/calendar/record_info.svg'
import WarnSvg from '@/assets/svg/calendar/record_warn.svg'
import ChildSvg from '@/assets/svg/calendar/child.svg'
import DebtSvg from '@/assets/svg/calendar/debt.svg'
import OneSvg from '@/assets/svg/calendar/one.svg'
import {mapState} from "vuex"

export default {
  name: "DRecord",
  components: {
    DRecordStatusIcon,
    DRecordResize,
    MenuSvg,
    InfoSvg,
    WarnSvg,
    ChildSvg,
    DebtSvg,
    OneSvg
  },
  props: {
    record: Object,
    doctor: Object,
    pos: RecordPosition
  },
  data: function () {
    return {
      isOnResize: false,
      elWidth: null,
      elHeight: null,
      minElWidth: 99,
      minElHeight: 40,
      minElWidthForIcon: 50,
    };
  },
  computed: {
    ...mapState({
      resizableRecord: state => state.calendar.resizableRecord,
      showDoctorColor: state => state.calendar.showDoctorColor,
      showStatusColor: state => state.calendar.showStatusColor,
      showServiceColor: state => state.calendar.showServiceColor,
      showClientTags: state => state.calendar.showClientTags,
      showRecordTagColor: state => state.calendar.showRecordTagColor,
      showPlannedServices: state => state.calendar.showPlannedServices,
      fillDoctorColor: state => state.calendar.fillDoctorColor,
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
    }),
    smallWidth: function() {
      return this.isWiderThanMinWidth ? '' : 'small-width'
    },
    smallHeight: function() {
      return this.isHigherThanMinHeight ? '' : 'small-height'
    },
    hideIcon: function() {
      return this.isSmallForIcon ? 'hide-icon' : '';
    },
    isSmallForIcon() {
      return !this.pos.width || this.pos.width < this.minElWidthForIcon || !this.isHigherThanMinHeight
    },
    isWiderThanMinWidth() {
      if(this.pos.width < 100 && this.elWidth) {
        return this.elWidth > 150
      }
      return this.pos.width && this.pos.width > this.minElWidth
    },
    isHigherThanMinHeight() {
      return this.elHeight && this.elHeight >= this.minElHeight
    },
    time() {
      const from = moment(this.record.start).format('HH:mm')
      const to = moment(this.record.end).format('HH:mm')
      return `${from} - ${to}`
    },
    patientName() {
      return this.patient ? this.patient.full_name : ''
    },
    patientNotes() {
      if(!this.patient || !this.patient.client_notes) return ''
      let notes = this.patient.client_notes.filter(el => el.type)
      notes = notes.map(({description}) => description)
      return notes.join(', ')
    },
    services() {
      return this.record.services
    },
    doctorColor() {
      return this.doctor && this.doctor.color ? this.doctor.color : '#fff';
    },
    position() {
      if (!this.pos) return {}
      return {
        width: this.pos.width + '%',
        height: this.pos.height + '%',
        top: this.pos.y + '%',
        left: this.pos.x + '%',
      }
    },
    doctorFill() {
      if(this.fillDoctorColor && this.doctor) {
        return {
          background: this.doctorColor
        }
      }
      return {}
    },
    recordTagFill() {
      if(this.showRecordTagColor && this.record.tags?.length) {
        return {
          background: this.record.tags[0].color
        }
      }
      return {}
    },
    textColor() {
      if(!this.fillDoctorColor || !this.clinic.calendar_color) return {}
      return {
        color: this.clinic.calendar_color
      }
    },
    defineBorderColor() {
      return 'dborder-'+this.record.iconStatus
    },
    patient() {
      return this.record.patient
    },
    firstVisitPatient() {
      if(!this.patient) return false
      return this.patient.status_id !== 2
    },
    firstVisit() {
      return !!this.record.first_visit
    },
    isOpenedNow() {
      return this.opened_visit?.id
    },
    isOnlineBooking() {
      return this.record?.record_type === 'online'
    },
  },
  methods: {
    onMouseHover(bool) {
      this.record.isOnResize = bool
      this.isOnResize = bool
    },
    updateDimensions() {
      this.elWidth = this.$el.clientWidth
      this.elHeight = this.$el.clientHeight
      setTimeout(() => {
        this.elWidth = this.$el.clientWidth
        this.elHeight = this.$el.clientHeight
      }, 350)
    },
    stopClick() {
      this.$store.commit('setContextMenuVisible', false)
    },
    touchStart(e) {
      e.stopPropagation()
      this.$store.commit('setContextMenuVisible', false)
      this.$refs.popover.$emit('open')
    },
    clickedOutside() {
      this.$refs.popover.$emit('close')
    }
  },
  mounted() {
    this.updateDimensions()
    window.addEventListener("resize", this.updateDimensions)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateDimensions)
  }
}
</script>

<style scoped lang="scss">
.d-record {
  &.touchActive {
    touch-action: none;
    transform: scale(1.1);
  }
  &.online-booking {
    border: 2px solid $danger-red;
  }
  position: absolute;
  top: 0;
  //animation: gradient 4s ease infinite;
  //touch-action: none;
  background-color: white;
  border-radius: 5px;
  line-height: .9rem;
  z-index: 2;
  transition: all 0.1s ease-out;
  &:hover {
    //transform: scale(1.06);
    //transform: scale(0.96);
    opacity: 0.8;
    z-index: 3;
    left: 0 !important;
    width: 100% !important;
    .d-record__time, .d-record__services, .d-record__patient, .d-record__icon, .d-record__popover {
      display: block;
      .d-record__service {
        display: block;
      }
    }
    .d-record__main {

      //border-width: 5px;
    }
    //box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
  .descr {
    opacity: 0.7;
  }
}
.superShort {
  .d-record__popover {
    top: -4px;
  }
  .d-record__status-icon {
    position: relative;
    bottom: 5px;
  }
  .d-record__main {
    padding: 0 10px;
  }
  &:hover {
    height: 40px !important;
    .d-record__popover {
      top: 0;
    }
    .d-record__main {
      padding: 5px 10px;
    }
    .d-record__status-icon {
      bottom: 0;
    }
  }
}
.dborder-blue, .dborder-confirmed {
  border: 1px solid #0787C9;
}
.dborder-red, .dborder-did_not_come, .dborder-debt {
  border: 1px solid #E30D13;
}
.dborder-yellow, .dborder-not_confirmed {
  border: 1px solid #FEC943;
}
.dborder-green, .dborder-paid, .dborder-in_chair, .dborder-came {
  border: 1px solid #5E9246;
}
.dborder-blocked {
  border: 1px solid #E5E5E5;
}
.records-count {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
}
.d-record__doctor {
  position: absolute;
  left:0;
  top:0;
  bottom:0;
  width: 5px;
}
.d-record__main {
  width:100%;
  padding: 5px 10px;
  border-radius: 5px;
}
.d-record__content {
  padding-left: 10px;
}
.d-record__time {
  font-size: 12px;
  color: #87939E;
  word-break: break-word;
}
.d-record__popover {
  position:absolute;
  left: 24px;
  top: 2px;
}
.d-record__patient {
  color: #000000;
  font-weight: bold;
  font-size: 13px;
  word-break: break-word;
}
.d-record__service {
  width: 6px;
  height: 6px;
  overflow: hidden;
  border-radius: 50%;
  margin: 5px 2px;
}
.d-record__tag {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 8px solid;
  margin-right: 3px;
}
.d-record__menu {
  position: absolute;
  z-index: 2;
  right: 5px;
  top: 0;
  padding: 0px 5px;
  height: 100%;
  max-height: 42px;
  cursor: pointer;
}
.d-record__status-icon {
  float: left;
  margin-right: 10px;
  margin-bottom: 2px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.dbg {
  background-size: 4em 4em;
  background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, .8) 25%, transparent 25%,
      transparent 50%, rgba(255, 255, 255, .8) 50%, rgba(255, 255, 255, .8) 75%,
      transparent 75%, transparent);
  background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, .8) 25%, transparent 25%,
      transparent 50%, rgba(255, 255, 255, .8) 50%, rgba(255, 255, 255, .8) 75%,
      transparent 75%, transparent);
  background-image: linear-gradient(135deg, rgba(255, 255, 255, .8) 25%, transparent 25%,
      transparent 50%, rgba(255, 255, 255, .8) 50%, rgba(255, 255, 255, .8) 75%,
      transparent 75%, transparent);
}
.dbg-red, .dbg-debt, .dbg-did_not_come {
  background-color: #fcecec;
}
.dbg-yellow, .dbg-not_confirmed {
  background-color: #fffbf0;
}
.dbg-green, .dbg-paid, .dbg-came, .dbg-in_chair {
  background-color: #f2f6f0;
}
.dbg-blocked {
  background-color: #fafafa;
}
.dbg-blue, .dbg-confirmed {
  background-color: #f1fcff;
}

.small-width .d-record__time,
.small-width .d-record__patient,
.small-width .d-record__popover,
.small-width .d-record__service
{
  display: none;
}
.small-height .d-record__time,
.small-height .d-record__patient
{
  height: 13px;
  overflow: hidden;
}
.small-height {
  .d-record__service  {
    display: none;
  }
}
.hide-icon {
  .d-record__icon {
    display: none;
  }
  .d-record__popover {
    display: none;
  }
}
.planned-block {
  font-size: 12px;
  font-weight: 500;
  opacity: 0.8;
}
.child-svg {
  width: 9px;
  height: 10px;
  fill: $blue-color-darker;
}
.svg-first-visit {
  width: 14px;
  height: 14px;
}
.debtor-svg {
  width: 11px;
  height: 11px;
  fill: $danger-red;
}
//@keyframes gradient {
//  0% {
//    background-position: 0% 50%;
//  }
//  50% {
//    background-position: 50% 50%;
//  }
//  100% {
//    background-position: 100% 50%;
//  }
//}

@media screen and (max-width: 768px) {
  .d-record__time {
    font-size: 10px;
  }
  .d-record__patient {
    font-size: 11px;
  }
  .planned-block {
    font-size: 10px;
    line-height: 1;
  }
}
</style>
