import { serviceAdapter } from '@/adapters/services/service'
import { countries } from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'service',
    order: 'name',
    sort: 'asc',
    adapter: serviceAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_service',
    additionalFormComponent: 'ServicesAdditionalFormComponent',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    rights: {
        edit: "clinic_services",
        delete: "clinic_services",
    },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'name',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true,
                classes: "col-md-8 col-12",
                conditionFunc(store) {
                    return store.state.datatable.formTabActive === 'main'
                }
            }
        },
        {
            id: 'cod',
            title: 'code',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
                classes: "col-md-4 col-12",
                conditionFunc(store) {
                    return store.state.datatable.formTabActive === 'main'
                }
            }
        },
        {
            id: 'category_id',
            title: 'category',
            table: true,
            table_show: 'category.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                required: true,
                select_data: {
                    "title": "title",
                },
                conditionFunc(store) {
                    return store.state.datatable.formTabActive === 'main'
                }
            },
            tdcomponent: 'ServicesDatatableTDCategory',
        },
        {
            id: 'price_id',
            title: 'price_price',
            table: true,
            table_show: 'price.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                required: true,
                select_data: {
                    "title": "title",
                },
                conditionFunc(store) {
                    return store.state.datatable.formTabActive === 'main'
                }
            }
        },
        {
            id: 'cost',
            title: 'cost',
            table: true,
            sortable: true,
            filter: true,
            filter_number: true,
            tdcomponent: 'ServicesDatatableTDCost',
            form: {
                type: "number",
                required: true,
                component: "ServicesDatatableFormCard"
            }
        },
        {
            id: 'ndfl_code',
            title: 'ndfl_code',
            table: false,
            form: {
                type: "select",
                select_type: "list",
                options: [
                    { id: '01', title: '01' },
                    { id: '02', title: '02' },
                ],
                classes: "col-md-4 col-12",
                conditionFunc(store) {
                    return store.state.datatable.formTabActive === 'main' && store.state.auth.clinic.country_id == countries.RUSSIA // Russia
                }
            },
        },
        {
            id: 'price_can_be_changed',
            title: 'price_can_be_changed',
            form: {
                type: "checkbox",
                conditionFunc(store) {
                    return store.state.datatable.formTabActive === 'main'
                }
            },
        },
    ]

}
