<template>
  <div>
    <a class="btn-themed-squared btn-themed btn-themed-outline d-inline-block" @click="addPlanTreatment">{{ $t('add') }}</a>
    <DataTable :options="options"
               module="patients"
               :params="{ client_id: patient.id }"/>

    <PlanTreatmentModal/>
    <div v-if="imageTesting">
      <div ref="patientFormula" id="patientFormulaId">
        <PatientFormula style="display: none"
                        id="patientFormula"
                        :imageMaking="true"/>
      </div>
    </div>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/patients/plan_treatment"
import { mapState } from "vuex"
import PlanTreatmentModal from "@/components/parts/patients/PlanTreatmentModal"
import PatientFormula from "@/components/pages/patients/PatientFormula"

export default {
  name: "PatientTreatmentPlan",
  metaInfo() {
    return {
      title: this.$t("treatment_plan"),
      meta: []
    }
  },
  components: {
    PatientFormula,
    PlanTreatmentModal,
    DataTable
  },
  computed: {
    ...mapState({
      patient:  state => state.patient.patient,
    })
  },
  data() {
    return {
      options: options,
      imageTesting: true
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    this.$store.commit('setAdditionalParams', {
      client_id: this.patient.id
    })
  },
  methods: {
    addPlanTreatment() {
      this.$store.commit('setVisit', {
        client_id: this.patient.id,
        discount_type: 'percent',
        discount_value: 0,
        visits: [
          {
            services: {},
            diagnoses: {},
            manual_status: 'new'
          }
        ]
      })
      this.$bvModal.show('plan-treatment-modal')
    },
  },
  beforeDestroy() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>

<style scoped lang="scss">
.btn-themed-squared {
  margin-bottom: 10px;
}
</style>
