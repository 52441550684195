import general from './uk_general'
import header from './uk_header'
import sidebar from './uk_sidebar'
import calendar from './uk_calendar'
import warehouses from './uk_warehouses'
import patients from "./uk_patients"
import services from "./uk_services"
import staff from "./uk_staff"
import accounting from "./uk_accounting"
import reports from "./uk_reports"
import templates from "./uk_templates"
import cashdesk from "./uk_cashdesk"
import planning from "./uk_planning"
import marketing from "./uk_marketing"
import crm from "./uk_crm"
import egisz from "./uk_egisz"
import settings from "./uk_settings"
import nontranslated from "./uk_nontranslated"

export default {
    ...general,
    ...header,
    ...sidebar,
    ...calendar,
    ...warehouses,
    ...patients,
    ...services,
    ...staff,
    ...accounting,
    ...reports,
    ...templates,
    ...cashdesk,
    ...planning,
    ...crm,
    ...egisz,
    ...marketing,
    ...settings,
    ...nontranslated
}
