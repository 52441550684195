<template>
  <div>
    <DataTable :options="options"
               module="accounting"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/accounting/accountingmonth"

export default {
  name: "AccountingMain",
  metaInfo() {
    return {
      title: this.$t('accounting'),
      meta: []
    }
  },
  components: {
    DataTable
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
