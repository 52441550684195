export default {
    apply_plan_treatment: "Apply treatment plan",
    calls_planning: "Calls planning",
    choose_criterias_for_fill: "Select the required criteria to fill out",
    comments: "Comments",
    grouped: "Grouped",
    grouped_by_teeth: "Grouped by teeth",
    last_update: "Last update",
    level_description: "Level description",
    main_complaints: "Main complaints",
    notification_date: "Notification date",
    plan: "Plan",
    plan_treatment_status: "Treatment plan status",
    reset_all: "Reset all",
    route_list: "Route list",
    select_all: "Select all"
}
