<template>
  <b-modal id="visit-details-modal"
           size="xl"
           :title="$t('details_by_visits')"
           hide-footer
           centered>
    <table class="table-blue services-table">
      <thead>
      <tr>
        <th>Id</th>
        <th>{{ $t('date') }}</th>
        <th>{{ $t('doctor') }}</th>
        <th>{{ $t('patient') }}</th>
        <th>{{ $t('sum') }}</th>
        <th>{{ $t('paid') }}</th>
        <th>{{ $t('unpaid') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(v, vind) in visits" :key="`tr-${vind}`">
        <td>{{ v.id }}</td>
        <td>{{ v.readableStart }}</td>
        <td>{{ v.doctor ? v.doctor.full_name : '-' }}</td>
        <td>{{ v.patient ? v.patient.full_name : '-' }}</td>
        <td>{{ v.price | formatNumber }}</td>
        <td>{{ v.paid | formatNumber }}</td>
        <td>{{ (v.price - v.paid) | formatNumber }}</td>
        <!--        <td v-if="detailsType === 'invoiced'">{{ v.price | formatNumber }}</td>-->
      </tr>
      </tbody>
      <tfoot class="font-weight-bold border-top">
      <tr>
        <td>{{ $t('total') }}</td>
        <td></td>
        <td></td>
        <td></td>
        <td>{{ totalPrice | formatNumber }}</td>
        <td>{{ totalPaid | formatNumber }}</td>
        <td>{{ totalUnpaid | formatNumber }}</td>
      </tr>
      </tfoot>
    </table>

  </b-modal>
</template>

<script>
import {mapState} from "vuex"

export default {
  name: "ReportDayVisitDetails",
  props: {
    visits: {},
    detailsType: String
  },
  computed: {
    totalPrice() {
      return this.visits.reduce((a, b) => a + (b.price ? parseFloat(b.price) : 0), 0)
    },
    totalPaid() {
      return this.visits.reduce((a, b) => a + (b.paid ? parseFloat(b.paid) : 0), 0)
    },
    totalUnpaid() {
      return this.totalPrice - this.totalPaid
    },
    ...mapState({
      branchId: state => state.auth.branch,
    })
  }
}
</script>

<style scoped>

</style>
