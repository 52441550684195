export default {
    abs: "abs",
    activate: "Activate",
    add_category: "Add category",
    additional_materials: "Additional materials",
    archive: "Archive",
    are_you_sure_you_want_to_delete_category: "Are you sure you want to delete this category?",
    are_you_sure_you_want_to_delete_service: "Are you sure you want to delete this service?",
    average_price: "Average price",
    category: "Category",
    categories: "Categories",
    complex_service: "Complex service",
    complex_services: "Complex services",
    current_value: "Current price",
    discounted_price: "Discounted price",
    doctor_reward: "Doctor's reward",
    import_standard_services: "Import of standard services",
    manager: "Manager",
    manager_reward: "Manager reward",
    money_lost_on_discount: "Money lost on discount",
    ndfl_code: "NDFL code",
    packages_cost: "Prime cost of the packages",
    personal_percent_for_assistant: "Assistant's personal interest",
    personal_percent_for_doctor: "Doctor's personal interest",
    personal_percent_for_manager: "Manager's personal interest",
    personal_percent_for_technic: "Technician's personal interest",
    price_can_be_changed: "Price can be changed in the treatment card",
    pricelist_price: "Pricelist price",
    pricelist_short: "Pricelist",
    Service: "Service",
    service_category: "Category",
    service_code: "Service code",
    technic: "Technician",
    technic_reward: "Technician's reward"
}
