<template>
  <section id="staff" class="staff">
    <div v-if="!['StaffDoctorVisits', 'StaffDoctorSchedule', 'StaffDoctorFeedbacks'].includes($route.name)">
      <transition name="fade" mode="out-in" >
        <div class="section-top-row">
          <div class="row row-narrow align-items-center no-print position-relative">
            <div class="col order-md-0 order-0">
              <h1>{{ $t('staff') }}</h1>
            </div>
            <div class="col-md-auto col mt-md-0 mt-2 order-md-1 order-3 table-view-navigator">
              <SearchTable/>
            </div>
            <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator">
              <DataTableFilterButton/>
            </div>
            <div class="col-auto order-md-4 order-1 table-view-navigator">
              <DataTableExcel/>
            </div>
            <div class="col-auto order-md-5 order-2 table-view-navigator" v-if="canAdd">
              <AddObject module="staff"/>
            </div>
          </div>
        </div>
      </transition>
      <div class="sub-menu no-print">
        <template v-for="(m, mind) in menu">
          <router-link :to="{ name: m.to }"
                       v-if="!m.right || rights.includes(m.right)"
                       :key="`warehouse-link-${mind}}`">
            {{ $t(m.title) }}
          </router-link>
        </template>
      </div>
    </div>
    <router-view/>
  </section>
</template>

<script>

import SearchTable from "@/components/parts/datatable/SearchTable"
import AddObject from "@/components/parts/datatable/AddObject"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import JwtService from "@/services/jwt.service"
import {mapState} from "vuex"

export default {
  name: "StaffGeneral",
  components: {
    DataTableFilterButton,
    AddObject,
    SearchTable,
    DataTableExcel
  },
  data() {
    return {
      menu: [
        { to: 'StaffDoctors', title: 'doctors', right: 'doctors_page' },
        { to: 'StaffAssistants', title: 'assistants', right: 'assistants' },
        { to: 'StaffTechnics', title: 'technics' },
        { to: 'StaffAdministrators', title: 'administrators', right: 'create_admin' },
        { to: 'StaffChairs', title: 'chairs', right: 'create_chairs' },
      ]
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
      branch: state => state.auth.branch,
    }),
    canAdd() {
      if(this.$route.name == 'StaffDoctors' && !this.rights.includes('create_docs')) {
        return false
      }
      if(this.$route.name == 'StaffAssistants' && !this.rights.includes('assistants')) {
        return false
      }
      if(this.$route.name == 'StaffAdministrators' && !this.rights.includes('create_admin')) {
        return false
      }
      if(this.$route.name == 'StaffChairs' && !this.rights.includes('create_chairs')) {
        return false
      }
      return true
    }
  },
  methods: {
    printPrice() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/pricelist?branchId='+this.branch, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">

</style>
