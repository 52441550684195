<template>
  <div v-if="month">
    <DataTable :options="options"
               module="accounting"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/accounting/other_income_category"
import { mapState } from "vuex"

export default {
  name: "AccountingMonthOtherIncomeCategory",
  components: {
      DataTable,
  },
  metaInfo() {
    return {
      title: this.$t('categories'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      month: state => state.accounting.accountingmonth
    })
  },
  data() {
    return {
      options: options
    }
  },
  watch: {
      month(val) {
        this.$store.commit('setAdditionalParams', { bookkeeping_id: val.id })
      }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  },
  beforeDestroy() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>

<style scoped>

</style>
