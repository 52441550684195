<template>
  <section id="logs" class="logs">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print">
        <div class="col-md col-12 order-md-0 order-0">
          <h1>{{ $t('logs') }}</h1>
        </div>
        <div class="col-md-auto col mt-md-0 mt-2 table-view-navigator order-md-1 order-3">
          <SearchTable/>
        </div>
        <div class="col-auto table-view-navigator order-md-2 mt-md-0 mt-2 order-4">
          <DataTableFilterButton/>
        </div>
      </div>
    </div>
    <div>
      <DataTable :options="options"
                 class="td-different"
                 module="settings"/>
    </div>
  </section>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/settings/log"
import SearchTable from "@/components/parts/datatable/SearchTable"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"

export default {
  name: "Logs",
  components: {
    DataTableFilterButton,
    SearchTable,
    DataTable,
  },
  metaInfo() {
    return {
      title: this.$t('logs'),
      meta: []
    }
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>