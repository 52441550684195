<template>
  <section id="service" class="service">
    <transition name="fade" mode="out-in" >
      <div class="section-top-row">
        <div class="row row-narrow align-items-center no-print position-relative">
          <div class="col order-md-0 order-0">
            <h1>
              <span class="d-inline-block align-middle">{{ $t('services') }}</span>
              <ServicesHeaderPricesFilter class="ml-3 d-md-inline-block align-middle"/>
            </h1>
          </div>
          <div class="col-md-auto col mt-md-0 mt-2 order-md-1 order-3 table-view-navigator">
            <SearchTable/>
          </div>
          <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator">
            <DataTableFilterButton/>
          </div>
          <div class="col-auto table-view-navigator order-md-5 order-2" v-if="$route.name === 'ServicesServices'">
            <DataTableExcel/>
          </div>
          <div class="col-auto order-md-4 order-1 table-view-navigator">
            <a class="btn btn-themed gray d-md-inline-flex d-block align-items-center excel-btn" @click="printPrice">
              <PrintSvg class="svg-gray mr-md-2"/>
              <span>{{ $t('pricelist_short') }}</span>
            </a>
          </div>
          <div class="col-auto order-md-5 order-2 table-view-navigator" v-if="rights.includes('clinic_services')">
            <AddObject module="services"/>
          </div>
        </div>
      </div>
    </transition>
    <div class="sub-menu no-print">
      <router-link v-for="(m, mind) in menu" :to="{ name: m.to }" :key="`warehouse-link-${mind}}`">
        {{ $t(m.title) }}
      </router-link>
    </div>
    <router-view/>
  </section>
</template>

<script>

import SearchTable from "@/components/parts/datatable/SearchTable"
import AddObject from "@/components/parts/datatable/AddObject"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import PrintSvg from '@/assets/svg/general/print.svg'
import JwtService from "@/services/jwt.service"
import {mapState} from "vuex"
import ServicesHeaderPricesFilter from "@/components/parts/datatable/custom/services/ServicesHeaderPricesFilter.vue"

export default {
  name: "ServicesGeneral",
  components: {
    ServicesHeaderPricesFilter,
    DataTableFilterButton,
    AddObject,
    SearchTable,
    DataTableExcel,
    PrintSvg
  },
  data() {
    return {
      menu: [
        { to: 'ServicesServices', title: 'services' },
        { to: 'ServicesCategories', title: 'categories' },
        { to: 'ServicesArchive', title: 'archive' },
        { to: 'ServicesPrices', title: 'prices_price' },
        { to: 'ServicesComplexServices', title: 'complex_services' },
      ]
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
      branch: state => state.auth.branch,
    })
  },
  methods: {
    printPrice() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/pricelist?branchId='+this.branch, '_blank')
    }
  }
}
</script>
