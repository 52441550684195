<template>
  <div class="d-week__day position-relative d-flex align-items-center w-100"
       :class="{ today: isToday }">
    <div class="d-week__day_title">
<!--      {{ day.format('D MMM') }}-->
      {{ day.format('LL') }}
    </div>
    <div class="d-week__info" @click="goToDate">
      <div v-if="quantity > 0" class="d-week__number" :class="{  
          busy: doctor && future && minutesFree <= 0 
      }">
        {{ quantity }}
      </div>
      <div class="d-week__subtitle" :class="{  
          busy: doctor && future && minutesFree <= 0 && scheduleMinutes > 0
      }">
        {{ text }}
      </div>
      <div v-if="doctor && future" class="free-time-note">
        <template v-if="scheduleMinutes <= 0">{{ $t('not_working') }}</template>
        <template v-else>
          <div v-if="minutesFree" class="text-primary">{{ $t('free') }}: {{ minutesFree }} {{ $t('min_short') }}</div>
          <div v-else class="text-danger">{{ $t('no_free_time') }}</div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment"
import {mapState} from "vuex"

export default {
  name: "DMonthWeekDay",
  props: {
    day: Object,
    quantity: Number,
    recordMinutes: Number
  },
  computed: {
    text() {
      switch (this.quantity) {
        case null:
          return this.$tc('visits_count', 0)
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
          return this.$tc('visits_count', this.quantity)
      }
      return this.$t('visits_count_much')
    },
    isToday() {
      return moment().format('YYYY-MM-DD') == moment(this.day).format('YYYY-MM-DD')
    },
    ...mapState({
      selectedDoctors: state => state.calendar.selectedDoctors,
      doctorSchedules: state => state.calendar.doctorSchedules,
    }),
    doctor() {
      if(this.selectedDoctors.length === 1) return this.selectedDoctors[0]
      return null
    },
    schedule() {
      if(!this.doctor) return null
      let schedule = this.doctorSchedules[this.doctor.id]
      if(!schedule) return null
      let date = this.day.format('YYYY-MM-DD')
      return schedule.find(s => s.day == date)
    },
    scheduleMinutes() {
      if(!this.schedule) return 0
      return parseInt(this.schedule.minutes)
    },
    minutesFree() {
      return Math.max(0, (this.scheduleMinutes - this.recordMinutes).toFixed(0)) 
    },
    future() {
      let date = this.day.format('YYYY-MM-DD')
      let today = moment().format('YYYY-MM-DD')
      return date >= today 
    },
    
  },
  methods: {
    goToDate() {
      this.$store.commit('goToDate', this.day)
    }
  },
}
</script>

<style scoped lang="scss">

.d-week__day {
  border-left:1px solid #E5E5E5;
  background: white;
  height: 100px;
}
.d-week__day.today {
  background-color: #F1FCFF;
}
.d-week__day:last-child {
  border-right: 1px solid #E5E5E5;
}
.d-week__day_title {
  text-transform: uppercase;
  font-size: 11px;
  color: #BAC0C6;
  position: absolute;
  top: 5px;
  left: 5px;
}
.d-week__info {
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.d-week__info:hover {
  opacity: .5;
}
.d-week__number {
  font-size: 24px;
  font-weight: bold;
  color: black;
  &.busy {
    color: $danger-red;
  }
  &.free {
    color: $blue-color;
  }
}
.d-week__subtitle {
  text-transform: uppercase;
  font-size: 12px;
  color: #87939E;
  &.busy {
    color: $danger-red;
    opacity: 0.7;
  }
  &.free {
    color: $blue-color;
  }
}
.free-time-note {
  margin-top: 3px;
  font-size: 11px;
  line-height: 1;
  opacity: 0.8;
}

@media screen and (max-width: 768px) {
  .d-week__subtitle {
    font-size: 9px;
  }
  .d-week__day_title {
    font-size: 8px;
  }
  .d-week__number {
    font-size: 18px;
  }
  .free-time-note {
    font-size: 9px;
  }
}
</style>
