const ID_TOKEN_KEY = "user_token"
const SUPERADMIN_KEY = "superadmin"
const APP_VERSION = "app_version"
const SHOULD_RELOAD = "should_reload"

export const getLinkToken = () => {
  return btoa(getToken().substring(0,16))
};

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getPrintPrelink = (clinic, superadmin = false) => {
  let token = getLinkToken();
  let domain = clinic?.use_proxy ? process.env.VUE_APP_API_PROXY_DOMAIN : process.env.VUE_APP_API_DOMAIN;
  return domain+'/'+(superadmin ? 'superadmin' : clinic.id)+'/'+token
}

export const saveToken = (token, superadmin = false) => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
  if(superadmin) {
    window.localStorage.setItem(SUPERADMIN_KEY, true)
  }
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
  window.localStorage.removeItem(SUPERADMIN_KEY)
}

export const isSuperAdmin = () => {
  return !!window.localStorage.getItem(SUPERADMIN_KEY)
}

export const getAppVersion = () => {
  return window.localStorage.getItem(APP_VERSION)
}

export const shouldReload = () => {
  return window.localStorage.getItem(SHOULD_RELOAD) == 1
}

export const turnOffReload = () => {
  return window.localStorage.setItem(SHOULD_RELOAD, 0)
}

export const saveAppVersion = (appVersion) => {
  let currentAppVersion = getAppVersion()
  if(appVersion && currentAppVersion != appVersion) {
    window.localStorage.setItem(SHOULD_RELOAD, 1)
  }
  window.localStorage.setItem(APP_VERSION, appVersion)
}

export default {
  getToken,
  getLinkToken,
  saveToken,
  destroyToken,
  getPrintPrelink,
  isSuperAdmin,
  getAppVersion,
  saveAppVersion,
  shouldReload,
  turnOffReload
}
