<template>
  <b-row>
    <b-col>
      <SelectGroup :label="label"
                   :options="options"
                   variant="white"
                   title="full_name"
                   :showlabel="true"
                   v-model="user.id"/>
    </b-col>
    <b-col>
      <InputGroup :label="$t('sum') + ' ('+labelPart+')'"
                  type="number"
                  @keyup="percentLimit"
                  :required="true"
                  v-model="num"/>
      <div class="text-muted small validation-error"
           v-if="user.pivot.rate_type == 'percent' && absoluteSum > 0">
        = {{ absoluteSum | formatNumber }} {{ $store.getters.getCurrencyName }}
      </div>
    </b-col>
    <b-col cols="auto">
      <div class="form-group">
        <label class="form-label">{{ $t('type') }}&nbsp;&nbsp;</label>
        <select style="width: 110px"
                class="form-control"
                :disabled="!absoluteEnabled"
                v-model="user.pivot.rate_type">
          <option value="percent">%</option>
          <option value="absolute">{{ $store.getters.getCurrencyName }}</option>
          <option value="abs">{{ $store.getters.getCurrencyName }} ({{ $t('abs') }})</option>
        </select>
      </div>
    </b-col>
    <b-col cols="auto">
      <a class="d-block" @click="$emit('removeUser')">
        <label class="form-label">&nbsp;&nbsp;</label>
        <div class="mt-2"><TrashSvg/></div>
      </a>
    </b-col>
  </b-row>
</template>

<script>

import InputGroup from "@/components/form/InputGroup"
import SelectGroup from "@/components/form/SelectGroup"
import TrashSvg from "@/assets/svg/general/trash.svg"
import {mapState} from "vuex"

export default {
  name: "ServiceUserPercentFormPart",
  components: {
    InputGroup,
    SelectGroup,
    TrashSvg
  },
  data() {
    return {
      num: ''
    }
  },
  computed: {
    ...mapState({
      branch: state => state.auth.branch,
    }),
    labelPart() {
      return this.rateType === 'percent' ? '%' : this.$store.getters.getCurrencyName
    },
    rateType() {
      return this.user.pivot.rate_type
    },
    serviceCost() {
      return this.service.cost_json && this.service.cost_json[this.branch] ? this.service.cost_json[this.branch] : this.service.cost
    },
    absoluteSum() {
      let percent = this.user.pivot[this.percentField]
      return this.rateType === 'absolute' ? percent : (percent * this.serviceCost / 100)
    }
  },
  props: {
    user: Object,
    service: Object,
    options: {},
    label: String,
    view: {
      type: String,
      default: 'doctor'
    },
    percentField: {
      type: String,
      default: 'percent'
    },
    absoluteEnabled: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    percentLimit() {
      let p = this.num
      if(p < 0) this.num = 0
      if(p > 100 && this.rateType === 'percent') this.num = 100
      if(['absolute', 'abs'].includes(this.rateType) && Number(p) > Number(this.serviceCost)) this.num = this.serviceCost
    }
  },
  watch: {
    num() {
      if(this.rateType === 'abs') {
        this.user.pivot[this.percentField] = 0
        this.user.pivot.absolute = this.num
      } else {
        this.user.pivot[this.percentField] = this.num
        this.user.pivot.absolute = null
      }
    },
    rateType: {
      handler(val, oldval) {
        if(oldval) {
          let rate = oldval === 'abs' ? this.user.pivot.absolute  : this.user.pivot[this.percentField]
          let newRate = null
          let newAbs = null
          if(val === 'percent') {
            newRate = this.serviceCost > 0 ? ((rate / this.serviceCost) * 100) : 0
          }
          if(['absolute', 'abs'].includes(val) && oldval === 'percent') {
            newRate = this.serviceCost > 0 ? (rate * this.serviceCost / 100) : 0
          }
          if(['absolute', 'abs'].includes(val) && oldval !== 'percent') {
            newRate = rate
          }
          if(val === 'abs') {
            newAbs = rate
          }
          this.$set(this.user.pivot, this.percentField, newRate)
          this.$set(this.user.pivot, 'absolute', newAbs)
          this.num = newRate
        }
      },
      immediate: false,
    }
  },
  mounted() {
    this.num = Number(this.user.pivot.absolute) > 0 ? this.user.pivot.absolute : this.user.pivot[this.percentField]
  }
}
</script>
