<template>
  <span>
  <a class="data-table-action"
     :title="$t('retry')"
     v-b-tooltip.hover
     v-if="object.status === 'error'"
     @click="retry">
    <RefreshSvg/><span class="action-button-mob-title">{{ $t('retry') }}</span>
  </a>
  <a :title="$t('info')"
     v-b-tooltip.hover
     class="data-table-action"
     @click="info">
    <InfoSvg/><span class="action-button-mob-title">{{ $t('info') }}</span>
  </a>
    </span>
</template>

<script>

import RefreshSvg from '@/assets/svg/general/refresh2.svg'
import InfoSvg from '@/assets/svg/general/info2.svg'
import {mapState} from "vuex"
import {MarketingService} from "@/services/api.service"

export default {
  name: "MarketingNotificationDatatableActions",
  components: {
    RefreshSvg,
    InfoSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
  },
  methods: {
    async retry() {
      let res = await MarketingService.resendNotification(this.object.id)
      if(res.data) {
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
      }
    },
    async info() {
      this.$store.commit('setMarketingNotification', this.copyObject(this.object))
      this.$nextTick(() => {
        this.$bvModal.show('notification-info-modal')
      })
    }
  }
}
</script>

<style scoped>

</style>
