<template>
  <div class="pb-4">
    <div style="max-width: 650px;">
      <FileGroup label="Excel"
                 :key="`file-c-${counter}`"
                 :fid="`file-doctor-photo`"
                 :deletefile="true"
                 filetype="excel"
                 @deleteFile="deleteFile"
                 v-model="file"/>
      <div v-if="analysis && file">
        <div class="alert" :class="{ 'alert-info': analysisValid, 'alert-danger': !analysisValid }">
          <div>Найдено записей: {{ analysis.records }}</div>
          <div>Колонки найдены: {{ analysis.columns_found.join(', ') }}</div>
        </div>
        <button v-if="analysisValid" :disabled="loading" class="btn-themed btn-themed-squared mb-4" @click="save">
          {{ $t('upload') }}
        </button>
      </div>

      <div v-if="!file">
        <b-button class="btn-collapse" v-b-toggle="'collapse-inst'">Инструкции</b-button>
        <b-collapse id="collapse-inst">
          <ul class="pt-4">
            <li>Файл должен быть в формате xlsx</li>
            <li>В файле должен быть только один лист</li>
            <li>Первая строка должна содержать названия колонок</li>
            <li>Последующие строки должны содержать значения услуг (1 строка - 1 услуга) - не объединять ячейки</li>
            <li>Не оставлять никаких значений в ячейках ниже основной таблицы (даже пробелы, закрашенные ячейки и тд)</li>
            <li>Принимаются следующие названия колонок: 'Название', 'Код', 'Id категории', 'Стоимость', 'НДФЛ', 'Id'</li>
            <li>Поле <code>Стоимость</code> должно содержать только цифровое значение</li>
            <li>Поле <code>Id категории</code> должно содержать только цифровое значение (id категории нижнего уровня)</li>
            <li>Поле <code>Id прайса</code> должно содержать только цифровое значение</li>
            <li>Поле <code>НДФЛ</code> должно содержать только значения 01 или 02 (в любом другом случае будет загружаться как 01)</li>
            <li>Если заполнено поле <code>Id</code>, то система будет пытаться обновить данные уже существующей услуги (если у клиники услуги с таким id нет, то новая создаваться не будет)</li>
            <li>Загружать файлы с кол-вом услуг не более 3 000. Если у клиники больше, то лучше разбить на несколько файлов (заголовки ставить на первую строку у каждого файла)</li>
          </ul>
        </b-collapse>
      </div>

      <div class="mt-5" v-if="imports.length">
        <table class="table-blue services-table">
          <thead>
          <tr>
            <th>id</th>
            <th>{{ $t('records') }}</th>
            <th>{{ $t('created') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(i, ind) in imports" :key="`tr-i-${ind}`">
            <td>{{ i.id }}</td>
            <td>{{ i.records }}</td>
            <td>{{ i.created_at | formatDateTime }}</td>
            <td>
              <a class="pointer" @click="cancelImport(i.id)">
                <TrashSvg/>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex"
import FileGroup from "@/components/form/FileGroup"
import {SuperadminService} from "@/services/api.service"
import TrashSvg from "@/assets/svg/general/trash.svg"

export default {
  name: "SuperAdminClinicImport",
  components: {
    FileGroup,
    TrashSvg
  },
  metaInfo() {
    return {
      title: this.$t('import'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
    }),
    analysisValid() {
      return this.analysis && this.analysis.records > 0 && this.analysis.columns_found.length
    }
  },
  data() {
    return {
      counter: 0,
      file: null,
      analysis: null,
      imports: []
    }
  },
  async mounted() {
    await this.getImports()
  },
  methods: {
    async save() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, { file: this.file })
      let res = await SuperadminService.importServicesFile(this.clinic.id, formData)
      this.analysis = null
      this.file = null
      this.$noty.info(`Создано ${res.data.records} услуг`)
      this.counter++
      await this.getImports()
    },
    async getImports() {
      let res = await SuperadminService.getServicesImports(this.clinic.id)
      this.imports = res.data
    },
    async cancelImport(import_id) {
      if(confirm('Вы уверены, что хотите отменить этот импорт? Все импортированные услуги будут удалены, даже те, которые были изменены при импорте!')) {
        await SuperadminService.cancelImport(this.clinic.id, import_id)
        await this.getImports()
      }
    },
    deleteFile() {
      this.file = null
      this.counter++
    }
  },
  watch: {
    file(val) {
      if(val) {
        let formData = new FormData()
        formData = this.appendFormdata(formData, { file: val })
        SuperadminService.analyzeServicesImportFile(this.clinic.id, formData).then(res => {
          this.analysis = res.data
        })
      }
    }
  },

}
</script>
