<template>
  <div class="mt-4">
    <a class="btn-themed btn-themed-outline btn-themed-squared d-inline-block align-middle" @click="addStandard">
      {{ $t('add_standard') }}
    </a>
    <DataTable :options="options"
               class="mt-4"
               module="marketing"/>
  </div>
</template>

<script>

import {mapState} from "vuex"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/marketing/notification_setting"
import {MarketingService} from "@/services/api.service"

export default {
  name: "MarketingNotifications",
  components: {
    DataTable,
  },
  metaInfo() {
    return {
      title: this.$t('notifications'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    }),
  },
  data() {
    return {
      options: options
    }
  },
  methods: {
    addStandard() {
      MarketingService.createStandardNotifications().then(() => {
        this.$store.commit('incrementDataTableCounter')
      })
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
