<template>
  <div class="d-calendar__column w-100">
    <div class="d-calendar__title"
         v-if="item && item.type === 'doctor'"
         :id="`doctor-popover-${dayFormatted}-${item.item.id}`"
         :style="{ backgroundColor: item.item.color }">
      <!--      <router-link :to="{ name: 'StaffDoctorVisits', params: { id: item.id} }">-->
      <!--        <img class="round-img mr-2"-->
      <!--             :style="{ borderColor: item.item.color }"-->
      <!--             :src="item.item.photo"-->
      <!--             alt=""/>{{ item.title }}-->
      <!--        <span class="d-inline-block" v-if="schedule">{{ schedule }}</span>-->
      <!--      </router-link>-->
      <a class="pointer" :style="{ color: clinic.calendar_color ? clinic.calendar_color : 'white' }">
        <img class="round-img mr-2"
             @click="doctorMouseEnter(item.item, ...arguments)"
             :style="{ borderColor: item.item.color }"
             :src="item.item.photo"
             alt=""/>
        <span @click="doctorMouseEnter(item.item, ...arguments)">{{ item.title }}</span>
        <span @click="selectDoctor"
              class="ml-1 d-inline-block"
              v-if="schedule">{{ schedule }}</span>
      </a>

      <a @click.stop="editDoctorSchedule()"
         v-if="rights.includes('work_time_edit')"
         class="pl-md-2 pl-1 pr-1 pointer"
         style="margin-bottom: 3px">
        <EditSvg class="svg-size-sm svg-white"/>
      </a>
    </div>
    <b-popover :show.sync="showPopover"
               v-if="doctorHovered && doctorHovered.id === item.item.id"
               :target="`doctor-popover-${dayFormatted}-${item.item.id}`"
               :key="`popover-title-${item.id}`">
      <DoctorHoverCalendar :doctor="doctorHovered"
                           v-click-outside="clickedOutside"
                           :day="day"/>
    </b-popover>




    <div v-if="item && item.type !== 'doctor'" class="d-calendar__title">
      <div class="w-100 h-100 d-flex flex-column justify-content-center">
        <div>
          <a v-if="item.type == 'day'" tabindex="#" class="pointer" @click="navigateToDay">{{ item.title }}</a>
          <span v-else>{{ item.title }}</span>

          <a @click.stop="editChairSchedule"
             v-if="curView == 'CHAIR' && rights.includes('work_time_edit')"
             class="chair-schedule-btn pointer position-absolute" style="right: 5px; top: 2px;">
            <ScheduleSvg/>
          </a>
        </div>

        <div v-if="item.type === 'chair' && doctorsWithScheduleForChair.length > 0"
             style="flex: 2"
             class="d-flex flex-column">
          <template v-for="(d, dind) in doctorsWithScheduleForChair">
            <div :key="`chair-title-${item.id}-${dind}`"
                 @click="doctorMouseEnter(d, ...arguments)"
                 class="pointer"
                 :id="`doctor-popover-${day}-${d.id}`"
                 style="flex: 1">
              <div class="doctor-col h-100 d-flex flex-column justify-content-center"
                   :style="{ backgroundColor: d.color }"
                   :class="{ 'hasColor': d.color, oneLiner: oneLiner || smallCol, smallCol }">
                <b-row class="no-gutters align-items-center flex-nowrap">
                  <b-col cols="auto" v-if="colWidth >= 178">
                    <img class="round-img mr-2" :src="d.photo" alt=""/>
                  </b-col>
                  <b-col col class="text-left">
                    <b-row class="align-items-center no-gutters" :style="{ color: clinic.calendar_color ? clinic.calendar_color : 'white' }">
                      <b-col :cols="smallCol || !oneLiner ? 12 : ''"
                             class="doctor-chair-title"
                             :style="{ maxWidth: `${(colWidth < 178 ? (colWidth - 10) : (colWidth - 40))}px`}">
                          {{ d.full_name }}
                      </b-col>
                      <b-col :cols="smallCol || !oneLiner ? 12 : 'auto'">
                        <span style="opacity: 0.7;"
                              :style="{ width: smallCol || !oneLiner ? 'auto' : 65+'px' }"
                              class="d-block">
                          {{ getDoctorSchedule(d.id, item) }}
                        </span>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </div>
            </div>
            <b-popover :show.sync="showPopover"
                       v-if="doctorHovered && doctorHovered.id === d.id"
                       :target="`doctor-popover-${day}-${d.id}`"
                       :key="`popover-title-${item.id}-${dind}`">
              <DoctorHoverCalendar :doctor="doctorHovered"
                                   v-click-outside="clickedOutside"
                                   :chair="item"
                                   :day="day"/>
            </b-popover>
          </template>

        </div>

      </div>

    </div>

    <div v-if="!item" class="d-calendar__title">
      {{ $t('no_schedule') }}
      <a @click.stop="editChairSchedule" v-if="curView == 'CHAIR'" class="chair-schedule-btn pointer position-absolute" style="right: 5px; top: 2px;">
        <ScheduleSvg/>
      </a>
    </div>
  </div>
</template>

<script>

import ColumnTitle from "@/components/pages/calendar/calendar/classes/ColumnTitle"
import {mapGetters, mapState} from "vuex"
import moment from "moment-timezone"
import EditSvg from '@/assets/svg/general/edit_s0.svg'
import ScheduleSvg from '@/assets/svg/calendar/add_schedule.svg'
import DoctorHoverCalendar from "@/components/parts/calendar/DoctorHoverCalendar.vue"

export default {
  name: "DColumnTitle",
  components: {
    DoctorHoverCalendar,
    EditSvg,
    ScheduleSvg
  },
  props: {
    item: ColumnTitle,
    day: {},
    colWidth: {}
  },
  data() {
    return {
      doctorHovered: null,
      showPopover: false,
      justOpened: false
    }
  },
  computed: {
    ...mapState({
      curView: state => state.calendar.curView,
      doctorSchedules: state => state.calendar.doctorSchedules,
      doctors: state => state.calendar.doctors,
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
      selectedProfessions: state => state.calendar.selectedProfessions,
    }),
    ...mapGetters([
      'doctorHasProfession'
    ]),
    schedule() {
      if(this.item.type !== 'doctor') return ''
      return this.getDoctorSchedule(this.item.id)
    },
    dayFormatted() {
      return moment(this.day).format('YYYY-MM-DD')
    },
    doctorsWithScheduleForChair() {
      if(this.item.type !== 'chair') return []
      return this.doctors
          .filter(d => this.doctorSchedules && this.doctorSchedules[d.id] && this.getDoctorSchedule(d.id, this.item) && this.doctorFitsProfession(d))
          .sort((a, b) => {
            const schedulesA = this.doctorSchedules[a.id].filter(s => s.day === this.dayFormatted)
            const schedulesB = this.doctorSchedules[b.id].filter(s => s.day === this.dayFormatted)
            if(!schedulesB || !schedulesB.length) return 1
            if(!schedulesA || !schedulesA.length) return -1
            return (schedulesA[0].time_from > schedulesB[0].time_from) ? 1 : -1
          })
    },
    smallCol() {
      return this.colWidth < 150
    },
    oneLiner() {
      return this.doctorsWithScheduleForChair.length > 1
    }
  },
  methods: {
    navigateToDay() {
      this.$store.commit('goToDate', this.item.date)
    },
    selectDoctor() {
      const newList = this.doctors.filter(doc => doc.id === this.item.id)
      this.$store.commit('setSelectedDoctors', newList)
      this.$store.commit('setDoctors', this.copyObject(this.doctors))
    },
    getDoctorSchedule(doctor_id, chair = null) {
      let res = []
      if(this.doctorSchedules && this.doctorSchedules[doctor_id]) {
        this.doctorSchedules[doctor_id].forEach((sch) => {
          if(sch.time_from && sch.time_to && sch.day == this.dayFormatted) {
            if(chair && sch.chair_id && sch.chair_id != chair.id) return; // schedule has specific chair and it's not it.
            if(chair && !sch.chair_id && !chair.item.doctors.find(d => d.id === doctor_id)) return; // specific chair is not populated for doctor schedule, and he is not assigned to chair
            res.push(`${sch.time_from.substring(0,5)} - ${sch.time_to.substring(0,5)}`)
          }
        })
      }
      return res.sort().join(', ')
    },
    doctorFitsProfession(doctor) {
      if(!this.selectedProfessions || !this.selectedProfessions.length) return true
      return this.doctorHasProfession(doctor)
    },
    async editChairSchedule() {
      if(!this.item) {
        this.editDoctorSchedule(null, null)
        return
      }
      const doctors = this.doctors.filter(d => {
        return this.doctorSchedules && this.doctorSchedules[d.id] && this.getDoctorSchedule(d.id, this.item) && this.doctorFitsProfession(d)
      })
      if(!doctors.length) {
        this.editDoctorSchedule(null, this.item)
        return
      }
      const schedules = []
      for (const d of doctors) {
        const schedule = this.copyObject(this.doctorSchedules[d.id].filter(x => x.day === this.dayFormatted && (!x.chair_id || Number(x.chair_id) === this.item.id)))
        schedule.map(s => s.chair_id = this.item.id)
        schedules.push(schedule)
        this.$store.commit('setDoctor', d)
      }
      this.$store.commit('setDoctorPreconfig', schedules.flat())
      this.$store.commit('setDoctorScheduleDay', this.day)
      await this.$nextTick()
      this.$bvModal.show('doctor-day-edit-modal')
    },
    editDoctorSchedule(doctor = null, chair = null) {
      if(!doctor && this.item) doctor = this.doctors.find(doc => doc.id == this.item.id)
      if(doctor && this.doctorSchedules && this.doctorSchedules[doctor.id]) {
        const schedule = this.copyObject(this.doctorSchedules[doctor.id].filter(x => x.day === this.dayFormatted))
        if(chair) schedule.map(s => s.chair_id = chair.id)
        this.$store.commit('setDoctorPreconfig', schedule)
      } else {
        this.$store.commit('setDoctorPreconfig', [{
          day: moment(this.day).format('YYYY-MM-DD'),
          doctor_id: doctor ? doctor.id : null,
          chair_id: chair ? chair.id : (doctor && doctor.chairs.length ? doctor.chairs[0].id : null),
          time_from: this.$store.state.calendar.timeFromHour.toString().padStart(2, '0') + ':00',
          time_to: this.$store.state.calendar.timeToHour.toString().padStart(2, '0') + ':00',
        }])
      }
      this.$store.commit('setDoctor', doctor)
      this.$store.commit('setDoctorScheduleDay', this.day)
      this.$nextTick(() => {
        this.$bvModal.show('doctor-day-edit-modal')
      })
    },
    doctorMouseEnter(doctor, e) {
      e.preventDefault()
      this.justOpened = true
      setTimeout(() => { this.justOpened = false },20)
      if(this.doctorHovered && this.doctorHovered.id === doctor.id) {
        this.doctorHovered = null
        this.showPopover = false
        return
      }
      this.doctorHovered = doctor
      this.showPopover = true
    },
    clickedOutside() {
      if(!this.justOpened) {
        this.doctorHovered = null
        this.showPopover = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.d-calendar__column:last-child {
  border-right: 1px solid #E5E5E5;
}
.round-img {
  width: 22px;
  height: 22px;
  border-width: 2px;
  border-style: solid;
}
.d-calendar__title {
  background-color: #F2F6FA;
  font-size: 13px;
  border-left: 1px solid #B5C6D8;
  text-align: center;
  height: 100%;
  display: flex;
  word-break: break-word;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: relative;
  /*padding: 3px 5px;*/
  padding: 3px 0 0 0;
  -webkit-box-shadow: 0px 7px 13px -10px rgba(34, 60, 80, 0.2) inset;
  -moz-box-shadow: 0px 7px 13px -10px rgba(34, 60, 80, 0.2) inset;
  box-shadow: 0px 7px 13px -10px rgba(34, 60, 80, 0.2) inset;
  .chair-schedule-btn {
    display: none;
    svg {
      width: 15px;
      height: 15px;
    }
  }
  &:hover {
    .chair-schedule-btn {
      display: inline-block;
    }
  }
}
.d-calendar__title, .d-calendar__title a {
  color: #5D6D7C;
}
.doctor-col {
  padding: 2px 5px;
  border-radius: 2px;
  font-size: 11px;
  &.hasColor {
    color: #fff;
  }
  .doctor-chair-title {
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
  }
  &.smallCol {
    .doctor-chair-title {
      white-space: normal;
    }
  }
  &.oneLiner {
    .doctor-chair-title {

    }
  }
}
@media screen and (max-width: 768px) {
  .d-calendar__title {
    font-size: 12px;
    .doctor-col {
      font-size: 10px;
    }
  }
}
</style>
