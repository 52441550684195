<template>
  <section id="accounting" class="accounting">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print position-relative">
        <div class="col order-md-0 order-0">
          <h1 v-if="$route.name === 'AccountingMain'">{{ $t('accounting') }}</h1>
          <h1 v-else>
        <span class="d-md-inline d-none">
        <router-link :to="{ name: 'AccountingMain' }">{{ $t('accounting') }}</router-link>
          / </span> <span class="text-capitalize" v-if="month">{{ month.date | formatDate('MMMM, YYYY') }}</span>
          </h1>
        </div>
        <div class="col-auto table-view-navigator order-md-4 order-1">
          <DataTableExcel/>
        </div>
        <div class="col-auto order-md-5 order-2 table-view-navigator">
          <AddObject module="accounting"/>
        </div>
      </div>
    </div>
    <router-view/>
  </section>
</template>

<script>

import AddObject from "@/components/parts/datatable/AddObject"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import { mapState } from "vuex"

export default {
  name: "AccountingGeneral",
  components: {
    AddObject,
    DataTableExcel
  },
  computed: {
    ...mapState({
      month: state => state.accounting.accountingmonth
    })
  }
}
</script>
