export default {
    "-": "-",
    actions: "Harakatlar",
    actions_with_selected: "Tanlangan bilan harakatlar",
    active: "Faol",
    add: "Qo‘shish",
    add_attachment: "Qo'shiq qo'shish",
    add_condition: "Shart qo‘shish",
    all: "Hammasi",
    app_is_in_maintenance_mode: "Ilovada texnik ishlar olib borilmoqda. Biz albatta tez orada qaytamiz",
    are_you_sure_you_want_to_delete: "Haqiqatan ham materialni o‘chirmoqchimisiz?",
    are_you_sure_you_want_to_logout: "Haqiqatan ham chiqmoqchimisiz?",
    authorization: "Avtorizatsiya",
    authorize_in_system: "Tizimga avtorizatsiya qiling",
    balance: "Depozit",
    cancel: "Bekor qilish",
    change: "O‘zgartirish",
    change_password: "Parolni o‘zgartirish",
    comment: "Izoh",
    contacts: "Kontaktlar",
    contains: "O‘z ichiga oladi",
    continue: "Davom ettirish",
    currency: "Valyuta",
    delete: "O‘chirish",
    does_not_equal: "Teng emas",
    done: "Tayyor",
    down: "Pastga",
    edit: "Tahrir qilish",
    email: "Email",
    english: "Ingliz tili",
    error: "Xato",
    error_message: "Nimadir xato ketdi, operatsiya muvaffaqiyatli yakunlanmadi",
    equals: "Teng",
    excel: "Excel",
    file: "Fayl",
    file_does_not_exist: "Fayl topilmadi",
    file_size_is_too_big: "Fayl hajmi juda katta",
    filled: "To‘ldirildi",
    filter: "Filtr",
    finish: "Tugatish",
    forgot_password: "Parolni unutdingizmi?",
    hide: "Yashirish",
    home: "Asosiy",
    image: "Rasm",
    import: "Import",
    input_field: "Matnni kiritish uchun maydon",
    KZT: "KZT",
    language: "Til",
    less: "Kamroq",
    load_more: "Ko'proq yuklang",
    login: "Login",
    login_or_pass_incorrect: "Login va / yoki parol noto‘g‘ri",
    logout: "Chiqish",
    main: "Asosiy",
    message: "Xabar",
    module_unavailable: "Modul mavjud emas",
    months: "Oylar",
    more: "Ko‘proq",
    name: "Nom",
    no: "Yo‘q",
    no_data_available: "Hozircha hech qanday ma’lumot yo‘q",
    nonactive: "Faol emas",
    not_enough_rights_for_this_operation: "Ushbu operatsiya uchun huquqlar yetarli emas",
    nothing_found: "Hech narsa topilmadi",
    old_password: "Eski parol",
    on_page: "Sahifada",
    page_not_found: "Sahifa topilmadi",
    password: "Parol",
    password_changed: "Parol o‘zgartirildi",
    password_confirm: "Parolni tasdiqlash",
    passwords_do_not_match: "Parollar mos kelmayapti",
    patient_search: "Bemorni qidirish",
    payment_expires_at: "To'lov muddati tugaydi",
    phone: "Telefon",
    photo: "Foto",
    price: "Narx",
    print_noun: "Nashr",
    program: "Dastur",
    profile: "Profil",
    registration: "Ro‘yxatga olish",
    refresh: "Yangilash",
    requirements: "Talablar",
    reset: "Qayta tiklash",
    reset_password: "Parolni qayta tiklash",
    reset_success: "Qayta tiklash xati sizning elektron pochtangizga yuborildi",
    role: "Rol",
    russian: "Rus tili",
    save: "Saqlash",
    search: "Qidirish",
    select: "Tanlash",
    sent: "Yuborildi",
    settings: "Sozlamalar",
    signin: "Kirish",
    show: "Ko‘rsatish",
    slogan: "Zamonaviy stomatologiyani boshqarish uchun bulutli yechim",
    start: "Boshlash",
    status: "Holat",
    subscription_sum: "Har bir obuna uchun miqdor",
    success_message: "Operatsiya muvaffaqiyatli yakunlandi",
    sum: "Summa",
    table_search: "Jadval bo‘yicha qidiruv",
    text: "Matn",
    this_module_is_not_in_tariff: "Bu modul Free tarifga kiritilmagan, siz Standard yoki Premium tarifni ulashingiz kerak, va siz Dentist Plus dasturining katta hajmli funksiyalaridan foydalana olasiz.",
    title: "Nomlanishi",
    today: "Bugun",
    total: "Jami",
    translation: "Tarjima",
    unknown: "Noma’lum",
    upload: "Yuklash",
    yes: "Ha",
}
