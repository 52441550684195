<template>
  <div>
    <span class="text-primary" v-if="object.version2">{{ $t('yes') }}</span>
    <span class="text-secondary" v-if="!object.version2">{{ $t('no') }}</span>
<!--    <span class="text-danger" v-if="object.blocked == 2">Удален</span>-->
  </div>
</template>

<script>
export default {
  name: "SuperAdminDatatableTDNewVersion",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
