import { leadStatuses, leadSources } from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'lead',
    order: 'id',
    sort: 'desc',
    // modalsize: 'lg',
    // adapter: taskAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_lead',
    actionscomponent: 'CRMLeadDatatableActions',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true,
            form: {
                component: "CRMDatatableLeadFormCard"
            }
        },
        {
            id: 'phone',
            title: 'phone',
            table: true,
            sortable: true,
            filter: true,
            format_phonecall: true,
            // form: {
            //     type: "text",
            //     required: true
            // },
        },
        {
            id: 'fname',
            title: 'name',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
        },
        {
            id: 'patient_id',
            title: 'patient',
            // table: true,
            // sortable: true,
            table_show: 'patient.full_name',
            filter: true,
        },
        {
            id: 'reason',
            title: 'application_reason',
            table: true,
            filter: true,
            form: {
                type: "textarea",
            }
        },
        {
            id: 'comment',
            title: 'comment',
            table: true,
            filter: true,
            form: {
                type: "textarea",
            }
        },
        {
            id: 'created_by',
            title: 'author',
            table: true,
            sortable: true,
            table_show: 'author.full_name',
            filter: true,
            filter_model_select: true,
        },
        {
            id: 'created_at',
            title: 'created',
            table: true,
            sortable: true,
            format_datetime: true,
            filter: false,
            filter_date: true
        },
        {
            id: 'status',
            title: 'status',
            filter: true,
            filter_list_select: true,
            table: true,
            sortable: true,
            form: {
                type: "select",
                select_type: "list",
                options: leadStatuses,
            },
        },
        {
            id: 'source',
            title: 'source',
            table: true,
            filter: true,
            filter_list_select: true,
            format_translate: true,
            form: {
                type: "select",
                select_type: "list",
                disabled_on_edit: true,
                required: true,
                options: leadSources,
            },
        },
        {
            id: 'call_date',
            title: 'recall',
            table: false,
            sortable: true,
            format_datetime: true,
            filter: true,
            filter_date: true,
            form: {
                type: "date"
            },
        },
        {
            id: 'urgent',
            title: 'urgent',
            table: true,
            sortable: true,
            tdcomponent: 'CRMDatatableTDYesNoBoolean',
            form: {
                type: "checkbox",
            },
        },
        {
            id: 'date_from',
            title: 'date_from',
            filter: true,
            filter_date: true,
        },
        {
            id: 'date_to',
            title: 'date_to',
            filter: true,
            filter_date: true,
        }
    ]

}
