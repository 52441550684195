export default {
    adjustment: "Корректировка",
    add_all_materials_from_warehouse: "Добавить все материалы со склада",
    add_material: "Добавить материал",
    add_new: "Добавить новый",
    adding_new_supplier: "Добавление нового поставщика",
    address: "Адрес",
    addition: "Приход",
    amount: "Кол-во",
    amount_of_stock_on_all_warehouses: "Общее количество товара, доступное на всех складах клиники",
    approve: "Провести",
    approved: "Проведен",
    are_you_sure_you_want_to_delete_group: "Вы уверены, что хотите удалить группу?",
    are_you_sure_you_want_to_delete_kit: "Вы уверены, что хотите удалить комплект?",
    are_you_sure_you_want_to_delete_supplier: "Вы уверены, что хотите удалить поставщика?",
    are_you_sure_you_want_to_delete_warehouse: "Вы уверены, что хотите удалить склад?",
    artikul: "Артикул",
    bank_requisites: "Банковские реквизиты",
    barcode: "Штрихкод",
    bin: "БИН",
    branch: "Филиал",
    bulk_addition: "Добавить группой",
    by_categories: "По категориям",
    consumption: "Расход",
    consumption_manual: "Ручной расход",
    contact_person: "Контактное лицо",
    cost: "Стоимость",
    created: "Создан",
    destination: "Назначение",
    details: "Детали",
    difference: "Расхождение",
    documents: "Документы",
    draft: "Черновик",
    enter_title_or_barcode: "Введите наименование или штрихкод",
    factual_amount: "Кол-во по факту",
    finished: "Проведен",
    from: "от",
    from_warehouse: "Перемещение со склада",
    from_where: "Откуда",
    general_price: "Общая сумма",
    general_stock: "Общий остаток",
    group: "Группа",
    groups: "Группы",
    id: "Id",
    inventarization: "Инвентаризация",
    Kit: "Комплект",
    kit: "Комплект",
    kits: "Комплекты",
    material: "Материал",
    materialgroup: "Группа",
    materialstock: "Материал",
    materials: "Материалы",
    movement: "Перемещение",
    movements: "Движения",
    movement_from: "Перемещение",
    movement_to: "Перемещение",
    nomenclature: "Номенклатура",
    notify_when_stock: "Уведомлять при остатке",
    operation: "Операция",
    parent_group: "Родительская группа",
    physical: "Физический",
    print: "Распечатать",
    return: "Возврат",
    stocks: "Остатки",
    stocks_archive: "Архив материалов",
    supplier: "Поставщик",
    suppliers: "Поставщики",
    to_warehouse: "Перемещение на склад",
    to_where: "Куда",
    type: "Тип",
    unit_of_measure: "Ед./изм.",
    virtual: "Виртуальный",
    warehouse_amount: "Кол-во на складе",
    warehouses: "Склады",
    warehouseoperation: "Операции",
    write_off: "Списание"
}
