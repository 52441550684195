<template>
  <div>
    <DataTable :options="options"
               :params="{ clinic_id: clinic.id }"
               module="superadmin"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/superadmin/branch"
import {mapState} from "vuex"

export default {
  name: "SuperAdminClinicBranches",
  components: { DataTable },
  metaInfo() {
    return {
      title: this.$t('branches'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
