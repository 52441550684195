<template>
  <div :class="{
    'text-danger': ['error'].includes(object.status),
    'text-warning': ['cancelled'].includes(object.status),
    'text-muted': object.status === 'waiting',
    'text-success': object.status === 'sent'
  }">
    <WarningSvg v-if="object.status === 'error'" class="mr-2"/>
    <ClockSvg v-if="object.status === 'waiting'" class="mr-2"/>
    <span>{{ $t(object.status) }}</span><span v-if="jetAPIStatus"> -> {{ jetAPIStatus }}</span>
    <div v-if="object.status != 'cancelled'">{{ $t(dateLabel) }}: {{ dateValue | formatDateTime }}</div>
  </div>
</template>

<script>

import WarningSvg from '@/assets/svg/general/warning.svg'
import ClockSvg from '@/assets/svg/header/clock3.svg'
import {jetAPIStatuses} from "@/dictionaries/dictionary"

export default {
  name: "PatientsDatatableTDNotificationStatus",
  components: {
    WarningSvg,
    ClockSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    dateLabel() {
      return {
        'sent': "send_date",
        'waiting': "planned_send_date",
        'error': "send_try"
      }[this.object.status] ?? 'send_date'
    },
    dateValue() {
      if(this.object.status === 'error') return this.object.updated_at_timezoned
      if(this.object.status === 'waiting') return this.object.ignore_till_timezoned
      if(this.object.status === 'sent') return this.object.sent_at_timezoned
      return this.object.sent_at
    },
    jetAPIStatus() {
      if(this.object.vendor_status_id === null) return null
      const status = jetAPIStatuses.find(status => status.code === this.object.vendor_status_id)
      return status?.status || null
    }
  }
}
</script>
