<template>
  <b-modal id="tariff-functionality-modal"
           :title="$t('functionality')"
           @show="onShow"
           @hide="onHide"
           size="lg"
           centered>
    <div v-if="loaded">
      <b-row class="mb-3" v-for="(f, find) in functionList" :key="`fl-${find}`">
        <b-col col>
          {{ f.title }}
        </b-col>
        <b-col cols="auto">
          <div class="d-inline-block">
            <CheckboxGroup :label="$t('on')"
                           class="mb-0"
                           v-model="f.status"
                           :fid="`chk-${find}`"/>
          </div>
          <div class="d-inline-block ml-3">
            <CheckboxGroup :label="$t('requires_payment')"
                           class="mb-0"
                           :checkboxvalue="f.id"
                           :readonly="!f.status"
                           @input="showChecked(f.id, ...arguments)"
                           v-model="showPayments"
                           :fid="`pay-chk-${find}`"/>
          </div>
        </b-col>
        <b-col cols="12" v-if="f.status && showPayments.includes(f.id)">
          <CurrencyJsonGroup :label="$t('payment')"  v-model="f.payment"/>
        </b-col>
      </b-row>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel mr-3 d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('cancel') }}
        </b-button>
        <button @click="save"
                class="w-auto px-3 btn-themed align-middle btn-themed-squared">
          {{ $t('save') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import { SuperadminService } from "@/services/api.service"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import {tariffFunctionalityAdapter} from "@/adapters/superadmin/tariffFunctionality"
import CurrencyJsonGroup from "@/components/form/CurrencyJsonGroup"

export default {
  name: "TariffFunctionalityModal",
  components: {
    CurrencyJsonGroup,
    CheckboxGroup
  },
  computed: {
    ...mapState({
      tariff: state => state.superadmin.tariff,
    }),
  },
  data() {
    return {
      loaded: false,
      functionList: [],
      showPayments: [],
    }
  },
  methods: {
    async save() {
      await SuperadminService.updateTariffFunctionality(this.tariff.id, tariffFunctionalityAdapter(this.functionList))
      this.$noty.info(this.$t('success_message'))
      this.$bvModal.hide('tariff-functionality-modal')
    },
    showChecked(function_id, new_list) {
      if(!new_list.includes(function_id)) {
        this.functionList.forEach((item, index) => {
          if(item.id == function_id) this.functionList[index].payment = null
        })
      }
    },
    onShow() {
      SuperadminService.tariffFunctionality(this.tariff.id).then(res => {
        this.functionList = res.data
        this.showPayments = (this.functionList.filter(x => x.status && x.payment)).map(a => a.id)
        this.loaded = true
      })
    },
    onHide() {
      this.functionList = []
      this.loaded = false
    }

  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">

</style>
