<template>
  <section id="insurance" class="insurance">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print">
        <div class="col-md col-12 order-md-0 order-0">
          <h1>{{ $t('insurance') }}</h1>
        </div>
        <div class="col-md-auto col mt-md-0 mt-2 table-view-navigator order-md-1 order-3">
          <SearchTable/>
        </div>
        <div class="col-auto table-view-navigator order-md-2 mt-md-0 mt-2 order-4">
          <DataTableFilterButton/>
        </div>
        <div class="col-auto order-md-4 order-1 table-view-navigator">
          <DataTableExcel/>
        </div>
        <div class="col-auto order-md-5 order-2 table-view-navigator">
          <AddObject module="insurance"/>
        </div>
      </div>
    </div>
    <div>
      <DataTable :options="options"
                 class="td-different"
                 module="settings"/>
    </div>
  </section>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/insurance/insurance_company"
import SearchTable from "@/components/parts/datatable/SearchTable"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import AddObject from "@/components/parts/datatable/AddObject"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"

export default {
  name: "Insurance",
  components: {
    DataTableFilterButton,
    SearchTable,
    DataTable,
    AddObject,
    DataTableExcel
  },
  metaInfo() {
    return {
      title: this.$t('insurance'),
      meta: []
    }
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
