<template>
  <div class="pb-5 d-inline-block position-relative">
    <div>
      <template v-for="t in teeth.top">
        <Tooth :key='`top-t-${t.cnt}`'
               :toothNumber="t.cnt"
               :disabled="disabled"
               position="top"
               :mode="mode"/>
      </template>
    </div>
    <div class="dashed-v"></div>
    <div class="mt-2 pt-2 dashed-b">
      <template v-for="t in teeth.bottom">
        <Tooth :key='`top-t-${t.cnt}`'
               :toothNumber="t.cnt"
               :disabled="disabled"
               position="bottom"
               :mode="mode"/>
      </template>
    </div>
  </div>
</template>

<script>
import Tooth from "@/components/parts/patients/teeth/Tooth"

export default {
  name: "AdultTeeth",
  components: {
    Tooth
  },
  props: {
    mode: {
      type: String,
      default: 'status'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      teeth: {
        top: [
          { cnt: 18 },
          { cnt: 17 },
          { cnt: 16 },
          { cnt: 15 },
          { cnt: 14 },
          { cnt: 13 },
          { cnt: 12 },
          { cnt: 11 },
          { cnt: 21 },
          { cnt: 22 },
          { cnt: 23 },
          { cnt: 24 },
          { cnt: 25 },
          { cnt: 26 },
          { cnt: 27 },
          { cnt: 28 },
        ],
        bottom: [
          { cnt: 48 },
          { cnt: 47 },
          { cnt: 46 },
          { cnt: 45 },
          { cnt: 44 },
          { cnt: 43 },
          { cnt: 42 },
          { cnt: 41 },
          { cnt: 31 },
          { cnt: 32 },
          { cnt: 33 },
          { cnt: 34 },
          { cnt: 35 },
          { cnt: 36 },
          { cnt: 37 },
          { cnt: 38 },
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dashed-b {
  border-top: 2px dashed #fff;
}
.dashed-v {
  border-right: 2px dashed #fff;
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  margin-left: -1px;
}
</style>
