<template>
  <span v-if="role === 'superadmin' && object.blocked == 2">
    <a @click="restoreUser"
       :title="$t('restore')"
       v-b-tooltip.hover
       class="tdclickable data-table-action">
    <RefreshSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('restore') }}</span>
  </a>
    </span>
</template>

<script>

import RefreshSvg from '@/assets/svg/form/tick.svg'
import { SuperadminService } from "@/services/api.service"

export default {
  name: "SuperAdminUsersActions",
  components: {
    RefreshSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    role() {
      return this.$store.state.auth.user.role
    }
  },
  methods: {
    async restoreUser() {
      try {
        let res = await SuperadminService.restoreUser(this.object.id)
        if(res.data) this.$store.commit('incrementDataTableCounter')
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
