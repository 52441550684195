<template>
  <span>
    <a @click="printPrice"
       :title="$t('print')"
       v-b-tooltip.hover
       class="tdclickable data-table-action">
      <PrintSvg/><span class="action-button-mob-title">{{ $t('print') }}</span>
    </a>
  </span>
</template>

<script>

import PrintSvg from '@/assets/svg/general/print2.svg'
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"
// import  { EloquentService } from "@/services/api.service"
// import { serviceAdapter } from '@/adapters/services/service'

export default {
  name: "ServicesDatatableActionsPrice",
  components: {
    PrintSvg,

  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
      branch: state => state.auth.branch,
    })
  },
  methods: {
    printPrice() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/pricelist?branchId='+this.branch+'&price_id='+this.object.id, '_blank')
    }
  }
}
</script>
