<template>
  <div class="">
    <b-row>
      <template v-for="(f, find) in options.fields">
        <div :key="'form-field-'+find"
             v-if="f.form && !f.form.hidden && !(edit && f.unavailableonedit)"
             :class="f.form && f.form.classes ? f.form.classes : 'col-12'">
          <VariableInput :field="f"
                         :object="object"
                         :edit="edit"
                         :selectModels="selectModels"/>
          <div v-if="f.form.note" class="text-muted small">{{ $t(f.form.note) }}</div>
          <ValidationError :validationId="f.validation_id || f.id"/>
        </div>
      </template>
    </b-row>
    <div v-for="(tg, tind) in templateGroups" :key="`template-g-${tind}`">
      <div class="font-weight-bold mt-2 option-label">{{ $t(tg) }}</div>
      <template v-for="(o, oind) in object.options">
        <div :key="`tem-g-${tind}-o-${oind}`"
             class="option-row"
             v-if="o.treatment_field === tg">
          <b-row>
            <b-col>
              <template v-if="oind != optionEdited">{{ o.text }}</template>
              <template v-if="oind == optionEdited">
                <textarea class="form-control" v-model="o.text"/>
              </template>
            </b-col>
            <b-col cols="auto">
              <a v-if="oind != optionEdited" class="pointer" tabindex="#" @click="editOption(oind)">
                <EditSvg/>
              </a>
              <a v-if="oind == optionEdited" class="pointer" tabindex="#" @click="optionEdited = null">
                <TickSvg class="svg-light"/>
              </a>
              <a class="pointer ml-3" tabindex="#" @click="deleteOption(oind)"><DeleteSvg/></a>
            </b-col>
          </b-row>
        </div>
      </template>
      <b-row class="mt-3">
        <b-col>
        <InputGroup class="md"
                    :placeholder="$t('text')"
                    v-model="texts[tg]"/>
        </b-col>
        <b-col cols="auto">
          <button class="d-inline-block btn-themed btn-themed-squared btn-themed-outline"
                  :disabled="!texts[tg] || texts[tg].length < 1"
             @click="add(tg)">{{ $t('add') }}</button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

// import InputGroup from "@/components/form/InputGroup"
import ValidationError from "@/components/form/ValidationError"
import VariableInput from "@/components/form/VariableInput"
import {mapState} from "vuex"
import {templateGroups} from "@/dictionaries/dictionary"
import InputGroup from "@/components/form/InputGroup"
// import EditSvg from '@/assets/svg/general/edit.svg'
import DeleteSvg from '@/assets/svg/general/delete.svg'
import EditSvg from '@/assets/svg/general/edit.svg'
import TickSvg from '@/assets/svg/form/tick.svg'

export default {
  name: "TemplateDatatableFormFullTreatmentTemplateForm",
  components: {
    InputGroup,
    // InputGroup,
    ValidationError,
    VariableInput,
    DeleteSvg,
    TickSvg,
    EditSvg
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
    options: {}
  },
  data() {
    return {
      templateGroups: templateGroups,
      texts: {},
      optionEdited: null
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      clinic: state => state.auth.clinic,
      model: state => state.datatable.viewModel,
    }),
    edit() {
      return !!this.object[this.options.primaryKey]
    },
  },
  methods: {
    add(group) {
      this.object.options.push({
        treatment_field: group,
        text: this.texts[group],
        template_id: this.object.id ?? null
      })
      this.texts[group] = ''
    },
    deleteOption(index) {
      this.optionEdited = null
      this.object.options.splice(index, 1)
    },
    editOption(index) {
      this.optionEdited = index
    }
  },
  mounted() {

  },
  watch: {

  }
}
</script>

<style scoped lang="scss">
.option-label {
  font-size: 14px;
}
.option-row {
  padding: 7px 0;
  font-size: 14px;
  border-bottom: 1px dashed #DEE1E9;
}
</style>
