<template>
  <div class="nowrap">
    <a class="data-table-action"
       :title="$t('payment')"
       v-b-tooltip.hover
       @click="salaryPressed">
      <DollarSvg/>
    </a>
    <a class="data-table-action ml-2"
       v-if="object.paid > 0"
       :title="$t('cancel')"
       v-b-tooltip.hover
       @click="deletePayment">
      <BackSvg/>
    </a>
  </div>
</template>

<script>

import DollarSvg from '@/assets/svg/accounting/dollar.svg'
import BackSvg from '@/assets/svg/accounting/back.svg'
import {mapState} from "vuex"
import {AccountingService} from "@/services/api.service"


export default {
  name: "AccountingMonthDatatableTDSalariesAction",
  components: {
    DollarSvg,
    BackSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      accountingmonth: state => state.accounting.accountingmonth
    }),
  },
  methods: {
    salaryPressed() {
      this.$store.commit('setSalaryRow', this.copyObject(this.object))
      this.$nextTick(() => {
        this.$bvModal.show('accounting-salary-modal')
      })
    },
    deletePayment() {
      if(confirm(this.$t('are_you_sure_you_want_to_delete'))) {
        AccountingService.clearSalaryPayments(this.accountingmonth.id, this.object.user_id).then(() => {
          this.$noty.info(this.$t('success_message'))
          this.$store.commit('incrementDataTableCounter')
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
