<template>
  <div>
    <div class="font-weight-bold">{{ object[field.id][1] | formatNumber }}</div>
    <div class="text-muted">{{ object[field.id][0] | formatNumber }}</div>
  </div>
</template>

<script>

export default {
  name: "ReportByServicesDatatableTDService",
  components: {
  },
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    },
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
