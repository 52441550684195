export default {
    add_task: "Додати задачу",
    add_patient: "Додати пацієнта",
    calls: "Дзвінки",
    clinic_id: "Id клініки",
    expenses: "Витрати",
    high: "Висока",
    low: "Низька",
    medium: "Середня",
    no_notifications: "Повідомлень немає",
    tasks: "Задачі"
}
