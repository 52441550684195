<template>
  <div class="clinic-marketing-data page pb-5 pt-4" style="max-width: 500px;">
    <CheckboxGroup :label="$t('online_booking_activate')"
                   :checkboxvalue="1"
                   fid="online_booking"
                   v-model="form.online_booking"/>
    <div v-if="form.online_booking">

<!--      <div class="alert alert-warning">* {{ $t('in_development') }}</div>-->
      <div class="alert alert-info">https://booking.dentist-plus.com/{{ clinic.id }}/{{ clinic.hash }}</div>

      <div class="form-label mb-2">{{ $t('which_branches_use_online_booking') }}:</div>
      <div v-for="branch in branches"
           :key="`branch-${branch.id}`">
        <CheckboxGroup :label="branch.title"
                       :checkboxvalue="branch.id"
                       :fid="`branchesEnabled-${branch.id}`"
                       v-model="form.branchesEnabled"/>
      </div>

      <CheckboxGroup :label="$t('online_booking_feedbacks')"
                     class="mt-5"
                     :checkboxvalue="1"
                     fid="online_booking_feedbacks"
                     v-model="form.online_booking_feedbacks"/>

      <CheckboxGroup :label="$t('online_booking_time')"
                     :checkboxvalue="1"
                     fid="online_booking_time"
                     v-model="form.online_booking_time"/>

      <InputGroup type="number"
                  :label="$t('online_booking_max_days')"
                  validation-id="online_booking_max_days"
                  :showErrors="true"
                  v-model="form.online_booking_max_days"/>

      <CheckboxGroup :label="$t('online_booking_map')"
                     :checkboxvalue="1"
                     fid="online_booking_map"
                     v-model="form.online_booking_map"/>

      <CheckboxGroup :label="$t('online_booking_categories')"
                     :checkboxvalue="1"
                     fid="online_booking_categories"
                     v-model="form.online_booking_categories"/>

      <ServiceMultiSelect :label="$t('doctors')"
                          :dblclick="false"
                          model="doctor"
                          title="full_name"
                          :dbsearch="false"
                          :prepopulatedOptions="doctors"
                          v-model="selectedDoctors"/>

      <b-button class="btn-collapse mt-4" v-b-toggle="`services`">{{ $t('services') }}</b-button>
      <b-collapse id="services">
        <div class="border py-3 px-4 mb-2">
<!--          <div class="font-weight-bold">{{ $t('services') }}</div>-->
<!--          <hr/>-->
          <div v-for="(field, find) in fields"
               class="mb-4"
               :key="`field-${find}`">
            <b-row class="mb-2 font-weight-bold">
              <b-col>
                <div>{{ field.field.translatedTitle }}:</div>
              </b-col>
              <b-col cols="auto">
                <a class="pointer" @click="removeField(find)"><TrashSvg/></a>
              </b-col>
            </b-row>
            <table class="bg-white table-blue services-table" v-if="field.services.length">
              <thead>
              <tr>
                <th>{{ $t('title') }}</th>
                <th>{{ $t('minutes') }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(service, sind) in field.services" :key="`field-${find}-service-${sind}`">
                <td>{{ service.title }}</td>
                <td>
                  <input type="text"
                         class="form-control sm"
                         v-model="service.minutes"/>
                  <!--              {{ service.minutes }}-->
                </td>
                <td>
                  <a class="pointer"
                     @click="removeService(find, sind)">
                    <TrashSvg/>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
            <Autocomplete :placeholder="$t('add_service')"
                          model="service"
                          class="autocomplete-operation-material w-100 mt-2"
                          :render-title="(ser) => `${ser.title} (${ser.price_p})`"
                          @input="addService(find, ...arguments)"/>
          </div>
          <b-dropdown variant="link"
                      right
                      text="Dropdown Button">
            <template #button-content>
              <a class="btn-themed btn-themed-outline btn-themed-squared d-inline-block">
                {{  $t('add_field') }}
              </a>
            </template>
            <template v-for="f in fieldsDictionary">
              <b-dropdown-item v-if="!fields.find(field => field.field.id == f.id)"
                               :key="`foption-${f.id}`"
                               @click="addField(f)">
                {{ f.translatedTitle }}
              </b-dropdown-item>
            </template>
          </b-dropdown>
        </div>
      </b-collapse>

    </div>

    <div class="mt-5">
      <button class="btn btn-themed btn-themed-squared" @click="save">
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import {EloquentService, OnlineBookingService} from "@/services/api.service"
import InputGroup from "@/components/form/InputGroup.vue"
import Autocomplete from "@/components/parts/general/Autocomplete.vue"
import TrashSvg from "@/assets/svg/general/trash.svg"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"

export default {
  name: "SettingsOnlineBooking",
  metaInfo() {
    return {
      title: this.$t('online_booking'),
      meta: []
    }
  },
  components: {
    ServiceMultiSelect,
    TrashSvg,
    Autocomplete,
    InputGroup,
    CheckboxGroup,
  },
  data() {
    return {
      form: {},
      fields: [],
      fieldsDictionary: [],
      selectedDoctors: []
    }
  },
  computed: {
    ...mapState({
      clinic: state     => state.auth.clinic,
      superadmin: state => state.auth.superadmin,
      branches: state => state.auth.branches,
      doctors: state => state.auth.doctors,
    }),
    additional() {
      return this.clinic.clinic_additional
    },
  },
  watch: {

  },
  methods: {
    async save() {
      this.$store.commit('cleanValidationErrors')
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.form)
      let doctorsArr = this.selectedDoctors.map(d => d.id)
      if(!doctorsArr.length) doctorsArr = '[]'
      formData = this.appendFormdata(formData, { 'online_booking_doctors': doctorsArr })
      await EloquentService.update('clinic_additional', this.clinic.id, formData)
      await this.saveServices()
      await this.$store.dispatch('init')
      this.$noty.info(this.$t('success_message'))
    },
    addField(field) {
      this.fields.push({
        field,
        services: []
      })
    },
    removeField(fieldIndex) {
      this.fields.splice(fieldIndex, 1)
    },
    addService(index, service) {
      if(this.fields[index].services.find(s => s.id === service.id)) return
      this.fields[index].services.push({ ...service, minutes: 15 })
    },
    removeService(fieldIndex, serviceIndex) {
      this.fields[fieldIndex].services.splice(serviceIndex, 1)
    },
    async saveServices() {
      await OnlineBookingService.saveOnlineBookingServices({ fields: this.adapter(this.fields) })
    },
    adapter(fields) {
      return fields.map(field => {
        return {
          field_id: field.field.id,
          services: field.services.map(service => {
            return {
              service_id: service.id,
              minutes: service.minutes
            }
          })
        }
      })
    }
  },
  mounted() {
    this.form = this.copyObject(this.additional)
    this.selectedDoctors = this.doctors.filter(d => this.form.online_booking_doctors?.includes(d.id.toString()))
    this.$set(this.form, 'branchesEnabled', this.branches.filter(b => b.online_booking_enabled).map(b => b.id))
    this.$delete(this.form, 'online_booking_doctors')
    EloquentService.dataTable('field', { page: 1, limit: 999}).then(res => {
      this.fieldsDictionary = res.data.result.data
    })
    OnlineBookingService.getOnlineBookingServices().then(res => {
      this.fields = res.data
    })
  }
}
</script>
