<template>
  <span>
  <a class="data-table-action"
     @click="openReceipt"
     v-b-tooltip.hover
     :title="$t('receipt')"
  >
    <PrintSvg/><span class="action-button-mob-title">{{ $t('receipt') }}</span>
  </a>
    </span>

</template>

<script>

import PrintSvg from '@/assets/svg/general/print2.svg'
// import NoteSvg from '@/assets/svg/header/note.svg'
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"

export default {
  name: "PatientDatatableAdvanceActions",
  components: {
    PrintSvg,
    // NoteSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  methods: {
    openReceipt() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/deposit/'+this.object.advance_id, '_blank')
    },
  }
}
</script>
