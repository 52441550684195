<template>
  <section id="templates" class="templates">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print position-relative">
        <div class="col order-md-0 order-0">
          <h1>{{ $t('templates') }}</h1>
        </div>
        <div class="col-md-auto col mt-md-0 mt-2 order-md-1 order-3 table-view-navigator">
          <SearchTable/>
        </div>
        <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator">
          <DataTableFilterButton/>
        </div>
        <div class="col-auto order-md-5 order-2 table-view-navigator">
          <AddObject module="templates"/>
        </div>
      </div>
    </div>
    <div class="sub-menu">
      <template v-for="(m, mind) in menu">
        <a class="pointer"
           tabindex="#"
           v-if="m.rightPopup && !rights.includes(m.rightPopup) && hasFreeTariff"
           :key="`a-link-${mind}`"
           @click="$bvModal.show('module-not-in-tariff-modal')">
          {{ $t(m.title) }}
        </a>
        <router-link v-else-if="!m.right || rights.includes(m.right)"
                     :to="{ name: m.to }"
                     :key="`templates-link-${mind}}`">
          {{ $t(m.title) }}
        </router-link>
      </template>
    </div>
    <router-view/>
  </section>
</template>

<script>

import SearchTable from "@/components/parts/datatable/SearchTable"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import AddObject from "@/components/parts/datatable/AddObject"
import {mapGetters, mapState} from "vuex"

export default {
  name: "TemplatesGeneral",
  components: {
    SearchTable,
    DataTableFilterButton,
    AddObject
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights
    }),
    ...mapGetters([
      'hasFreeTariff'
    ])
  },
  data() {
    return {
      menu: [
        { to: 'TextTemplates', title: 'text' },
        { to: 'TreatmentTemplates', title: 'treatment', right: 'treatment_templates' },
        { to: 'PlanTreatmentTemplates', title: 'treatment_plan', right: 'treatment_templates' },
        { to: 'AgreementTemplates', title: 'consents', rightPopup: 'agreements', right: 'agreements' },
        { to: 'ContractTemplates', title: 'contracts', rightPopup: 'contracts', right: 'contracts' },
      ]
    }
  }
}
</script>

<style scoped>

</style>
