<template>
  <a class="btn btn-themed gray d-inline-flex align-items-center"
     :class="hasInitialFilters ? null : 'collapsed'"
     v-b-toggle="'filter-collapse-'+viewModel"
     v-if="options">
    <span class="position-relative">
      <FilterSvg class="svg-gray mr-2"/>
      <span v-if="count" class="notification-bubble">{{ count }}</span>
    </span>
    {{ $t('filter') }}
  </a>
</template>

<script>

import FilterSvg from '@/assets/svg/general/filter.svg'
import { mapState } from "vuex"

export default {
  name: "DataTableFilterButton",
  components: {
    FilterSvg
  },
  computed: {
    ...mapState({
      options: state => state.datatable.eloquentOptions,
      viewModel: state => state.datatable.viewModel,
      filters: state => state.datatable.filters
    }),
    hasInitialFilters() {
      return !!this.$route.query.filters
    },
    count() {
      return Object.keys(this.filters).length
    }
  }
}
</script>

<style scoped>

</style>
