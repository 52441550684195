<template>
  <div class="pt-3">
    <b-row>
      <b-col cols="auto">
        <img class="patient-img"
             :src="clinic.photo"
             :alt="clinic.name"
             :title="clinic.name"/>
      </b-col>
      <b-col>
        <h2>{{ clinic.name }}</h2>
        <div>{{ clinic.phone }}</div>
        <div>{{ clinic.email }}</div>
        <div class="mt-3">{{ $t('tariff') }}: <b>{{ clinic.tariffInfo && clinic.tariffInfo.tariff ? clinic.tariffInfo.tariff.title : '-' }}</b></div>
      </b-col>
      <b-col md="8" cols="12">
        <b-row>
          <b-col md cols="12">
            <PatientCol label="city" :title="clinic.city ? clinic.city.name + (clinic.city.country ? ', '+clinic.city.country.name : '') : ''"/>
            <PatientCol label="address" :title="clinic.address"/>
            <PatientCol label="date_of_registration" :title="$options.filters.formatDateTime(clinic.date_of_registration)"/>

          </b-col>
          <b-col md cols="12">
            <PatientCol label="language" :title="$t(clinic.language)"/>
            <PatientCol label="source" :title="clinic.source_object ? clinic.source_object.name : '-'"/>
            <PatientCol label="manager" :title="clinic.manager ? clinic.manager.full_name : '-'"/>
            <PatientCol label="comment" :title="clinic.comment"/>
          </b-col>
          <b-col md cols="12">
            <PatientCol label="doctors" :title="clinic.doctors_count"/>
            <PatientCol label="patients" :title="clinic.patients_count"/>
            <PatientCol label="administrators" :title="clinic.managers_count"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex"
import PatientCol from "@/components/parts/patients/PatientCol"

export default {
  name: "SuperAdminClinicGeneral",
  metaInfo() {
    return {
      title: this.$t('general'),
    }
  },
  components: {PatientCol},
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    })
  }
}
</script>

<style scoped lang="scss">
  .patient-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
  }
  h2 {
    font-size: 22px;
    margin-bottom: 16px;
    font-weight: bold;
  }
</style>
