<template>
  <div>
    <div :class="`${warning_class} ${warning_class}-primary d-inline-block`" v-if="isFree">Тариф бесплатен</div>
    <div :class="`${warning_class} ${warning_class}-danger d-inline-block`" v-else-if="!paid_till">Тариф не оплачен</div>
    <div :class="`${warning_class} ${warning_class}-warning d-inline-block`" v-else-if="paid_till < today">Тариф просрочен</div>
    <div :class="`${warning_class} ${warning_class}-primary d-inline-block`" v-else-if="paid_till >= today">Тариф оплачен до {{ paid_till | formatDate }}</div>
  </div>
</template>

<script>

import moment from "moment/moment"

export default {
  name: "TariffPaymentStatus",
  props: {
    paid_till: {},
    warning_class: {
      default: 'alert'
    },
    isFree: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    today() {
      return moment().format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped>

</style>
