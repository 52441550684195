<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="full_name"
                   initialSort="asc"
                   @loadReport="loadReport(...arguments)"
                   :rows="rows"/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import JwtService from "@/services/jwt.service"
import {mapState} from "vuex"
// import ru from "@/lang/ru/ru"

export default {
  name: "ReportsPatients",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    onlyNew: { type: Boolean, default: false },
    hideCancelled: { type: Boolean, default: false },
    selectedBranches: {}
  },
  metaInfo() {
    return {
      title: this.$t('patients'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    })
  },
  data() {
    return {
      columns: [
        {
          id: 'full_name',
          field: 'full_name',
          label: this.$t('name'),
          tdcomponent: 'PatientsDatatableTDName',
          sortable: true,
        },
        {
          id: 'phone',
          field: 'phone',
          label: this.$t('phone'),
          tdcomponent: 'PatientsDatatableTDPhone',
          sortable: true,
        },
        {
          id: 'first_visit_date',
          field: 'first_visit_date',
          label: this.$t('first_visit_date'),
          sortable: true,
          format_datetime: true
        },
        {
          id: 'first_visit_doctor',
          field: 'first_visit_doctor',
          label: this.$t('first_visit_doctor'),
          search_select: true,
          sortable: true
        },
        {
          id: 'last_visit_date',
          field: 'last_visit_date',
          label: this.$t('last_visit_date'),
          sortable: true,
          format_datetime: true
        },
        {
          id: 'last_visit_doctor',
          field: 'last_visit_doctor',
          label: this.$t('last_visit_doctor'),
          search_select: true,
          sortable: true
        },
        {
          id: 'last_visit_recommendation',
          field: 'last_visit_recommendation',
          label: this.$t('last_visit_recommendation'),
          sortable: true
        },
        {
          id: 'next_visit_date',
          field: 'next_visit_date',
          label: this.$t('next_visit_date'),
          sortable: true,
          format_datetime: true
        },
        {
          id: 'next_visit_doctor',
          field: 'next_visit_doctor',
          label: this.$t('next_visit_doctor'),
          search_select: true,
          sortable: true
        },
        {
          id: 'tags',
          field: 'tags',
          label: this.$t('tags'),
          sortable: true
        },
        {
          id: 'plan_treatments',
          field: 'plan_treatments',
          format_html: true,
          search_select_manual_options: [this.$t('preliminary'), this.$t('active'), this.$t('confirmed_male'), this.$t('treatment_complete'), this.$t('rejected'), this.$t('stopped')],
          changes_backend: true,
          label: this.$t('treatment_plan'),
          sortable: true
        },
        {
          id: 'plan_treatment_complexes',
          field: 'plan_treatment_complexes',
          search_select_manual_options: [this.$t('new'), this.$t('in_process'), this.$t('finished')],
          format_html: true,
          label: this.$t('complexes'),
          sortable: true
        },
        {
          id: 'source',
          field: 'source',
          label: this.$t('where_came_from'),
          search_select: true,
          sortable: true
        },
        {
          id: 'patient_status',
          field: 'patient_status',
          label: this.$t('status'),
          search_select: true,
          sortable: true
        },
        {
          id: 'patient_condition',
          field: 'patient_condition',
          label: this.$t('condition'),
          search_select: true,
          sortable: true
        },
        {
          id: 'records_count',
          field: 'records_count',
          label: this.$t('records_count'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'total_price',
          field: 'total_price',
          label: this.$t('sum'),
          sortable: true,
          format_number: true,
          totals: true
        },
      ],
      rows: [],
      needs_excel: false,
    }
  },
  methods: {
    loadReport(search = null) {
      let plan_status = search && search.plan_treatments ? search.plan_treatments : null
      if(plan_status) {
        const loc = require(`@/lang/${this.$i18n.locale}/${this.$i18n.locale}`)
        plan_status = Object.keys(loc.default).find(key => loc.default[key] === plan_status)
      }
      if(plan_status === 'confirmed_male') plan_status = 'confirmed'
      ReportsService.loadReport('patients', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
        onlyNew: this.onlyNew,
        hideCancelled: this.hideCancelled,
        plan_treatment_status: plan_status,
        needs_excel: this.needs_excel ? 1 : 0
      }).then(res => {
        this.needs_excel = false
        if(res.data.report) {
          this.rows = res.data.report
          return
        }
        if(res.data.excel) {
          let link = JwtService.getPrintPrelink(this.clinic, false)
          location.href = link+'/excel/'+res.data.excel
          return
        }
        if(res.data.needs_excel) {
          let res = confirm(this.$t('report_is_too_heavy_confirm'))
          if(res) {
            this.needs_excel = true
            return this.loadReport(search)
          }
        }
      })
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>
