export default {
    accepted_by: "Ким прийнято",
    additional_income: "Додатковий дохід",
    additional_costs: "Витрати",
    admin_statistics_future: "Статистика адміністратора про нові записи на майбутні дати",
    administrator_percent: "Відсоток адміністратора",
    administrator_sum: "Сума адміністратора",
    apply: "Застосувати",
    assistant_percent: "Відсоток помічника",
    assistant_sum: "Сума помічника",
    average_cheque: "Середній чек",
    balance_amount: "Сплачено з депозиту",
    balance_return: "Повернення депозиту",
    by_means: "За формою оплати",
    by_services: "За послугами",
    came_among_patients: "З них прийшли",
    cancel_date: "Дата відміни",
    cancel_reason: "Причина відміни",
    cancelled_visits: "Відмінені візиты",
    cashdesk_report: "Касовий звіт",
    cash_flow_statement: "Звіт про рух ДС",
    cos: "Собівартість",
    costs: "Витрати",
    cost_of_materials: "Вартість матеріалів",
    crm_leads_analytics: "Аналітика заявок CRM",
    daily_report: "Звіт за день",
    debt_payments: "Оплата боргів",
    debt_writeoff: "Списання боргів",
    debtor: "Боржник",
    debtors: "Боржники",
    debtors_statement: "Звіт по боржникам",
    details_by_visits: "Деталі по візитах",
    did_not_come_among_patients: "З них не прийшли",
    doctor_percent: "Відсоток лікаря",
    doctor_load_percent: "% завантаження лікаря",
    doctor_name: "Лікар",
    doctor_salary: "Зарплата лікаря",
    doctor_statistics: "Статистика по лікарях",
    doctor_sum: "Сума лікаря",
    doctors_load: "Завантаження лікарів",
    duration: "Тривалість",
    f057: "Форма 057",
    first_visit_date: "Дата першого візиту",
    first_visit_doctor: "Лікар першого візиту",
    full_name: "Ім'я",
    general_percent: "Загальний відсоток",
    hide_cancelled_visits: "Приховувати скасовані візити",
    hidden_columns: "Приховані колонки",
    income_by_doctors: "Виторг по лікарям",
    income_by_services: "Виторг по послугам",
    initiator: "Ініціатор",
    invoiced: "Виставлено рахунків",
    last_visit_date: "Дата останнього візиту",
    last_visit_doctor: "Лікар постаннього візиту",
    last_visit_recommendation: "Рекомендації останнього візиту",
    margins: "Валовий прибуток",
    next_visit_date: "Дата наступного візиту",
    next_visit_doctor: "Лікар наступного візиту",
    operation_type: "Тип операціи",
    orthodontist: "Ортодонт",
    orthopedist: "Ортопед",
    paid_from_account: "Сплачено з рахунку",
    paid_invoices: "Оплачені рахунки",
    patient_name: "Пацієнт",
    permanent_among_patients: "З них візити з постійними пацієнтами",
    pl_statement: "Звіт про доходи та витрати",
    plan_treatment_complexes: "Комплекси",
    plan_treatments: "План лікування",
    price_before_discount: "Сума до знижки",
    primary_among_patients: "З них візити з первинними пацієнтами",
    primary_records: "Первинні - записано",
    primary_records_came: "Первинні - прийшли",
    primary_records_further: "Первинні - записані пізніше",
    primary_records_revenue: "Первинні - виторг",
    records: "Записів",
    records_count: "Кіл-ть візитів",
    repeated_records: "Повторні - записано",
    repeated_records_came: "Повторні - прийшли",
    repeated_records_further: "Повторні - записані пізніше",
    repeated_records_revenue: "Повторні - виторг",
    report_is_too_heavy_confirm: "Звіт надто великий для перегляду на сторінці, вивантажити звіт у Excel файл?",
    revenue: "Виторг",
    revenue_per_hour: "Виторг в годину",
    revenues: "Виторг",
    saldo: "Сальдо",
    sales_funnel_applications: "Лійка: Заявки та звернення",
    sales_funnel_plans: "Лійка: Плани лікування",
    show_deleted_doctors: "Показувати видаленим лікарям",
    show_only_new_patients: "Відображати тільки нових пацієнтів",
    technic_percent: "Відсоток техніка",
    technic_sum: "Сума техніка",
    therapist: "Терапевт",
    total_patients: "Кількість записаних пацієнтів",
    total_revenue: "Загальний виторг",
    unpaid_invoices: "Неоплачені рахунки",
    visit_id: "Id запису",
    visit_statistics: "Статистика візитів",
    where_came_from: "Звідки прийшов(-ла)",
    who_cancelled: "Хто відмінив",
    who_created: "Ким створено",
    worked_hours: "Відпрацьованих годин",
}
