<template>
  <div class="d-inline-flex align-middle addobject-btn">
    <a class="btn btn-themed d-md-inline-flex d-block align-items-center"
       :class="btnclass"
       v-if="options && options.actions && options.actions.includes('add') && !document"
       @click="show">
      <template v-if="svgPassed">
        <component :is="svgPassed" class="svg-white mr-md-2"/>
      </template>
      <template v-else>
        <BoxSvg v-if="module=='warehouse'" class="svg-white mr-md-2"/>
        <PatientsSvg v-if="module=='patients'" class="svg-white mr-md-2"/>
        <FolderSvg v-if="module=='services'" class="svg-white mr-md-2"/>
        <TemplatesSvg v-if="module=='templates'" class="svg-white mr-md-2"/>
        <CalculatorSvg v-if="module=='accounting'" class="svg-white mr-md-2"/>
        <CalculatorSvg v-if="module=='expenses'" class="svg-white mr-md-2"/>
        <ShieldSvg v-if="module=='insurance'" class="svg-white mr-md-2"/>
        <CRMSvg v-if="module=='crm'" class="svg-white mr-md-2"/>
        <MarketingSvg v-if="module=='marketing'" class="svg-white mr-md-2"/>
        <ClinicSvg v-if="module=='superadmin'" class="svg-white mr-md-2"/>
      </template>
      <span>{{ $t('add') }}</span>
    </a>
  </div>
</template>

<script>

import BoxSvg from '@/assets/svg/sidebar/box.svg'
import PatientsSvg from '@/assets/svg/sidebar/patients.svg'
import TemplatesSvg from '@/assets/svg/sidebar/template.svg'
import CalculatorSvg from '@/assets/svg/sidebar/calculator.svg'
import ShieldSvg from '@/assets/svg/sidebar/shield.svg'
import FolderSvg from '@/assets/svg/sidebar/folder.svg'
import CRMSvg from '@/assets/svg/sidebar/crm.svg'
import MarketingSvg from '@/assets/svg/sidebar/marketing.svg'
import ClinicSvg from '@/assets/svg/sidebar/clinic.svg'
import UserSvg from '@/assets/svg/header/adduser_t.svg'
import { mapState } from "vuex"
import moment from "moment/moment"

export default {
  name: "AddObject",
  components: {
    BoxSvg,
    TemplatesSvg,
    PatientsSvg,
    CalculatorSvg,
    FolderSvg,
    ShieldSvg,
    ClinicSvg,
    MarketingSvg,
    CRMSvg,
    UserSvg
  },
  data() {
    return {
      showForm: false
    }
  },
  props: {
    module: {
      type: String,
    },
    btnclass: {
      type: String,
    },
    svgPassed: {
      type: String,
      default: ''
    }
  },
  methods: {
    show() {
      // console.log(this.newObject)
      this.$store.commit('setModule', this.module)
      this.$store.commit('setSelectedModel', JSON.parse(JSON.stringify(this.newObject)))
      this.$store.commit('setShowModal', true)
    }
  },
  computed: {
    ...mapState({
      model: state  => state.datatable.viewModel,
      showModal: state => state.datatable.showModal,
      options: state => state.datatable.eloquentOptions,
      document: state => state.warehouseoperation.operation,
      branch: state => state.auth.branch,
      accountingmonth: state => state.accounting.accountingmonth,
      clinic: state => state.auth.clinic,
      prices: state => state.auth.prices
    }),
    newObject() {
      let obj = {}
      if(this.module === 'patients' && ['scan','client_document','client_note'].includes(this.model)) {
        let patient = this.$store.state.patient.patient
        if(patient) {
          obj.client_id = patient.id
        }
      }
      if(this.module === 'services' && ['service'].includes(this.model)) {
        obj.visible = 1
        obj.price_id = this.prices.length ? this.prices[0].id : null
        obj.ndfl_code = '01'
      }
      if(this.module === 'settings' && ['notification_setting'].includes(this.model)) {
        obj.notification_channels = []
      }
      if(this.module === 'settings' && ['pay_method', 'client_source'].includes(this.model)) {
        if(this.clinic) obj.clinic_id = this.clinic.id
      }
      if(this.module === 'staff' && ['doctor','technic','administrator','assistant','chair'].includes(this.model)) {
        obj.branch_id = this.branch
      }
      if(this.module === 'superadmin') {
        if(this.clinic) obj.clinic_id = this.clinic.id
        if(['service'].includes(this.model)) {
          obj.visible = 1
        }
        if(['billing_payment'].includes(this.model)) {
          obj.sum = 0
          obj.months = 1
        }
      }
      if(this.module === 'warehouse' && ['materialstock'].includes(this.model)) {
        obj.min_stock = 0;
      }
      if(this.module === 'patients' && ['patient'].includes(this.model)) {
        obj.branch_id = this.branch
        obj.send_marketing = true
        obj.send_notifications = true
      }
      if(this.module === 'patients' && ['waiting_list'].includes(this.model)) {
        obj.interval_from = moment().format('YYYY-MM-DD')
        obj.interval_to = moment().format('YYYY-MM-DD')
      }
      if(this.module === 'staff' && ['technic'].includes(this.model)) {
        obj.profession_string = this.$t('technic')
      }
      if(this.module === 'staff' && ['assistant'].includes(this.model)) {
        obj.profession_string = this.$t('assistant')
      }
      if(['accounting', 'expenses'].includes(this.module) && ['other_income', 'other_expense'].includes(this.model)) {
        if(this.accountingmonth) obj.bookkeeping_id = this.accountingmonth.id
        obj.date = moment().format('YYYY-MM-DD')
      }
      if(this.module === 'templates' && ['full_treatment_template'].includes(this.model)) {
        obj.options = []
      }
      if(this.module === 'crm' && ['task'].includes(this.model)) {
        obj.task_type = 'task'
        obj.start = moment().format('YYYY-MM-DD')
        obj.deadline = moment().format('YYYY-MM-DD')
      }
      if(this.module === 'crm' && ['lead'].includes(this.model)) {
        obj.status = 'new'
      }
      if(this.module === 'superadmin' && ['popup'].includes(this.model)) {
        obj.roles = []
      }
      if(this.module === 'marketing' && ['marketing_mailing'].includes(this.model)) {
        obj.notification_channel_id = 1
      }
      if(this.module === 'services' && ['price'].includes(this.model)) {
        obj.active = 1
      }
      return obj
    }
  }
}
</script>

<style scoped lang="scss">
.btn-themed {
  svg {
    width: 16px;
    height: 16px;
  }
}
</style>
