<template>
  <div>
    {{  object.min_stock }}
    <a class="data-table-action ml-2"
       v-if="allowEdit"
       :title="$t('edit')"
       v-b-tooltip.hover
       @click="editMinStock">
      <EditSvg/>
    </a>
  </div>
</template>

<script>
import EditSvg from '@/assets/svg/general/edit.svg'

export default {
  name: "WarehouseDatatableTDNotify",
  components: {
    EditSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  data() {
    return {
      allowEdit: false
    }
  },
  methods: {
    editMinStock() {
      this.$store.commit('setSelectedModel', JSON.parse(JSON.stringify(this.object)))
      this.$store.commit('setShowModal', true)
      this.$nextTick(() => {
        this.$bvModal.show('form-modal')
      })
    }
  }
}
</script>

<style scoped>

</style>
