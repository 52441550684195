<template>
  <div class="d-inline-flex action-buttons-td position-relative">
    <a class="d-md-none d-inline-block action-dots" @click="dotsPressed"><DotsSvg/></a>
    <div class="action-buttons-div" :class="{ expanded: mob_expanded }" v-click-outside="clickedOutside">
      <b-row class="row-narrow">
        <b-col cols="auto">
          <a class="btn btn-themed gray d-md-inline-flex d-flex align-items-center"
             @click="addMaterial">
            <BoxSvg class="svg-gray mr-2"/>
            {{ $t('add_material') }}
          </a>
        </b-col>
        <b-col cols="auto">
          <a class="btn btn-themed gray d-md-inline-flex d-flex align-items-center"
             @click="print">
            <PrintSvg class="svg-gray mr-2"/>
            {{ $t('print') }}
          </a>
        </b-col>
        <b-col cols="auto" v-if="editable">
          <a class="btn btn-themed gray d-md-inline-flex d-flex align-items-center"
             @click="edit">
            <EditSvg class="svg-gray mr-2"/>
            {{ $t('edit') }}
          </a>
        </b-col>
        <b-col cols="auto" v-if="editable && (document.items_with_materials.length || document.warehouse_inventarization_sheets.length)">
          <b-button class="btn btn-themed gray d-md-inline-flex d-flex align-items-center"
                    :disabled="loading"
                    @click="approve">
            <ApproveSvg class="svg-gray mr-2"/>
            {{ $t('approve') }}
          </b-button>
        </b-col>
        <b-col cols="auto" v-if="document.status == 'finished'">
          <a class="btn btn-themed gray d-md-inline-flex d-flex align-items-center"
             @click="cancel">
            <ApproveSvg class="svg-gray mr-2"/>
            {{ $t('cancel') }}
          </a>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import PrintSvg from '@/assets/svg/general/print.svg'
import EditSvg from '@/assets/svg/general/pencil.svg'
import ApproveSvg from '@/assets/svg/general/approve.svg'
import DotsSvg from '@/assets/svg/header/vertical_dots.svg'
import BoxSvg from '@/assets/svg/sidebar/box.svg'
import options from "../../../data/warehouse/warehouseoperation"
import {mapState} from "vuex"

export default {
  name: "DocumentItemTopButtons",
  components: {
    EditSvg,
    PrintSvg,
    ApproveSvg,
    BoxSvg,
    DotsSvg
  },
  props: {
    document: {
      type: Object
    }
  },
  data() {
    return {
      mob_expanded: false,
      justOpened: false
    }
  },
  methods: {
    async approve() {
      await this.$store.dispatch('saveDocument')
      await this.$store.dispatch('approveDocument')
      this.$noty.info(this.$t('success_message'))
    },
    async cancel() {
      await this.$store.dispatch('cancelDocument')
      this.$noty.info(this.$t('success_message'))
    },
    print() {
      if(window) {
        window.print()
      }
    },
    dotsPressed() {
      this.justOpened = true
      this.mob_expanded = !this.mob_expanded
      setTimeout(() => {
        this.justOpened = false
      },20)
    },
    clickedOutside() {
      if(!this.justOpened) {
        this.mob_expanded = false
      }
    },
    edit() {
      this.$store.commit('setModule', 'warehouse')
      this.$store.commit('setSelectedModel', JSON.parse(JSON.stringify(this.document)))
      this.$store.commit('setShowModal', true)
    },
    addMaterial() {
      this.$store.commit('setModule', 'warehouse')
      this.$store.commit("setViewModel", 'materialstock')
      this.$store.commit('setSelectedModel', {})
      this.$store.commit('setShowModal', true)
    }
  },
  mounted() {
    if(this.document.status == 'draft') {
      this.$store.commit('setViewModel', options.model)
      this.$store.commit('setEloquentOptions', options)
    }
  },
  computed: {
    ...mapState({
      loading: state => state.dom.loading,
    }),
    editable() {
      return this.document.status == 'draft' && this.document.operation_type != 'movement_to'
    }
  }
}
</script>

<style scoped lang="scss">
.btn-themed {
  svg {
    fill: #87939E;
    width: 16px;
    height: 16px;
  }
}
@media screen and (max-width: 768px) {
  .action-buttons-td {
    .action-dots {
      position: relative;
    }
  }
}
</style>
