export default {
    administrator: "Administrator",
    administrators: "Administratorlar",
    are_you_sure_you_want_to_delete_field: "Siz yo‘nalishni o‘chirishga ishonchingiz komilmi",
    assistants: "Yordamchilar",
    block: "Bloklash",
    by_days: "Kunlar bo‘yicha",
    by_days_of_week: "Hafta kunlari bo‘yicha",
    by_even_days: "Juft kunlar bo‘yicha",
    by_odd_days: "Toq kunlar bo‘yicha",
    chair: "O‘rindiq",
    chairs: "O‘rindiqlar",
    config: "Konfigurasiya",
    confirm_password: "Parolni qaytarish",
    doctor_position: "Shifokorning pozitsiyasi",
    doctor_specialty: "Doktorlik mutaxassisligi",
    field: "Yo'nalish",
    fields: "Yo'nalishlar",
    functionality: "Funksional",
    login_and_password: "Login va parol",
    password_does_not_match_confirmation: "Parol tasdiqlanganga mos kelmaydi",
    percent: "Foiz",
    period_from: "dan boshlab davr",
    period_to: "gacha bo‘lgan davr",
    phone2: "2-telefon",
    profession: "Mutaxassislik",
    rate: "Maosh",
    rate_per_day: "Kunlik tarif",
    rate_per_hour: "Bir soatlik tarif",
    rate_per_month: "Oylik stavka",
    schedule_conflicts_found: "Joriy yozuvlar bilan jadvalda ziddiyatlar topildi",
    staff: "Xodimlar",
    technics: "Texniklar",
    weekend: "Dam olish kuni",
    without_warehouse_form_note: "Omborga bog‘lanmasdan, materiallar asosiy ombordan yoki o‘rindiqqa bog‘langan ombordan hisobdan chiqariladi",
    work_day: "Ish kuni",
    work_days: "Ish kunlari",
    work_schedule: "Ishlash jadvali",
    work_time: "Ish vaqti",
    user_data: "Foydalanuvchi ma’lumotlari"
}
