<template>
  <div>
    <b-row class="pt-sm-3 pt-1 align-items-center">
      <b-col sm="auto" cols="12">
        <div class="patient-search-bar form-group">
          <input type="text"
                 class="form-control"
                 v-model="search"
                 :placeholder="$t('patient_search')"/>
        </div>
      </b-col>
    </b-row>
    <DataTable :options="options"
               ref="dataTable"
               class="mt-4"
               module="crm"/>
    <LeadShowModal/>
  </div>
</template>

<script>

import { mapState } from "vuex"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/crm/lead"
import LeadShowModal from "@/components/parts/crm/LeadShowModal"

export default {
  name: "CRMApplications",
  components: {
    LeadShowModal,
    DataTable,
  },
  metaInfo() {
    return {
      title: this.$t('applications_and_appeals'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    }),
  },
  data() {
    return {
      options: options,
      search: '',
      timer: null
    }
  },
  methods: {
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  },
  watch: {
    search(val) {
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        let datafilter = this.$refs.dataTable.$refs.datatablefilter
        if(val) {
          datafilter.filters.patient_id = {
            "id": "patient_id",
            "title": "patient",
            "condition": val,
            "type": "text",
            "ctype": "like"
          }
        } else {
          delete datafilter.filters.patient_id
        }
        datafilter.refresh()
      }, 600)
    }
  }
}
</script>

<style scoped lang="scss">
.patient-search-bar {
  input {
    border: none;
    background: #E0E7EE;
    border-radius: 25px;
    max-width: 250px;
    height: 33px;
    padding-left: 25px;
  }
}
@media(max-width: 768px) {
  .patient-search-bar {
    input {
      width: 100%;
      max-width: none;
    }
  }
}
</style>
