<template>
  <div>
    <DataTable :options="options"
               :params="{ visible: 0 }"
               module="services"/>
  </div>
</template>

<script>

import DataTable from "../../parts/general/DataTable"
import options from "../../../data/services/archive"

export default {
  name: "ServiceArchive",
  metaInfo() {
    return {
      title: this.$t('archive'),
      meta: []
    }
  },
  components: { DataTable },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    this.$store.commit('setAdditionalParams', { visible: 0 })
  },
  beforeDestroy() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>

<style scoped>

</style>
