<template>
  <a class="btn btn-themed gray d-md-inline-flex d-block align-items-center excel-btn"
     @click="excel"
     v-if="options && options.actions && options.actions.includes('excel')">
    <ExcelSvg class="svg-gray mr-md-2"/>
    <span>{{ $t('excel') }}</span>
  </a>
</template>

<script>

import ExcelSvg from '@/assets/svg/general/excel.svg'
import JwtService from "@/services/jwt.service"
import  { EloquentService } from "@/services/api.service"
import { mapState } from "vuex"

export default {
  name: "DataTableExcel",
  components: {
    ExcelSvg,
  },
  props: {
      superadmin: {
        type: Boolean,
        default: false
      }
  },
  methods: {
    excel() {
      EloquentService.excel(this.viewModel, this.additionalParams, this.filters, this.superadmin).then(res => {
        let link = JwtService.getPrintPrelink(this.clinic, this.superadmin)
        location.href = link+'/excel/'+res.data.result
      })
    }
  },
  computed: {
    ...mapState({
      viewModel: state => state.datatable.viewModel,
      options: state => state.datatable.eloquentOptions,
      additionalParams: state => state.datatable.additionalParams,
      clinic: state => state.auth.clinic,
      filters: state => state.datatable.filters,
    })
  }
}
</script>