import general from './uz_general'
import header from './uz_header'
import sidebar from './uz_sidebar'
import calendar from './uz_calendar'
import warehouses from './uz_warehouses'
import patients from "./uz_patients"
import settings from "./uz_settings"
import services from "./uz_services"
import staff from "./uz_staff"
import accounting from "./uz_accounting"
import reports from "./uz_reports"
import templates from "./uz_templates"
import cashdesk from "./uz_cashdesk"
import planning from "./uz_planning"
import crm from "./uz_crm"
import egisz from "./uz_egisz"
import marketing from "./uz_marketing"
import nontranslated from "./uz_nontranslated"

export default {
    ...general,
    ...header,
    ...sidebar,
    ...calendar,
    ...warehouses,
    ...patients,
    ...services,
    ...staff,
    ...accounting,
    ...reports,
    ...templates,
    ...cashdesk,
    ...planning,
    ...settings,
    ...crm,
    ...egisz,
    ...marketing,
    ...nontranslated
}
