<template>
  <div class="form-group">
    <label class="form-label">
      {{ $t('text') }}
      <span> *</span>
    </label>
    <ckeditor class=""
              @namespaceloaded="onNamespaceLoaded"
              :config="ckConfig"
              v-model="object.text26">
    </ckeditor>
    <ValidationError validation-id="text26"/>
    <div class="mt-4 tags-note" v-html="$t('tags_for_document')"></div>
  </div>
</template>

<script>

import CKEditor from 'ckeditor4-vue'
import {ckeditorConfig} from "@/dictionaries/ckeditorConfig"
import ValidationError from "@/components/form/ValidationError"
import {mapState} from "vuex"

export default {
  name: "AgreementDatatableFormText",
  components: {
    ValidationError,
    ckeditor: CKEditor.component
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
  },
  computed: {
    ...mapState({
      options: state => state.datatable.eloquentOptions
    }),
  },
  data() {
    return {
      ckConfig: ckeditorConfig,
    }
  },
  methods: {
    onNamespaceLoaded(CKEDITOR) {
      CKEDITOR.plugins.addExternal('lineheight', '/js/ckeditor/lineheight/', 'plugin.js' );
    }
  }
}
</script>
