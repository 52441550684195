<template>
  <section id="settings" class="settings">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print position-relative">
        <div class="col order-md-0 order-0">
          <h1 v-if="isStandard">{{ $t('marketing') }}</h1>
          <div v-if="isGroup">
            <router-link :to="{ name: 'MarketingGroups'}">{{ $t('groups') }}</router-link>
            <span v-if="$route.name === 'MarketingGroup' && group"> / {{ group.title }}</span>
            <template v-if="$route.name === 'MarketingGroupAdd' && group">
              <span> / </span>
              <router-link :to="{ name: 'MarketingGroup', params: group.id }">{{ group.title }}</router-link>
              <span> / {{ $t('add_patients') }}</span>
            </template>
          </div>
          <div v-if="isMailing">
            <router-link :to="{ name: 'MarketingMailing'}">{{ $t('mailings') }}</router-link>
            <span v-if="mailing"> / {{ mailing.title  }}</span>
          </div>
        </div>
        <div class="col-md-auto col mt-md-0 mt-2 order-md-1 order-3 table-view-navigator">
          <SearchTable/>
        </div>
        <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator">
          <DataTableFilterButton/>
        </div>
        <div class="col-auto table-view-navigator order-md-4 order-1" v-if="$route.name === 'MarketingGroup'">
          <DataTableExcel/>
        </div>
        <div class="col-auto order-md-5 order-2 table-view-navigator" v-if="rights.includes('notifications')">
          <AddObject module="marketing"/>
<!--          <router-link v-if="isGroup && group && $route.name != 'MarketingGroupAdd'"-->
<!--                       class="btn-themed d-inline-block"-->
<!--                       :to="{ name: 'MarketingGroupAdd', params: { id: group.id } }">-->
<!--            {{ $t('add') }}-->
<!--          </router-link>-->
        </div>
      </div>
    </div>
    <div class="sub-menu" v-if="isStandard">
      <template v-for="(m, mind) in menu">
        <router-link :to="{ name: m.to }"
                     v-if="!m.right || rights.includes(m.right)"
                     :key="`marketing-link-${mind}}`">
          {{ $t(m.title) }}
        </router-link>
      </template>
    </div>
    <hr v-if="!isStandard" class="mt-1"/>
    <router-view/>
  </section>
</template>

<script>

import {mapState} from "vuex"
import AddObject from "@/components/parts/datatable/AddObject"
import SearchTable from "@/components/parts/datatable/SearchTable"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"

export default {
  name: "MarketingGeneral",
  components: {
    DataTableFilterButton,
    DataTableExcel,
    SearchTable,
    AddObject
  },
  data() {
    return {
      menu: [
        { to: 'MarketingGroups', title: 'groups', right: 'marketing' },
        { to: 'MarketingMailing', title: 'mailings', right: 'marketing' },
        { to: 'MarketingNotifications', title: 'notifications', right: 'marketing' },
        { to: 'MarketingSettings', title: 'settings', right: 'marketing' },
      ]
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      group: state => state.marketing.marketingGroup,
      mailing: state => state.marketing.marketingMailing,
    }),
    isGroup() {
      return this.$route.name === 'MarketingGroup' || this.$route.name === 'MarketingGroupAdd'
    },
    isMailing() {
      return this.$route.name === 'MarketingMailingItem'
    },
    isStandard() {
      return !this.isGroup && !this.isMailing
    }
  }
}
</script>

<style scoped lang="scss">
.settings {
  padding-bottom: 70px;
}
</style>
