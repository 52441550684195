export default {
    add_to_egisz: "Add to EGISZ",
    download_all: "Download all",
    egisz: "EGISZ",
    main_doctor: "Chief physician",
    patient_rejected_to_send_to_egisz: "Patient rejected to send data to EGISZ",
    requires_attention: "Requires attention",
    representative_relation: "Who is the representative to the patient",
    send_to_egisz: "Send to EGISZ",
    sent_to_egisz: "Sent to EGISZ",
    sign: "Sign",
    sign_all: "Sign all",
    signed_by_doctor: "Signed by doctor",
    signed_by_main_doctor: "Signed by main doctor",
    to_egisz_page: "To EGISZ page",
    to_rework: "To rework",
    to_sign: "Send to signature",
}
