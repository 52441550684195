<template>
  <div id="app">
    <component v-if="loaded" :is="layout">
      <router-view/>
    </component>
    <div class="loader" v-if="loading" >
      <b-spinner label="Spinning"></b-spinner>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/app";
</style>

<script>

import { mapState } from "vuex"

export default {
  computed: {
    layout() {
      if(!this.$route) return 'empty-layout'
      return (this.$route.meta.layout || "empty") + '-layout'
    },
    ...mapState({
      loading: state => state.dom.loading,
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      loaded: true,
      logoutTimer: null,
    }
  },
  metaInfo: {
    titleTemplate: '%s | Dentist+'
  },
  mounted() {
    this.$store.commit('updateMobile')
    this.resetLogoutTimer()
  },
  created() {
    window.addEventListener("resize", this.resizeEventHandler)
    document.onmousemove = this.resetLogoutTimer
    document.onkeypress = this.resetLogoutTimer
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeEventHandler)
  },
  methods: {
    resizeEventHandler() {
      this.$store.commit('updateMobile')
    },
    resetLogoutTimer() {
      if(this.logoutTimer) {
        clearTimeout(this.logoutTimer)
      }
      if(this.clinic && this.clinic.logout_timeout && parseInt(this.clinic.logout_timeout) > 0) {
        this.logoutTimer = setTimeout(this.logout, parseInt(this.clinic.logout_timeout) * 1000)
      }
    },
    logout() {
      this.$store.dispatch('logout')
      this.$router.push('/login').catch(()=>{})
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -1rem;
  margin-top: -1rem;
  z-index: 5000;
}
</style>
