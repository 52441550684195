export default {
    administrator: "Manager",
    administrators: "Managers",
    are_you_sure_you_want_to_delete_field: "Are you sure you want to delete field",
    assistants: "Assistants",
    block: "Block",
    by_days: "By days",
    by_days_of_week: "By week days",
    by_even_days: "By even days",
    by_odd_days: "By odd days",
    chair: "Chair",
    chairs: "Chairs",
    config: "Configuration",
    confirm_password: "Confirm password",
    doctor_position: "Doctor's position",
    doctor_specialty: "Doctor's specialty",
    field: "Field",
    fields: "Fields",
    functionality: "Functionality",
    login_and_password: "Login and Password",
    password_does_not_match_confirmation: "Password does not match with confirmation",
    percent: "Interest",
    period_from: "Period from",
    period_to: "Period to",
    phone2: "Phone 2",
    profession: "Profession",
    rate: "Rate",
    rate_per_day: "Rate per day",
    rate_per_hour: "Rate per hour",
    rate_per_month: "Rate per month",
    schedule_conflicts_found: "Schedule conflicts found with current records",
    staff: "Staff",
    technics: "Technicians",
    weekend: "Day off",
    without_warehouse_form_note: "If not attached to a warehouse, materials will be written off from the main warehouse or from a warehouse attached to a chair",
    work_day: "Work day",
    work_days: "Work days",
    work_schedule: "Work schedule",
    work_time: "Work hours",
    user_data: "User data"
}
