import { render, staticRenderFns } from "./MarketingGeneral.vue?vue&type=template&id=0bed912c&scoped=true&"
import script from "./MarketingGeneral.vue?vue&type=script&lang=js&"
export * from "./MarketingGeneral.vue?vue&type=script&lang=js&"
import style0 from "./MarketingGeneral.vue?vue&type=style&index=0&id=0bed912c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bed912c",
  null
  
)

export default component.exports