<template>
  <div v-if="hasAQSIRight" class="pb-5">
    <b-button class="btn-collapse" v-b-toggle="`collapse-aqsi`">AQSI</b-button>
    <b-collapse id="collapse-aqsi" class="pt-4">
      <div v-for="(a, aind) in aqsi.aqsi_key" :key="`k-${aind}`">
        <b-row class="align-items-center">
          <b-col>
            <InputGroup v-model="aqsi.aqsi_key[aind].title"
                        class="mb-3"
                        :label="$t('title')"/>
          </b-col>
          <b-col>
            <InputGroup v-model="aqsi.aqsi_key[aind].key"
                        class="mb-3"
                        :label="$t('integration_key')"/>
          </b-col>
          <b-col cols="auto">
            <a class="pointer" @click="removeKey(aind)"><DeleteSvg/></a>
          </b-col>
        </b-row>
      </div>
      <div class="text-right">
        <a class="btn btn-themed btn-themed-outline btn-themed-squared" @click="addKey"><PlusSvg/></a>
      </div>

      <div>
        <CheckboxGroup :label="$t('send_services_in_cheque')"
                       fid="send_services"
                       class="mt-3"
                       :checkboxvalue="1"
                       v-model="aqsi.aqsi_send_services"/>
        <SelectGroup :options="aqsiTaxModes"
                     :label="$t('vat_rate')"
                     class="mt-3"
                     variant="white"
                     :showlabel="true"
                     v-model="aqsi.aqsi_tax"/>
        <ServiceMultiSelect :label="$t('pay_methods_cash')"
                            :dblclick="false"
                            v-if="loaded"
                            model="pay_method"
                            title="translatedTitle"
                            class="mt-3"
                            :dbsearch="false"
                            :prepopulatedOptions="pay_methods"
                            v-model="aqsi.aqsi_cashmethods"/>
      </div>

      <div class="mt-4">
        <button class="btn btn-themed btn-themed-squared"
                :disabled="loading"
                @click="save">
          {{ $t('save') }}
        </button>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { ClinicService } from "@/services/api.service"
import { mapState } from "vuex"
import InputGroup from "@/components/form/InputGroup.vue"
import PlusSvg from '@/assets/svg/patients/plus_tag.svg'
import DeleteSvg from '@/assets/svg/general/delete.svg'
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"
import SelectGroup from "@/components/form/SelectGroup.vue"
import { aqsiTaxModes } from "@/dictionaries/dictionary"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"

export default {
  name: "AQSIIntegration",
  components: {
    ServiceMultiSelect,
    SelectGroup,
    CheckboxGroup,
    InputGroup,
    PlusSvg,
    DeleteSvg
  },
  computed: {
    ...mapState({
      clinic: state   => state.auth.clinic,
      superadmin: state => state.auth.superadmin,
      rights: state => state.auth.rights,
      loading: state => state.dom.loading,
      ppoData: state => state.ppo.ppoData,
      pay_methods: state => state.auth.pay_methods,
      branch: state => state.auth.branch
    }),
    standardSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        [`aqsi_key${this.branch}`]: [{key: '', title: ''}],
        aqsi_send_services: 0,
        aqsi_tax: 6,
        [`aqsi_cashmethods${this.branch}`]: [],
      }
    },
    hasAQSIRight() {
      return this.rights.includes('aqsi')
    }
  },
  data() {
    return {
      loaded: false,
      aqsi: {
        aqsi_key: [{key: '', title: ''}],
        aqsi_send_services: 0,
        aqsi_tax: 6,
        aqsi_cashmethods: [],
      },
      aqsiTaxModes
    }
  },
  methods: {
    async save() {
      await ClinicService.savePPOAttribute([
        `aqsi_key${this.branch}`,
        'aqsi_send_services',
        'aqsi_tax',
        `aqsi_cashmethods${this.branch}`,
      ], [
        this.aqsi.aqsi_key,
        this.aqsi.aqsi_send_services,
        this.aqsi.aqsi_tax,
        this.aqsi.aqsi_cashmethods.map(x => x.id),
      ], this.superadmin, this.clinic.id)
      this.$noty.info(this.$t('success_message'))
      this.$emit('update')
    },
    addKey() {
      this.aqsi.aqsi_key.push({key: '', title: ''})
    },
    removeKey(index) {
      this.aqsi.aqsi_key.splice(index, 1)
    }
  },
  async mounted() {
    this.aqsi.aqsi_key = this.standardSettings[`aqsi_key${this.branch}`] || [{key: '', title: ''}]
    this.aqsi.aqsi_send_services = this.standardSettings.aqsi_send_services
    this.aqsi.aqsi_tax = this.standardSettings.aqsi_tax
    this.aqsi.aqsi_cashmethods = []
    let cashMethods = this.standardSettings[`aqsi_cashmethods${this.branch}`]
    if(cashMethods) {
      this.aqsi.aqsi_cashmethods = this.pay_methods.filter(p => cashMethods.includes(p.id))
    }
    this.loaded = true
  }
}
</script>
