<template>
  <div class="d-month-view">
    <DMonthWeek v-for="week in monthDates"
                :key="week.week"
                :week="week" />
  </div>
</template>

<script>

import DMonthWeek from "@/components/pages/calendar/calendar/DMonthWeek"

export default {
  name: "DMonth",
  components: {DMonthWeek},
  props: {
    monthDates: Array
  },
  beforeDestroy() {
    this.$store.commit('setDoctorSchedules', {})
  }
}
</script>

<style scoped>

</style>
