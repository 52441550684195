<template>
  <div>
    <div>
      <div v-for="(t, tind) in visit.tasks" :key="`task-${tind}`">
        <div :class="`task task-${t.importance}`">
          <b-row class="align-items-center">
            <b-col md cols="12">
              <InputGroup v-model="t.title"
                          :label="$t('text')"/>
              <ValidationError :validationId="`tasks.${tind}.title`"/>
            </b-col>
            <b-col md="3" col>
              <SelectGroup :options="importances"
                           :nullOption="false"
                           :showlabel="true"
                           :required="true"
                           :label="$t('importance')"
                           variant="white"
                           v-model="t.importance"/>
              <ValidationError :validationId="`tasks.${tind}.importance`"/>
            </b-col>
            <b-col cols="auto" class="mt-2 mt-md-0">
              <a @click="removeTask(tind)" class="pointer">
                <TrashSvg/>
              </a>
            </b-col>
          </b-row>
        </div>
      </div>
      <a class="btn btn-themed btn-themed-outline btn-themed-squared" @click="addTask">
        {{ $t('create_task') }}
      </a>
    </div>
  </div>
</template>

<script>

// import TaskTeaser from "@/components/parts/header/TaskTeaser"
import { taskImportances } from "@/dictionaries/dictionary"
import SelectGroup from "@/components/form/SelectGroup"
import TrashSvg from '@/assets/svg/general/trash.svg'
import InputGroup from "@/components/form/InputGroup"
import ValidationError from "@/components/form/ValidationError"

export default {
  name: "VisitModalTasksPart",
  components: {
    ValidationError,
    InputGroup,
    SelectGroup,
    TrashSvg
  },
  props: {
    visit: {}
  },
  data() {
    return {
      importances: taskImportances
    }
  },
  methods: {
    addTask() {
      this.visit.tasks.push({
        importance: 'low',
        title: ''
      })
    },
    removeTask(index) {
      this.visit.tasks.splice(index, 1)
    }
  },
}
</script>

<style scoped lang="scss">
.task {
  padding: 8px 22px;
  margin-bottom: 14px;
  &-low {
    border-left: 5px solid $calendar-border-color;
  }
  &-high {
    background: #FFFAFA;
    border-left: 5px solid #DD1515;
  }
  &-medium {
    background: #FFFCF2;
    border-left: 5px solid #FEC943;

  }
}

@media screen and (max-width: 768px) {
  .task {
    padding: 6px 18px;
    //margin-bottom: 14px;
    &-low, &-high, &-medium {
      border-left-width: 3px;
    }
  }
}
</style>
