const state = {
    sidebarCollapsed: true,
    loading: false,
    isMobile: false,
    backLink: null
}

const getters = {

}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    toggleCollapsed(state) {
        state.sidebarCollapsed = !state.sidebarCollapsed
    },
    setSidebarCollapsed(state, collapsed) {
        state.sidebarCollapsed = collapsed
    },
    updateMobile(state) {
        state.isMobile = window && window.innerWidth <= 768
    },
    setBackLink(state, link) {
        state.backLink = link
    }
}

const actions = {

};

export default {
    state,
    actions,
    mutations,
    getters
}
