<template>
  <div class="pb-4" v-if="loaded">
    <div class="data-table-wrapper">
      <table class="table data-table mt-3">
        <tbody>
        <template v-for="(d, dind) in excelData">
          <tr :class="d.class" :key="`td-${dind}`">
            <td>{{ d.title }}</td>
            <td :class="{negative: d.amount < 0}">{{ d.amount | formatNumber(true) }}</td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <vue-excel-xlsx
        ref="excel"
        class="d-none"
        :data="excelData"
        :columns="columns"
        :filename="'report'"
        :sheetname="'report'">
    </vue-excel-xlsx>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"

export default {
  name: "ReportsPL",
  metaInfo() {
    return {
      title: this.$t('pl_statement'),
      meta: []
    }
  },
  props: {
    from: {},
    to: {},
    selectedBranches: {}
  },
  data() {
    return {
      loaded: false,
      rows: {},
      columns: [
        {
          id: 'title',
          field: 'title',
          label: this.$t('title'),
        },
        {
          id: 'amount',
          field: 'amount',
          label: this.$t('sum'),
        },
      ],
      structure: ['revenues', 'cos', 'margins', 'additional_costs'],
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('pnl', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
      }).then(res => {
        this.rows = res.data
        this.loaded = true
      })
    },
    excel() {
      this.$refs.excel.$el.click()
    }
  },
  computed: {
    totals() {
      let res = {
        revenues: 0, cos: 0, margins: 0, additional_costs: 0
      }
      this.structure.forEach(item => {
        if(this.rows[item]) {
          res[item] = Object.values(this.rows[item]).reduce((acc, curr) => acc + parseFloat(curr.amount), 0)
        }
      })
      return res
    },
    EBITDA() {
      return (this.totals.margins + this.totals.additional_costs)
    },
    excelData() {
      let arr = []
      this.structure.forEach(parameter => {
        arr.push({
          title: this.$t(parameter),
          amount: this.totals[parameter],
          class: 'font-weight-bold subtotal'
        })
        if(this.rows[parameter]) {
          for(let r in this.rows[parameter]) {
            if ({}.hasOwnProperty.call(this.rows[parameter], r)) {
              arr.push({
                title: this.rows[parameter][r].title,
                amount: this.rows[parameter][r].amount
              })
            }
          }
        }
      })
      arr.push({
        title: "EBITDA",
        amount: this.EBITDA,
        class: 'font-weight-bold subtotal'
      })
      return arr
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>

<style scoped>

</style>
