<template>
  <b-modal id="lead-show-modal"
           :title="`${$t('lead')} #${lead ? lead.id : '-'}`"
           @show="parse"
           @hide="onHide"
           size="xl"
           centered>
    <div v-if="lead && form">
      <b-row>
        <b-col md="4" cols="12">
          {{ $t('status') }}:
          <span class="font-weight-bold" :style="`color: ${leadStatuses.find(l => l.id === lead.status).color}`">{{ $t(lead.status) }}</span>
          <div>{{ $t('responsible') }}: <span v-if="lead.responsible">{{ lead.responsible.full_name }}</span></div>
        </b-col>
        <b-col md="4" cols="12">
          {{ $t('lead_channel') }}: {{ $t(lead.source) }}<br/>
          <b-row class="align-items-center mt-1">
            <b-col cols="auto">
              {{ $t('source') }}:
            </b-col>
            <b-col v-if="clientSources.length">
              <SelectGroup :options="clientSources"
                           class="mb-0 sm w-100"
                           :nullOption="true"
                           :showlabel="false"
                           :required="false"
                           @input="sourceChanged"
                           :label="$t('source')"
                           variant="white"
                           v-model="form.source_id"/>
            </b-col>
          </b-row>
          <div class="mt-1" v-if="lead.utm">UTM: {{ lead.utm }}</div>
        </b-col>
        <b-col md="4" cols="12">
          <div>{{ $t('date_add') }}: {{ lead.created_at | formatDate }}</div>
          <div v-if="lead.record">{{ $t('recorded_on_visit') }}: {{ lead.record.readableStart }}</div>
        </b-col>
      </b-row>
      <hr/>
      <b-row class="align-items-center">
        <b-col>
          <div>
            {{ lead.patient_name }}
            <span v-if="lead.urgent" class="notification-bubble position-relative d-inline-block" style="top: 0; left: 10px"></span>
          </div>

          <div>
            {{ $t('phone') }}: <OutgoingCallElement :phone="lead.phone"/><WhatsappTelegramIcon :phone="lead.phone" :patient-id="form.patient_id"/>
          </div>
          <div>Email: {{ lead.email }}</div>
          <div v-if="callVendor">
            <a class="btn btn-themed-squared btn-themed-outline mt-2" @click="listen">{{ $t('listen') }}</a>
          </div>
          <ValidationError validationId="phone" class="mt-3"/>
        </b-col>
        <b-col md="4" cols="12" v-if="possiblePatients.length">
          <div>{{ $t('by_phone_found', { phone: lead.phone}) }}:</div>
          <a class="d-block text-underlined pointer" v-for="p in possiblePatients"
             @click="lead.patient = p; form.patient_id = p.id; changed = true"
             :key="`pat-p-${p.id}`">{{ p.full_name }} ({{ p.phone }})
          </a>
        </b-col>
        <b-col md="auto" cols="12">
          <ServiceMultiSelect :label="$t('patient')"
                              :dblclick="false"
                              model="patient"
                              :multiple="false"
                              :readonly="['recorded'].includes(lead.status)"
                              title="full_name"
                              :dbsearch="true"
                              v-model="lead.patient"/>
          <button class="btn-themed btn-success btn-themed-squared"
                  v-if="!['recorded'].includes(lead.status)"
                  @click="recordPatient">
            {{ $t('record_on_visit') }}
          </button>
          <div v-if="lead.patient && ['recorded'].includes(lead.status)" style="margin-top: -10px;">
            <router-link :to="{ name: 'PatientVisits', params: { id: lead.patient.id } }">{{ $t('patient_card') }}</router-link>
          </div>
        </b-col>
      </b-row>
      <hr/>
      <div class="mb-3 overflow-scroll nowrap">
        <div class="d-flex flex-nowrap">
          <button v-for="l in leadStatuses"
                  class="btn-themed btn-themed-outline btn-themed-squared mr-2 lead-status-btn"
                  :style="`border-color: ${l.color}; color: ${l.color};`"
                  @click="changeStatus(l.id)"
                  :key="`le-${l.id}`">
            {{ $t(l.title) }}
          </button>
        </div>
      </div>
      <div>
        <AutosizeTextArea v-model="form.reason"
                          @input="changed = true"
                          :label="$t('application_reason')"/>
      </div>
      <div>
        <AutosizeTextArea v-model="form.comment"
                          @input="changed = true"
                          :label="$t('client_comment')"/>
      </div>
      <DateGroup :label="$t('recall')"
                 @input="changed = true"
                 v-model="form.call_date"/>
      <div class="text-right">
        <b-button class="btn-themed btn-themed-squared"
                  v-if="changed"
                  @click="saveChange">
          {{ $t('save') }}
        </b-button>
      </div>
      <GeneralCommentsBlock :object="lead"
                            model="lead_comment"
                            parent-column="lead_id"/>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons no-print">
        <b-button class="cancel mr-md-3 mr-2 px-3 d-inline-block align-middle w-auto"
                  variant="themed"
                  @click="edit">
          {{ $t('edit') }}
        </b-button>
        <button :disabled="loading"
                @click="close()"
                class="btn-themed align-middle btn-themed-squared">
          {{ $t('done') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import GeneralCommentsBlock from "@/components/parts/general/GeneralCommentsBlock"
import { leadStatuses } from "@/dictionaries/dictionary"
import { BinotelService, EloquentService, WhatsappService } from "@/services/api.service"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import DateGroup from "@/components/form/DateGroup"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import ValidationError from "@/components/form/ValidationError.vue"
import OutgoingCallElement from "@/components/parts/patients/OutgoingCallElement.vue"
import SelectGroup from "@/components/form/SelectGroup.vue"
import WhatsappTelegramIcon from "@/components/pages/patients/WhatsappTelegramIcon.vue"

export default {
  name: "LeadShowModal",
  components: {
    WhatsappTelegramIcon,
    SelectGroup,
    OutgoingCallElement,
    ValidationError,
    ServiceMultiSelect,
    DateGroup,
    AutosizeTextArea,
    GeneralCommentsBlock
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      lead: state => state.crm.lead,
      rights: state => state.auth.rights,
      wazzupEnabled: state => state.crm.wazzupEnabled,
      clientSources: state => state.auth.clientSources
    }),
    recordLink() {
      return this.lead?.patient_comment?.variables?.record_link || null
    },
    callVendor() {
      return this.lead?.patient_comment?.variables?.vendor || null
    }
  },
  data() {
    return {
      leadStatuses: leadStatuses,
      form: null,
      changed: false,
      possiblePatients: []
    }
  },
  methods: {
    edit() {
      this.$bvModal.hide('lead-show-modal')
      let newObject = JSON.parse(JSON.stringify(this.lead))
      this.$store.commit('setModule', 'crm')
      this.$store.commit('setSelectedModel', newObject)
      this.$store.commit('setViewModel', 'lead')
      this.$nextTick(this.$store.commit('setShowModal', true))
    },
    async saveChange() {
      this.$store.commit('cleanValidationErrors')
      try {
        let formData = new FormData()
        formData = this.appendFormdata(formData, this.form)
        await EloquentService.update('lead', this.lead.id, formData)
        this.changed = false
        this.$store.commit('incrementDataTableCounter')
      } catch (e) {
        console.log(e)
      }
    },
    parse() {
      this.possiblePatients = []
      this.form = {
        reason: this.lead.reason,
        comment: this.lead.comment,
        source: this.lead.source,
        source_id: this.lead.source_id,
        fname: this.lead.fname,
        phone: this.lead.phone,
        patient_id: this.lead.patient_id,
        call_date: this.lead.call_date,
        status: this.lead.status,
      }
      // if(this.lead.source === 'online_booking' && !this.lead.patient) {
      EloquentService.show('lead', this.lead.id).then(res => {
        this.lead.record = res.data.record
        if(!this.lead.patient) {
          this.possiblePatients = res.data?.possiblePatients || []
          if(this.possiblePatients.length === 1) this.lead.patient = this.possiblePatients[0]
        }
      })
      // }
      if(!this.clientSources.length) {
        this.$store.dispatch('getClientSources')
      }

    },
    changeStatus(status) {
      this.form.status = status
      this.lead.status = status
      this.saveChange()
    },
    async sourceChanged() {
      await this.saveChange()
      this.lead.source_id = this.form.source_id
    },
    async recordPatient() {
      if(this.lead.patient && this.lead.patient.id != this.form.patient_id) {
        this.form.patient_id = this.lead.patient.id
        await this.saveChange()
      }
      this.$router.push({ name: 'Calendar' }).catch(()=>{})
      if(this.lead.patient) {
        this.$store.commit('setPatientFromCall', this.copyObject(this.lead.patient))
      }
      this.$store.commit('setLeadForRecord', this.copyObject(this.lead))
      this.$bvModal.hide('lead-show-modal')
    },
    async listen() {
      if(this.callVendor === 'sipuni') {
        window.open(this.recordLink, '_blank')
      }
      if(this.callVendor === 'binotel' && this.lead?.patient_comment?.variables?.id) {
        let res = await BinotelService.getCallRecording(this.lead.patient_comment.variables.id)
        if(res.data?.url) {
          window.open(res.data.url, '_blank')
        } else {
          this.$noty.error(this.$t('record_not_available'))
        }
      }
    },
    async wazzupIframe(type = 'whatsapp') {
      let res = await WhatsappService.createIframe(this.form.patient_id, type)
      if(res.data.url) {
        this.$store.commit('setWazzupIframeUrl', res.data.url)
        this.$nextTick(() => {
          this.$bvModal.show('wazzup-iframe-modal')
        })
        return
      }
      if(res.data.error) {
        this.$noty.error(res.data.error)
      }
    },
    onHide() {
      this.$store.commit('cleanValidationErrors')
    }
  }
}
</script>

<style scoped>
.lead-status-btn {
  line-height: 30px;
  border-width: 3px;
}
@media screen and (max-width: 768px) {
  .lead-status-btn {
    line-height: 24px;
  }
}
</style>
