<template>
    <div>
      <div>yandex: {{ yandex }}ms</div>
      <div>hetzner: {{ hetzner }}ms</div>
      <button @click="refresh">Refresh</button>
    </div>
</template>

<script>
import { TestService } from "@/services/api.service"

export default {
  name: "SettingsTest",
  data() {
    return {
      yandex: null,
      hetzner: null
    }
  },
  methods: {
    refresh() {
      this.hetzner = null
      this.yandex = null
      TestService.hetznerTest().then(res => {
        this.hetzner = res.data
      })
      TestService.yandexTest().then(res => {
        this.yandex = res.data
      })  
    }
  },
  mounted() {
    this.refresh()
    // fetch('https://api.dentist-plus.com/test.php?t='+Date.now()).then()
  }
}
</script>