<template>
  <div>
    <b-button class="btn-collapse" v-b-toggle="`collapse-${model}`">{{ title }}</b-button>
    <b-collapse :visible="opened" :id="`collapse-${model}`">
      <div>
        <TemplateGroup v-model="v"
                       :dictation="dictation"
                       :label="title"
                       :saveAsTemplate="saveAsTemplate"
                       :disable-search="disableSearch"
                       :model="model"/>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import TemplateGroup from "@/components/form/TemplateGroup"

export default {
  name: "TemplateCollapse",
  components: {
    TemplateGroup
  },
  props: {
    title: {
      type: String,
    },
    value: {},
    model: {
      type: String,
      required: true
    },
    disableSearch: {
      type: Boolean,
      default: false
    },
    opened: {
      type: Boolean,
      default: false
    },
    dictation: {
      type: Boolean,
      default: true
    },
    saveAsTemplate: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      v: this.value
    }
  },
  watch: {
    v(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped>

</style>
