<template>
  <div>
    <template v-if="object.image">
      <div style="width: 100px;">
        <expandable-image :src="object.image"/>
      </div>
    </template>
    <template v-if="!object.image && object.url">
      <a :href="object.url" target="_blank">{{ $t('link') }}</a>
    </template>
    <template v-if="!object.image && !object.url" @click="addScan">
      <AddSvg/>
    </template>
  </div>
</template>

<script>

import AddSvg from '@/assets/svg/general/add.svg'

export default {
  name: "PatientsDatatableTDScanImg",
  components: {
    AddSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  methods: {
    addScan() {
      this.$store.commit('setViewModel', 'scan')
      this.$store.commit('setModule', 'patients')
      this.$store.commit('setSelectedModel', {})
      this.$store.commit('setShowModal', true)
      // this.$bvModal.show('scan-modal')
    }
  }
}
</script>

<style scoped lang="scss">
  //.img-preview:not(.expanded) {
  //  max-width: 80px;
  //  max-height: 80px;
  //  object-fit: contain;
  //  object-position: left center;
  //}
</style>
