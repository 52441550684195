<template>
  <div>
    <DataTable :options="options"
               :params="{ flag: 'active' }"
               module="warehouse"/>
    <StocksModal/>
  </div>
</template>

<script>

import DataTable from "../../parts/general/DataTable"
import options from "../../../data/warehouse/materialstock"
import StocksModal from "@/components/parts/warehouse/StocksModal"

export default {
  name: "Stocks",
  metaInfo() {
    return {
      title: this.$t("stocks"),
      meta: []
    }
  },
  components: {StocksModal, DataTable },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
