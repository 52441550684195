<template>
  <div v-if="settings && rights.includes('cashdesk')" class="form-narrow pt-3">
    <div v-if="rights.includes('ppo')" class="mb-3">
      <b-button class="btn-collapse" v-b-toggle="`collapse-checkbox`">Checkbox</b-button>
      <b-collapse id="collapse-checkbox">
        <!--      <div class="font-weight-bold">Checkbox</div>-->
        <div class="mt-3">
          <div class="alert alert-info" v-if="ppoData.vendor === 'checkbox' && ppoData.valid">{{ $t('data_is_valid') }}</div>
          <div class="alert alert-danger" v-else>{{ $t('data_is_invalid') }}</div>
        </div>
        <InputGroup v-model="settings.login" class="mb-3" :label="$t('login')"/>
        <InputGroup v-model="settings.password" class="mb-3" :label="$t('password')"/>
        <InputGroup v-model="settings.license" class="mb-3" :label="$t('license')"/>
        <ServiceMultiSelect :label="$t('pay_methods_cash')"
                            v-if="loaded"
                            :dblclick="false"
                            model="pay_method"
                            title="translatedTitle"
                            class="mb-3"
                            :dbsearch="false"
                            :prepopulatedOptions="pay_methods"
                            v-model="settings.cashmethods"/>
        <div class="pt-4">
          <button class="btn btn-themed btn-themed-squared"
                  :disabled="loading"
                  @click="saveSettings">{{ $t('save') }}</button>
        </div>
      </b-collapse>
    </div>

    <WebkassaIntegration @update="$emit('update')"/>

    <AQSIIntegration @update="$emit('update')" v-if="rights.includes('aqsi')"/>
  </div>
</template>

<script>

import { ClinicService } from "@/services/api.service"
import InputGroup from '@/components/form/InputGroup'
import { mapState } from "vuex"
import WebkassaIntegration from "@/components/parts/cashdesk/WebkassaIntegration.vue"
import AQSIIntegration from "@/components/parts/cashdesk/AQSIIntegration.vue"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"

export default {
  name: "CashdeskCheques",
  components: {
    ServiceMultiSelect,
    WebkassaIntegration,
    AQSIIntegration,
    InputGroup
  },
  metaInfo() {
    return {
      title: this.$t('settings'),
      meta: []
    }
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapState({
      ppoData: state => state.ppo.ppoData,
      rights: state => state.auth.rights,
      loading: state => state.dom.loading,
      clinic: state   => state.auth.clinic,
      pay_methods: state => state.auth.pay_methods,
      superadmin: state => state.auth.superadmin,
    }),
    settings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : { login: '', password: '', license: '', cashmethods: [] }
    },
  },
  methods: {
    async saveSettings() {
      await ClinicService.savePPOAttribute([
        'login',
        'password',
        'license',
        'cashmethods'
      ], [
        this.settings.login,
        this.settings.password,
        this.settings.license,
        this.settings.cashmethods.map(x => x.id)
      ], this.superadmin, this.clinic.id)
      this.$noty.info(this.$t('success_message'))
    },
  },
  mounted() {
    let cashMethods = this.copyObject(this.settings.cashmethods)
    if(cashMethods) {
      this.settings.cashmethods = this.pay_methods.filter(p => cashMethods.includes(p.id))
    }
    this.loaded = true
  }
}
</script>
