<template>
  <b-modal id="tasks-modal"
           :title="$t('tasks')"
           hide-footer
           @show="loadTasks"
           modal-class="right">
    <div v-if="loaded">
      <div class="add-task-form">
        <b-row class="row-narrow align-items-center">
          <b-col col>
            <CRMDateNavigator  ref="navigator"
                               :left="true"
                               class="w-100"
                               @daySelected="daySelected"/>
          </b-col>
          <b-col cols="auto">
            <a class="btn-themed btn-themed-outline btn-themed-default d-inline-block w-100 text-center"
               tabindex="#"
               @click="todayClicked">
              {{ $t('today') }}
            </a>
          </b-col>
        </b-row>
        <div class="mt-3">
        <CheckboxGroup :label="$t('my_tasks')"
                       fid="modal_my_tasks"
                       @input="tasksClicked"
                       v-model="myTasks"/>
        </div>
        <hr/>
        <a @click="addTask"
           v-if="!adding"
           class="d-flex justify-content-start align-items-center pointer">
          <div>
            <PlusSvg class=""/>
            <span class="ml-2">{{ $t('add_task') }}</span>
          </div>
          <!--        <DotsSvg/>-->
        </a>
        <div v-if="adding">
          <AutosizeTextArea v-model="form.title"
                            :label="$t('text')"/>
          <SelectGroup variant="white"
                       v-model="form.importance"
                       :label="$t('importance')"
                       :options="options"
                       :showlabel="true"/>
          <b-row no-gutters>
            <b-col cols="6">
              <button class="btn-themed btn-themed-outline btn-themed-squared btn cancel mr-2 px-0 w-100"
                      @click="addTask">
                {{ $t('cancel') }}
              </button>
            </b-col>
            <b-col cols="6">
              <button class="btn-themed btn ml-2 px-0 w-100  btn-themed-squared"
                      :disabled="!valid"
                      @click="save">
                {{ $t('save') }}
              </button>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="mt-4">
        <TaskTeaser v-for="(task, tind) in tasks"
                    :key="`task-${task.id}-${tind}`"
                    @loadTasks="loadTasks"
                    :task="task"/>
      </div>
    </div>
  </b-modal>
</template>

<script>

import PlusSvg from '@/assets/svg/header/plus.svg'
// import DotsSvg from '@/assets/svg/header/vertical_dots.svg'
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import SelectGroup from "@/components/form/SelectGroup"
import  { EloquentService } from "@/services/api.service"
import TaskTeaser from "@/components/parts/header/TaskTeaser"
import { mapActions, mapState } from "vuex"
import CRMDateNavigator from "@/components/parts/crm/CRMDateNavigator"
import moment from "moment/moment"
import CheckboxGroup from "@/components/form/CheckboxGroup"

export default {
  name: "TasksModal",
  components: {
    CheckboxGroup,
    CRMDateNavigator,
    TaskTeaser,
    SelectGroup,
    AutosizeTextArea,
    PlusSvg,
    // DotsSvg
  },
  data() {
    return {
      adding: false,
      loaded: false,
      myTasks: false,
      form: {
        title: '',
        importance: 'low'
      },
      options: [
        { id: 'low', title: 'low' },
        { id: 'medium', title: 'medium' },
        { id: 'high', title: 'high' },
      ],
      date: moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    ...mapActions(['getTasks']),
    addTask() {
      this.$store.commit('setViewModel', 'task')
      this.$store.commit('setModule', 'crm')
      this.$store.commit('setSelectedModel', {})
      this.$store.commit('setShowModal', true)
      // this.adding = !this.adding
    },
    loadTasks() {
      let filters = null
      if(this.myTasks) {
        filters = '{"my_tasks":{"id":"my_tasks","condition":1,"type":"equals"}}'
      }
      this.getTasks({ condition: { date: this.date }, filters: filters, loading: true }).then(() => {
        this.loaded = true
      })
    },
    save() {
      if(!this.valid) { return }
      this.adding = false
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.form)
      EloquentService.create('task', formData).then(() => {
        this.getTasks({ condition: { date: this.date }})
        this.form = {
          title: '',
          importance: 'low'
        }
      })
    },
    daySelected(val) {
      this.date = val
      this.loadTasks()
    },
    todayClicked() {
      this.$refs.navigator.day = moment()
    },
    tasksClicked() {
      this.loadTasks()
    }
  },
  mounted() {

  },
  created() {
    // this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
    //   if (modalId === 'tasks-modal') {
    //     this.loadTasks()
    //   }
    // })
    // this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
    //   if (modalId === 'tasks-modal') {
    //     this.loaded = false
    //   }
    // })
  },
  computed: {
    valid() {
      return this.form.importance && this.form.title
    },
    ...mapState({
      tasks: state => state.auth.tasks,
      counter: state => state.datatable.dataTableCounter
    }),
  },
  watch: {
    counter() {
      this.loadTasks()
    }
  }
}
</script>

<style scoped lang="scss">
.add-task-form {
  padding: 14px 22px 0 22px;
  svg {
    margin-top:-1px;
  }
}
</style>
