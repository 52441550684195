<template>
  <div></div>
</template>

<script>

import {UsersService} from "@/services/api.service"
import {mapState} from "vuex"

export default {
  name: "WebPush",
  computed: {
    ...mapState({
      user: state => state.auth.user,
    })
  },
  data() {
    return {
      messaging: null
    }
  },
  mounted() {
    if(this.$firebase.messaging.isSupported()) {
      this.messaging = this.$firebase.messaging()
      this.getFCMToken()
      this.receivingMessages()
    }
  },
  methods: {
    getFCMToken() {
      this.messaging.getToken({ vapidKey: 'BIeEdHVgaob9yC5BwUENSXwGVVlTQTSOvW9xp3TAd3xr2Vyop_6-XD6Rcy2WqwfKXmlVfSHc4Zxlr4lEWjePWH8' }).then((currentToken) => {
        if (currentToken) {
          let params = {
            token: currentToken,
            type: 'web',
            user_id: this.user ? this.user.id : null
          }
          let existingToken = this.user.fcm_tokens.find(x => x.token === currentToken)
          if(!existingToken) UsersService.fcmToken(params)
          // console.log(currentToken)
        } else {
          console.log('No registration token available. Request permission to generate one.')
        }
      }).catch(() => {
        console.log('The notification permission was not granted and blocked instead. (messaging/permission-blocked).')
      })
    },
    receivingMessages() {
      this.messaging.onMessage((payload) => {
        console.log('Message received. ', payload)
        let notification = payload.notification
        this.$noty.info(`${notification.title} ${notification.body}`)
      });
    }
  }
}
</script>

<style scoped>

</style>
