export default {
    apply_plan_treatment: "Применить план лечения",
    calls_planning: "Планирование звонков",
    choose_criterias_for_fill: "Выберите необходимые критерии для заполнения",
    comments: "Комментарии",
    grouped: "Сгруппированный",
    grouped_by_teeth: "Сгруппированный по зубам",
    last_update: "Последнее изменение",
    level_description: "Описание этапа",
    main_complaints: "Основные жалобы",
    notification_date: "Дата уведомления",
    plan: "План",
    plan_treatment_status: "Статус плана лечения",
    reset_all: "Сбросить все",
    route_list: "Маршрутный лист",
    select_all: "Отметить все"
}
