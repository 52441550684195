<template>
  <div class="egisz-form pb-5" style="max-width: 1100px;">
    <div v-if="visit.egisz_record">
      <EGISZForm :disabled="!['draft', 'requires_attention'].includes(visit.egisz_record.status)"
                 :record-id="visit.id"/>
      <div class="mt-4">
<!--        {{ $t(visit.egisz_record.status) }}.-->
        <router-link to="/egisz">{{ $t('to_egisz_page') }}</router-link>
      </div>
    </div>
    <div class="pt-3 pb-5"
         v-if="!visit.egisz_record">
      <div v-if="!visit.patient.send_to_egisz" class="alert alert-warning">{{ $t('patient_rejected_to_send_to_egisz') }}</div>
      <button :disabled="loading"
              v-else
              class="btn-themed btn-themed-squared"
              @click="save">
        {{ $t('add_to_egisz') }}
      </button>
    </div>
  </div>
</template>

<script>

import { CalendarService } from "@/services/api.service"
import { mapState } from "vuex"
import EGISZForm from "@/components/parts/patients/EGISZForm.vue"

export default {
  name: "PatientVisitEGISZ",
  components: {
    EGISZForm
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapState({
      visit:  state => state.patient.visit,
      loading: state => state.dom.loading,
    }),
  },
  methods: {
    async save() {
      let res = await CalendarService.initiateEGIZS(this.visit.id)
      if(res.data) {
        this.$emit('reloadVisit')
        this.$noty.info(this.$t('success_message'))
      }
    },
  },
}
</script>

<style scoped lang="scss">
</style>
