<template>
  <b-modal id="tooth-status-modal"
           :title="$t('condition')"
           @hide="$store.commit('cleanValidationErrors')"
           hide-footer
           size="xl"
           centered>
    <div>
      <CheckboxGroup v-if="innerTooth"
                     :label="$t('apply_to_whole_tooth')"
                     fid="whole-tooth"
                     v-model="whole"/>
    </div>
    <div class="border-top py-3 border-bottom">
      <b-row class="row-narrow">
        <div v-for="(s, sind) in statuses" class="col-md-6 col-6" :key="`tooth-status-${sind}`">
          <div :class="{ active: s.id == toothStatus }" class="pointer status-item">
            <div>
              <b-row class="mb-1 row-narrow">
                <b-col cols="auto" @click="statusSelected(s)">
                  <div class="tooth-status-color"
                       :class="{ bordered: ['transparent','rgb(255, 255, 255)','#ffffff','#FFFFFF'].includes(s.color) }"
                       :style="`background-color: ${s.color}`"></div>
                </b-col>
                <b-col>
                  <div class="title d-inline-block align-middle" @click="statusSelected(s)">
                    {{ s.title }}
                  </div>
                  <a v-if="s.clinic_id > 0" class="ml-3 d-inline-block align-middle" @click="editStatus(s)">
                    <EditSvg/>
                  </a>
                  <a v-if="s.clinic_id > 0" class="ml-3 d-inline-block align-middle" @click="deleteStatus(s, sind)">
                    <TrashSvg class="svg-light"/>
                  </a>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-row>
    </div>
    <div>
      <div class="pt-4">
        <div>
          <a class="btn-themed btn-themed-squared btn-themed-outline d-inline-block" v-if="!editingStatus" @click="addNew">{{ $t('add') }}</a>
        </div>
        <div v-if="editingStatus">
          <b-row>
            <b-col md="4" cols="12">
              <InputGroup v-model="editingStatus.title"
                          :show-errors="true"
                          validation-id="title"
                          :label="$t('title')"/>
            </b-col>
            <b-col md="4" cols="12">
              <InputGroup v-model="editingStatus.short_name"
                          :show-errors="true"
                          validation-id="short_name"
                          :label="$t('short_name')"/>
            </b-col>
            <b-col md="4" cols="12">
              <InputGroup v-model="editingStatus.color"
                          :show-errors="true"
                          validation-id="color"
                          type="color"
                          :label="$t('color')"/>
            </b-col>
          </b-row>
          <b-row class="mt-4 row-narrow justify-content-end">
            <b-col cols="auto">
              <button @click="cancel" class="btn-themed btn-themed-squared w-100 text-center d-inline-block btn-themed-outline">{{ $t('cancel') }}</button>
            </b-col>
            <b-col cols="auto">
              <button @click="saveStatus" :disabled="loading" class="btn-themed btn-themed-squared w-100 text-center d-inline-block">{{ $t('save') }}</button>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

import {EloquentService, PatientService} from "@/services/api.service"
import {mapActions, mapState} from "vuex"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import InputGroup from "@/components/form/InputGroup"
import EditSvg from '@/assets/svg/general/edit.svg'
import TrashSvg from '@/assets/svg/general/trash_tr.svg'

export default {
  name: "ToothStatusModal",
  components: {
    InputGroup,
    CheckboxGroup,
    EditSvg,
    TrashSvg
  },
  methods: {
    ...mapActions(['getPatientTeeth']),
    statusSelected(status) {
      PatientService.updateClientTeeth(this.patient.id, this.selectedTooth, {
        status_id: status.id,
        area: this.selectedToothArea,
        all: this.whole
      }).then(res => {
        let d = res.data
        this.$store.commit('setTeeth', d.teeth)
        this.$store.commit('setStatuses', d.statuses)
        this.$bvModal.hide('tooth-status-modal')
      }).catch(err => {
        console.log(err)
      })
    },
    saveStatus() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.editingStatus)
      formData.append('area', this.selectedToothArea)
      this.loading = true
      if(this.editingStatus.id) {
        EloquentService.update('tooth_status', this.editingStatus.id, formData).then(() => {
          this.editingStatus = null
          this.getPatientTeeth(this.patient.id)
          this.loading = false
        }).catch(this.loading = false)
      } else {
        EloquentService.create('tooth_status', formData).then(() => {
          this.editingStatus = null
          this.getPatientTeeth(this.patient.id)
          this.loading = false
        }).catch(this.loading = false)
      }
    },
    addNew() {
      this.editingStatus = {
        title: '',
        color: '#ffffff',
        short_name: ''
      }
    },
    editStatus(s) {
      this.editingStatus = s
    },
    deleteStatus(s) {
      let res = confirm(this.$t('are_you_sure_you_want_to_delete_status'))
      if(!res) return
      EloquentService.delete('tooth_status', s.id).then(() => {
        this.$noty.info(this.$t('success_message'))
        let newTS = this.copyObject(this.toothStatuses)
        for(const area in newTS) {
          for(const id in newTS[area]) {
            if(id == s.id) {
              delete newTS[area][s.id];
            }
          }
        }
        this.$store.commit('setStatuses', newTS)
      })
    },
    cancel() {
      this.editingStatus = null
    }
  },
  computed: {
    ...mapState({
      patient: state => state.patient.patient,
      selectedTooth:  state => state.teeth.selectedTooth,
      teeth:  state => state.teeth.teeth,
      selectedToothArea:  state => state.teeth.selectedToothArea,
      toothStatuses:  state => state.teeth.toothStatuses,
    }),
    statuses() {
      let st = []
      if(!this.selectedToothArea) { return [] }
      if(['gum','tooth'].includes(this.selectedToothArea)) {
        st = this.toothStatuses[this.selectedToothArea]
      } else {
        st = this.toothStatuses['tooth_circle']
      }
      st = Object.values(st)
      // st.sort((a, b) => (a.clinic_id > b.clinic_id) ? 1 : -1)
      st.sort((a, b) => (a.clinic_id > b.clinic_id) ? 1 : (a.clinic_id === b.clinic_id) ? ((a.title > b.title) ? 1 : -1) : -1 )
      st.unshift({ id: 0, title: this.$t('norm'), color: '#ffffff' })
      return st
    },
    toothStatus() {
      let status = this.teeth.find(x => x.tooth_number == this.selectedTooth)
      if(status) {
        return status[this.selectedToothArea]
      }
      return 0
    },
    innerTooth() {
      return ['tooth_inner_right','tooth_inner_left','tooth_left','tooth_top','tooth_right','tooth_bottom','tooth_inner'].includes(this.selectedToothArea)
    }
  },
  data() {
    return {
      whole: false,
      editingStatus: null,
      loading: false
    }
  },
  watch: {
    innerTooth(val) {
      if(!val) {
        this.whole = false
      }
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if(modalId === 'tooth-status-modal') {
        this.$store.commit("setSelectedTooth", { tooth: null, area: null })
        this.editingStatus = null
      }
    })
  }
}
</script>

<style scoped lang="scss">
#tooth-status-modal {
  font-size: 14px;
}
.tooth-status-color {
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: inline-block;
  &.bordered {
    border: 2px solid #eee;
  }
}
.status-item {
  &.active, &:hover {
    .title {
      color: $blue-color;
    }
  }
}
</style>
