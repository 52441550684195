export default {
    primaryKey: 'id',
    model: 'insurance_company',
    order: 'id',
    sort: 'asc',
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    deletemessage: 'are_you_sure_you_want_to_delete_company',
    showTotalRecords: true,
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            tabletdclass: "order-mob-0",
            sortable: true,
            filter: true,
            form: {
                type: "text",
                required: true
            }
        }
    ]
}
