<template>
  <div>
    <router-link class="font-weight-bold"
                 v-if="!isPatient && object.status != 2 && !object.isOtherBranch"
                 :to="{name: 'PatientVisitPage', params: { visitid: object.id, id: object.client_id, page: 'description' } }">
      <div>{{ object.start | formatDate('DD.MM.YYYY, HH:mm') }}</div>
      <div v-if="object.doctor">
        <a>{{ object.doctor.full_name }}</a>
      </div>
    </router-link>
    <div v-else>
      {{ object.start | formatDate('DD.MM.YYYY, HH:mm') }}
      <div v-if="object.doctor">{{ object.doctor.full_name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PatientsDatatableTDStart",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    isPatient() {
      return this.$store.getters.isPatient
    }
  }
}
</script>

<style scoped>

</style>
