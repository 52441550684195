<template>
  <section id="clinics" class="clinics">
    <transition name="fade" mode="out-in">
      <div class="section-top-row" v-if="['superadmin', 'supermanager'].includes(role)">
        <div class="row row-narrow align-items-center no-print position-relative">
          <div class="col order-md-0 order-0">
            <h1>{{ $t('clinics') }}</h1>
          </div>
          <div class="col-md-auto col mt-md-0 mt-2 order-md-1 order-3 table-view-navigator">
            <SearchTable/>
          </div>
          <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator">
            <DataTableFilterButton/>
          </div>
          <div class="col-auto order-md-4 order-1 table-view-navigator">
            <DataTableExcel :superadmin="true"/>
          </div>
          <div class="col-auto order-md-5 order-2 table-view-navigator">
            <AddObject module="superadmin"/>
          </div>
        </div>
      </div>
      <div v-if="role === 'manager'" class="pt-4 mb-3">
        <b-row>
          <b-col cols="auto">
        <input type="text"
               style="max-width: 200px; height: 36px;"
               class="form-control"
               v-model="q"
               :placeholder="$t('search')"/>
          </b-col>
          <b-col cols="auto">
        <button class="btn-themed btn-themed-squared"
                @click="search">
          {{ $t('search') }}
        </button>
          </b-col>
        </b-row>
      </div>
    </transition>
    <div>
      <DataTable :options="options"
                 v-if="['superadmin', 'supermanager'].includes(role) || searchMode"
                 ref="dataTable"
                 module="superadmin"/>
    </div>
  </section>
</template>

<script>

import SearchTable from "@/components/parts/datatable/SearchTable"
import AddObject from "@/components/parts/datatable/AddObject"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import {mapState} from "vuex"
import options from "@/data/superadmin/clinic"
import DataTable from "@/components/parts/general/DataTable"

export default {
  name: "SuperAdminClinics",
  components: {
    DataTable,
    DataTableFilterButton,
    AddObject,
    SearchTable,
    DataTableExcel
  },
  metaInfo() {
    return {
      title: this.$t('clinics'),
      meta: []
    }
  },
  data() {
    return {
      options: options,
      q: '',
      searchMode: ''
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights
    }),
    role() {
      return this.$store.state.auth.user.role
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  },
  methods: {
    search() {
      if(this.q) {
        this.searchMode = true
        this.$nextTick(() => {
          this.$store.commit('setSearch',this.q)
        })
      }
    }
  },
  watch: {
    q() {
      this.searchMode = false
    }
  }
}
</script>

<style scoped lang="scss">

</style>
