import {recordTypes, recordStatusesWithCancelled} from "@/dictionaries/dictionary"

export default {
    primaryKey: 'id',
    model: 'record',
    order: 'start',
    sort: 'desc',
    // actionscomponent: 'PatientDatatableRecordActions',
    actionscomponent: 'CRMRecordDatatableActions',
    deletemessage: 'are_you_sure_you_want_to_delete_record',
    // showAllOption: true,
    showTotalRecords: true,
    checks: true,
    actions: [
        "excel"
        // "add",
        // "delete"
    ],
    // rights: {
    //     delete: "patients",
    // },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true,
            filter: true
        },
        {
            id: 'start',
            title: 'date',
            table: true,
            sortable: true,
            format_datetime: true,
            form: {
                type: "date",
                required: true
            },
            tabletdclass: "order-mob-0",
            filter: true,
            filter_date: true
        },
        {
            id: 'doctor_id',
            title: 'doctor',
            table: true,
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_data: {
                    "title": "full_name",
                },
            },
            tdcomponent: 'PlanningDatatableTDDoctorName',
            table_show: 'doctor.full_name',
        },
        {
            id: 'client_id',
            title: 'patient',
            table: true,
            sortable: true,
            filter: true,
            tdcomponent: 'PatientsDatatableTDName',
            table_show: 'patient.full_name',
        },
        {
            id: 'client_phone',
            title: 'phone',
            table: true,
            sortable: true,
            filter: true,
            tdcomponent: 'PatientsDatatableTDPhone',
            table_show: 'patient.phone',
        },
        {
            id: 'record_type',
            title: 'type',
            table: true,
            sortable: true,
            filter: true,
            filter_list_select: true,
            form: {
                options: recordTypes
            },
            format_translate: true,
        },
        {
            id: 'description',
            title: 'description',
            table: true,
            sortable: true,
        },
        {
            id: 'admin_id',
            title: 'who_created',
            table: true,
            sortable: true,
            filter: true,
            filter_model_select: true,
            tdcomponent: 'CRMDatatableTDAdminName',
            // table_show: 'author.full_name',
        },
        {
            id: 'records_statuses_id',
            title: 'status',
            table: true,
            table_show: 'readableStatus',
            sortable: true,
            format_translate: true,
            filter: true,
            filter_list_select: true,
            form: {
                options: recordStatusesWithCancelled
            },
            tdcomponent: "PatientsDatatableTDRecordStatus"
        },
        {
            id: 'date_from',
            title: 'date_from',
            filter: true,
            filter_date: true,
        },
        {
            id: 'date_to',
            title: 'date_to',
            filter: true,
            filter_date: true,
        }
    ]
}
