<template>
  <section id="patient" class="patient">
    <div class="section-top-row align-items-center" :class="{ 'border-0': treatmentCard }">
      <div class="action-buttons-td position-relative">
        <div class="d-md-none d-inline-block">
          <h1>{{ patient ? patient.full_name : '' }}</h1>
        </div>
        <a class="d-md-none d-inline-block action-dots" @click="dotsPressed"><DotsSvg/></a>
        <div class="action-buttons-div d-md-flex row no-gutters align-items-center no-print"
             :class="{ expanded: mob_expanded }"
             v-click-outside="clickedOutside">
          <div class="col d-md-block d-none">
            <h1>
            <span class="d-md-inline d-none">
            <router-link :to="{name: 'Patients' }">{{ $t('patients') }}</router-link>
              </span>
              <span v-if="patient && !treatmentCard"><span class="d-md-inline d-none"> / </span>{{ patient.full_name }}</span>
              <span v-if="patient && treatmentCard"> / <router-link :to="{ name: 'PatientVisits', params: {id: patient.id} }">{{ patient.full_name }}</router-link></span>
              <span v-if="patient && treatmentCard"> / {{ $t('treatment_card') }}</span>
            </h1>
          </div>
          <div class="col-auto table-view-navigator" v-if="!treatmentCard && !isPatient">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed gray" @click="anketaPressed">
                <FileSvg class="mr-2 file-svg"/>{{ $t('questionnaire') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator" v-if="!treatmentCard && !isPatient && rights.includes('contracts')">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed gray" @click="contractPressed">
                <FileSvg class="mr-2 file-svg"/>{{ $t('contract') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator"
               v-if="!treatmentCard && rights.includes('payments')">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed gray"
                 @click="paymentPressed">
                <DollarSvg class="mr-2 file-svg"/>{{ $t('payment') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator"
               v-if="!treatmentCard && rights.includes('patients_update')">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed gray" @click="editPatient">
                <EditSvg class="mr-2"/>{{ $t('edit') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator"
               v-if="!treatmentCard && !isPatient">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed gray" @click="printPressed">
                <PrintSvg class="mr-2 file-svg"/>{{ $t('print_noun') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator" v-if="!treatmentCard && rights.includes('add_records')">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed d-inline-flex align-items-center" @click="addVisit">
                <CalendarSvg class="svg-white mr-2"/>
                {{ $t('add') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator" v-if="treatmentCard && visit">
            <a class="btn btn-themed d-inline-flex align-items-center"
               @click="startTreatment"
               v-if="['1','2','3'].includes(visit.records_statuses_id)">
              {{ $t('start_treatment') }}
            </a>
            <span v-if="visit.records_statuses_id == 4" class="text-primary d-block font-weight-bold h-100">
              <span class="circle circle-primary sm mr-2"></span>{{ $t('in_chair') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="patient-card no-print" v-if="!treatmentCard">
      <b-row v-if="patient">
        <b-col md="12" cols="12">
          <b-row>
            <b-col cols="12" lg="auto" class="text-center">
              <PatientImg class="p-img"/>
            </b-col>
            <b-col md cols="12">
              <h2 class="text-lg-left text-center">
                <span>{{ patient.full_name }}</span>
                <span class="ml-2 small font-weight-normal">({{ $t(patient.gender_string+'_short') }})</span>
                <span class="ml-2" v-if="!isPatient">
                  <a @click="toggleTag(t, 'remove', tind)"
                     class="circle mr-2 pointer"
                     :style="`background-color: ${t.color}`"
                     v-for="(t, tind) in patient.tags"
                     :title="t.title"
                     v-b-tooltip.hover
                     :key="`made-tag-${tind}`"></a>
                </span>
                <b-dropdown right variant="link" no-caret v-if="patientTags.length < tags.length && !isPatient">
                  <template #button-content>
                    <a class="pointer position-relative" style="top: -3px;"><PlusSvg/></a>
                  </template>
                  <div class="pt-0">
                    <template v-for="(t, tind) in tags">
                      <a class="dropdown-item pointer"
                         :key="`tag-${tind}`"
                         @click="toggleTag(t, 'add')"
                         v-if="!patientTags.includes(t.id)">
                        <span class="circle mr-2" :style="`background-color: ${t.color}`"></span>
                        {{ t.title }}
                      </a>
                    </template>
                  </div>
                </b-dropdown>
              </h2>
              <div>
                <span class="badge" :class="'badge-'+patient.clientStatus">
                {{ $t(patient.clientStatus) }}
                </span>
              </div>
              <div>
                <div v-if="patient.blocked == 1">
                  <div class="d-inline-block text-danger font-weight-bold">{{ $t('patient_deleted') }}</div>
                  <a class="pointer text-underlined ml-2 text-lowercase" tabindex="#" @click="restorePatient">({{ $t('restore') }})</a>
                </div>
<!--                <div><span class="text-muted">{{ $t('activity') }}:</span> {{ $t(patient.activity_status) }}</div>-->
<!--                <div v-if="patient.activity_status">{{ $t(patient.activity_status) }}</div>-->
                <div v-if="clinic.country_id == countries.KAZAKHSTAN && patient.iin">{{ $t('iin') }}: {{ patient.iin }}</div>
                <div>
                  {{ $t('years') }}: {{ patient.age || patient.age === 0 ? patient.age : '-' }}
                  <span class="text-muted" v-if="patient.date_of_birth">({{ patient.date_of_birth | formatDate }})</span>
                </div>
                <div v-if="patient.patient_condition">{{ $t('condition') }}: {{ patient.patient_condition.title }}</div>
                <div v-if="patient.email || patient.phone">
                  <div v-if="patient.email" class="d-inline-block">
                    <a @click="sendMail"
                       class="link pointer text-primary"
                       v-b-tooltip.hover
                       :title="$t('send_mail')"
                       v-if="rights.includes('emailing')">
                      {{ patient.email }}
                    </a>
                    <span v-else>{{ patient.email }}</span>
                  </div>
                  <a class="pointer"
                     :class="{ 'ml-3': patient.email }"
                     v-b-tooltip.hover
                     v-if="rights.includes('crm') && (patient.email || patient.phone)"
                     :title="$t('plan_notification')"
                     @click="$bvModal.show('deferred-message-modal')">
                    <ClockSvg class="svg-primary"/>
                  </a>
                </div>
                <div v-if="patient.description">{{ patient.description }}</div>
                <div v-if="patient.url">
                  <a target="_blank"
                     :href="patient.url"
                     ref="nofollow">
                    {{ patient.url }}
                  </a>
                </div>
                <div class="font-italic">{{ $t('source') }}: {{ patient.sourceName}}</div>
              </div>
            </b-col>
            <b-col lg="6" xl="6" class="mt-3 mt-lg-0">
              <b-row class="justify-content-xl-end">
                <b-col cols="auto" md="6" lg="auto">
                  <div v-if="patient.mphone && !rights.includes('hide_patient_phone')">
                    <OutgoingCallElement :phone="patient.mphone"
                                     color="black"
                                     class="mphone"/>
                    <a class="ml-3"
                       :href="`tel:${patient.mphone}`"
                       v-b-tooltip.hover
                       :title="$t('call')">
                      <PhoneSvg class="svg-primary"/>
                    </a>
                    <a v-if="clinic && clinic.sms_info && rights.includes('sms_send')"
                       v-b-tooltip.hover
                       :title="$t('send_sms')"
                       class="ml-3 pointer"
                       @click="smsModal(patient.mphone)">
                      <SmsSvg class="svg-primary"/>
                    </a>

                    <WhatsappTelegramIcon :patient-id="patient.id" :phone="patient.phone"/>

                    <div class="phone-comment" v-if="patient.mphone_descr">{{ patient.mphone_descr }}</div>
                  </div>
                </b-col>
                <b-col cols="auto" md="6" lg="auto" class="mt-md-0 mt-3" v-if="patient.mphone2 && !rights.includes('hide_patient_phone')">
                  <div>
                    <div class="left-separator"></div>
                    <div class="d-inline-block">
                      <OutgoingCallElement :phone="patient.mphone2"
                                           color="black"
                                           class="mphone"/>
                      <a class="ml-3"
                         :href="`tel:${patient.mphone2}`"
                         v-b-tooltip.hover
                         :title="$t('call')">
                        <PhoneSvg class="svg-primary"/>
                      </a>
                      <a v-if="clinic && clinic.sms_info && rights.includes('sms_send')"
                         v-b-tooltip.hover
                         :title="$t('send_sms')"
                         class="ml-3 pointer"
                         @click="smsModal(patient.mphone2)">
                        <SmsSvg class="svg-primary"/>
                      </a>
                      <WhatsappTelegramIcon :patient-id="patient.id" :phone="patient.mphone2"/>

                      <div class="phone-comment" v-if="patient.mphone2_descr">{{ patient.mphone2_descr }}</div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="12" cols="12" class="mt-3">
          <a class="d-block d-md-none more-info-link collapse-btn-arrow" v-b-toggle.collapse-patient-info>{{ $t('detailed_information') }}</a>
          <b-collapse id="collapse-patient-info" :visible="!isMobile">
            <b-row>
              <b-col md cols="12" class="mb-md-0 mb-3">
                <div class="card-p">
                  <PatientColInline label="card_number" :title="patient.card"/>
                  <PatientColInline label="activity" :title="$t(patient.activity_status)"/>
                  <PatientColInline label="treating_doctor" :title="patient.doctor ? patient.doctor.full_name : '-'"/>
                  <PatientColInline label="discount" :title="((patient.discount ? patient.discount : 0) + '%')"/>
                </div>
              </b-col>
              <b-col md cols="12" class="mb-md-0 mb-3" v-if="rights.includes('payments')">
                <div class="card-p">
                  <PatientColInline v-if="usesBonuses"
                                    label="bonuses"
                                    :title="$options.filters.formatCurrency(patient.total_bonuses)">
                    <a class="pointer ml-2 position-relative"
                       style="top: -2px;"
                       v-b-tooltip.hover
                       :title="$t('bonus_adjustment')"
                       @click="bonusRefill"
                       v-if="rights.includes('settings')">
                      <DollarSvg class="svg-light"/>
                    </a>
                  </PatientColInline>
                  <PatientColInline label="balance"
                                    v-if="rights.includes('deposit')"
                                    :title="$options.filters.formatCurrency(patient.advance_calculated)">
                    <a class="pointer ml-2 position-relative"
                       v-b-tooltip.hover
                       style="top: -2px;"
                       :title="$t('balance_refill')"
                       v-if="rights.includes('payments')"
                       @click="balanceRefill">
                      <DollarSvg class="svg-light"/>
                    </a>
                  </PatientColInline>
                  <PatientColInline label="total_paid"
                                    :title="$options.filters.formatCurrency(patient.totalPaidBranch)"/>
                  <PatientColInline :label="debtForBranch > 0 ? 'overpayment' : 'debt'"
                                    :class="{ 'text-danger': debtForBranch < 0, 'text-success': debtForBranch > 0 }"
                                    :title="$options.filters.formatCurrency(debtForBranch)">
                    <a v-if="debtForBranch < 0 && rights.includes('payments')"
                       v-b-tooltip.hover
                       :title="$t('payment')"
                       style="top: -2px;"
                       class="ml-2 pointer position-relative"
                       @click="paymentPressed">
                      <DollarSvg class="svg-light"/>
                    </a>
                  </PatientColInline>
                </div>
              </b-col>
              <b-col md cols="12" class="mb-md-0 mb-3">
                <div class="card-p">
                  <PatientColInline label="last_visit"
                                    :title="patient.lastVisit ? $options.filters.formatDate(patient.lastVisit.start, 'DD.MM.YYYY, HH:mm') : '-'"/>
                  <PatientColInline label="next_visit"
                                    :title="patient.nextVisit ? $options.filters.formatDate(patient.nextVisit.start, 'DD.MM.YYYY, HH:mm') : '-'"/>
                </div>
              </b-col>
            </b-row>
          </b-collapse>
        </b-col>
      </b-row>
    </div>

    <div v-if="patient && patient.pinnedComments && patient.pinnedComments.length"
         style="max-width: 1200px;">
      <PatientCommentTeaser v-for="c in patient.pinnedComments"
                            :key="`comment-${c.id}`"
                            :comment="c"
                            @getComments="$store.commit('incrementDataTableCounter')"
                            class="mb-3">
      </PatientCommentTeaser>
    </div>

    <div class="d-md-none d-block mobile-big-separator"></div>
    <div class="sub-menu no-print" v-if="!treatmentCard">
      <template v-for="(m, mind) in menu">
        <a class="pointer" tabindex="#" v-if="m.rightPopup && !rights.includes(m.rightPopup) && hasFreeTariff"
           :key="`a-link-${mind}}`"
           @click="$bvModal.show('module-not-in-tariff-modal')">
          {{ $t(m.title) }}
        </a>
        <router-link :to="{ name: m.to }"
                     v-else-if="(!m.right || rights.includes(m.right)) && !(isPatient && m.hiddenForPatient)"
                     :key="`patient-link-${mind}}`">
          {{ $t(m.title) }}
        </router-link>
      </template>
    </div>
    <div class="patient-add-wrap d-block no-print"
         v-if="canAdd && !['PatientPayments', 'PatientDepositTransactions', 'PatientNotifications'].includes($route.name)">
      <AddObject module="patients" btnclass="btn-themed-outline"/>
    </div>
    <div class="patient-add-wrap d-block no-print"
         v-if="canAdd && $route.name == 'PatientPayments' && rights.includes('accept_payments')">
      <button @click="paymentPressed" class="btn-themed btn-themed-outline">{{ $t('add') }}</button>
    </div>
    <div class="patient-add-wrap d-block no-print"
         v-if="canAdd && $route.name == 'PatientDepositTransactions' && rights.includes('accept_payments')">
      <button @click="balanceRefill" class="btn-themed btn-themed-outline">{{ $t('add') }}</button>
    </div>
    <router-view class="no-print" v-if="patient"/>
    <ScanModal/>
    <PatientAnketaModal/>
    <PatientContractSelectModal/>
    <PatientPrintModal/>
    <PatientSMSModal :specified-phone="selectedPhone"/>
    <RefillBalanceModal/>
    <RefillBonusModal/>
    <PatientToPayModal/>
    <VisitPayModal/>
    <SendMailModal/>
    <AddNewVisitModal/>
    <DeferredMessageModal/>
  </section>
</template>

<script>

import EditSvg from '@/assets/svg/general/edit.svg'
import SmsSvg from '@/assets/svg/general/sms_t.svg'
import FileSvg from '@/assets/svg/patients/file.svg'
import DollarSvg from '@/assets/svg/patients/dollar.svg'
import PhoneSvg from '@/assets/svg/header/phone_t.svg'
import PrintSvg from '@/assets/svg/general/print.svg'
import PlusSvg from '@/assets/svg/patients/plus_tag.svg'
import CalendarSvg from '@/assets/svg/calendar/calendar_plus.svg'
import ClockSvg from '@/assets/svg/calendar/clock2.svg'
import DotsSvg from '@/assets/svg/header/vertical_dots.svg'
import { mapState, mapActions, mapGetters } from "vuex"
import PatientColInline from "@/components/parts/patients/PatientColInline"
import ScanModal from "@/components/parts/patients/ScanModal"
import AddObject from "@/components/parts/datatable/AddObject"
import PatientImg from "@/components/parts/patients/PatientImg"
import PatientAnketaModal from "@/components/parts/patients/PatientAnketaModal"
import PatientContractSelectModal from "@/components/parts/patients/PatientContractSelectModal"
import PatientPrintModal from "@/components/parts/patients/PatientPrintModal"
import PatientSMSModal from "@/components/parts/patients/PatientSMSModal"
import RefillBalanceModal from "@/components/parts/patients/RefillBalanceModal"
import PatientToPayModal from "@/components/parts/patients/PatientToPayModal"
import VisitPayModal from "@/components/parts/patients/VisitPayModal"
import { EloquentService, PatientService } from "@/services/api.service"
import SendMailModal from "@/components/parts/patients/SendMailModal"
import AddNewVisitModal from "@/components/parts/calendar/AddNewVisitModal"
import moment from "moment/moment"
import OutgoingCallElement from "@/components/parts/patients/OutgoingCallElement"
import DeferredMessageModal from "@/components/parts/patients/DeferredMessageModal"
import RefillBonusModal from "@/components/parts/patients/RefillBonusModal.vue"
import PatientCommentTeaser from "@/components/parts/patients/PatientCommentTeaser"
import WhatsappTelegramIcon from "@/components/pages/patients/WhatsappTelegramIcon.vue"
import { countries } from "@/dictionaries/dictionary"

export default {
  name: "PatientGeneral",
  components: {
    WhatsappTelegramIcon,
    RefillBonusModal,
    DeferredMessageModal,
    OutgoingCallElement,
    AddNewVisitModal,
    SendMailModal,
    VisitPayModal,
    PatientToPayModal,
    RefillBalanceModal,
    PatientSMSModal,
    PatientPrintModal,
    PatientContractSelectModal,
    PatientAnketaModal,
    PatientImg,
    AddObject,
    ScanModal,
    PatientColInline,
    PatientCommentTeaser,
    CalendarSvg,
    EditSvg,
    DollarSvg,
    PhoneSvg,
    SmsSvg,
    PrintSvg,
    PlusSvg,
    FileSvg,
    DotsSvg,
    ClockSvg,
  },
  data() {
    return {
      menu: [
        { to: 'PatientVisits', title: 'visits' },
        { to: 'PatientFormula', title: 'formula' },
        { to: 'PatientPayments', title: 'payments', right: 'payments' },
        { to: 'PatientDepositTransactions', title: 'deposit', right: 'deposit' },
        { to: 'PatientFamilyAccount', title: 'family_account', right: 'family_account' },
        { to: 'PatientTreatmentPlan', title: 'treatment_plan', rightPopup: 'planning' },
        { to: 'PatientScans', title: 'scans', right: 'scans' },
        { to: 'PatientNotes', title: 'notes', hiddenForPatient: true },
        { to: 'PatientDocuments', title: 'documents', right: 'documents_upload' },
        { to: 'PatientPersonal', title: 'personal_data' },
        { to: 'PatientComments', title: 'live_feed' },
        { to: 'PatientNotifications', title: 'notifications' },
        // { to: 'PatientClientService', title: 'client_service' },
      ],
      mob_expanded: false,
      justOpened: false,
      tags: [],
      selectedPhone: null,
      countries
    }
  },
  computed: {
    ...mapState({
      clinic:  state => state.auth.clinic,
      branchId:  state => state.auth.branch,
      user:  state => state.auth.user,
      patient:  state => state.patient.patient,
      counter: state => state.datatable.dataTableCounter,
      isMobile: state => state.dom.isMobile,
      rights: state => state.auth.rights,
      visit:  state => state.patient.visit,
      wazzupEnabled: state => state.crm.wazzupEnabled,
      ...mapGetters([
        'hasFreeTariff', 'usesBonuses'
      ]),
      // hasWazzup() {
      //   if(!this.clinic.ppo_settings) return false
      //   let settings = JSON.parse(this.clinic.ppo_settings)
      //   return !!settings.wazzup
      // }
    }),
    treatmentCard() {
      return ['PatientVisitPage'].includes(this.$route.name)
    },
    patientTags() {
      return (this.patient && this.patient.tags) ? this.patient.tags.map(el => el.id) : []
    },
    debtForBranch() {
      if(!this.patient) return 0
      if(this.patient.debt_calculated_branched) {
        return this.patient.debt_calculated_branched[this.branchId]
      }
      return this.patient.debt_calculated
    },
    advanceForBranch() {
      if(!this.patient) return 0
      if(this.patient.advance_calculated_branched) {
        return this.patient.advance_calculated_branched[this.branchId]
      }
      return this.patient.advance_calculated
    },
    bonusForBranch() {
      if(!this.patient) return 0
      return this.patient.total_bonuses
      // if(this.patient.bonuses_branched) return this.patient.bonuses_branched[this.branchId]
      // return 0
    },
    canAdd() {
      let route = this.$route.name
      if(['PatientPersonal','PatientVisitPage','PatientTreatmentPlan','PatientVisits','PatientFamilyAccount','PatientComments'].includes(route)) {
        return false
      }
      if(['PatientPayments', 'PatientDepositTransactions'].includes(route) && !this.rights.includes('payments')) {
        return false
      }
      return true
    },
    isPatient() {
      return this.$store.getters.isPatient
    }
  },
  methods: {
    ...mapActions(['getPatient']),
    addVisit() {
      let now = moment().format('YYYY-MM-DD HH')+":30:00"
      if(moment().format('mm') > 30) {
        now = moment().add(1, 'hours').format('YYYY-MM-DD HH')+":00:00"
      }
      this.$store.commit('setNewVisit', {
        time: now,
        client_id: this.patient
      })
      this.$bvModal.show('add-new-visit-modal')
    },
    editPatient() {
      this.$store.commit("setViewModel", 'patient')
      this.$store.commit('setSelectedModel', this.copyObject(this.patient))
      this.$store.commit('setShowModal', true)
    },
    contractPressed() {
      this.$bvModal.show('contract-modal')
    },
    anketaPressed() {
      this.$bvModal.show('anketa-modal')
    },
    printPressed() {
      this.$bvModal.show('patient-print-modal')
    },
    sendMail() {
      this.$bvModal.show('send-mail-modal')
    },
    smsModal(phone = null) {
      this.selectedPhone = phone
      this.$bvModal.show('patient-sms-modal')
    },
    // async wazzupIframe(type = 'whatsapp') {
    //   let res = await WhatsappService.createIframe(this.patient.id, type)
    //   if(res.data.url) {
    //     this.$store.commit('setWazzupIframeUrl', res.data.url)
    //     this.$nextTick(() => {
    //       this.$bvModal.show('wazzup-iframe-modal')
    //     })
    //     return
    //   }
    //   if(res.data.error) {
    //     this.$noty.error(res.data.error)
    //   }
    // },
    paymentPressed() {
      this.$bvModal.show('patient-topay-modal')
    },
    startTreatment() {
      if(!this.visit || !this.visit.id) return
      let formData = new FormData()
      formData = this.appendFormdata(formData, { records_statuses_id: 4 })
      EloquentService.update('record', this.visit.id, formData).then(() => {
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
        this.visit.records_statuses_id = '4'
      })
    },
    async restorePatient() {
      await EloquentService.restore('patient', this.patient.id)
      await this.getPatient(this.patient.id)
      this.$noty.info(this.$t('success_message'))
    },
    dotsPressed() {
      this.justOpened = true
      this.mob_expanded = !this.mob_expanded
      setTimeout(() => {
        this.justOpened = false
      },20)
    },
    clickedOutside() {
      if(!this.justOpened) {
        this.mob_expanded = false
      }
    },
    toggleTag(tag, action = 'add', index = null) {
      if(action === 'remove') {
        if(!confirm(this.$t('confirm_tag_deletion'))) return
      }
      PatientService.toggleTag(this.patient.id, tag.id).then(() => {
        if(action === 'add') {
          this.patient.tags.push(tag)
        }
        if(action === 'remove' && index !== null) {
          this.patient.tags.splice(index, 1)
        }
      })
    },
    balanceRefill() {
      this.$bvModal.show('refill-balance-modal')
    },
    bonusRefill() {
      this.$bvModal.show('refill-bonus-modal')
    }
  },
  mounted() {
    this.$store.commit('setPatient', null)
    this.$store.commit('setModule', 'patients')
    if(this.isPatient && this.$route.params.id != this.user.id) {
      this.$router.push('/').catch(()=>{})
      return
    }
    this.getPatient(this.$route.params.id)
    EloquentService.autocomplete('client_tag', '', 999).then(res => {
      this.tags = res.data.result
    })
  },
  destroyed() {
    this.$store.commit('setPatient', null)
  },
  watch: {
    counter() {
      this.getPatient(this.$route.params.id)
    },
    $route(val) {
      if(this.patient && this.$route.params.id != this.patient.id) {
        this.$store.commit('setPatient', null)
        this.getPatient(this.$route.params.id)
        return
      }
      if(val.name === 'PatientVisits') {
        this.getPatient(this.$route.params.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#patient {
  h2 {
    font-size: 22px;
    margin-bottom: 0;
    font-weight: bold;
  }
  .section-top-row {
    border-bottom: 1px solid #e5e5e5;
  }
  .patient-card {
    padding: 20px;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 50px;
    background: #f4f8fb;
    border-radius: 14px;
  }
  .file-svg {
    fill: $label-color;
  }
  .card-p {
    background: white;
    border-radius: 14px;
    padding: 17px;
    height: 100%;
  }
  .p-img {
    position: relative;
    top: -50px;
  }
  .badge-regular_client, .badge-first_visit {
    color: white;
    border-radius: 100px;
    padding: 2px 8px;
    display: inline-block;
  }
  .badge-regular_client {
    background: #42BEFE;
  }
  .badge-first_visit {
    background: $gray-text;
  }
  .phone-comment {
    max-width: 200px;
    font-style: italic;
    color: #273849;
  }
  .left-separator {
    background: #D9D9D9;
    width: 1px;
    height: 25px;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
  }
  .mphone {
    font-size: 16px;
  }
}

@media screen and (max-width: 1200px) {
  #patient {
    .left-separator {
      display: none;
    }
  }
}

@media screen and (max-width: 992px) {
  #patient {
    .p-img {
      margin-bottom: -40px;
    }
    .mphone {
      font-size: 14px;
    }
  }
}

@media screen and (max-width: 768px) {
  .more-info-link {
    color: $gray-text;
    font-weight: bold;
    margin-top: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid $calendar-border-color;
  }
  #patient {
    .sub-menu {
      padding-top: 4px;
    }
    h2 {
      font-size: 18px;
      margin-bottom: 8px;
    }
    .section-top-row {
      border-bottom: none;
    }
    .patient-card {
      font-size: 12px;
      margin-bottom: 20px;
      padding-bottom: 0;
    }
    .mphone {
      font-size: 12px;
    }
    .card-p {
      padding: 10px;
    }
  }
}
</style>
