<template>
  <b-modal id="task-show-modal"
           :title="task ? task.title : $t('task')"
           size="lg"
           centered>
    <div v-if="task">
      <div v-if="!task.onlyComments">
        <b-row class="justify-content-between">
          <b-col cols="auto" class="mb-3">
            <label class="form-label">{{ $t('type') }}</label>
            <div>{{ $t(task.task_type) }}</div>
          </b-col>
          <b-col cols="auto" v-if="task.patient" class="mb-3">
            <div>
              <label class="form-label">{{ $t('patient') }}</label>
              <div>
                {{ task.patient.full_name }}
                <span v-if="(task.phone || task.patient.phone) && !rights.includes('hide_patient_phone')" class="text-primary">
                  ({{ task.phone ? task.phone : task.patient.phone }})
                </span>
              </div>
            </div>
          </b-col>
          <b-col cols="auto" class="mb-3">
            <div>
              <label class="form-label">{{ $t('importance') }}</label>
              <div>
                <CRMDatatableTDTaskType :object="task" fieldType="importance"/>
              </div>
            </div>
          </b-col>
          <b-col v-if="task.start" cols="auto" class="mb-3">
            <div>
              <label class="form-label">{{ $t('start_period') }}</label>
              <div>{{ task.start | formatDateTime }}</div>
            </div>
          </b-col>
          <b-col v-if="task.deadline" cols="auto" class="mb-3">
            <div>
              <label class="form-label">{{ $t('deadline') }}</label>
              <div>{{ task.deadline | formatDateTime }}</div>
            </div>
          </b-col>
        </b-row>
        <hr class="mt-0"/>
        <b-row>
          <b-col>
            <label class="form-label">{{ $t('status') }}</label>
            <div>{{ $t(task.status) }}</div>
          </b-col>
          <b-col v-if="task.user">
            <label class="form-label">{{ $t('responsible') }}</label>
            <div>{{ task.user.full_name }}</div>
          </b-col>
          <b-col v-if="task.author">
            <label class="form-label">{{ $t('author') }}</label>
            <div>{{ task.author.full_name }}</div>
          </b-col>
        </b-row>
        <div class="mt-3" v-if="task.description">
          <label class="form-label black">{{ $t('description') }}</label>
          <div>{{task.description }}</div>
        </div>
        <div class="mt-3" v-if="task.files && task.files.length">
          <div v-for="(t, tind) in task.files" :key="`file-${tind}`">
<!--            <a v-if="t.original" :href="preUrl+t.id">-->
            <a v-if="t.original" :href="t.fileUrl" target="_blank">
              {{ t.original }}
            </a>
          </div>
        </div>
      </div>
      <GeneralCommentsBlock :object="task"
                            model="task_comment"
                            parent-column="task_id"/>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons no-print">
        <b-button class="cancel mr-md-3 mr-2 px-3 d-inline-block align-middle w-auto"
                  variant="themed"
                  @click="edit">
          {{ $t('edit') }}
        </b-button>
        <button :disabled="loading"
                @click="close()"
                class="btn-themed align-middle btn-themed-squared">
          {{ $t('done') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import CRMDatatableTDTaskType from "@/components/parts/datatable/td/crm/CRMDatatableTDTaskType"
import JwtService from "@/services/jwt.service"
import GeneralCommentsBlock from "@/components/parts/general/GeneralCommentsBlock"

export default {
  name: "TaskShowModal",
  components: {
    GeneralCommentsBlock,
    CRMDatatableTDTaskType
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      task: state => state.crm.task,
      rights: state => state.auth.rights,
    }),
    preUrl() {
      let link = JwtService.getPrintPrelink(this.clinic)
      return link+'/task/file/'
    }
  },
  methods: {
    edit() {
      this.$bvModal.hide('task-show-modal')
      let newObject = JSON.parse(JSON.stringify(this.task))
      this.$store.commit('setModule', 'crm')
      this.$store.commit('setSelectedModel', newObject)
      this.$store.commit('setViewModel', 'task')
      this.$nextTick(() => {
        this.$store.commit('setShowModal', true)
      })
    }
  },
}
</script>
