<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="sort"
                   initialSort="asc"
                   :hide-rows-counter="true"
                   :hide-whole-search-panel="true"
                   :can-hide-rows="false"
                   :rows="rows"
                   :transferToExcel="transferToExcel"
    />
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import moment from "moment"

export default {
  name: "ReportDoctorsLoad",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    chairs: {
      type: Array
    },
    selectedBranches: {},
    selectedProfessions: {},
  },
  metaInfo() {
    return {
      title: this.$t('doctors_load'),
      meta: []
    }
  },
  data() {
    return {
      standardColumns: [
        {
          id: 'doctor_name',
          field: 'doctor_name',
          label: this.$t('doctor'),
          search_select: true,
          sortable: false,
          rowspan: 3
        },
        {
          id: 'load_percent',
          field: 'load_percent',
          label: this.$t('doctor_load_percent'),
          sortable: false,
          format_html: true,
          rowspan: 3
        },
        {
          id: 'subtitle',
          field: 'subtitle',
          label: '',
          sortable: false,
        },
        {
          id: 'total',
          field: 'total',
          label: this.$t('total'),
          sortable: false,
          format_number: true,
        },
      ],
      columns: [],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('doctors_load', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
        doctors: this.doctors.map(a => a.id),
        chairs: this.chairs.map(a => a.id),
        professions: this.selectedProfessions
      }).then(res => {
        this.rows = res.data.res
        const days = res.data.days
        this.columns = this.copyObject(this.standardColumns)
        days.forEach(d => {
          this.columns.push({
            id: d,
            field: d,
            label: moment(d).format('DD.MM.YYYY'),
            format_number: true,
            sortable: false,
          })
        })
      })
    },
    transferToExcel(data) {
      let excelRows = []
      if(data) {
        data.forEach(item => {
          const i = this.copyObject(item)
          Object.keys(i).forEach((key) => {
            i[key] = i[key] === '[tdskip]' ? '' : (i[key]).toString().replace(/<\/?[^>]+(>|$)/g, "")
          })
          excelRows.push(i)
        })
      }
      return excelRows
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>
