<template>
  <div class="pb-4" v-if="loaded">
    <div class="data-table-wrapper">
      <table class="table data-table table-hover">
        <thead>
        <tr>
          <th class="right-td">{{ $t('right') }}</th>
          <th v-for="role in roles" :key="`role-th-${role}`">{{ $t(role) }}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(arr, cat) in allrights">
          <tr :key="`cat-${cat}`">
            <!--            <td :colspan="(1 + Object.values(roles).length)">-->
            <!--              <b>{{ $t(cat) }}</b>-->
            <!--            </td>-->
            <td>
              <b>{{ $t(cat) }}</b>
            </td>
            <td v-for="(role, role_id) in roles" :key="`role-td-tot-${cat}-${role}`">
              <CheckboxGroup label=" "
                             class="mb-0"
                             v-model="catsChecked[cat][role_id]"
                             @input="totalCheckboxChanged(role_id, cat, ...arguments)"
                             :fid="`checkbox-${cat}-${role}`"/>
            </td>
          </tr>
          <tr v-for="right in arr" :key="`right-row-${right.id}`">
            <td class="right-td">
              <span class="pl-md-5">
              {{ right.title }}
                </span>
              <a v-if="right.note_russian"
                 :title="right.note_russian"
                 class="info ml-3"
                 v-b-tooltip.hover>
                <InfoSvg class="info-svg"/>
              </a>
              <span class="text-muted small pl-3">{{ right.name }}</span>
            </td>
            <td v-for="(role, role_id) in roles" :key="`role-td-${right.id}-${role}`">
              <CheckboxGroup label=" "
                             v-if="right.roles.includes(role_id)"
                             class="mb-0 pl-5"
                             @input="checkboxChanged(role_id, right.id, ...arguments)"
                             v-model="rolerights[right.id]"
                             :checkboxvalue="Number(role_id)"
                             :fid="`checkbox-${right.id}-${role}`"/>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import InfoSvg from '@/assets/svg/general/question.svg'
import {SuperadminService} from "@/services/api.service"
import {mapState} from "vuex"
import {roles} from "@/dictionaries/dictionary"
import CheckboxGroup from "@/components/form/CheckboxGroup"

export default {
  name: "SuperAdminClinicRights",
  components: {
    InfoSvg,
    CheckboxGroup
  },
  metaInfo() {
    return {
      title: this.$t('rights'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    })
  },
  data() {
    return {
      allrights: {},
      rolerights: {},
      catsChecked: {},
      roles: [],
      loaded: false
    }
  },
  async mounted() {
    let data = (await SuperadminService.loadRights(this.clinic.id)).data
    let rights = data.rights
    // this.allrights = data.rights
    let order = ['general','calendar','treatment','staff','communications','finance','reports','egisz','additional_modules']
    this.rolerights = data.role_rights
    // console.log(this.rolerights)
    this.allrights = Object.keys(rights).sort(function(a,b) {
      return order.indexOf(a) - order.indexOf(b)
    }).reduce((obj, key) => {
          obj[key] = rights[key]
          return obj
        }, {}
    )
    this.roles = roles
    // console.log(this.roles)
    delete this.roles[6]
    for(let cat in this.allrights) {
      // console.log(cat)
      this.catsChecked[cat] = {}
      // if(!this.allrights[])
      for(let role in this.roles) {
        let categoryRights = this.allrights[cat]
        let res = true
        let counter = 0
        categoryRights.forEach(right => {
          if(!this.rolerights[right.id]) this.rolerights[right.id] = []
          if(right.roles.includes(role)) {
            if(!this.rolerights[right.id] || !this.rolerights[right.id].includes(role)) {
              res = false
            }
            counter++
          }
        })
        this.catsChecked[cat][role] = (res && counter > 0)
      }
    }
    this.loaded = true
  },
  methods: {
    async checkboxChanged(role_id, right_id, e) {
      // console.log(role_id)
      // console.log(right_id)
      // console.log(e)
      let status = e && e.includes(Number(role_id))
      // console.log(status)
      await this.sendStatusToServer(role_id, right_id, status)
    },
    async sendStatusToServer(role_id, right_id, status) {
      if(!Array.isArray(this.rolerights[right_id])) this.rolerights[right_id] = [this.rolerights[right_id]]
      await SuperadminService.toggleRight(this.clinic.id, {
        role_id: parseInt(role_id),
        functional_id: right_id,
        status: status
      })
    },
    async totalCheckboxChanged(role_id, cat_id, res) {
      let rights = this.allrights[cat_id]
      rights.forEach(async (right) => {
        let sendToServer = false
        if(!Array.isArray(this.rolerights[right.id])) this.rolerights[right.id] = [this.rolerights[right.id]]
        if(res) {
          if(!this.rolerights[right.id].includes(role_id)) {
            this.rolerights[right.id].push(role_id)
            sendToServer = true
          }
        }
        if(!res) {
          var index = this.rolerights[right.id].indexOf(role_id)
          if (index !== -1) {
            this.rolerights[right.id].splice(index, 1)
            sendToServer = true
          }
        }
        if(sendToServer) await this.sendStatusToServer(role_id, right.id, res)
      })
    }
  }

}
</script>

<style scoped lang="scss">
.right-td {
  width: 40%;
}
.info {
  cursor: help;
}
</style>
