<template>
  <div class="template-group">
    <div class="position-relative">
      <AutosizeTextArea
          :showlabel="false"
          :placeholder="label"
          :rows="rows"
          :label="label"
          v-model="v"
          :dictation="dictation"
          :minH="isMobile ? 32 : 46"
      />
      <div class="autocomplete-dropdown bg-white w-100 shadow-sm rounded"
           v-if="found.length > 0"
           v-click-outside="clickedOutside">
        <a class="d-block pointer"
           @click="select(f)"
           v-for="(f, find) in found"
           :key="`found-${find}`">{{ f.title }}</a>
      </div>
      <div v-if="saveAsTemplate && !bigSaveTemplate">
        <button :disabled="loading"
                :title="$t('save_as_template')"
                v-b-tooltip.hover
                class="position-absolute d-inline-block small-template-save btn"
                @click="addAsNewTemplate">
          <SaveSvg/>
        </button>
      </div>  
    </div>
    <div class="text-right mt-3" v-if="saveAsTemplate && bigSaveTemplate">
      <button :disabled="loading"
              class="btn-themed btn-themed-outline btn-themed-squared d-inline-block"
              @click="addAsNewTemplate">
        {{ $t('save_as_template') }}
      </button>
    </div>
  </div>
</template>

<script>

import {EloquentService} from "@/services/api.service"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import {mapState} from "vuex"
import SaveSvg from '@/assets/svg/general/save.svg'

export default {
  components: {
    AutosizeTextArea,
    SaveSvg
  },
  data() {
    return {
      v: this.value,
      found: [],
      timer: null,
      loading: false
    }
  },
  props: {
    label: {},
    value: {},
    model: {
      type: String,
      required: true
    },
    dictation: {
      type: Boolean,
      default: false
    },
    saveAsTemplate: {
      type: Boolean,
      default: true
    },
    bigSaveTemplate: {
      type: Boolean,
      default: true
    },
    disableSearch: {
      type: Boolean,
      default: false
    },
    readonly: {},
    required: {
      type: Boolean,
      default: false
    },
    rows: {
      default: 'auto'
    },
  },
  mounted() {
  },
  methods: {
    search() {
      if(this.disableSearch) {
        return
      }
      if(!this.searchString) {
        this.found = []
        return
      }
      this.loading = true
      EloquentService.autocomplete(this.model, this.searchString, 10, false).then(res => {
        this.found = res.data.result
        this.loading = false
      })
    },
    clickedOutside() {
      this.found = []
    },
    select(c) {
      let arr = this.v.split(' ')
      let len = arr.length
      arr[(len - 1)] = c.title
      this.v = arr.join(' ')
      this.found = []
    },
    addAsNewTemplate() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        title: this.v
      })
      this.loading = true
      EloquentService.create(this.model, formData).then(() => {
        this.loading = false
      })
    }
  },
  watch: {
    v(val) {
      this.$emit('input', val)
      if(this.timer) {
        clearTimeout(this.timer)
      }
      if(this.disableSearch) {
        return
      }
      this.timer = setTimeout(() => {
        this.search()
      }, 600)
    },
  },
  computed: {
    searchString() {
      var n = this.v.lastIndexOf(' ')
      return this.v.substring(n + 1)
    },
    ...mapState({
      isMobile: state => state.dom.isMobile
    }),
  }
}
</script>

<style scoped lang="scss">
.autocomplete-dropdown {
  top: 100%;
}
.small-template-save {
  top: 3px;
  right: 33px;
  &:disabled {
    opacity: 0.5;
  }
  svg {
    width: 16px;
    height: 16px;
    fill: $gray-icon;
  }
}
@media screen and (max-width: 768px) {
  .small-template-save {
    padding-top: 1px;
    top: 0;
    right: 27px;
    svg {
      width: 13px;
      height: 13px;
    } 
  }
}
</style>
