<template>
  <div class="pb-5 d-inline-block position-relative">
    <div>
      <a class="pointer"
         @click="selectZone('В')">
        <UpperJawSvg/>
      </a>
    </div>
    <div>
      <a class="pointer"
         @click="selectZone('Н')">
        <LowerJawSvg/>
      </a>
    </div>
  </div>
</template>

<script>

import LowerJawSvg from '@/assets/svg/teeth/jaws/lower_jaw.svg'
import UpperJawSvg from '@/assets/svg/teeth/jaws/upper_jaw.svg'

export default {
  name: "Jaws",
  components: {
    UpperJawSvg,
    LowerJawSvg
  },
  props: {
    mode: {
      type: String,
      default: 'status'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectZone(zone) {
      if(this.disabled) return
      if(this.mode == 'select') {
        this.$store.commit('setSelectedTooth', { tooth: zone, area: 'tooth', type: 'select' })
      }
    },
  }

}
</script>
