<template>
  <section id="patients" class="patients">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print">
        <div class="col order-md-0 order-0">
          <h1>
            <span class="d-inline-block align-middle">{{ $t('patients') }}</span>
            <PatientsHeaderDoctorsFilter class="ml-3 d-md-inline-block align-middle d-none"/>
          </h1>
        </div>
        <div class="col-md-auto col mt-md-0 mt-2 table-view-navigator order-md-1 order-3">
          <SearchTable/>
        </div>
        <div class="col-auto table-view-navigator order-md-2 mt-md-0 mt-2 order-4">
          <DataTableFilterButton/>
        </div>
        <div class="col-auto table-view-navigator order-md-4 order-1" v-if="rights.includes('excel_clients')">
          <DataTableExcel/>
        </div>
        <div class="col-auto table-view-navigator  order-md-5 order-2" v-if="rights.includes('add_clients')">
          <AddObject module="patients"/>
        </div>
      </div>
    </div>
    <div>
<!--      <DataTable :options="options"-->
<!--                 :params="{ branch_id: branch, blocked: 0 }"-->
<!--                 module="patients"/>-->
      <DataTable :options="options"
                 :params="{ blocked: 0 }"
                 module="patients"/>
      <PatientSMSModal/>
    </div>
  </section>
</template>

<script>

import SearchTable from "@/components/parts/datatable/SearchTable"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import AddObject from "@/components/parts/datatable/AddObject"
import DataTable from "@/components/parts/general/DataTable"
import options from "../../../data/patients/patient"
import PatientSMSModal from "@/components/parts/patients/PatientSMSModal"
import PatientsHeaderDoctorsFilter from "@/components/parts/datatable/custom/patients/PatientsHeaderDoctorsFilter"
import {mapState} from "vuex"

export default {
  name: "Patients",
  components: {
    PatientsHeaderDoctorsFilter,
    DataTable,
    AddObject,
    DataTableExcel,
    DataTableFilterButton,
    SearchTable,
    PatientSMSModal,
  },
  metaInfo() {
    return {
      title: this.$t('patients'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      branch: state => state.auth.branch,
      rights: state => state.auth.rights,
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
