export const clinicAdapter =
    ({
         name,
         type,
         idcity,
         address,
         bin,
         phone,
         email,
         currency_id,
         language,
         source,
         total_docs,
         quote_value_readable,
         time_zone,
         comment,
         version2,
         use_proxy
     }) => ({
        name: name ? name : null,
        type: type ? type : null,
        idcity: idcity ? idcity : null,
        address: address ? address : null,
        bin: bin ? bin : null,
        phone: phone ? phone : null,
        email: email ? email : null,
        currency_id: currency_id ? currency_id : null,
        language: language ? language : null,
        source: source ? source : null,
        total_docs: total_docs ? total_docs : null,
        quote_value_readable: quote_value_readable ? quote_value_readable : null,
        time_zone: time_zone ? time_zone : null,
        comment: comment ? comment : null,
        version2: version2 === true ? 1 : 0,
        use_proxy: use_proxy === true ? 1 : 0,
    })

