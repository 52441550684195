<template>

  <section id="billing" class="billing">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print">
        <b-col cols="12">
          <h1>{{ $t('billing') }}</h1>
        </b-col>
      </div>
    </div>
    <div v-if="loaded">
      <div>{{ $t('tariff') }}: <b>{{ tariff ? tariff.title : '-' }}</b></div>
      <div v-if="clinic_tariff">
        {{ $t('paid_till') }}: <TariffPaymentStatus warning_class="text"
                                                    class="d-inline-block font-weight-bold"
                                                    :isFree="clinic_tariff.isFree"
                                                    :paid_till="clinic_tariff.paid_till"/>
      </div>
      <div v-if="next_payment">
        {{ $t('next_payment') }}: <b>{{ next_payment | formatNumber(true) }} {{ billing_currency }}</b>
        <div class="mt-3">
          <button class="btn-themed btn-themed-squared mr-3"
                  v-if="!tariffCanBeChanged"
                  :disabled="!canBePaid || loading"
                  @click="pay">
            {{ $t('pay') }}
          </button>
          <button v-if="tariffCanBeChanged"
                  @click="changeTariff"
                  class="btn-themed btn-themed-squared">
            {{ $t('change_tariff') }}
          </button>
          <button v-if="canBeUnblocked"
                  class="btn-themed btn-themed-squared btn-themed-outline"
                  :disabled="loading"
                  @click="unblockForThreeDays">
            {{ $t('unblock_for_3_days') }}
          </button>
        </div>
      </div>
      <div class="font-weight-bold mt-4">{{ $t('payment_history') }}</div>
      <DataTable class="mt-3"
                 :params="{ clinic_id: clinic.id }"
                 :options="options"
                 module="settings"/>
      <ChangeTariffModal/>
    </div>
  </section>
</template>

<script>

import {mapState} from "vuex"
import TariffPaymentStatus from "@/components/parts/superadmin/TariffPaymentStatus"
import {BillingService} from "@/services/api.service"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/settings/billing_payment"
import moment from "moment/moment"
import ChangeTariffModal from "@/components/parts/billing/ChangeTariffModal"

export default {
  name: "SettingsBilling",
  components: {
    ChangeTariffModal,
    DataTable,
    TariffPaymentStatus
  },
  metaInfo() {
    return {
      title: this.$t('billing'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
    }),
    canBePaid() {
      return true
      // return this.clinic.id === 1843
    },
    today() {
      return moment().format('YYYY-MM-DD')
    },
    canBeUnblocked() {
      return !this.clinic_tariff.isFree && this.clinic_tariff.paid_till < this.today && this.clinic_tariff.can_use_unblock
    },
    tariffCanBeChanged() {
      return this.clinic_tariff && this.clinic_tariff.tariff_id == 14
    }
  },
  data() {
    return {
      options: options,
      tariff: null,
      clinic_tariff: null,
      next_payment: null,
      billing_currency: null,
      loaded: false,
      IokaWidgetItem: null
    }
  },
  methods: {
    async load() {
      let res = await BillingService.getTariffInfo()
      this.populateData(res)
    },
    async pay() {
      this.loaded = false
      // let res = await BillingService.payImitation()
      let res = await BillingService.pay({
        amount: this.next_payment,
        currency: this.billing_currency
      })
      let d = res.data
      if(d.url && window) {
        window.open(d.url,"_self")
      } else if(d.detail) {
        this.$noty.error(d.detail)
        this.loaded = true
      } else if(d.Details) {
        this.$noty.error(d.Details)
        this.loaded = true
      }
    },
    populateData(res) {
      this.tariff = res.data.tariff_data ? res.data.tariff_data.tariff : null
      this.clinic_tariff = res.data.tariff_data
      this.next_payment = res.data.next_payment
      this.billing_currency = res.data.billing_currency
      this.$store.commit('setPaidTill', this.clinic_tariff ? this.clinic_tariff.paid_till : null)
      this.$store.commit('setFreeDay', this.clinic_tariff ? this.clinic_tariff.free_day : null)
      this.loaded = true
    },
    async unblockForThreeDays() {
      let res = await BillingService.unblockTemporarily()
      let d = res.data
      if(d) location.reload()
    },
    changeTariff() {
      this.$bvModal.show('change-tariff-modal')
    }
  },
  async mounted() {
    this.$store.commit('setEloquentOptions', options)
    await this.load()
  },
  beforeDestroy() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>

<style scoped>

</style>
