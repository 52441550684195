<template>
  <b-modal id="happy-birthday-modal" size="lg" :title="$t('birthdays')" centered>
    <b-tabs nav-class="sub-menu" content-class="pt-3" class="w-100">
      <b-tab v-for="(users, period) in birthdays"
             :active="period === 'today'"
             :key="`tab-${period}`"
             :title="$t(period)">
        <div class="data-table-wrapper" v-if="users.length > 0">
          <table class="table data-table more-button">
            <thead>
            <tr>
              <th>{{ $t('name') }}</th>
              <th>{{ $t('age') }}</th>
              <th v-if="!rights.includes('hide_patient_phone')">{{ $t('phone') }}</th>
              <th v-if="!rights.includes('hide_patient_phone')">SMS</th>
              <th>E-mail</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(u, uind) in users" :key="`birthday-${uind}-${u.id}`">
              <td>{{ u.full_name }}</td>
              <td>{{ u.age }}</td>
              <td v-if="!rights.includes('hide_patient_phone')">{{ u.phone }}</td>
              <td v-if="!rights.includes('hide_patient_phone')">
                <CheckboxGroup class="mb-0 sm"
                               v-if="u.phone"
                               :fid="`sms-${period}-${u.id}`"
                               :checkboxvalue="u.id"
                               v-model="sendSms"/>
              </td>
              <td>
                <CheckboxGroup class="mb-0 sm"
                               v-if="u.email"
                               :fid="`email-${period}-${u.id}`"
                               :checkboxvalue="u.id"
                               v-model="sendEmails"/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div v-else class="mb-4 alert alert-warning">
          {{ $t('no_birthdays_on_this_date') }}
        </div>
      </b-tab>
    </b-tabs>
    <div v-if="template">
      <b-button class="btn-collapse" v-b-toggle="`collapse-birth-templates`">{{ $t('text') }}</b-button>
      <b-collapse :id="`collapse-birth-templates`">
        <InputGroup v-model="template.email_theme"
                    class="mt-3"
                    :label="$t('email_topic')"/>
        <AutosizeTextArea v-model="template.email_text_stripped"
                          class="mt-3"
                          :label="$t('email_text')"/>
        <AutosizeTextArea v-model="template.sms"
                          class="mt-3"
                          :label="$t('sms_text')"/>
      </b-collapse>
    </div>


    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="ml-auto cancel d-inline-block align-middle" variant="themed" @click="close()">{{ $t('close') }}</b-button>
        <button :disabled="loading || (sendSms.length === 0 && sendEmails.length === 0)"
                @click="send"
                class="ml-3 btn-themed align-middle d-inline-block btn-themed-squared">
          {{ $t('send') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import { PatientService } from "@/services/api.service"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import InputGroup from "@/components/form/InputGroup"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"

export default {
  name: "HappyBirthdayModal",
  components: {
    AutosizeTextArea,
    InputGroup,
    CheckboxGroup
  },
  data() {
    return {
      birthdays: [],
      template: null,
      loaded: false,
      sendSms: [],
      sendEmails: []
    }
  },
  computed: {
    ...mapState({
      visit:  state => state.patient.visit,
      patient: state => state.patient.patient,
      errors: state => state.datatable.validationErrors,
      loading: state => state.dom.loading,
      rights: state => state.auth.rights,
    }),
  },
  methods: {
    // ...mapActions(['getClientPlan']),
    load() {
      PatientService.getBirthdays().then(res => {
        this.birthdays = res.data.birthdays
        this.template = res.data.template
        this.loaded = true
      })
    },
    send() {
      PatientService.sendBirthdayGreetings({
        sms: this.sendSms,
        email: this.sendEmails,
        email_topic: this.template.email_theme,
        email_text: this.template.email_text_stripped,
        sms_text: this.template.sms,
      }).then(() => {
        this.$noty.info(this.$t('success_message'))
        this.$bvModal.hide('happy-birthday-modal')
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if(modalId === 'happy-birthday-modal') {
        this.load()
      }
    })
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if(modalId === 'happy-birthday-modal') {
        this.loaded = false
        this.sendEmails = []
        this.sendSms = []
        this.birthdays = []
      }
    })
  },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) {

}
</style>
