<template>
  <b-modal id="planning-comment-modal"
           v-if="planning"
           @hide="onHide"
           :title="$t('comments')"
           centered>
    <b-row class="mb-3" v-for="(c, cind) in planning.comments" :key="`comment-${cind}`">
      <b-col cols="12">
        {{ c.author ? c.author.full_name : '-' }}
      </b-col>
      <b-col cols="12"><span class="small text-muted">{{ c.date_add | formatDateTime }}</span></b-col>
      <b-col cols="12">{{ c.message }}</b-col>
    </b-row>
    <div class="mt-4">
      <AutosizeTextArea v-model="message"
                        :label="$t('message')"/>
    </div>
    <template #modal-footer="{ close }">
      <div class="w-100">
        <b-row class="align-items-center">
          <b-col cols="12" class="mt-md-0 mt-3 text-right">
            <div class="modal-footer-buttons">
              <b-button class="cancel mr-3 d-inline-block align-middle" variant="themed" @click="close()">{{ $t('cancel') }}</b-button>
              <button @click="save"
                      :disabled="message.length < 1"
                      class="w-auto px-3 btn-themed align-middle btn-themed-squared">
                {{ $t('save') }}
              </button>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import { EloquentService } from "@/services/api.service"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"

export default {
  name: "PlanningCommentsModal",
  components: {
    AutosizeTextArea,
    // TemplateGroup
  },
  methods: {
    save() {
      let formData = new FormData()
      formData.append('message', this.message)
      formData.append('planning_id', this.planning.planning_id)
      EloquentService.create('call_planning_comment', formData).then(res => {
        if(res.data.planning_comments_id) {
          this.$noty.info(this.$t('success_message'))
          this.$store.commit('incrementDataTableCounter')
          this.message = ''
          this.$bvModal.hide('planning-comment-modal')
        } else {
          this.$noty.error(res.data.message ? res.data.message : (res.data.error ? res.data.error : this.$t('server_error')))
        }
      })
    },
    onHide() {
      this.$store.commit('setPlannedCall', null)
      this.message = ''
    }
  },
  computed: {
    ...mapState({
      planning: state => state.patient.plannedCall,
      clinic:  state => state.auth.clinic,
    }),
  },
  data() {
    return {
      message: '',
    }
  }
}
</script>

<style scoped>

</style>
