import Vue from 'vue'
import VueRouter from 'vue-router'
import unauthorized from '@/middleware/unauthorized'
import authorized from '@/middleware/authorized'
import paid from '@/middleware/paid'
import settings from '@/router/router_settings'
import warehouses from '@/router/router_warehouses'
import patient from '@/router/router_patient'
import services from '@/router/router_services'
import staff from '@/router/router_staff'
import templates from '@/router/router_templates'
import reports from '@/router/router_reports'
import accounting from '@/router/router_accounting'
import cashdesk from '@/router/router_cashdesk'
import planning from '@/router/router_planning'
import crm from '@/router/router_crm'
import egisz from '@/router/router_egisz'
import marketing from '@/router/router_marketing'
import superadmin from '@/router/router_superadmin'
import store from '@/store'

import Login from '@/components/pages/auth/Login'
import Calendar from '@/components/pages/calendar/Calendar'
import Patients from '@/components/pages/patients/Patients'
import WaitingList from '@/components/pages/patients/WaitingList'
import Logs from '@/components/pages/logs/Logs'
import Page404 from '@/components/pages/general/404'
import Insurance from '@/components/pages/insurance/Insurance'
import Billing from '@/components/pages/billing/Billing'
import Unpaid from '@/components/pages/billing/Unpaid'
import PaymentBack from '@/components/pages/settings/PaymentBack'
import Expenses from '@/components/pages/expenses/Expenses'
import SettingsMaintenance from "@/components/pages/settings/SettingsMaintenance"
import SettingsTest from "@/components/pages/settings/SettingsTest"

import JwtService from "@/services/jwt.service"

Vue.use(VueRouter)

const routes = [

  /// --- Authorization --- ///
  {
    path: '/login', name: 'Login',
    meta: { middleware: [unauthorized], layout: "empty" },
    // component:() => import('../components/pages/auth/Login.vue'),
    component: Login,
  },
  {
    path: '/admin/login', name: 'AdminLogin',
    meta: { middleware: [unauthorized], layout: "empty" },
    // component:() => import('../components/pages/auth/Login.vue'),
    component: Login,
  },
  /// --- Calendar --- ///
  { path: '', redirect: 'calendar' },
  {
    path: '/calendar', name: 'Calendar',
    meta: { middleware: [authorized, paid], layout: "default" },
    // component:() => import('../components/pages/calendar/Calendar.vue'),
    component: Calendar,
  },
  /// --- Patients --- ///
  {
    path: '/patients', name: 'Patients',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['patients'] },
    // component:() => import('../components/pages/patients/Patients.vue'),
    component: Patients,
  },
  {
    path: '/logs', name: 'Logs',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['log_history'] },
    // component:() => import('../components/pages/logs/Logs.vue'),
    component: Logs,
  },
  {
    path: '/waiting', name: 'WaitingList',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['waiting_list'] },
    // component:() => import('../components/pages/patients/WaitingList.vue'),
    component: WaitingList,
  },
  /// --- Insurance --- ///
  {
    path: '/insurance', name: 'Insurance',
    meta: { middleware: [authorized, paid], layout: "default" },
    // component:() => import('../components/pages/insurance/Insurance.vue'),
    component: Insurance,
  },
  {
    path: '/billing', name: 'Billing',
    meta: { middleware: [authorized], layout: "default", rights: ['billing'] },
    // component:() => import('../components/pages/insurance/Insurance.vue'),
    component: Billing,
  },
  {
    path: '/unpaid', name: 'Unpaid',
    meta: { middleware: [authorized], layout: "default" },
    // component:() => import('../components/pages/insurance/Insurance.vue'),
    component: Unpaid,
  },
  {
    path: '/expenses', name: 'Expenses',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['enter_expenses'] },
    // component:() => import('../components/pages/expenses/Expenses.vue'),
    component: Expenses,
  },
  {
    path: '/payment/back', name: 'PaymentBack',
    meta: { middleware: [authorized], layout: "default" },
    // component:() => import('../components/pages/logs/Logs.vue'),
    component: PaymentBack,
  },
  {
    path: '/maintenance', name: "SettingsMaintenance",
    meta: { layout: "empty" },
    component: SettingsMaintenance
  },
  {
    path: '/test', name: "SettingsTest",
    meta: { layout: "empty" },
    component: SettingsTest
  },
  patient,
  warehouses,
  services,
  staff,
  reports,
  accounting,
  templates,
  cashdesk,
  settings,
  planning,
  crm,
  egisz,
  marketing,
  superadmin,
  {
    path: '/:path', name: '404',
    meta: { layout: "empty" },
    // component: () => import('../components/pages/general/404.vue')
    component: Page404
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    return { x: 0, y: 0 }
  }
})


///--- *** MIDDLEWARE *** ---///

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next
  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({ ...context, next: nextMiddleware })
  };
}

router.beforeEach((to, from, next) => {
  // EventBus.$emit('menuclose')
  // console.log(from)
  if(from.name && to.name === 'PatientVisitPage' && from.name !== 'PatientVisitPage') {
    store.commit('setBackLink', from)
  }
  if(window && window.innerWidth <= 768) {
    store.commit('setSidebarCollapsed', true)
  }
  /// ** check for page update
  const now = Date.now()
  const minutesUpdateInterval = 720
  let lastUpdate = window.localStorage.getItem('lastUpdate') ?? 0
  const diff = (now - lastUpdate) / 1000
  if(diff > (60 * minutesUpdateInterval) || JwtService.shouldReload()) {
    window.localStorage.setItem('lastUpdate', now)
    JwtService.turnOffReload()
    window.location.href = to.fullPath
  }
  /// *** end of check for page update
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    const context = { from, next, router, to }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({ ...context, next: nextMiddleware })
  }

  return next()
})

export default router
