<template>
  <button v-if="soundSupported" @click="micClick" :class="{ active: this.recognizing }">
    <MicrophoneSvg/>
  </button>
</template>

<script>

import MicrophoneSvg from "@/assets/svg/general/microphone.svg"

export default {
  components: {
    MicrophoneSvg
  },
  name: "SpeechRecognizer",
  props: {
    value: {},
  },
  data() {
    return {
      recognizer: null,
      soundSupported: false,
      recognizing: false,
      initialVal: '',
      timer: null
    }
  },
  mounted() {
    if (!window || !('webkitSpeechRecognition' in window)) {
      this.soundSupported = false
      return
    }
    this.soundSupported = true
    this.recognizer = new window.webkitSpeechRecognition()
    this.recognizer.continuous = true
    this.recognizer.interimResults = true
    this.recognizer.lang = this.$i18n.locale

    // this.recognizer.onstart = function () {
    // this.start
    // recognizing = true;
    // button.style.color = 'red';
    // finalText = element[0].value;
    // restartTimer();
    // };
    this.recognizer.onstart = this.startRecognizer
    this.recognizer.onend = this.endRecognizer
    this.recognizer.onresult = this.resultRecognizer

    // this.recognizer.onend = function () {
    //   recognizing = false;
    //   button.style.color = '';
    //   clearTimeout(timeout);
    // };
  },
  methods: {
    micClick() {
      if (this.recognizing) {
        this.recognizer.stop()
        return
      }
      this.recognizer.start()
    },
    startRecognizer() {
      this.initialVal = this.value
      this.recognizing = true
      this.startTimer()
    },
    endRecognizer() {
      this.recognizing = false
    },
    resultRecognizer(e) {
      this.prolongTimer()
      let v = this.initialVal ?? ''
      // var interim_transcript = ''
      // var final_transcript = ''
      if (typeof(e.results) == 'undefined') {
        this.recognizer.stop()
        return
      }
      for (var i = e.resultIndex; i < e.results.length; ++i) {
        if(e.results[i][0].transcript) {
          v += e.results[i][0].transcript
        }
        // if (e.results[i].isFinal) {
        //   v += e.results[i][0].transcript
        // final_transcript += e.results[i][0].transcript;
        // this.$emit('input', final_transcript)
        // } else {
        //   v += e.results[i][0].transcript
        // interim_transcript += e.results[i][0].transcript;
        // this.$emit('input', interim_transcript)
        // }
        // console.log(v)
        this.$emit('input', v)
      }
    },
    startTimer() {
      this.timer = setTimeout(() => {
        this.recognizer.stop()
      }, 2000)
    },
    prolongTimer() {
      if(this.timer) { clearTimeout(this.timer) }
      this.startTimer()
    }
  }
}
</script>

<style scoped lang="scss">
svg {
  width: 18px;
  height: 16px;
}

@media screen and (max-width: 768px) {
  svg {
    width: 15px;
    height: 14px;
  }
}
</style>
