export default {
    adjustment: "Tuzatish",
    add_all_materials_from_warehouse: "Stokdagi barcha materiallarni qo'shing",
    add_material: "Material qo‘shish",
    add_new: "Yangi qo‘shish",
    adding_new_supplier: "Yangi yetkazib beruvchi qo‘shish",
    address: "Manzil",
    addition: "Kirim",
    amount: "Miqdor",
    amount_of_stock_on_all_warehouses: "Klinikaning barcha omborlarida mavjud bo‘lgan tovarlarning umumiy miqdori",
    approve: "O‘tkazish",
    approved: "O‘tkazilgan",
    are_you_sure_you_want_to_delete_group: "Haqiqatan ham guruhni o‘chirmoqchimisiz?",
    are_you_sure_you_want_to_delete_kit: "Haqiqatan ham to‘plamnini o‘chirmoqchimisiz?",
    are_you_sure_you_want_to_delete_supplier: "Haqiqatan ham yetkazib beruvchini o‘chirmoqchimisiz?",
    are_you_sure_you_want_to_delete_warehouse: "Haqiqatan ham omborni o‘chirmoqchimisiz?",
    artikul: "Artikul",
    bank_requisites: "Bank rekvizitlari",
    barcode: "Shtrixkod",
    bin: "BIN",
    branch: "Filial",
    bulk_addition: "Guruh bo'yicha qo'shing",
    by_categories: "Kategoriya bo'yicha",
    consumption: "Chiqim",
    consumption_manual: "Qo‘shimcha chiqim",
    contact_person: "Aloqa qiluvchi shaxs",
    cost: "Qiymat",
    created: "Yaratilgan",
    destination: "Ko‘rsatma",
    details: "Tafsilotlar",
    difference: "Divergentsiya",
    documents: "Hujjatlar",
    draft: "Qoralama",
    enter_title_or_barcode: "Nom yoki shtrix kodni kiriting",
    factual_amount: "Haqiqatan ham miqdor",
    finished: "O‘tkazildi",
    from: "dan",
    from_warehouse: "Ombordan o'tkazish",
    from_where: "Qayerdan",
    general_price: "Umumiy summa",
    general_stock: "Umumiy qoldiq",
    group: "Guruh",
    groups: "Guruhlar",
    id: "Id",
    inventarization: "Inventarizasiya",
    Kit: "To‘plam",
    kit: "To‘plam",
    kits: "To‘plamlar",
    material: "Material",
    materialgroup: "Guruh",
    materialstock: "Material",
    materials: "Materiallar",
    movement: "Joy o‘zgarishi",
    movements: "Harakatlar",
    movement_from: "Joy o‘zgarishi",
    movement_to: "Joy o‘zgarishi",
    nomenclature: "Nomenklatura",
    notify_when_stock: "Qoldiq haqida xabar berish",
    operation: "Operasiya",
    parent_group: "Ota-onalar guruhi",
    physical: "Jismoniy",
    print: "Bosib chiqarish",
    return: "Qaytarish",
    stocks: "Qoldiqlar",
    stocks_archive: "Materiallar arxivi",
    supplier: "Yetkazib beruvchi",
    suppliers: "Yetkazib beruvchilar",
    to_warehouse: "Omborga o'tkazish",
    to_where: "Qayerga",
    type: "Тur",
    unit_of_measure: "O‘l./bir.",
    virtual: "Virtual",
    warehouse_amount: "Stokdagi miqdor",
    warehouses: "Omborlar",
    warehouseoperation: "Operasiyalar",
    write_off: "Hisobdan chiqarish"
}
