<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="date"
                   initialSort="desc"
                   :canHideRows="false"
                   :rows="rows"
                   :transferToExcel="transferToExcel"/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"

export default {
  name: "ReportsIncomeByServices",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    doctors: Array,
    selectedBranches: {}
  },
  metaInfo() {
    return {
      title: this.$t('by_services'),
      meta: []
    }
  },
  data() {
    return {
      columns: [],
      rows: [],
      excelRows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('income_by_services', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
        doctors: this.doctors.map(a => a.id),
      }).then(res => {
        this.rows = res.data.rows
        this.columns = res.data.columns
      })
    },
    transferToExcel(data) {
      let excelRows = []
      if(data) {
        data.forEach(item => {
          excelRows.push(item)
          let secondRow = {}
          for(let i in item) {
            if(i == 'date') {
              secondRow[i] = ''
            } else {
              secondRow[i] = item[i].length > 1 ? item[i][0] : item[i]
            }
          }
          excelRows.push(secondRow)
        })
      }
      return excelRows
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>

<style scoped>

</style>
