<template>
  <div v-if="loaded" class="description-form">
    <b-row>
      <b-col md="6" cols="12">
        <!--        <DateGroup-->
        <!--                   :required="true"-->
        <!--                   :label="$t('visit_date')"-->
        <!--                   :showErrors="true"-->
        <!--                   :readonly="true"-->
        <!--                   validation-id="start"-->
        <!--                   v-model="date"/>-->
        <DateGroup v-if="!object.id"
                   :required="true"
                   :label="$t('visit_date')"
                   :showErrors="true"
                   :readonly="true"
                   validation-id="start"
                   v-model="date"/>
        <InputGroup v-else v-model="date" :readonly="true" :label="$t('visit_date')"/>
      </b-col>
      <b-col md="auto" cols="auto">
        <TimeGroup v-if="!object.id"
                   :required="true"
                   :label="$t('visit_time')"
                   v-model="time"/>
        <InputGroup v-else v-model="time" :readonly="true" :label="$t('visit_time')"/>
      </b-col>
      <b-col md="3" col>
        <InputGroup v-if="!object.id"
                    :label="$t('visit_duration')"
                    type="number"
                    :required="true"
                    v-model="duration"/>
        <InputGroup v-else v-model="duration" :readonly="true" :label="$t('visit_duration')"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="6">
        <SelectGroup :options="doctors"
                     title="full_name"
                     variant="white"
                     :readonly="cannotChange"
                     :showErrors="true"
                     validationId="doctor_id"
                     :label="$t('treating_doctor')"
                     :showlabel="true"
                     v-model="object.doctor_id"/>
      </b-col>
      <b-col cols="12" md="6">
        <SelectGroup :options="assistants"
                     v-if="dictionariesLoaded"
                     title="full_name"
                     variant="white"
                     :readonly="cannotChange"
                     :showErrors="true"
                     validationId="assistant_id"
                     :label="$t('assistant')"
                     :showlabel="true"
                     v-model="object.assistant_id"/>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <ServiceMultiSelect :label="$t('planned_services')"
                            :dblclick="true"
                            :readonly="cannotChange"
                            v-model="object.planned_services"/>
      </b-col>
      <b-col md="6">
        <ServiceMultiSelect :label="$t('fields')"
                            :dblclick="false"
                            :multiple="false"
                            title="translatedTitle"
                            model="field"
                            :readonly="cannotChange"
                            :dbsearch="true"
                            v-model="object.fields"/>
      </b-col>
    </b-row>

    <SelectGroup :options="statuses"
                 variant="white"
                 v-if="rights.includes('recording_status')"
                 :showlabel="true"
                 :label="$t('status')"
                 v-model="object.records_statuses_id"/>
    <!--    <SelectGroup :options="templates"-->
    <!--                 v-if="date && time && object.doctor_id && duration && !object.visit_id"-->
    <!--                 variant="white"-->
    <!--                 :label="$t('treatment_template')"-->
    <!--                 :translate="false"-->
    <!--                 :showlabel="true"-->
    <!--                 :readonly="paid"-->
    <!--                 @input="templateChanged"-->
    <!--                 v-model="template_id"/>-->
    <div class="mb-5">
      <b-row class="align-items-end">
        <b-col col>
          <SelectGroup :options="plan_visits"
                       variant="white"
                       v-if="date && time && object.doctor_id && duration && rights.includes('planning')"
                       :label="$t('treatment_plan')"
                       :translate="false"
                       :readonly="cannotChange"
                       :showlabel="true"
                       class="mb-0"
                       @input="visitChanged"
                       v-model="visit_id"/>
        </b-col>
        <b-col cols="auto">
          <button class="btn-themed btn-themed-squared btn-apply" @click="visitChanged">
            {{ $t('apply') }}
          </button>
        </b-col>
      </b-row>
      <div class="mt-2" v-if="object && object.visit && object.visit.title">
        {{ $t('applied_complex') }}: {{ object.visit.title }}
      </div>
    </div>

    <div>
      <TemplateCollapse class="mt-4"
                        v-model="object.complaints"
                        :opened="true"
                        :title="$t('complaints')"
                        model="complaint_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.inspection"
                        :opened="true"
                        :title="$t('inspection')"
                        model="inspection_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.objectively"
                        :opened="true"
                        :title="$t('objectively')"
                        model="objective_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.subjectively"
                        :opened="true"
                        :title="$t('subjectively')"
                        model="subjective_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.diseases"
                        :opened="true"
                        :title="$t('diseases_passed_and_current')"
                        model="disease_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.development"
                        :opened="true"
                        :title="$t('disease_development')"
                        model="development_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.bite"
                        :opened="true"
                        :title="$t('bite')"
                        model="bite_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.research"
                        :opened="true"
                        :title="$t('results_of_xray_and_lab_research')"
                        model="research_template"/>
      <TemplateCollapse class="mt-4"
                        v-model="object.description"
                        :opened="true"
                        :title="$t('description')"
                        :disable-search="true"
                        model="description"/>
      <template v-for="(f, fid) in object.customFields">
        <TemplateCollapse class="mt-4"
                          :key="`custom-f-${fid}`"
                          v-model="f.text"
                          :opened="true"
                          :saveAsTemplate="false"
                          :title="f.title"
                          :disable-search="true"
                          :model="`customField-${fid}`"/>
      </template>
      <CheckboxGroup :label="$t('include_in_f043')"
                     fid="checkbox-43"
                     class="mt-4"
                     v-model="object.f043"/>
    </div>
    <div class="pt-5 pb-5">
      <button :disabled="loading"
              class="btn-themed btn-themed-squared"
              @click="save(false)">
        {{ $t('save') }}
      </button>
      <button :disabled="loading"
              class="btn-themed btn-themed-squared ml-3"
              v-if="backLink"
              @click="save(true)">
        {{ $t('save_and_close') }}
      </button>
      <button v-if="object.id !== 'new' && visitCanBeDeleted"
              @click="deleteVisit"
              class="btn-themed ml-md-4 ml-3 btn-themed-squared btn-outline-danger">
        {{ $t('delete') }}
      </button>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import { CalendarService, EloquentService, PatientService } from "@/services/api.service"
import {mapGetters, mapState} from "vuex"
import DateGroup from "@/components/form/DateGroup"
import TimeGroup from "@/components/form/TimeGroup"
import InputGroup from "@/components/form/InputGroup"
import SelectGroup from "@/components/form/SelectGroup"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import TemplateCollapse from "@/components/form/TemplateCollapse"
import { recordTeethAdapter } from "@/adapters/patients/recordTeeth"
import { recordStatuses } from "@/dictionaries/dictionary"
// import TemplateOptionsModal from "@/components/parts/patients/TemplateOptionsModal"

export default {
  name: "PatientVisitDescription",
  components: {
    // TemplateOptionsModal,
    TemplateCollapse,
    ServiceMultiSelect,
    DateGroup,
    TimeGroup,
    InputGroup,
    SelectGroup,
    CheckboxGroup,
  },
  props: {
    object: {},
    services: {},
    diagnoses: {},
    materials: {},
    packages: {},

  },
  data() {
    return {
      // object: null,
      date: '',
      time: '',
      duration: 60,
      loaded: false,
      plan_visits: [],
      templates: [],
      assistants: [],
      visit_id: null,
      template_id: null,
      dictionariesLoaded: false,
      visitCanBeDeleted: false,
      statuses: recordStatuses,
      planComplex: null
    }
  },
  computed: {
    ...mapState({
      visit:  state => state.patient.visit,
      doctors: state => state.auth.doctors,
      rights: state => state.auth.rights,
      branch: state => state.auth.branch,
      patient:  state => state.patient.patient,
      complexServices: state => state.treatment.complexServices,
      user: state => state.auth.user,
      loading: state => state.dom.loading,
      backLink:  state => state.dom.backLink,
      counter:  state => state.datatable.dataTableCounter,
    }),
    ...mapGetters(['recordTimingIsOn', 'isDoctor']),
    datetime() {
      return this.date+' '+this.time+':00'
    },
    paid() {
      return !!(!this.visit || (this.visit.price > 0 && (this.visit.debt == 0 || (this.visit.payments && this.visit.payments.length))))
    },
    recordClosed() {
      return !!this.visit?.closed_visit?.id
    },
    recordOpened() {
      return !!this.visit?.opened_visit?.id
    },
    cannotChangeOthersVisit() {
      // return false
      return this.isDoctor && !this.rights.includes('edit_other_doctors_visits') && this.visit.doctor_id != this.user.id
    },
    cannotChange() {
      if(this.cannotChangeOthersVisit) return true
      if(this.isDoctor && this.recordTimingIsOn && !this.recordOpened) return true
      return this.paid || (this.isDoctor && this.recordClosed && !this.recordOpened)
    },
  },
  methods: {
    async save(close = false) {
      this.$store.commit('cleanValidationErrors')
      this.object.template_id = this.template_id
      this.object.visit_id = this.visit_id
      // console.log(this.object)
      let res = await CalendarService.updateRecord(recordTeethAdapter(this))
      // let res = await CalendarService.updateRecord(recordDescriptionAdapter(this.object))
      this.$store.commit('setVisit', res.data)
      this.$noty.info(this.$t('success_message'))
      if(this.$route.params.visitid === 'new') {
        this.$router.push({name: 'PatientVisitPage', params: {
            id: this.patient.id,
            visitid: res.data.id,
            page: 'description'
          }
        }).catch(()=>{})
      }
      this.$nextTick(() => {
        this.initial()
        if(close && this.backLink) {
          this.$router.push(this.backLink).catch(()=>{})
        }
      })
    },
    calcEnd() {
      this.object.end = moment(this.datetime).add(this.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss')
    },
    deleteVisit() {
      this.$store.commit('cleanValidationErrors')
      if(confirm(this.$t('are_you_sure_you_want_to_delete'))) {
        EloquentService.delete('record', this.object.id).then(() => {
          // CalendarService.deleteRecord(this.object.id).then(() => {
          this.$router.push({ name: 'PatientVisits', params: { id: this.patient.id } }).catch(()=>{})
        })
      }
    },
    initial() {
      // this.object = this.copyObject(this.visit)
      if(this.object && this.object.start) {
        this.date = this.object.start.substr(0,10)
        this.time = this.visit.start.substr(11,5)
        this.duration = moment(this.object.end).diff(moment(this.object.start), 'minutes')
      }
      this.loaded = true
    },
    loadDictionaries() {
      if(!this.patient) return
      PatientService.getVisitDictionaries(this.patient.id, this.object ? this.object.visit_id : null).then(res => {
        this.templates = res.data.templates
        this.$store.commit('setTemplates', res.data.templates)
        this.plan_visits = res.data.plan_visits
        this.assistants = res.data.assistants
        this.$nextTick(() => {
          this.visit_id = this.object.visit_id
          this.template_id = this.object.template_id
          this.dictionariesLoaded = true
        })
      })
    },
    async visitChanged() {
      this.$emit('applyComplex', this.visit_id)
    },
  },
  watch: {
    date() {
      this.object.start = this.datetime
    },
    time() {
      this.object.start = this.datetime
    },
    datetime() {
      this.calcEnd()
    },
    duration() {
      this.calcEnd()
    },
  },
  mounted() {
    this.initial()
    this.loadDictionaries()
  }
}
</script>

<style scoped lang="scss">
.description-form {
  max-width: 1000px;
}
.btn-apply {
  height: 46px;
  line-height: 44px;
}
@media(max-width: 768px) {
  .btn-apply {
    height: 32px;
    line-height: 30px;
  }
}
</style>
