export default {
    after_scanning_qr: "После сканирования qr кода",
    application_reason: "Причина обращения",
    are_you_sure_you_want_to_delete_lead: "Вы уверены, что хотите удалить заявку?",
    check_status: 'Проверить статус',
    click_here: "нажмите сюда",
    connect: "Подключить",
    connection_is_right_can_be_used: "Подключение верно - можно использовать",
    'crm-form': "CRM-форма",
    forms: "Формы",
    lead: "Заявка",
    not_reached: "Не дозвонились",
    site_form: "Форма",
    stale: "Неликвид",
    telegram: "Telegram",
    thinking: "Думает",
    unpaid_message: 'В вашем аккаунте просрочена оплата. Пожалуйста, пополните баланс или обратитесь в тех поддержку.',
    urgent: "Срочно",
    whatsapp: "Whatsapp",
    whatsapp_qr_instructions: "На телефоне в настройках whatsapp выберите 'привязать устройство' и считайте qr код",
}
