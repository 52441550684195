import { patientAdapter } from "@/adapters/patients/patient"
import { countries } from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'patient',
    order: 'lname',
    modalsize: 'xl',
    sort: 'asc',
    showTotalRecords: true,
    prepareParams: ['id'],
    actionscomponent: 'PatientDatatableActions',
    additionalFormComponent: 'PatientsAdditionalFormComponent',
    adapter: patientAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_user',
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    rights: {
        edit: "patients_update",
        delete: "patients_delete",
    },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'full_name',
            title: 'fio',
            table: true,
            sortable: 'lname',
            tabletdclass: "order-mob-0",
            tdcomponent: 'PatientsDatatableTDName',
        },
        {
            id: 'lname',
            title: 'surname',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                required: true,
                tab: 'general_info',
            }
        },
        {
            id: 'fname',
            title: 'name',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                required: true,
                tab: 'general_info',
            }
        },
        {
            id: 'mname',
            title: 'middle_name',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'general_info',
            }
        },
        {
            id: 'gender',
            title: 'gender',
            table: false,
            filter: true,
            filter_list_select: true,
            form: {
                classes: 'col-md-4',
                type: "select",
                select_type: "list",
                options: {
                    "0": { id: "0", title: "male" },
                    "1": { id: "1", title: "female" },
                },
                tab: 'general_info',
            }
        },
        {
            id: 'visit_date',
            title: 'visit_date',
            table: false,
            filter: true,
            filter_date: true
        },
        {
            id: 'date_of_birth',
            title: 'date_of_birth',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "date",
                tab: 'general_info',
            }
        },
        {
            id: 'mphone',
            title: 'phone',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
                classes: 'col-md-4',
                required: true,
                component: 'PatientsDatatableFormMPhone',
                tab: 'general_info',
            },
            right_excludes: 'hide_patient_phone',
            format_phone: true,
        },
        {
            form: {
                classes: 'col-md-12',
                type: 'separator',
                full_card: true,
                tab: 'general_info',
            }
        },
        {
            id: 'mphone_descr',
            title: 'comment_to_phone',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'general_info',
            },
            right_excludes: 'hide_patient_phone',
        },
        {
            id: 'card',
            title: 'card',
            table: true,
            sortable: true,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                required: true,
                component: "PatientsDatatableFormCard",
                tab: 'general_info',
            }
        },
        {
            id: 'address',
            title: 'address',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'general_info',
            }
        },
        {
            id: 'age',
            title: 'age',
            table: true,
            sortable: 'date_of_birth',
            tdcomponent: 'PatientsDatatableTDAge',
        },
        {
            id: 'email',
            title: 'email',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'general_info',
            }
        },
        {
            id: 'mphone2',
            title: 'phone_2',
            form: {
                type: "text",
                full_card: true,
                classes: 'col-md-4',
                tab: 'general_info',
            },
            right_excludes: 'hide_patient_phone',
        },
        {
            id: 'mphone2_descr',
            title: 'comment_to_phone_2',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'general_info',
            },
            right_excludes: 'hide_patient_phone',
        },
        {
            id: 'advance_calculated',
            title: 'balance',
            table: true,
            sortable: true,
            format_currency: true
        },
        {
            id: 'debt_calculated',
            title: 'debt',
            table: false,
            sortable: true,
            format_currency: true
        },
        {
            id: 'passport_series',
            title: 'series',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'passport_data',
            }
        },
        {
            id: 'passport',
            title: 'passport_number',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'passport_data',
            }
        },
        {
            id: 'passport_given_by',
            title: 'issued_by',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'passport_data',
            }
        },
        {
            id: 'passport_issue_date',
            title: 'issue_date',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "date",
                full_card: true,
                tab: 'passport_data',
            }
        },
        {
            id: 'passport_dep_code',
            title: 'department_code',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'passport_data',
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.RUSSIA
                }
            }
        },
        {
            id: 'iin',
            title: 'iin',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'passport_data',
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.KAZAKHSTAN
                }
            }
        },
        {
            id: 'taxcode',
            title: 'inn',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'passport_data',
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.RUSSIA
                }
            }
        },
        {
            id: 'snils',
            title: 'snils',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'passport_data',
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.RUSSIA
                }
            }
        },
        {
            id: 'pesel',
            title: 'pesel',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'passport_data',
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.POLAND
                }
            }
        },
        {
            id: 'representative',
            title: 'representative_id',
            table: false,
            form: {
                classes: 'col-md-6',
                type: "autocomplete",
                autocomplete_title: 'full_name',
                model: "patient",
                full_card: true,
                tab: 'representative',

            }
        },
        {
            id: 'representative_relation',
            title: 'representative_relation',
            table: false,
            form: {
                classes: 'col-md-6',
                type: "select",
                translate: false,
                full_card: true,
                tab: 'representative',

            }
        },
        {
            id: 'representative_fio',
            title: 'representative',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'representative',
            }
        },
        {
            id: 'representative_phone',
            title: 'representative_phone',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'representative',
            }
        },
        {
            id: 'representative_address',
            title: 'representative_address',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'representative',
            }
        },
        // {
        //     id: 'representative_passport',
        //     title: 'representative_passport',
        //     table: false,
        //     form: {
        //         classes: 'col-md-4',
        //         type: "text",
        //         full_card: true,
        //         tab: 'representative',
        //
        //     }
        // },
        {
            id: 'representative_passport',
            title: 'representative_document',
            table: false,
            form: {
                classes: 'col-md-12',
                type: "text",
                full_card: true,
                tab: 'representative',
            }
        },
        {
            id: 'representative_passport_data',
            title: 'representative_passport_data',
            table: false,
            form: {
                classes: 'col-md-8',
                type: "text",
                full_card: true,
                tab: 'representative',
            }
        },
        {
            id: 'representative_inn',
            title: 'representative_inn',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'representative',
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.RUSSIA
                }
            }
        },
        {
            id: 'discount',
            title: 'discount',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "number",
                full_card: true,
                tab: 'general_info',
            }
        },
        {
            id: 'doctor_id',
            title: 'treating_doctor',
            table: false,
            form: {
                type: "select",
                classes: 'col-md-4',
                select_type: "model",
                full_card: true,
                select_data: {
                    "title": "full_name"
                },
                tab: 'general_info',
            }
        },
        {
            id: 'status_id',
            title: 'status',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "select",
                select_type: "list",
                options: {
                    "2": { id: "2", title: "regular_client" },
                    "3": { id: "3", title: "first_visit" },
                },
                tab: 'general_info',
            }
        },
        {
            id: 'source',
            title: 'came_from',
            table: false,
            filter: true,
            filter_model_select: true,
            form: {
                classes: 'col-md-4',
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                tab: 'general_info',
            }
        },
        {
            id: 'invoiced',
            title: 'invoiced',
            table: true,
            filter: true,
            filter_number: true,
            sortable: true,
            format_currency: true
        },
        {
            id: 'paid',
            title: 'paid',
            table: true,
            filter: true,
            filter_number: true,
            sortable: true,
            format_currency: true
        },
        {
            id: 'debt',
            title: 'debt',
            table: true,
            filter: true,
            filter_number: true,
            sortable: true,
            format_currency: true
        },
        {
            id: 'patient_condition_id',
            title: 'condition',
            table: true,
            table_show: 'patient_condition.title',
            filter: true,
            filter_model_select: true,
            form: {
                classes: 'col-md-4',
                type: "select",
                full_card: true,
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                tab: 'general_info',
            }
        },
        {
            id: 'tag_id',
            title: 'tags',
            table: true,
            filter: true,
            sortable: true,
            filter_model_select: true,
            tdcomponent: 'PatientsDatatableTDTags'
        },
        {
            id: 'url',
            title: 'link',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                full_card: true,
                tab: 'general_info',
            }
        },
        {
            id: 'description',
            title: 'description',
            filter: true,
            form: {
                type: "textarea",
                full_card: true,
                classes: 'col-md-12',
                tab: 'general_info',
            }
        },
        // {
        //     form: {
        //         classes: 'col-md-12',
        //         full_card: true,
        //         type: 'separator'
        //     }
        // },
        {
            id: 'send_notifications',
            title: 'send_notifications',
            table: false,
            form: {
                classes: 'col-md-auto',
                type: "checkbox",
                full_card: true,
                tab: 'general_info',
            }
        },
        {
            id: 'send_marketing',
            title: 'send_marketing',
            table: false,
            form: {
                classes: 'col-md-auto',
                type: "checkbox",
                full_card: true,
                tab: 'general_info',
            }
        },
        {
            id: 'send_to_egisz',
            title: 'send_to_egisz',
            table: false,
            form: {
                classes: 'col-md-auto',
                type: "checkbox",
                full_card: true,
                tab: 'general_info',
                conditionFunc(store) {
                    return store.state.auth.rights.includes('egisz_view')
                }
            }
        },
        {
            id: 'doctor_id',
            title: 'doctor',
            filter: true,
            filter_model_select: true
        },
        {
            id: 'activity_status',
            title: 'activity',
            filter: true,
            filter_list_select: true,
            form: {
                options: {
                    "new": { id: "new", title: "new" },
                    "active": { id: "active", title: "active" },
                    "passive": { id: "passive", title: "passive" },
                    "lost": { id: "lost", title: "lost" },
                }
            }
        },
        {
            id: 'blocked',
            title: 'is_deleted',
            filter: true,
            filter_boolean: true
        },
        {
            id: 'region_egisz_id',
            title: 'region',
            table: false,
            form: {
                classes: 'col-md-6',
                type: "select",
                translate: false,
                full_card: true,
                tab: 'egisz',
            }
        },
        {
            id: 'zip_code',
            title: 'zipcode',
            table: false,
            form: {
                classes: 'col-md-6',
                type: "text",
                full_card: true,
                tab: 'egisz',
            }
        },
        // {
        //     form: {
        //         full_card: true,
        //         component: 'PatientsDatatableFormLoginForm'
        //     }
        // }
    ]

}
