<template>
  <div>
    <DataTable :options="options"
               :params="{ clinic_id: this.clinic.id }"
               module="templates"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/templates/plan_treatment_template"
import {mapState} from "vuex"
// import AddObject from "@/components/parts/datatable/AddObject"

export default {
  name: "PlanTreatmentTemplates",
  metaInfo() {
    return {
      title: this.$t('treatment_plan'),
      meta: []
    }
  },
  components: {
    // AddObject,
    DataTable
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
