<template>
  <span>
    <a v-if="outgoingCallThrough === 'standard'"
       :href="`tel:${phone}`">
      {{ phone | formatPhone }}
    </a>
    <a v-else
       @click="makeCall"
       class="pointer"
       :class="`text-${color}`">
      {{ phone | formatPhone }}
    </a>
  </span>
</template>

<script>

import { mapState } from "vuex"
import { makeCall } from "@/services/call.service"

export default {
  name: "OutgoingCallElement",
  props: {
    phone: {
      required: true
    },
    color: {
      type: String,
      default: 'primary'
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    }),
    outgoingCallThrough() {
      return this.clinic?.clinic_additional?.outgoing_calls || 'standard'
    },
  },
  methods: {
    async makeCall() {
      const res = await makeCall(this.clinic, this.phone)
      if(res?.data) {
        this.$noty.info(this.$t('success_message'))
      } else {
        this.$noty.error(this.$t('error_message'))
      }
    }
  }
}
</script>
