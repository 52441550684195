import superadmin from "@/middleware/superadmin"
import onlySuperadmin from "@/middleware/onlySuperadmin"
import supermanager from "@/middleware/supermanager"
import superuser from "@/middleware/superuser"

import SuperAdminGeneral from "@/components/pages/superadmin/SuperAdminGeneral"
import SuperAdminClinics from "@/components/pages/superadmin/clinics/SuperAdminClinics"
import SuperAdminManagerClinic from "@/components/pages/superadmin/clinics/SuperAdminManagerClinic"
import SuperAdminTariffs from "@/components/pages/superadmin/tariffs/SuperAdminTariffs"
import SuperAdminPayments from "@/components/pages/superadmin/payments/SuperAdminPayments"
import SuperAdminNotifications from "@/components/pages/superadmin/notifications/SuperAdminNotifications"
import SuperAdminClinicPage from "@/components/pages/superadmin/clinics/SuperAdminClinicPage"
import SuperAdminClinicManagers from "@/components/pages/superadmin/clinics/SuperAdminClinicManagers"
import SuperAdminClinicServices from "@/components/pages/superadmin/clinics/SuperAdminClinicServices"
import SuperAdminClinicCategories from "@/components/pages/superadmin/clinics/SuperAdminClinicCategories"
import SuperAdminClinicRights from "@/components/pages/superadmin/clinics/SuperAdminClinicRights"
import SuperAdminClinicGeneral from "@/components/pages/superadmin/clinics/SuperAdminClinicGeneral"
import SuperAdminClinicSuperManagers from "@/components/pages/superadmin/clinics/SuperAdminClinicSuperManagers"
import SuperAdminClinicDoctors from "@/components/pages/superadmin/clinics/SuperAdminClinicDoctors"
import SuperAdminClinicAssistants from "@/components/pages/superadmin/clinics/SuperAdminClinicAssistants"
import SuperAdminClinicPatients from "@/components/pages/superadmin/clinics/SuperAdminClinicPatients"
import SuperAdminClinicTariffs from "@/components/pages/superadmin/clinics/SuperAdminClinicTariffs"
import SuperAdminClinicBranches from "@/components/pages/superadmin/clinics/SuperAdminClinicBranches"
import SuperAdminClinicImport from "@/components/pages/superadmin/clinics/SuperAdminClinicImport"
import SuperAdminClinicServicesImport from "@/components/pages/superadmin/clinics/SuperAdminClinicServicesImport"
import SuperAdminPopups from "@/components/pages/superadmin/notifications/SuperAdminPopups"
import SuperAdminAPI from "@/components/pages/superadmin/api/SuperAdminAPI"
import SuperAdminClinicIntegrations from "@/components/pages/superadmin/clinics/SuperAdminClinicIntegrations"
import SuperAdminClinicLogs from "@/components/pages/superadmin/clinics/SuperAdminClinicLogs"
import SuperAdminUsers from "@/components/pages/superadmin/users/SuperAdminUsers"

export default {
    path: '/superadmin',
    meta: {middleware: [superuser], layout: "super_admin", rights: []},
    // component: () => import('../components/pages/superadmin/SuperAdminGeneral.vue'),
    component: SuperAdminGeneral,
    children: [
        { path: '', redirect: 'clinics' },
        {
            path: 'clinics', name: "SuperAdminClinics",
            meta: { middleware: [superuser], layout: "super_admin", rights: [] },
            // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinics.vue')
            component: SuperAdminClinics
        },
        {
            path: 'tariffs', name: "SuperAdminTariffs",
            meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
            component: SuperAdminTariffs
        },
        {
            path: 'payments', name: "SuperAdminPayments",
            meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
            component: SuperAdminPayments
        },
        {
            path: 'notifications', name: "SuperAdminNotifications",
            meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
            component: SuperAdminNotifications
        },
        {
            path: 'popups', name: "SuperAdminPopups",
            meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
            component: SuperAdminPopups
        },
        {
            path: 'api', name: "SuperAdminAPI",
            meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
            component: SuperAdminAPI
        },
        {
            path: 'users', name: "SuperAdminUsers",
            meta: { middleware: [onlySuperadmin], layout: "super_admin", rights: [] },
            component: SuperAdminUsers
        },
        {
            path: 'clinic/manager/:id', name: "SuperAdminManagerClinic",
            meta: { middleware: [supermanager], layout: "super_admin", rights: [] },
            // component: () => import('../components/pages/superadmin/payments/SuperAdminPayments.vue')
            component: SuperAdminManagerClinic
        },
        {
            path: 'clinic/:id',
            meta: { middleware: [superadmin], layout: "default", rights: [] },
            // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicPage.vue'),
            component: SuperAdminClinicPage,
            children: [
                { path: '', redirect: 'general' },
                {
                    path: 'general', name: "SuperAdminClinicGeneral",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicGeneral.vue')
                    component: SuperAdminClinicGeneral
                },
                {
                    path: 'rights', name: "SuperAdminClinicRights",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicRights.vue')
                    component: SuperAdminClinicRights
                },
                {
                    path: 'services', name: "SuperAdminClinicServices",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicServices.vue')
                    component: SuperAdminClinicServices
                },
                {
                    path: 'categories', name: "SuperAdminClinicCategories",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicCategories
                },
                {
                    path: 'managers', name: "SuperAdminClinicManagers",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicManagers.vue')
                    component: SuperAdminClinicManagers
                },
                {
                    path: 'supermanagers', name: "SuperAdminClinicSuperManagers",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicSuperManagers.vue')
                    component: SuperAdminClinicSuperManagers
                },
                {
                    path: 'doctors', name: "SuperAdminClinicDoctors",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicDoctors.vue')
                    component: SuperAdminClinicDoctors
                },
                {
                    path: 'assistants', name: "SuperAdminClinicAssistants",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicManagers.vue')
                    component: SuperAdminClinicAssistants
                },
                {
                    path: 'patients', name: "SuperAdminClinicPatients",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicPatients.vue')
                    component: SuperAdminClinicPatients
                },
                {
                    path: 'tariffs', name: "SuperAdminClinicTariffs",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicTariffs.vue')
                    component: SuperAdminClinicTariffs
                },
                {
                    path: 'branches', name: "SuperAdminClinicBranches",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicBranches.vue')
                    component: SuperAdminClinicBranches
                },
                {
                    path: 'patients_import', name: "SuperAdminClinicImport",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicImport.vue')
                    component: SuperAdminClinicImport
                },
                {
                    path: 'services_import', name: "SuperAdminClinicServicesImport",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    // component: () => import('../components/pages/superadmin/clinics/SuperAdminClinicImport.vue')
                    component: SuperAdminClinicServicesImport
                },
                {
                    path: 'integrations', name: "SuperAdminClinicIntegrations",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicIntegrations
                },
                {
                    path: 'logs', name: "SuperAdminClinicLogs",
                    meta: { middleware: [superadmin], layout: "super_admin", rights: [] },
                    component: SuperAdminClinicLogs
                },
            ]
        },
    ]
}
