<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="cancel_date"
                   initialSort="desc"
                   :rows="rows"/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import {mapState} from "vuex"

export default {
  name: "ReportsCancelledVisits",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    selectedBranches: {}
  },
  metaInfo() {
    return {
      title: this.$t('cancelled_visits'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      branchId: state => state.auth.branch,
    })
  },
  data() {
    return {
      columns: [
        {
          id: 'visit_date',
          field: 'visit_date',
          label: this.$t('visit_date'),
          format_datetime: true,
          sortable: true
        },
        {
          id: 'duration',
          field: 'duration',
          label: this.$t('duration'),
          sortable: true
        },
        {
          id: 'cancel_date',
          field: 'cancel_date',
          label: this.$t('cancel_date'),
          format_datetime: true,
          sortable: true
        },
        {
          id: 'next_visit_date',
          field: 'next_visit_date',
          label: this.$t('next_visit_date'),
          format_datetime: true,
          sortable: true
        },
        {
          id: 'last_visit_date',
          field: 'last_visit_date',
          label: this.$t('last_visit_date'),
          format_datetime: true,
          sortable: true
        },
        {
          id: 'doctor_name',
          field: 'doctor_name',
          label: this.$t('doctor'),
          search_select: true,
          sortable: true
        },
        {
          id: 'patient_name',
          field: 'patient_name',
          label: this.$t('patient'),
          tdcomponent: 'PatientsDatatableTDName',
          search_select: true,
          sortable: true
        },
        {
          id: 'type',
          field: 'type',
          label: this.$t('type'),
          search_select: true,
          sortable: true
        },
        {
          id: 'reason',
          field: 'reason',
          label: this.$t('cancel_reason'),
          sortable: true,
        },
        {
          id: 'description',
          field: 'description',
          label: this.$t('description'),
          sortable: true,
        },
        {
          id: 'cancelled_name',
          field: 'cancelled_name',
          label: this.$t('initiator'),
          search_select: true,
          sortable: true
        },
        {
          id: 'author_name',
          field: 'author_name',
          label: this.$t('who_created'),
          search_select: true,
          sortable: true
        },
      ],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('cancelled_visits', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
      }).then(res => {
        this.rows = res.data
      })
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>
