<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="source"
                   initialSort="asc"
                   :rows="rows"/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import { mapState } from "vuex"

export default {
  name: "ReportCRMAnalytics",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    sources: {},
    selectedBranches: {},
    fields: {},
    doctors: {},
  },
  metaInfo() {
    return {
      title: this.$t('crm_leads_analytics'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      counter: state => state.datatable.dataTableCounter,
      branchId: state => state.auth.branch,
    }),
  },
  data() {
    return {
      columns: [
        {
          id: 'source',
          field: 'source',
          label: this.$t('source'),
          sortable: true,
        },
        {
          id: 'cnt',
          field: 'cnt',
          label: this.$t('amount'),
          format_number: true,
          totals: true
        },
        {
          id: 'primary_records',
          field: 'primary_records',
          label: this.$t('primary_records'),
          format_number: true,
          totals: true
        },
        {
          id: 'primary_records_came',
          field: 'primary_records_came',
          label: this.$t('primary_records_came'),
          format_number: true,
          totals: true
        },
        {
          id: 'primary_records_revenue',
          field: 'primary_records_revenue',
          label: this.$t('primary_records_revenue'),
          format_number: true,
          totals: true
        },
        {
          id: 'primary_records_further',
          field: 'primary_records_further',
          label: this.$t('primary_records_further'),
          format_number: true,
          totals: true
        },
        {
          id: 'repeated_records',
          field: 'repeated_records',
          label: this.$t('repeated_records'),
          format_number: true,
          totals: true
        },
        {
          id: 'repeated_records_came',
          field: 'repeated_records_came',
          label: this.$t('repeated_records_came'),
          format_number: true,
          totals: true
        },
        {
          id: 'repeated_records_revenue',
          field: 'repeated_records_revenue',
          label: this.$t('repeated_records_revenue'),
          format_number: true,
          totals: true
        },
        {
          id: 'repeated_records_further',
          field: 'repeated_records_further',
          label: this.$t('repeated_records_further'),
          format_number: true,
          totals: true
        },
        {
          id: 'total_revenue',
          field: 'total_revenue',
          label: this.$t('total_revenue'),
          format_number: true,
          totals: true
        },
      ],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('crm_analytics', {
        from: this.from,
        to: this.to,
        doctors: this.doctors.map(s => s.id),
        fields: this.fields.map(s => s.id),
        sources: this.sources.map(s => s.title),
        branch_id: this.selectedBranches,
      }).then(res => {
        this.rows = res.data
      })
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  },
  watch: {
    counter() {
      this.loadReport()
    }
  }
}
</script>
