<template>
  <router-link class="data-table-action"
               :to="{ name: 'WarehousesDocumentItem', params: { id: object.id } }"
               :title="$t('details')"
               v-b-tooltip.hover>
    <PictureSvg/><span class="action-button-mob-title">{{ $t('details') }}</span>
  </router-link>
</template>

<script>
import PictureSvg from '@/assets/svg/general/picture.svg'

export default {
  name: "WarehouseDatatableActionsOperation",
  components: {
    PictureSvg
  },
  props: {
    object: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>

