import authorized from "@/middleware/authorized"
import paid from "@/middleware/paid"
import PlanningGeneral from "@/components/pages/planning/PlanningGeneral"
import CallPlanning from "@/components/pages/planning/CallPlanning"
import CallVisits from "@/components/pages/planning/CallVisits"
// import PatientVisits from "@/components/pages/patients/PatientVisits"

export default {
    path: '/planning',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['call_planning'] },
    // component: () => import('../components/pages/patients/PatientGeneral.vue'),
    component: PlanningGeneral,
    children: [
        { path: '', redirect: 'calls' },
        {
            path: 'calls', name: "CallPlanning",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['call_planning'] },
            // component: () => import('../components/pages/patients/PatientVisits.vue')
            component: CallPlanning
        },
        {
            path: 'visits', name: "CallVisits",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['call_planning'] },
            // component: () => import('../components/pages/patients/PatientVisits.vue')
            component: CallVisits
        },
    ]

}
