<template>
  <div :class="`task task-${task.importance}`">
    <b-row class="align-items-start">
      <b-col>
        <a @click="deleteTask" class="deleteButton">
          <TrashSvg class="svg-light pointer"/>
        </a>
        <div class="checkbox">
          <input type="checkbox"
                 @click="ticked"
                 :id="`tt-${task.id}`"
                 :checked="task.finished"/>
          <label :for="`tt-${task.id}`" class="task-checkbox-label"></label>
          <div class="task-teaser-content">
            {{ task.title }} <a class="pointer" @click="showTaskModal"><InfoSvg/></a>
            <div class="small text-muted mt-1">
              {{ task.task_type == 'call' ? $t('call_noun') : $t(task.task_type) }}
              <span v-if="task.deadline" class="ml-1">{{ task.deadline | formatDateTime }}</span>
            </div>
            <div class="small text-muted" v-if="task.description">{{ task.description }}</div>
            <div class="small text-muted" v-if="task.record">{{ $t('visit') }} {{ task.record.readableStart }}</div>
            <div v-if="task.patient && task.task_type == 'call'" class="small">
              <router-link :to="{ name: 'PatientVisits', params: { id: task.patient_id }}">{{ task.patient.full_name }}</router-link> |
              <OutgoingCallElement :phone="task.patientPhone"/>
            </div>
            <b-row class="align-items-center">
              <b-col col v-if="task.user">
                <!--                <div class="small d-inline-block">{{ $t('responsible') }}:</div>-->
                <div class="small d-inline-block">
                  {{ task.user.full_name }}
                </div>
              </b-col>
              <b-col :cols="task.user ? 'auto' : '12'" class="text-right">
                <CRMDatatableTDTaskStatus :object="task"/>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import TrashSvg from '@/assets/svg/general/trash_tr.svg'
import InfoSvg from '@/assets/svg/general/info2.svg'
import { CalendarService, EloquentService } from "@/services/api.service"
import CRMDatatableTDTaskStatus from "@/components/parts/datatable/td/crm/CRMDatatableTDTaskStatus"
import OutgoingCallElement from "@/components/parts/patients/OutgoingCallElement.vue"

export default {
  name: "TaskTeaser",
  components: {
    OutgoingCallElement,
    CRMDatatableTDTaskStatus,
    TrashSvg,
    InfoSvg
  },
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  methods: {
    ticked() {
      CalendarService.finishTask(this.task.id).then(() => {
        // this.$store.dispatch('getTasks')
        this.$emit('loadTasks')
      })
    },
    deleteTask() {
      EloquentService.delete('task', this.task.id).then(() => {
        // this.$store.dispatch('getTasks')
        this.$emit('loadTasks')
      })
    },
    showTaskModal() {
      this.$store.commit('setTask', this.copyObject(this.task))
      this.$bvModal.show('task-show-modal')
    }
  }
}
</script>

<style scoped lang="scss">
.task {
  padding: 14px 22px;
  border-bottom: 1px solid $calendar-border-color;

  &-low {
    border-left: 5px solid #fff;
  }
  &-high {
    background: #FFFAFA;
    border-left: 5px solid #DD1515;
  }
  &-medium {
    background: #FFFCF2;
    border-left: 5px solid #FEC943;

  }
  label {
    height: auto;
    padding-left: 40px;
    color: #000;
  }
  .deleteButton {
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 2;
  }
  .task-teaser-content {
    padding-left: 40px;
  }
  .task-checkbox-label {
    top: 11px;
  }
}

@media screen and (max-width: 768px) {
  .task {
    .task-teaser-content {
      padding-left: 30px;
    }
  }
}
</style>
