<template>
  <div>
    <DataTableFilter :options="options" class="mt-3"/>

    <div style="overflow-x: scroll" class="pt-3">

      <b-row class="flex-nowrap" no-gutters>
        <b-col v-for="(p, pind) in planTreatmentStatuses" :key="`plan-${pind}`">
          <div class="plan-wrap h-100" :class="{ 'ml-3': pind > 0 }">

            <div>
              {{ $t(p.title) }} | <span :class="{ 'text-danger': !!plans[p.id] }">{{ plans[p.id] ? plans[p.id].length : 0 }}</span>
              <div class="separator w-100 mt-2 mb-3" :style="`background-color: ${p.color}`"/>
            </div>

            <draggable class="list-group h-100"
                       :list="plans[p.id]"
                       group="leads"
                       :data-status="p.id"
                       :move="planMoved">
              <div v-for="plan in plans[p.id]"
                   class="lead-card card p-md-3 p-2 mb-3 pointer"
                   :data-id="plan.planning_id"
                   :key="`plan-id-${plan.planning_id}`">
                <div class="font-weight-bold">
                  <router-link :to="`/patients/${plan.client_id}/visits`">{{ plan.patient.full_name }}</router-link>
                </div>
                <div>{{ plan.patient.phone }}</div>
                <div>
                  <div v-for="visit in plan.visits" :key="`visit-${visit.id}`">
                    {{ visit.fullTitle }}
                    <span class="circle mr-2" :class="`status-${visit.manual_status}`"></span>
                  </div>
                </div>
                <div v-if="plan.next_visit">
                  {{ $t('next_visit') }}: {{ plan.next_visit.readableStart }}{{ plan.next_visit.doctor ? ' - '+plan.next_visit.doctor.full_name : '' }}
                </div>
                <div class="font-weight-bold text-primary pointer" @click="openPlanTreatment(plan)">{{ plan.message }}</div>
              </div>
            </draggable>

          </div>

        </b-col>
      </b-row>

      <PlanTreatmentModal/>

    </div>
  </div>
</template>

<script>

import {CRMService, EloquentService} from "@/services/api.service"
import {planTreatmentStatuses} from "@/dictionaries/dictionary"
import draggable from 'vuedraggable'
import PlanTreatmentModal from "@/components/parts/patients/PlanTreatmentModal"
import options from "@/data/patients/plan_treatment"
import {mapState} from "vuex"
import DataTableFilter from "@/components/parts/datatable/DataTableFilter"
import moment from "moment"

export default {
  name: "CRMSalesFunnel",
  metaInfo() {
    return {
      title: this.$t('sales_funnel'),
      meta: []
    }
  },
  components: {
    DataTableFilter,
    PlanTreatmentModal,
    draggable
  },
  data() {
    return {
      planTreatmentStatuses: planTreatmentStatuses,
      plans: {},
      options
    }
  },
  computed: {
    ...mapState({
      counter: state => state.datatable.dataTableCounter,
      filters: state => state.datatable.filters,
    }),
  },
  mounted() {
    this.$store.commit("setViewModel", 'plan_treatment')
    this.$store.commit('setEloquentOptions', options)
    this.loadData()
  },
  methods: {
    async loadData() {
      try {
        this.initialState()
        let filters = this.copyObject(this.filters)
        if(Object.keys(filters).length === 0) {
          filters = {
            date_add: {
              id: 'date_add',
              type: 'more',
              condition: moment().subtract(90, 'days').format('YYYY-MM-DD')
            }
          }
        }
        let data = await CRMService.getPlanTreatments(filters)
        data.data.data.forEach(plan => {
          if(!this.plans[plan.status]) {
            this.$set(this.plans, plan.status, [])
          }
          this.plans[plan.status].push(plan)
        })
      } catch (e) {
        console.log(e)
      }
    },
    planMoved(e) {
      const planId = String(e.dragged.getAttribute('data-id'))
      const newStatus = e.to.getAttribute('data-status')
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        manual_status: newStatus,
      })
      EloquentService.update('plan_treatment', planId, formData, false, false)
    },
    openPlanTreatment(plan) {
      this.$store.commit('setVisit', this.copyObject(plan))
      this.$store.commit('setPatient', this.copyObject(plan.patient))
      this.$bvModal.show('plan-treatment-modal')
    },
    initialState() {
      this.plans = {}
      this.planTreatmentStatuses.forEach(status => {
        this.$set(this.plans, status.id, [])
      })
    },
  },
  watch: {
    counter() {
      this.loadData()
    },
    $route() {
      this.loadData()
    },
  },
  beforeDestroy() {
    this.$store.commit('setFilters', {})
  }
}
</script>

<style scoped lang="scss">
.separator {
  height: 2px;
}
.plan-wrap {
  min-width: 220px;
}
.plan-card {
  border-radius: 8px;
  &:hover {
    background-color: $light-blue;
  }
}
.circle {
  width: 12px;
  height: 12px;
  &.status-new {
    background: $gray-text;
  }
  &.status-in_process {
    background: $warning-yellow;
  }
  &.status-finished {
    background: #65CA35;
  }
}
@media screen and (max-width: 768px) {
  .plan-wrap {
    min-width: 150px;
  }
  .circle {
    width: 10px;
    height: 10px;
  }
}
</style>