<template>
  <div class="clinic-marketing-data page pb-5 pt-4">
    <CheckboxGroup :label="$t('create_application_on_call')"
                   :checkboxvalue="1"
                   fid="create_application_on_call"
                   v-model="create_application_on_call"/>
    <div class="mt-5">
      <button class="btn btn-themed btn-themed-squared" @click="save">{{ $t('save') }}</button>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import { ClinicService } from "@/services/api.service"

export default {
  name: "CRMSettings",
  metaInfo() {
    return {
      title: this.$t('settings'),
      meta: []
    }
  },
  components: {
    CheckboxGroup,
  },
  data() {
    return {
      create_application_on_call: 0,
    }
  },
  computed: {
    ...mapState({
      clinic: state     => state.auth.clinic,
      superadmin: state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        create_application_on_call: 0,
      }
    },
  },
  watch: {

  },
  methods: {
    async save() {
      await ClinicService.savePPOAttribute([
        'create_application_on_call',
      ], [
        this.create_application_on_call,
      ], this.superadmin, this.clinic.id)
      this.$noty.info(this.$t('success_message'))
      await this.$store.dispatch('init')
    },
  },
  mounted() {
    this.create_application_on_call = this.ppoSettings.create_application_on_call
  }
}
</script>
