<template>
  <div>
    <div class="mb-3" v-if="clinic.import_services == 1 && importAllowed">
      <b-dropdown variant="link"
                  right
                  text="Dropdown Button">
        <template #button-content>
          <a class="btn-themed btn-themed-outline btn-themed-squared d-inline-block">
            {{  $t('import_standard_services') }}<ArrowSvg class="ml-2"/>
          </a>
        </template>
        <b-dropdown-item @click="importStandardServices">{{ $t('import') }}</b-dropdown-item>
        <b-dropdown-item @click="closeImport">{{ $t('close') }}</b-dropdown-item>
      </b-dropdown>

    </div>
    <DataTable :options="options"
               :params="{ visible: 1 }"
               module="services"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/services/service"
import {ClinicService } from "@/services/api.service"
import {mapState} from "vuex"
import ArrowSvg from '@/assets/svg/general/arrow_down.svg'

export default {
  name: "ServiceServices",
  metaInfo() {
    return {
      title: this.$t('services'),
      meta: []
    }
  },
  components: {
    DataTable,
    ArrowSvg
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      branch: state => state.auth.branch,
    }),
  },
  data() {
    return {
      options: options,
      importAllowed: false
    }
  },
  methods: {
    async importStandardServices() {
      let res = await ClinicService.importStandardServices()
      if(res.data) {
        this.$noty.info(this.$t('success_message'))
        await this.$store.dispatch('init')
        this.$store.commit('incrementDataTableCounter')
      }
    },
    async closeImport() {
      await ClinicService.disableStandardServices()
      await this.$store.dispatch('init')

    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    this.$store.commit('setAdditionalParams', { visible: 1 })
  },
  beforeDestroy() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>
