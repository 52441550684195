<template>
  <div class="pb-5 d-inline-block position-relative">
    <div>
      <template v-for="t in teeth.top">
        <Tooth :key='`top-t-${t.cnt}`'
               :toothNumber="t.cnt"
               :disabled="disabled"
               position="top"
               :mode="mode"/>
      </template>
    </div>
    <div class="dashed-v"></div>
    <div class="mt-2 pt-2 dashed-b">
      <template v-for="t in teeth.bottom">
        <Tooth :key='`top-t-${t.cnt}`'
               :toothNumber="t.cnt"
               :disabled="disabled"
               position="bottom"
               :mode="mode"/>
      </template>
    </div>
  </div>
</template>

<script>
import Tooth from "@/components/parts/patients/teeth/Tooth"

export default {
  name: "BabyTeeth",
  components: {
    Tooth
  },
  props: {
    mode: {
      type: String,
      default: 'status'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      teeth: {
        top: [
          { cnt: 55 },
          { cnt: 54 },
          { cnt: 53 },
          { cnt: 52 },
          { cnt: 51 },
          { cnt: 61 },
          { cnt: 62 },
          { cnt: 63 },
          { cnt: 64 },
          { cnt: 65 },
        ],
        bottom: [
          { cnt: 85 },
          { cnt: 84 },
          { cnt: 83 },
          { cnt: 82 },
          { cnt: 81 },
          { cnt: 71 },
          { cnt: 72 },
          { cnt: 73 },
          { cnt: 74 },
          { cnt: 75 },
        ]
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dashed-b {
  border-top: 2px dashed #fff;
}
.dashed-v {
  border-right: 2px dashed #fff;
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  margin-left: -1px;
}
</style>
