<template>
  <div v-if="hasRight" class="mb-4">
    <b-button v-b-toggle.sipuni-c class="btn-collapse">Sipuni</b-button>
    <b-collapse id="sipuni-c" @show="onShow">
      <div class="mt-3 pb-5">
        <div class="mt-1" v-html="$t('sipuni_integration_instructions', { url: `https://api2.dentist-plus.com/sipuni/call/${clinic.id}` })"></div>
        <div class="mt-2">

          <div v-for="(r, rind) in responsible"
               :key="`resp-${rind}`"
               :class="{'mt-3': rind > 0}">
            <b-row class="align-items-center">
              <b-col>
                <ServiceMultiSelect :label="$t('staff')"
                                    :dblclick="false"
                                    model="staff"
                                    :multiple="false"
                                    title="full_name"
                                    :dbsearch="true"
                                    v-model="responsible[rind].user"/>
              </b-col>
              <b-col>
                <InputGroup v-model="responsible[rind].tel"
                            :label="$t('internal_phone')"/>
              </b-col>
              <b-col cols="auto">
                <a class="mt-3 d-inline-block pointer" @click="removeResponsible(rind)">
                  <RemoveSvg/>
                </a>
              </b-col>
            </b-row>
          </div>

          <a class="mt-3 btn btn-themed-outline btn-themed-squared px-3 d-inline-block pointer"
             @click="addResponsible">
            <AddSvg class="mr-2"/>{{ $t('add_user') }}
          </a>

          <InputGroup v-model="sipuni_key"
                      class="mt-3"
                      :label="$t('system_number')"/>
          <InputGroup v-model="sipuni_secret"
                      class="mt-3"
                      :label="$t('integration_key')"/>
          <InputGroup v-model="sipuni_internal"
                      class="mt-3"
                      :label="$t('internal_phone')"/>
          <button class="btn-themed btn-themed-squared d-inline-block mt-4"
                  :disabled="loading"
                  @click="saveUsers">
            {{ $t('save') }}
          </button>
        </div>
      </div>
    </b-collapse>
  </div>

</template>

<script>
import {mapState} from "vuex"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import { ClinicService } from "@/services/api.service"
import InputGroup from "@/components/form/InputGroup"
import AddSvg from '@/assets/svg/general/add.svg'
import RemoveSvg from '@/assets/svg/general/trash.svg'

export default {
  name: "SipuniIntegration",
  components: {
    InputGroup,
    ServiceMultiSelect,
    AddSvg,
    RemoveSvg
  },
  data() {
    return {
      responsible: [],
      sipuni_key: '',
      sipuni_secret: '',
      sipuni_internal: ''
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      superadmin:  state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        sipuni: [],
        sipuni_key: '',
        sipuni_secret: '',
        sipuni_internal: '',
      }
    },
    sipuniUsers() {
      return this.ppoSettings.sipuni ?? []
    },
    sipuniUsersIds() {
      return this.sipuniUsers.map(u => u?.id ?? u)
    },
    hasRight() {
      if(this.superadmin) return this.clinic.rights.includes('crm')
      return this.rights.includes('crm')
    }
  },
  watch: {

  },
  async mounted() {
    this.sipuni_key = this.ppoSettings.sipuni_key
    this.sipuni_secret = this.ppoSettings.sipuni_secret
    this.sipuni_internal = this.ppoSettings.sipuni_internal
  },
  methods: {
    async onShow() {
      await this.getInitialUsers()
    },
    async saveUsers() {
      await ClinicService.savePPOAttribute([
        'sipuni',
        'sipuni_key',
        'sipuni_secret',
        'sipuni_internal',
      ], [
        this.responsible.map(x => {
          return {
            id: x.user?.id ?? x.id,
            tel: x.tel
          }
        }),
        this.sipuni_key,
        this.sipuni_secret,
        this.sipuni_internal,
      ], this.superadmin, this.clinic.id)
      this.$noty.info(this.$t('success_message'))
      await this.$store.dispatch('init')
    },
    async getInitialUsers() {
      if(this.sipuniUsersIds.length) {
        let res = await ClinicService.getUsersList(this.sipuniUsersIds, this.superadmin)
        this.responsible = []
        this.sipuniUsers.forEach(obj => {
          const id = obj?.id ?? obj
          const user = res.data.find(user => user.id == id)
          if(user) {
            this.responsible.push({
              user,
              tel: obj?.tel ?? ''
            })
          }
        })
      }
    },
    addResponsible() {
      this.responsible.push({
        user: null,
        tel: ''
      })
    },
    removeResponsible(index) {
      this.responsible.splice(index, 1)
    }
  }
}
</script>
