<template>
  <div class="mb-4" v-if="wazzupEnabled">
    <b-button v-b-toggle.wazzup-c class="btn-collapse">Wazzup</b-button>
    <b-collapse id="wazzup-c">
      <div class="mt-3 pb-2">
        <InputGroup v-model="wazzupKey"
                    :label="'Wazzup API Key'"/>
        <ServiceMultiSelect :label="$t('staff')"
                            class="mt-3"
                            :dblclick="false"
                            model="staff"
                            :multiple="true"
                            title="full_name"
                            :dbsearch="true"
                            v-model="wazzupUsersArr"/>
        <div v-if="wazzupKey" class="mb-3 mt-3">
          <div v-if="wazzupStatus && ppoSettings.wazzup == wazzupKey">
            <div class="alert alert-info">{{ $t('connected_successfully') }}</div>
          </div>
          <div v-if="!wazzupStatus && ppoSettings.wazzup == wazzupKey">
            <div class="alert alert-danger">{{ $t('not_connected') }}</div>
          </div>
          <button class="btn btn-themed btn-themed-squared"
                  @click="getwazzupStatus"
                  :disabled="loading">
            {{ ppoSettings.wazzup != wazzupKey ? $t('connect') : $t('refresh') }}
          </button>
          <button v-if="wazzupStatus && ppoSettings.wazzup == wazzupKey" @click="launchIframe" class="ml-2 btn btn-themed btn-themed-squared">
            {{ $t('open_whatsapp') }}
          </button>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>

import InputGroup from '@/components/form/InputGroup'
import {mapState} from "vuex"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import {ClinicService, WhatsappService} from "@/services/api.service"

export default {
  name: "WazzupAPIIntegration",
  components: {
    InputGroup,
    ServiceMultiSelect
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      wazzupStatus: state => state.crm.wazzupEnabled,
      superadmin:  state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : { wazzup: '', wazzupUsers: [] }
    },
    wazzupUsers() {
      return this.ppoSettings.wazzupUsers ?? []
    }
  },
  data() {
    return {
      wazzupUsersArr: [],
      wazzupKey: '',
      wazzupEnabled: true,
    }
  },
  methods: {
    async getwazzupStatus() {
      // if(this.ppoSettings.wazzup != this.wazzupKey) {
      await ClinicService.savePPOAttribute(['wazzup', 'wazzupUsers'], [this.wazzupKey, this.wazzupUsersArr.map(x => x.id)], this.superadmin, this.clinic.id)
      // }
      let res = await WhatsappService.getWazzupStatus(this.superadmin)
      this.$store.commit('setWazzupEnabled', res.data)
      this.$store.dispatch('init')
    },
    async launchIframe() {
      let res = await WhatsappService.createIframe();
      if(res.data.url) {
        this.$store.commit('setWazzupIframeUrl', res.data.url)
        this.$nextTick(() => {
          this.$bvModal.show('wazzup-iframe-modal')
        })
        return
      }
      if(res.data.error) {
        this.$noty.error(res.data.error)
      }
    },
    async getInitialUsers() {
      if(this.wazzupUsers.length) {
        let res = await ClinicService.getUsersList(this.wazzupUsers, this.superadmin)
        this.wazzupUsersArr = res.data
      }
    }
  },
  async mounted() {
    this.wazzupKey = this.ppoSettings.wazzup
    await this.getInitialUsers()
    if(this.wazzupKey) {
      this.getwazzupStatus()
    }

  }
}
</script>
