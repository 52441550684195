import  { WarehouseService } from "@/services/api.service"

const state = {
    operation: null,
}

const getters = {
}

const mutations = {
    setOperation(state, operation) {
        state.operation = operation
    },
    addOperationItem(state, item) {
        state.operation.items_with_materials.push(item)
    },
    removeOperationItem(state, index) {
        state.operation.items_with_materials.splice(index, 1)
    },
    addInventarizationItem(state, item) {
        const index = state.operation.warehouse_inventarization_sheets.findIndex(s => s.material_id === item.material_id)
        if(index !== -1) {
            state.operation.warehouse_inventarization_sheets[index].counted_amount++
            return
        }
        state.operation.warehouse_inventarization_sheets.push(item)
    },
    removeInventarizationItem(state, index) {
        state.operation.warehouse_inventarization_sheets.splice(index, 1)
    },
}

const actions = {
    getDocument({ commit }, id) {
        commit('setOperation', null)
        return new Promise(resolve => {
            WarehouseService.getDocument(id).then(res => {
                commit('setOperation', res.data.data)
                resolve(res.data.data)
            })
        })
    },
    addDocumentItem({ commit, state }, params) {
        return new Promise(resolve => {
            WarehouseService.addDocumentItem(state.operation.id, params).then(res => {
                commit('addOperationItem', res.data)
                resolve(res.data.data)
            })
        })
    },
    deleteDocumentItem({ commit, state }, index) {
        return new Promise(resolve => {
            WarehouseService.deleteDocumentItem(state.operation.id, state.operation.items_with_materials[index].id).then(res => {
                commit('removeOperationItem', index)
                resolve(res.data)
            })
        })
    },
    fillInventarization({ state, commit }, params) {
        return new Promise(resolve => {
            WarehouseService.autoFillInventarization(state.operation.id, params).then(res => {
                commit('setOperation', res.data.data)
                resolve(res.data.data)
            })
        })
    },
    addInventarizationItem({ commit, state }, params) {
        return new Promise(resolve => {
            WarehouseService.addInventarizationItem(state.operation.id, params).then(res => {
                if(res.data?.id) commit('addInventarizationItem', res.data)
                resolve(res.data)
            })
        })
    },
    deleteInventarizationItem({ commit, state }, index) {
        return new Promise(resolve => {
            WarehouseService.deleteInventarizationItem(state.operation.id, state.operation.warehouse_inventarization_sheets[index].id).then(res => {
                commit('removeInventarizationItem', index)
                resolve(res.data)
            })
        })
    },
    saveDocument({commit}) {
        return new Promise(resolve => {
            const isInventarization = state.operation.operation_type === 'inventarization'
            WarehouseService.saveDocument(
                state.operation.id,
                isInventarization ? state.operation.warehouse_inventarization_sheets : state.operation.items_with_materials,
                isInventarization
            ).then(res => {
                commit('setOperation', res.data.data)
                resolve(res.data)
            })
        })
    },
    approveDocument({ commit }) {
        return new Promise(resolve => {
            WarehouseService.approveDocument(state.operation.id).then(res => {
                commit('setOperation', res.data.data)
                resolve(res.data)
            })
        })
    },
    cancelDocument({ commit }) {
        return new Promise(resolve => {
            WarehouseService.cancelDocument(state.operation.id).then(res => {
                commit('setOperation', res.data.data)
                resolve(res.data)
            })
        })
    },

};

export default {
    state,
    actions,
    mutations,
    getters
}
