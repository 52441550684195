<template>
  <div>
    <DataTable :options="options"
              :params="{ operation_type: ['write_off', 'return', 'movement_from', 'addition', 'inventarization', 'adjustment', 'consumption_manual'] }"
               module="warehouse"/>
  </div>
</template>

<script>

import DataTable from "../../parts/general/DataTable"
import options from "../../../data/warehouse/warehouseoperation"

export default {
  name: "Documents",
  metaInfo() {
    return {
      title: this.$t('documents'),
      meta: []
    }
  },
  components: { DataTable },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
