<template>
  <div class="position-relative">
    <label for="patientimgfile" class="pointer">
      <img class="patient-img"
           :style="bordercolor ? `border: 4px solid ${bordercolor};` : ''"
           :src="user.photo"
           alt=""
           :title="user.full_name"/>
    </label>
<!--    <span class="patient-img-warning"-->
<!--          v-if="user.importantNotes > 0"-->
<!--          v-b-popover.hover="$t('patient_has_important_notes', { cnt: user.importantNotes})">-->
      <span class="patient-img-warning"
            v-if="user.importantNotes"
            v-b-popover.hover="user.importantNotes">
      <WarningSvg/>
    </span>
    <input type="file"
           id="patientimgfile"
           accept="image/*"
           class="d-none"
           ref="patientimgfile"
           @change="processFile($event)"/>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex"
import WarningSvg from '@/assets/svg/general/warning.svg'
import {EloquentService} from "@/services/api.service"

export default {
  name: "PatientImg",
  components: {
    WarningSvg
  },
  props: {
    view: {
      type: String,
      default: 'patient'
    },
    bordercolor: {
      type: String,
      default: null,
    }
  },
  computed: {
    ...mapState({
      patient:  state => state.patient.patient,
      doctor:  state => state.doctor.doctor,
    }),
    user() {
      return this.view === 'doctor' ? this.doctor : this.patient
    }
  },
  data() {
    return {
      sizelimit: 1, // in MB
      form: {

      },
    }
  },
  methods: {
    ...mapActions(['getPatient', 'getDoctor']),
    processFile(event) {
      let file = event.target.files[0]
      if(!file) {
        return
      }
      if(file.size > (this.sizelimit * 1024 * 1024)) {
        this.$noty.error(this.$t('file_size_is_too_big'))
        return
      }
      this.form = {
        image: file,
        lname: this.user.lname,
        mname: this.user.mname,
        fname: this.user.fname,
        mphone: this.user.mphone,
        card: this.user.card,
        mode: 'image'
      }
      this.upload()
    },
    upload() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.form)
      if(this.view == 'doctor') {
        EloquentService.update('doctor', this.user.id, formData).then(() => {
          this.getDoctor(this.user.id)
        })
      }
      if(this.view == 'patient') {
        EloquentService.update('patient', this.user.id, formData).then(() => {
          this.getPatient(this.user.id)
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">
.patient-img {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 100%;
}
.patient-img-warning {
  position: absolute;
  top: 0;
  right: 0;
  border: 2px solid #fff;
  font-size: 0;
  border-radius: 100%;
  background-color: #fff;
}
</style>
