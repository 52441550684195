<template>
  <div>
    <div class="mb-3">
      <a class="btn-themed btn-themed-outline btn-themed-squared d-inline-block"
         v-if="clinic.import_services == 1"
         @click="importServices">Импорт стандартных услуг</a>
    </div>
    <DataTable :options="options"
               :params="{ clinic_id: clinic.id, visible: 1 }"
               module="superadmin"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/superadmin/service"
import {mapState} from "vuex"
import { SuperadminService } from "@/services/api.service"

export default {
  name: "SuperAdminClinicServices",
  components: { DataTable },
  metaInfo() {
    return {
      title: this.$t('services'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      options: options
    }
  },
  methods: {
    async importServices() {
      let res = await SuperadminService.importServices(this.clinic.id)
      if(res.data) this.$noty.info(this.$t('success_message'))
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
