<template>
  <div class="form-control gray-input d-flex align-items-center position-relative">
    <span ref="docListBtn" class="doctor-span pointer" @click="showHide">{{ text }}</span>
    <div v-if="popupShowed" class="d-doctor-selector view-dd-selector position-absolute"
         v-closable="{
            exclude: ['docListBtn'],
            handler: 'onClose'
        }">
      <div class="px-3 py-2 d-flex flex-fill">
        <div class="w-100 py-1"><a @click="onSelectAll" href="#">{{ $t('choose_all') }}</a></div>
        <div class="w-100 py-1"><a @click="onDeselectAll" href="#">{{ $t('clear') }}</a></div>
      </div>
      <div class="d-doctor-selector__container" :style="{'max-height': maxHeight() + 'px' }">
        <DDoctorItem v-for="doc in doctors"
                     :key="doc.id"
                     @click.native.stop="onDocTap(doc)"
                     v-model="selected"
                     :doc="doc" />
      </div>
      <div class="d-doctor-selector__submit p-2" @click="saveSelected">{{$t('save') }}</div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex"
import DDoctorItem from "@/components/pages/calendar/calendar/DDoctorItem"
import { Closable } from "@/components/pages/calendar/calendar/directives/CloseOutside"

export default {
  name: "DDoctors",
  components: {
    DDoctorItem
  },
  props: {
    onSaveList: Function
  },
  computed: {
    ...mapState({
      doctors: state => state.calendar.doctors,
      selectedDoctors: state => state.calendar.selectedDoctors,
    }),
    text: function() {
      let length = this.selected.length
      if (length === 0) return this.$t('select_a_doctor')
      if (length === 1) {
        let name = this.doctors.find(x => x.id === this.selected[0]).full_name
        return `${this.$t('selected')}: ${name}`
      }
      return `${this.$t('doctors_selected')}: ${length}`
    },
  },
  data() {
    return {
      selected: [],
      popupShowed: false,
    }
  },
  methods: {
    showHide() {
      this.popupShowed = !this.popupShowed
    },
    onSelectAll() {
      this.selected = this.doctors.map(item => item.id)
    },
    onDeselectAll() {
      this.selected = []
    },
    saveSelected() {
      if (!this.selected.length) {
        alert(this.$t('need_to_select_at_least_one_doctor'))
      } else {
        const newList = this.doctors.filter(item => this.selected.includes(item.id))
        this.$store.commit('setSelectedDoctors', newList)
        this.popupShowed = false
        this.onSaveList()
      }
    },
    onDocTap(doc) {
      if (!this.selected.includes(doc.id)) this.selected.push(doc.id)
      else this.selected.splice(this.selected.indexOf(doc.id), 1)
    },
    onClose() {
      this.popupShowed = false
    },
    maxHeight() {
      return window.innerHeight - 250
    }
  },
  directives: {
    Closable
  },
  watch: {
    doctors() {
      this.selected = this.selectedDoctors.map(item => item.id)
    }
  }
}
</script>

<style scoped lang="scss">
.doctor-span {
  line-height: 1.2;
}
.d-doctor-selector {
  width: 300px;
  top:45px;
}
.d-doctor-selector__container {
  border-top: 1px solid #E5E5E5;
  border-bottom: 1px solid #E5E5E5;
  overflow-y: scroll;
}
.d-doctor-selector__submit {
  color: white;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  background-color: #0787C9;
  cursor: pointer;
}
.d-doctor-selector__submit:hover {
  background-color: #0977AF;
}

@media screen and (max-width: 768px) {
  .d-doctor-selector {
    top: 35px;
    width: 250px;
  }
}
</style>
