<template>
  <div class="checkbox form-group" :class="{ checked: checked }">
    <input type="checkbox"
           :name="fid"
           :value="checkboxvalue ? checkboxvalue : 1"
           :checked="checked"
           :id="fid"
           :disabled="readonly"
           @input="handleInput"/>
    <label class="mb-0" :for="fid">{{ label }}<slot/></label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxGroup',
  data() {
    return {
      content: this.value
    }
  },
  props: {
    label: {},
    value: {},
    fid: {},
    readonly: {},
    checkboxvalue: {

    }
  },
  mounted() {
  },
  computed: {
      checked() {
        return this.checkboxvalue ? (Array.isArray(this.value) ? this.value.includes(this.checkboxvalue) : (this.value == this.checkboxvalue)) : this.value
      }
  },
  methods: {
    handleInput(e) {
      if(this.checkboxvalue) {
        let c = this.value
        if(Array.isArray(c)) {
          let ind = c.indexOf(this.checkboxvalue)
          if (ind === -1) {
            c.push(this.checkboxvalue)
          } else {
            c.splice(ind, 1)
          }
        } else {
          if(e.target.checked) {
            c = this.checkboxvalue
          } else {
            c = 0
          }
        }
        this.$emit('input', c)
      } else {
        this.$emit('input', e.target.checked)
      }

    }
  }
}
</script>

<style scoped lang="scss">

</style>
