import { render, staticRenderFns } from "./CRMDatatableTaskForm.vue?vue&type=template&id=048e1415&scoped=true&"
import script from "./CRMDatatableTaskForm.vue?vue&type=script&lang=js&"
export * from "./CRMDatatableTaskForm.vue?vue&type=script&lang=js&"
import style0 from "./CRMDatatableTaskForm.vue?vue&type=style&index=0&id=048e1415&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "048e1415",
  null
  
)

export default component.exports