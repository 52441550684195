<template>
    <span :class="{ negative: quantity < 0 }">
    {{ quantity * object.price | formatCurrency }}
  </span>
</template>

<script>
import {mapState} from "vuex"

export default {
  name: "WarehouseDatatableTDMaterialValue",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      filters: state => state.datatable.filters
    }),
    quantity() {
      if(this.filters && this.filters.warehouse_id) {
        return this.object.quantities?.[this.filters.warehouse_id.condition] || 0
      }
      return this.object.total_quantity
    }
  },
}
</script>

<style scoped lang="scss">

</style>
