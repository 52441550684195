export default {
    cashdesk: "Каса",
    cash_operation: "Касова операція",
    cheque: "Чек",
    cheques: "Чеки",
    closing_balance: "Залишок на кінець дня",
    close_shift: "Закрити зміну",
    data_is_invalid: "Дані невалідні",
    data_is_valid: "Дані валідні",
    injection: "Поповнення",
    name_of_service_in_cheque: "Назва послуги в чеку",
    not_subject_to_vat: "Не оподатковується ПДВ",
    opening_balance: "Залишок на початок дня",
    open_shift: "Відкрити зміну",
    opened_at: "Дата відкриття",
    sales: "Продажі",
    send_services_in_cheque: "Передавати послуги в чеку",
    shift: "Зміна",
    shifts: "Зміни",
    shift_number: "Номер зміни",
    vat_rate: "Ставка ПДВ",
    withdrawal: "Вилучення",
    xreport: "X-звіт",
    xreports: "X-звіти"
}
