<template>
  <span>
  <a class="data-table-action"
     :title="$t('view')"
     v-b-tooltip.hover
     @click="show">
    <EyeSvg class="svg-light"/>
    <span class="action-button-mob-title">{{ $t('view') }}</span>
  </a>
    <a class="data-table-action"
       :title="$t('call')"
       v-if="phone && !rights.includes('hide_patient_phone')"
       v-b-tooltip.hover
       :href="`tel:${phone}`">
    <PhoneSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('call') }}</span>
  </a>
    <a :title="$t('sms_sending')"
       v-if="rights.includes('sms_send') && phone && !rights.includes('hide_patient_phone')"
       v-b-tooltip.hover
       class="data-table-action"
       @click="sendSms">
    <SmsSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('sms_sending') }}</span>
  </a>
    <a @click="showHistory"
       v-b-tooltip.hover
       class="data-table-action"
       :title="$t('comments')">
      <NoteSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('comments') }}</span>
    </a>
    <a @click="tickTask"
       v-b-tooltip.hover
       class="data-table-action"
       :title="object.finished ? $t('mark_as_in_progress') : $t('mark_as_completed')">
      <BlockSvg v-if="object.finished"/>
      <TickSvg v-if="!object.finished" class="svg-light"/>
      <span class="action-button-mob-title">{{ object.finished ? $t('mark_as_in_progress') : $t('mark_as_completed') }}</span>
    </a>
    </span>
</template>

<script>

import EyeSvg from '@/assets/svg/form/eye.svg'
import SmsSvg from '@/assets/svg/general/sms_t.svg'
import PhoneSvg from '@/assets/svg/header/phone_t.svg'
import NoteSvg from '@/assets/svg/header/note_t.svg'
import TickSvg from '@/assets/svg/form/tick.svg'
import BlockSvg from '@/assets/svg/general/block.svg'
import { mapState } from "vuex"
import { CalendarService } from "@/services/api.service"

export default {
  name: "CRMTaskDatatableActions",
  components: {
    EyeSvg,
    PhoneSvg,
    SmsSvg,
    TickSvg,
    BlockSvg,
    NoteSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
    phone() {
      if(this.object.phone) return this.object.phone
      if(this.object.patient) return this.object.patient.mphone
      return null
    }
  },
  methods: {
    show() {
      this.$store.commit('setTask', this.copyObject(this.object))
      this.$bvModal.show('task-show-modal')
    },
    sendSms() {
      this.object.patient.mphone = this.phone
      this.$store.commit('setPatient', this.object.patient)
      this.$nextTick(() => {
        this.$bvModal.show('patient-sms-modal')
      })
    },
    showHistory() {
      let obj = this.copyObject(this.object)
      obj.onlyComments = true
      this.$store.commit('setTask', obj)
      this.$bvModal.show('task-show-modal')
    },
    async tickTask() {
      let res = await CalendarService.finishTask(this.object.id)
      if(res.data) this.$store.commit('incrementDataTableCounter')
    }
  }
}
</script>

<style scoped>
.data-table-action svg {
  width: 16px;
  height: 16px;
}
</style>
