<template>
  <span v-if="object.status === 'waiting'">
  <a class="data-table-action"
     @click="cancelNotification"
     v-b-tooltip.hover
     :title="$t('cancel_sending')"
  >
    <CancelSvg/><span class="action-button-mob-title">{{ $t('cancel_sending') }}</span>
  </a>
    </span>

</template>

<script>

import CancelSvg from '@/assets/svg/general/block.svg'
import { EloquentService } from "@/services/api.service"

export default {
  name: "PatientDatatableAdvanceActions",
  components: {
    CancelSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  methods: {
    async cancelNotification() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, { sms_error_code: 999 })
      await EloquentService.update('notification', this.object.id, formData)
      this.$store.commit('incrementDataTableCounter')
    },
  }
}
</script>
