import JwtService from "@/services/jwt.service"
import { UsersService, ClinicService, EloquentService, PatientService } from "@/services/api.service"
import LangHelper from "@/services/lang.helper"
import BranchHelper from "@/services/branch.helper"
import i18n from '@/lang/i18n'
import moment from 'moment'
import axios from "axios"

const state = {
    errors: {},
    user: null,
    clinic: null,
    branches: [],
    doctors: [],
    admins: [],
    chairs: [],
    branchChairs: [],
    tasks: [],
    units: {},
    rights: [],
    pay_methods: [],
    professions: [],
    record_tags: [],
    clientSources: [],
    superadmin: JwtService.isSuperAdmin(),
    branch: BranchHelper.getBranch(),
    isLogged: !!JwtService.getToken(),
    paid_till: null,
    free_day: null,
    clinicTariff: null,
    useProxy: true,

    headerUpdateCounter: 0,

    headerCounters: {
        tasks: 0,
        birthdays: 0,
        waiting: 0,
        notifications: 0,
        unread: 0,
    }
}

const getters = {
    user(state) {
        return state.user
    },
    isLogged(state) {
        return state.isLogged
    },
    isPatient(state) {
        return state.user && state.user.role_id == 5
    },
    isDoctor(state) {
        return state.user && state.user.role_id == 4
    },
    getCurrencyName(state) {
        // console.log(state.clinic)
        return state.clinic.currency ? state.clinic.currency.name : ''
    },
    hasFreeTariff() {
        if(!state.clinicTariff) return false
        return state.clinicTariff.tariff_id == 1
    },
    isPaid(state) {
        let today = moment().format('YYYY-MM-DD')
        if(state.free_day && state.free_day == today) return true
        if(state.paid_till && state.paid_till >= today) return true
        return false
    },
    usesBonuses(state) {
        return state.rights.includes('bonuses') && (state.clinic?.clinic_additional?.bonuses_enabled || false)
    },
    deductsAcquiringFromSalaries(state) {
        return state.clinic?.clinic_additional?.deduct_acquiring_from_salary || false
    },
    recordTimingIsOn(state) {
        return state.clinic?.clinic_additional?.record_timing_enabled || false
    }
}

const mutations = {
    setError(state, error) {
        state.errors = error
    },
    setBranch(state, branch) {
        state.branch = branch
        BranchHelper.saveBranch(branch)
    },
    setUser(state, user) {
        state.user = user
    },
    setClientSources(state, sources) {
        state.clientSources = sources
    },
    setAuth(state, data) {
        state.isLogged = true
        state.user = data.user
        state.errors = {}
        if(data.token) {
            JwtService.saveToken(data.token, data.superadmin ? data.superadmin : false)
        }
        if(!data.use_proxy) {
            axios.defaults.baseURL = process.env.VUE_APP_API_URL
            state.useProxy = false
        }
        if(data.clinic) {
            state.clinic = data.clinic
            if(state.clinic.lang_code) {
                i18n.locale = state.clinic.lang_code
                LangHelper.saveLanguage(state.clinic.lang_code)
                moment.locale(state.clinic.lang_code)
            }
            if(state.clinic.currency) {
                window.localStorage.setItem('currency', state.clinic.currency.name)
            }
        }
        if(data.branches) {
            state.branches = data.branches
            if(!state.branch && data.branches.length > 0) {
                let branch = data.branches[0].id
                state.branch = branch
                BranchHelper.saveBranch(branch)
            }
        }
        if(data.doctors) state.doctors = data.doctors
        if(data.prices) state.prices = data.prices
        if(data.chairs) {
            state.chairs = data.chairs
            state.branchChairs = data.chairs.filter(x => x.branch_id == state.branch)
        }
        if(data.units) {
            state.units = data.units
        }
        if(data.pay_methods) {
            state.pay_methods = data.pay_methods
        }
        if(data.rights) {
            state.rights = data.rights
        }
        if(data.professions) {
            state.professions = data.professions
        }
        if(data.superadmin) {
            state.superadmin = true
        }
        if(data.record_tags) {
            state.record_tags = data.record_tags
        }
        if(data.tariff) {
            state.paid_till = data.tariff.paid_till ?? null
            state.free_day = data.tariff.free_day ?? null
            state.clinicTariff = data.tariff
        }
    },
    setClinic(state, data) {
        state.clinic = data.clinic
    },
    setTasks(state, tasks) {
        state.tasks = tasks
    },
    setHeaderCounter(state, { counter, value } ) {
        state.headerCounters[counter] = value
    },
    incrementHeaderUpdateCounter() {
        state.headerUpdateCounter++
    },
    setFreeDay(state, value) {
        state.free_day = value
    },
    setPaidTill(state, value) {
        state.paid_till = value
    },
    setAdmins(state, value) {
        state.admins = value
    }
}

const actions = {
    login(context, params) {
        return new Promise(resolve => {
            UsersService.login(params).then(data => {
                let d = data.data
                context.commit('setAuth', d)
                context.commit('setMyOpenedVisit', d.my_opened_visit)
                saveCalendarSettings(context, d)
                resolve(d)
            }).catch(err => {
                context.commit('setError', err.response ? err.response.data.errors : {})
            })
        })
    },
    logout(context) {
        state.isLogged = false
        state.superadmin = false
        state.user = null
        state.clinic = null
        state.branch = null
        state.paid_till = null
        state.free_day = null
        state.doctors = []
        state.branches = []
        state.chairs = []
        state.tasks = []
        state.errors = {}
        window.localStorage.removeItem('currency')
        BranchHelper.saveBranch(null)
        JwtService.destroyToken()
        context.dispatch('nullifyOpenedVisit')
        context.dispatch('nullifySelectedDoctors')
        context.dispatch('dataTableLogout')
    },
    init(context) {
        return new Promise((resolve, reject) => {
            UsersService.init(state.superadmin).then(data => {
                let d = data.data
                context.commit('setAuth', d)
                context.commit('setMyOpenedVisit', d.my_opened_visit)
                saveCalendarSettings(context, d)
                resolve(d)
            }).catch(err => {
                context.commit('setError', err.response ? err.response.data.errors : {})
                reject(err.response ? err.response.data.errors : {})
            })
        })
    },
    saveData(context, params) {
        return new Promise((resolve, reject) => {
            ClinicService.saveData(params).then(data => {
                let d = data.data
                context.commit('setClinic', d)
                saveCalendarSettings(context, d)
                resolve(d)
            }).catch(err => {
                context.commit('setError', err.response.data.errors)
                reject(err.response.data.errors)
            })
        })
    },
    getTasks(context, params = { loading: false }) {
        return new Promise((resolve, reject) => {
            EloquentService.dataTable('task',1, params, params.loading).then(data => {
                let d = data.data.result.data
                context.commit('setTasks', d)
                resolve(d)
            }).catch(err => {
                reject(err.response.data.errors)
            })
        })
    },
    async getClientSources(context) {
        const sources = await PatientService.getClientSources()
        context.commit('setClientSources', sources.data)
    },
    async getAdmins(context) {
        if(!context.state.admins.length) {
            const data = await ClinicService.getAdmins()
            context.commit('setAdmins', data.data)
        }
    }
}

function saveCalendarSettings(context, data = null) {
    if(data.clinic) {
        let clinic = data.clinic
        context.commit('setTimeStep', parseInt(clinic.calendar_step))
        context.commit('setTimeFromHour', parseInt(clinic.time_start))
        // context.commit('setTimeToHour', parseInt(clinic.time_end))
        let timeToHour = parseInt(clinic.time_end) + 1
        if(clinic.time_end.substr(clinic.time_end.length - 5) === '00:00') timeToHour--
        context.commit('setTimeToHour', timeToHour)
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}
