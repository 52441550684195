// import { serviceCategoryAdapter } from "@/adapters/services/serviceCategory"

export default {
    primaryKey: "id",
    model: 'price',
    order: 'id',
    sort: 'asc',
    modaltitle: 'price_price',
    // adapter: serviceCategoryAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_category',
    actions: [
        "add",
        "edit",
        "delete"
    ],
    rights: {
        edit: "clinic_services",
        delete: "clinic_services",
    },
    actionscomponent: 'ServicesDatatableActionsPrice',
    // trcomponent: "ServicesDatatableTRServiceCategory",
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: false
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: false,
            filter: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'active',
            title: 'active',
            table: true,
            sortable: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: 'ActiveTD'
        }
    ]
}
