<template>
  <div>
    <div>

      <ServiceMultiSelect :label="$t('kits')"
                          :dblclick="true"
                          model="kit"
                          :readonly="editDisabled"
                          @select="addedPackage(...arguments)"
                          @remove="removedPackage(...arguments)"
                          v-model="tmpPackages"/>
      <div v-if="packages && packages.length > 0" class="text-muted small">{{ $t('packages_cost') }}: {{ packagesCost | formatCurrency }}</div>
    </div>
    <div v-if="!materialsBindedToPackages">
      <table class="table-blue mt-3" v-if="tmpPackages && tmpPackages.length > 0">
          <thead>
          <tr>
            <th>{{ $t('material') }}</th>
            <th class="nowrap">{{ $t('amount') }}</th>
            <th class="nowrap">{{ $t('unit_of_measure') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="pack in tmpPackages">
            <tr v-for="m in pack.materials" :key="`pack-${pack.id}-${m.id}`">
              <td :data-title="$t('material')" class="order-mob-0">{{ m.name }}</td>
              <td :data-title="$t('amount')">{{ m.pivot.amount }}</td>
              <td>{{ units[m.unit_id] }}</td>
            </tr>

          </template>
          </tbody>
      </table>
    </div>
    <div class="mt-4">
      <!--      <label class="form-label">{{ $t('materials') }}</label>-->
      <b-button class="btn-collapse"
                v-b-toggle="`service-collapse-${serviceid}`">
        {{ materialsBindedToPackages ? $t('materials') : $t('additional_materials') }}
      </b-button>
      <b-collapse class="mt-3" :id="`service-collapse-${serviceid}`">
        <div>
          <table class="table-blue" v-if="tmpMaterials && tmpMaterials.length > 0">
            <thead>
            <tr>
              <th>{{ $t('material') }}</th>
              <th>{{ $t('amount') }}</th>
              <th>{{ $t('unit_of_measure') }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(m, mind) in tmpMaterials"
                class="position-relative"
                :key="`material-${serviceid}-${m.material_id}-${mind}`">
              <td :data-title="$t('material')" class="order-mob-0">
                {{ m.name }}
              </td>
              <td :data-title="$t('amount')">
                <input class="form-control sm"
                       :readonly="editDisabled"
                       v-model="m.pivot.amount"/>
              </td>
              <td :data-title="$t('unit_of_measure')">
                {{ units[m.unit_id] }}
              </td>
              <td class="diagnosis-delete-td">
                <a class="pointer" v-if="!editDisabled" @click="deleteMaterial(mind)">
                  <TrashSvg/>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <Autocomplete :placeholder="$t('add_material')+'...'"
                      v-if="!editDisabled"
                      model="material"
                      class="template-like mt-4"
                      @input="newMaterialFound(...arguments)"/>
      </b-collapse>
    </div>
  </div>
</template>

<script>

import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import Autocomplete from "@/components/parts/general/Autocomplete"
import TrashSvg from "@/assets/svg/general/trash.svg"
import {mapState} from "vuex"

export default {
  name: "VisitPackagesAndMaterials",
  components: {
    ServiceMultiSelect,
    Autocomplete,
    TrashSvg
  },
  props: {
    // packages: {},
    // materials: {},
    serviceid: {},
    materialsBindedToPackages: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    ...mapState({
      units: state => state.auth.units,
      packages: state => state.treatment.packages,
      materials: state => state.treatment.materials,
      rights: state => state.auth.rights,
    }),
    packagesCost() {
      return this.packages ? this.packages.reduce((acc, cur) => acc + cur.cost, 0) : 0
    },
    serviceMaterials() {
      if(!this.serviceid && this.materials) return this.materials // for service create card
      return this.materials && this.materials[this.serviceid] ? this.materials[this.serviceid] : []
    },
    servicePackages() {
      if(!this.serviceid && this.packages) return this.packages // for service create card
      return this.packages && this.packages[this.serviceid] ? this.packages[this.serviceid] : []
    },
    hasRightToEditMaterials() {
      return this.rights.includes('treatment_materials')
    },
    editDisabled() {
      return this.readonly || !this.hasRightToEditMaterials
    }
  },
  data() {
    return {
      tmpPackages: this.copyObject(this.servicePackages),
      tmpMaterials: this.copyObject(this.serviceMaterials),
      loopTimer: null,
      avoidLoop: false
    }
  },
  methods: {
    newMaterialFound(material) {
      material.pivot = { amount: 1 }
      material.name = material.title
      material.material_id = material.id
      this.addedPackage(null, [material])
    },
    addedPackage(pack = null, materials = [], mutateTmp = false) {
      if(mutateTmp) this.tmpPackages.push(pack)
      if(!this.materialsBindedToPackages && pack) return
      let newMaterials = this.copyObject(pack ? pack.materials : materials)
      if(!this.tmpMaterials) this.tmpMaterials = []
      newMaterials.forEach(toAdd => {
        let found = false
        this.tmpMaterials.forEach(current => {
          if(!found) {
            if(current.material_id == toAdd.material_id) {
              current.pivot.amount = parseInt(current.pivot.amount) + parseInt(toAdd.pivot.amount)
              found = true
              this.$emit('versionChange')
            }
          }
        })
        if(!found) {
          this.tmpMaterials.push(toAdd)
        }
      })
    },
    removedPackage(pack, mutateTmp = false) {
      if(mutateTmp) {
        const index = this.tmpPackages.findIndex(obj => obj.id === pack.id)
        if(index !== -1) this.tmpPackages.splice(index, 1)
      }
      if(!this.materialsBindedToPackages) return
      this.$store.dispatch('removePackageMaterialsFromService', {
        pack: this.copyObject(pack),
        serviceId: this.serviceid
      })
    },
    deleteMaterial(index) {
      this.tmpMaterials.splice(index, 1)
    },
    removedMaterial(material) {
      const index = this.tmpMaterials.findIndex(obj => obj.material_id === material.material_id)
      if(index !== -1) this.deleteMaterial(index)
    },
    launchLoop() {
      this.avoidLoop = true
      if(this.loopTimer) clearTimeout(this.loopTimer)
      this.loopTimer = setTimeout(() => this.avoidLoop = false, 20)
    },
    materialAmountChanged(material) {
      const index = this.tmpMaterials.findIndex(obj => obj.material_id === material.material_id)
      if(index !== -1) this.tmpMaterials[index].pivot.amount = material.pivot.amount
    }
  },
  watch: {
    servicePackages: {
      handler(val) {
        if(!this.avoidLoop) {
          this.tmpPackages = this.copyObject(val)
        }
      },
      immediate: true,
    },
    serviceMaterials: {
      handler(val) {
        if(!this.avoidLoop) {
          this.tmpMaterials = this.copyObject(val)
        }
      },
      immediate: true,
      deep: true,
    },
    tmpPackages: {
      handler(val) {
        this.launchLoop()
        let packages = this.copyObject(this.packages)
        if(this.serviceid) {
          packages[this.serviceid] = val
        } else {
          packages = val // for service create card
        }
        this.$store.commit('setPackages', packages)
        this.$emit('packages', val)
      },
      deep: true,
    },
    tmpMaterials: {
      handler(val) {
        this.launchLoop()
        let materials = this.copyObject(this.materials)
        if(this.serviceid) {
          materials[this.serviceid] = val
        } else {
          materials = val // for service create card
        }
        // materials[this.serviceid] = val
        this.$store.commit('setMaterials', materials)
        this.$emit('materials', val)
      },
      deep: true,
    }
  }
}
</script>

<style scoped>

</style>
