<template>
  <div class="variable-input">
    <component v-if="field.form.component"
               :is="formcomponents[field.form.component]"
               :field="field"
               :object="object"
               :required="required"
               v-model="object[field.id]"
               :selectOptions="selectOptions"
               :selectModels="selectModels">
    </component>
    <InputGroup v-else-if="['text','number','color','password'].includes(type)"
                :label="$t(field.title)"
                :type="type"
                :readonly="field.form.readonly"
                :required="required"
                @enter="$emit('enter')"
                v-model="object[field.id]"/>
    <CheckboxGroup v-else-if="type === 'checkbox'"
                   :label="$t(field.title)"
                   :fid="`checkbox-${field.id}`"
                   :required="required"
                   v-model="object[field.id]"/>
    <FileGroup v-else-if="type === 'file'"
               :label="$t(field.title)"
               :fid="`file-${field.id}`"
               :deletefile="!!field.form.deletefile"
               :sizelimit="field.form.sizelimit ? field.form.sizelimit : 10"
               :multiple="field.form.multiple"
               :filetype="field.form.filetype"
               :required="required"
               :readonly="readonly"
               v-model="object[field.id]"/>
    <DateGroup v-else-if="type === 'date'"
               :label="$t(field.title)"
               :required="required"
               v-model="object[field.id]"/>
    <DateTimeGroup v-else-if="type === 'datetime'"
                   :showlabel="false"
                   :label="$t(field.title)"
                   :required="required"
                   v-model="object[field.id]"/>
    <TimeGroup v-else-if="type === 'time'"
               :label="$t(field.title)"
               :required="required"
               v-model="object[field.id]"/>
    <AutosizeTextArea v-else-if="type === 'textarea'"
                      :label="$t(field.title)"
                      :max-length="field.form.max_length"
                      :required="required"
                      v-model="object[field.id]"/>
    <SelectGroup v-else-if="type === 'select' && (field.form.select_type === 'list' || selectOptions.length > 0) && !field.form.multiple"
                 :options="field.form.select_type === 'list' ? Object.values(field.form.options) : selectOptions"
                 variant="white"
                 :title="field.form.select_data && field.form.select_data.title ? field.form.select_data.title : null"
                 :label="$t(field.title)"
                 :required="required"
                 :readonly="readonly"
                 :translate="field.form.translate !== false"
                 :nullOption="!field.form.hideNullOption"
                 :showlabel="true"
                 v-model="object[field.id]"/>
    <ServiceMultiSelect v-else-if="type === 'select' && (field.form.select_type === 'list' || selectOptions.length > 0) && field.form.multiple"
                        :label="$t(field.title)"
                        :dblclick="false"
                        :multiple="true"
                        :prepopulated-options="field.form.select_type === 'list' ? Object.values(field.form.options) : selectOptions"
                        :title="field.form.select_data.title ? field.form.select_data.title : 'title'"
                        :model="field.form.model"
                        :dbsearch="false"
                        v-model="object[field.id]"/>
    <CurrencyJsonGroup v-else-if="['currency_json'].includes(type)"
                       :label="$t(field.title)"
                       :readonly="field.form.readonly"
                       :required="required"
                       @enter="$emit('enter')"
                       v-model="object[field.id]"/>
    <ServiceMultiSelect v-else-if="['autocomplete'].includes(type)"
                        :label="$t(field.title)"
                        :dblclick="false"
                        :multiple="false"
                        :title="field.form.autocomplete_title ? field.form.autocomplete_title : 'name'"
                        :model="field.form.model"
                        :dbsearch="true"
                        v-model="object[field.id]"/>
    <div v-else-if="type === 'separator'" class="separator"></div>
    <div v-if="field.form.note" class="note-div text-muted small">{{ field.form.noteDoNotTransate ? field.form.note : $t(field.form.note) }}</div>
  </div>
</template>

<script>

import InputGroup from "@/components/form/InputGroup"
import SelectGroup from "@/components/form/SelectGroup"
import DateGroup from "@/components/form/DateGroup"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import FileGroup from "@/components/form/FileGroup"
import TimeGroup from "@/components/form/TimeGroup"
import CurrencyJsonGroup from "@/components/form/CurrencyJsonGroup"
import Autocomplete from "@/components/parts/general/Autocomplete"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import DateTimeGroup from "@/components/form/DateTimeGroup"

export default {
  name: "VariableInput",
  components: {
    DateTimeGroup,
    ServiceMultiSelect,
    Autocomplete,
    CurrencyJsonGroup,
    TimeGroup,
    AutosizeTextArea,
    SelectGroup,
    InputGroup,
    DateGroup,
    FileGroup,
    CheckboxGroup,
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    object: {
      type: Object,
    },
    selectModels: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  watch: {
  },
  computed: {
    fieldval() {
      return this.object[this.field.id]
    },
    type() {
      return this.field.form.type
    },
    selectOptions() {
      if(!this.selectModels || !this.selectModels[this.field.id]) {
        return []
      }
      return this.selectModels[this.field.id]
    },
    required() {
      return this.field.form.required ? this.field.form.required : false
    },
    readonly() {
      return this.field.form.disabled_on_edit && this.edit
    },
    formcomponents () {
      const components = require.context(`@/components/parts/datatable/form/`, true, /\.vue$/i)
      let arr = {}
      components.keys().map(x => {
        arr[x.split('/').pop().split('.')[0]] = components(x).default
      })
      return arr
    },
  }
}
</script>

<style scoped lang="scss">
.separator {
  height: 1px;
  background: #E0E7EE;
  width: 100%;
  margin: 15px 0;
}
.note-div {
  position: relative;
  top: -12px;
}
</style>
