<template>
  <div>
    <div class="d-inline-block navigator">
      <div class="form-control gray-input p-0 d-flex align-items-center justify-content-center">
        <button class="date-switcher"  @click="previousWeek" :disabled="startDate <= limitForStartDate">
          <LeftSvg/>
        </button>
        {{ startDate | formatDate }} - {{ endDate | formatDate }}
        <button class="date-switcher" @click="nextWeek">
          <RightSvg/>
        </button>
      </div>
    </div>
    <div class="doctor-schedule d-inline-block">
      <div v-for="(d, dind) in daysArray" :key="`d-${dind}`" class="day-row position-relative">
        <div class="day">
          <div class="day-num">{{ d | formatDate('DD') }}</div>
          <div>{{ d | formatDate('dd') }}</div>
        </div>
        <div class="position-relative blocks-wrapper">
          <template v-for="(h, hind) in hoursArray" >
            <div class="hour-block"
                 @click="hourClicked(d, h)"
                 :class="{ last: h === 23, clickable: !($options.filters.formatDate(d, 'YYYY-MM-DD') in schedule) && (d > now) }"
                 v-if="h <= 23"
                 :key="`h-${dind}-${hind}`"></div>
          </template>
          <span v-if="$options.filters.formatDate(d, 'YYYY-MM-DD') in schedule">
            <template v-for="(t, tind) in schedule[$options.filters.formatDate(d, 'YYYY-MM-DD')]">
            <div class="time-slot"
                 :key="`k-${t.id}-${tind}`"
                 v-b-popover.hover.html="generatePopoverString(d, t)"
                 @click="timeSlotPressed(d, t)"
                 :style="`left: ${getBlockLeft(t)}%; width: ${getBlockWidth(t)}%`">
            </div>
              </template>
          </span>
          <!--          <div class="time-slot"-->
          <!--               v-b-popover.hover.html="generatePopoverString(d)"-->
          <!--               @click="timeSlotPressed(d, schedule[$options.filters.formatDate(d, 'YYYY-MM-DD')])"-->
          <!--               v-if="$options.filters.formatDate(d, 'YYYY-MM-DD') in schedule && getBlockWidth(d) > 0"-->
          <!--               :style="`left: ${getBlockLeft(d)}%; width: ${getBlockWidth(d)}%`">-->
          <!--          </div>-->

        </div>
      </div>
      <div class="day-row">
        <div class="day">
        </div>
        <div class="position-relative blocks-wrapper">
          <div class="hour-label" v-for="(h, hind) in hoursArray" :key="`hl-${hind}`">{{ h.toString().padStart(2, '0') }}:00</div>
        </div>
      </div>
    </div>
    <DoctorDayEditModal/>
  </div>
</template>

<script>

import {mapState} from "vuex"
import moment from "moment"
import { DoctorService } from "@/services/api.service"
import RightSvg from '@/assets/svg/general/arrow_right.svg'
import LeftSvg from '@/assets/svg/general/arrow_left.svg'
import DoctorDayEditModal from "@/components/parts/staff/DoctorDayEditModal"

export default {
  name: "DoctorSchedule",
  components: {
    DoctorDayEditModal,
    LeftSvg,
    RightSvg
  },
  metaInfo() {
    return {
      title: this.$t("schedule"),
      meta: []
    }
  },
  data() {
    return {
      startDate: '',
      schedule: {},
      limitForStartDate: '',
    }
  },
  computed: {
    ...mapState({
      doctor:  state => state.doctor.doctor,
      counter: state => state.datatable.dataTableCounter,
      branch_id: state => state.auth.branch,
    }),
    endDate() {
      return this.startDate ? this.startDate.clone().add(6, 'days') : null
    },
    daysArray() {
      if(!this.startDate || !this.endDate) { return [] }
      let arr = []
      let tmpDate = this.startDate.clone().subtract(1, 'days')
      while(tmpDate.add(1, 'days').diff(this.endDate.clone().add(1, 'days')) < 0) {
        arr.push(tmpDate.clone().toDate())
      }
      return arr
    },
    hoursArray() {
      return [...Array(25).keys()]
    },
    now() {
      return moment()
    }
  },
  methods: {
    getSchedule() {
      DoctorService.doctorsSchedule(this.doctor.id, this.branch_id).then(res => {
        this.schedule = res.data
      })
    },
    // getBlockLeft(d) {
    //   let date = this.$options.filters.formatDate(d, 'YYYY-MM-DD')
    //   let from = this.schedule[date].time_from
    //   let hours = this.timeToHoursNumber(from)
    //   return (hours / 24) * 100
    // },
    // getBlockWidth(d) {
    //   let date = this.$options.filters.formatDate(d, 'YYYY-MM-DD')
    //   let from = this.schedule[date].time_from
    //   let to = this.schedule[date].time_to
    //   let hours_from = this.timeToHoursNumber(from)
    //   let hours_to = this.timeToHoursNumber(to)
    //   return ((hours_to - hours_from) / 24) * 100
    // },
    getBlockLeft(timeSlot) {
      // let date = this.$options.filters.formatDate(d, 'YYYY-MM-DD')
      let hours = this.timeToHoursNumber(timeSlot.time_from)
      return (hours / 24) * 100
    },
    getBlockWidth(timeSlot) {
      // let date = this.$options.filters.formatDate(d, 'YYYY-MM-DD')
      // let from = this.schedule[date].time_from
      // let to = this.schedule[date].time_to
      let hours_from = this.timeToHoursNumber(timeSlot.time_from)
      let hours_to = this.timeToHoursNumber(timeSlot.time_to)
      return ((hours_to - hours_from) / 24) * 100
    },
    timeToHoursNumber(timeString) {
      return parseInt(timeString.substr(0, 2)) + parseInt(timeString.substr(3, 2)) / 60
    },
    nextWeek() {
      this.startDate = this.startDate.clone().add(7, 'days')
    },
    previousWeek() {
      if(this.startDate <= this.limitForStartDate) return
      this.startDate = this.startDate.clone().subtract(7, 'days')
    },
    timeSlotPressed(day, config) {
      this.$store.commit('setDoctor', this.copyObject(this.doctor))
      this.$store.commit('setDoctorScheduleDay', day)
      this.$store.commit('setDoctorPreconfig', [config])
      this.$nextTick(() => {
        this.$bvModal.show('doctor-day-edit-modal')
      })
    },
    hourClicked(day, hour) {
      if(day < moment()) return
      hour = hour.toString().padStart(2, '0')
      this.$store.commit('setDoctorScheduleDay', day)
      this.$store.commit('setDoctorPreconfig', [{
        day: moment(day).format('YYYY-MM-DD'),
        time_from: hour + ':00',
        time_to: hour + ':00',
        doctor_id: this.doctor.id,
        doctor: this.doctor
      }])
      this.$nextTick(() => {
        this.$bvModal.show('doctor-day-edit-modal')
      })
    },
    generatePopoverString(d, conf) {
      // let date = moment(d).format('YYYY-MM-DD')
      // let conf = this.schedule[date]
      let str = ''
      // str += moment(d).format('DD.MM.YYYY')
      str += '<b>'
      str += moment(d).format('dddd')
      // str += '<br>'
      str += ', '
      str += conf.time_from.substr(0,5)
      str += ' - '
      str += conf.time_to.substr(0,5)
      str += '</b>'
      return str
    }
  },
  mounted() {
    var currentDate = moment()
    this.startDate = currentDate.clone().startOf('isoWeek')
    this.limitForStartDate = currentDate.clone().startOf('isoWeek')
    this.getSchedule()
  },
  watch: {
    counter() {
      this.getSchedule()
    }
  }
}
</script>

<style scoped lang="scss">
.doctor-schedule {
  overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
  .day {
    color: $gray-text;
    display: inline-block;
    //width: 50px;
    flex: 0 0 50px;
    font-size: 11px;
    .day-num {
      font-size: 13px;
    }
  }
  .hour-block, .hour-label {
    width: 42px;
    height: 42px;
    display: inline-block;
  }
  .hour-block {
    background: $light-back;
    border-left: 1px dashed $calendar-border-color;
    &.last {
      border-right: 1px dashed $calendar-border-color;
    }
    &.clickable {
      cursor: pointer;
      &:hover {
        background: $gray-btn;
      }
    }
  }
  .hour-label {
    text-align: left;
    text-indent: -12px;
    font-size: 12px;
    color: $gray-text;
  }
  .day-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3px;
  }
  .time-slot {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid $blue-color;
    background: rgba(7, 135, 201, 0.16);
    &:hover {
      background: rgba(7, 135, 201, 0.44);
    }
  }
  .blocks-wrapper {
    font-size: 0;
  }
}
.navigator {
  margin-bottom: 15px;
  .date-switcher {
    top: -1.5px;
    position: relative;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: transparent;
    &:disabled {
      opacity: 0.3;
    }
  }
}

</style>
