<template>
  <span :class="{ negative: object[field.id] < 0 }">
    <template v-if="field.form && field.form.type === 'select' && field.form.options">
      {{ field.form.translate === false ? selectOption : $t(selectOption) }}
    </template>
<!--    <template v-else-if="field.table_show">{{ object | nestedAttribute(field.table_show) }}</template>-->
    <span v-else-if="field.format_html" v-html="string"></span>
    <template v-else-if="field.format_number">{{ string | formatNumber(true) }}</template>
    <template v-else-if="field.format_currency">{{ string | formatCurrency }}</template>
    <template v-else-if="field.format_email"><a :href="`mailto:${object[field.id]}`">{{ string }}</a></template>
    <template v-else-if="field.format_phone"><a :href="`tel:${object[field.id]}`">{{ string | formatPhone }}</a></template>
    <template v-else-if="field.format_phonecall"><OutgoingCallElement :phone="object[field.id]"/></template>
    <template v-else-if="field.format_date">{{ string | formatDate }}</template>
    <template v-else-if="field.format_datetime">{{ string | formatDate('DD.MM.YYYY, HH:mm') }}</template>
    <template v-else-if="field.format_filesize">{{ string | formatFileSize }}</template>
    <template v-else-if="field.format_translate">{{ $t(string) }}</template>
    <template v-else-if="field.format_image"><a :href="string" target="_blank"><img class="admin-table-img" :src="string" alt=""/></a></template>
    <template v-else>{{ string }}</template>
  </span>
</template>

<script>

import OutgoingCallElement from "@/components/parts/patients/OutgoingCallElement.vue"

export default {
  name: "TDComponent",
  components: {OutgoingCallElement},
  props: {
    field: {
      type: Object
    },
    object: {
      type: Object
    },
  },
  computed: {
    string() {
      if(this.field.table_show) {
        let show = this.field.table_show
        if(!Array.isArray(this.field.table_show)) show = [show]
        let str = ''
        show.forEach((item, index) => {
          if(index > 0) str += ', '
          str += this.$options.filters.nestedAttribute(this.object, item)
        })
        return str
      }
      return this.object[this.field.id]
    },
    selectOption() {
      if(this.field.form && this.field.form.type === 'select' && this.field.form.options) {
        return this.field.form.options[this.object[this.field.id]] ? this.field.form.options[this.object[this.field.id]].title : this.object[this.field.id]
      }
      return ''
    }
  }
}
</script>
