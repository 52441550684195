export default {
    agreement_print: "Печать согласия",
    agreement_template: "Шаблон соглашения",
    agreements: "Соглашения",
    are_you_sure_you_want_to_delete_template: "Вы уверены, что хотите удалить шаблон?",
    bite_templates: "Прикус",
    choose_fields_for_autocomplete: "Выберите необходимые значения для автозаполнения:",
    complaints_templates: "Жалобы",
    consents: "Согласия",
    contracts: "Договора",
    contract_template: "Шаблон договора",
    development: "Развитие",
    development_templates: "Развитие",
    diseases: "Болезни",
    diseases_templates: "Болезни",
    document_template_word_file_note: "Вы можете прикрепить файл word в качестве шаблона. В файле word необходимо помечать тэги знаком $ и фигурными скобками и использовать только заглавные буквы. Например: ${FIO_CLIENT}.",
    full_treatment_template: "Шаблон лечения",
    general_template: "Шаблон",
    generate: "Сформировать",
    generated: "Сформирован",
    inspection_templates: "Осмотр",
    medication_templates: "Лечение",
    not_generated: "Не сформирован",
    objectively_templates: "Объективно",
    plansurvey_templates: "План обследования",
    plansurvey: "План обследования",
    plan_treatment_template: "Шаблон плана лечения",
    recommendations_templates: "Рекомендации",
    research: "Данные исследований",
    research_templates: "Данные исследований",
    signed: "Подписан",
    subjectively_templates: "Субъективно",
    teeth_medication: "Лечение",
    teeth_plansurvey: "План обследования",
    teeth_recommendations: "Рекомендации",
    upload_signed: "Загрузить подписанный",
    uploaded: "Загружен"
}
