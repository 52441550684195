<template>
  <span>
    <img class="img" v-if="img" :src="img"/>
    <a :title="$t('edit')"
       @click="editPlanTreatment"
       v-b-tooltip.hover
       class="data-table-action">
    <EditSvg/><span class="action-button-mob-title">{{ $t('edit') }}</span>
  </a>
    <a :title="$t('duplicate')"
       @click="copyPlanTreatment"
       v-b-tooltip.hover
       class="data-table-action">
    <CopySvg/><span class="action-button-mob-title">{{ $t('duplicate') }}</span>
  </a>
    <a :title="$t('refresh_prices')"
       @click="updatePlanTreatmentPrices"
       v-b-tooltip.hover
       class="data-table-action">
    <RefreshSvg/><span class="action-button-mob-title">{{ $t('refresh_prices') }}</span>
  </a>
    <a class="data-table-action"
       v-b-tooltip.hover
       :title="$t('print')">
      <b-dropdown right variant="link" no-caret>
        <template #button-content>
          <PrintSvg/><span class="action-button-mob-title">{{ $t('print') }}</span>
        </template>
        <a class="dropdown-item pointer" @click="printPlan">
          {{ $t('standard') }}
        </a>
        <a class="dropdown-item pointer" @click="printPlanGrouped">
          {{ $t('grouped') }}
        </a>
        <a class="dropdown-item pointer" @click="printPlanGroupedByTeeth">
          {{ $t('grouped_by_teeth') }}
        </a>
        <a class="dropdown-item pointer" @click="printPlanFact">
          {{ $t('plan_fact') }}
        </a>
        <a class="dropdown-item pointer" @click="printWithFormula">
          {{ $t('with_formula') }}
        </a>
         <a class="dropdown-item pointer" @click="printRouteList">
          {{ $t('route_list') }}
        </a>
        <div class="dropdown-divider"></div>
        <div class="dropdown-header font-weight-bold">{{  $t('by_complexes') }}</div>
        <a class="dropdown-item pointer"
           v-for="(complex, index) in object.visits"
           :key="`visit-${complex.id}`"
           @click="printPlanComplex(complex)">
          {{ complex.title ? complex.title : $t('complex')+' '+(index+1) }}
        </a>
      </b-dropdown>
    </a>
  </span>
</template>

<script>

import EditSvg from '@/assets/svg/general/edit.svg'
import PrintSvg from '@/assets/svg/general/print2.svg'
import CopySvg from '@/assets/svg/patients/copy2.svg'
import RefreshSvg from '@/assets/svg/general/refresh2.svg'
import JwtService from "@/services/jwt.service"
import {mapState} from "vuex"
import {PatientService, CalendarService} from "@/services/api.service"

export default {
  name: "PatientDatatablePlanTreatmentActions",
  components: {
    EditSvg,
    PrintSvg,
    CopySvg,
    RefreshSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    }),
  },
  data() {
    return {
      img: null
    }
  },
  methods: {
    editPlanTreatment() {
      this.$store.commit('setVisit', this.copyObject(this.object))
      this.$bvModal.show('plan-treatment-modal')
    },
    copyPlanTreatment() {
      CalendarService.duplicatePlanTreatment(this.object.planning_id).then(() => {
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
      }).catch((e) => { console.log(e) })

    },
    updatePlanTreatmentPrices() {
      CalendarService.updatePlanTreatmentPrices(this.object.planning_id).then(() => {
        this.$noty.info(this.$t('success_message'))
        // this.$store.commit('incrementDataTableCounter')
      }).catch((e) => { console.log(e) })
    },
    printPlan() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.object.planning_id, '_blank')
    },
    printPlanComplex(complex) {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.object.planning_id+'?complexId='+complex.id, '_blank')
    },
    printPlanGrouped() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.object.planning_id+'?grouped=1', '_blank')
    },
    printPlanGroupedByTeeth() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.object.planning_id+'?grouped=1&by_teeth=1', '_blank')
    },
    printPlanFact() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.object.planning_id+'?planfact=1', '_blank')
    },
    async printWithFormula() {
      let el = document.getElementById('patientFormulaSelectedView')
      const options = {
        type: 'dataURL',
        allowTaint: true,
        onclone: function(doc) {
          let hiddenDiv = doc.getElementById('patientFormula')
          hiddenDiv.style.display = 'block'
        }
      }
      const style = document.createElement('style') // this style addition is a hack to keep the styling, otherwise some paddings appear and image is cropped
      document.head.appendChild(style)
      style.sheet?.insertRule('body > div:last-child img { display: inline-block; }')
      let output = await this.$html2canvas(el, options)
      style.remove()
      let res = await PatientService.sendFormulaImage(this.object.client_id, output)
      if(res.data) {
        let link = JwtService.getPrintPrelink(this.clinic)
        window.open(link+'/plan/'+this.object.planning_id+'?formula=1', '_blank')
      }
    },
    printRouteList() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.object.planning_id+'?route_list=1', '_blank')
    }
  }
}
</script>

<style scoped>

</style>
