<template>
  <div class="position-relative">
    <a ref="rangeBtn" href="#" @click="showPopup" class="d-inline-block mr-3 current-view-range">
      {{ calendarDays }} {{ $t(dayTitle(calendarDays)) }} <Arrow />
    </a>
    <div v-if="popupShowed" class="view-range-selector view-dd-selector position-absolute"
         v-closable="{
            exclude: ['rangeBtn'],
            handler: 'onClose'
        }">
      <a href="#"
         size="sm"
         v-for="d in days"
         :key="`day-${d}`"
         @click="clickDay(d)">
        {{ d }} {{ $t(dayTitle(d)) }}
      </a>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import Arrow from '@/assets/svg/calendar/arrow.svg'
import {Closable} from "@/components/pages/calendar/calendar/directives/CloseOutside";

export default {
  name: "DDaysSwitcher",
  directives: {
    Closable
  },
  components: {
    Arrow
  },
  data() {
    return {
      popupShowed: false,
      days: [
        1,2,3,5,7,10
      ]
    }
  },
  computed: {
    ...mapState({
      calendarDays: state => state.calendar.calendarDays,
    }),
  },
  watch: {},
  methods: {
    ...mapActions([
      'changeCalendarDays'
    ]),
    showPopup() {
      this.popupShowed = !this.popupShowed
    },
    onClose() {
      this.popupShowed = false
    },
    clickDay(day) {
      this.popupShowed = false
      this.$nextTick(() => {
        this.changeCalendarDays(day)
      })
    },
    dayTitle(day) {
      if(day === 1) return 'day'
      if(day < 5) return 'of_day'
      return 'days'

    }
  },
}
</script>

<style scoped lang="scss">
.view-range-selector {
  top:30px;
  min-width: 150px;
  padding:10px 0;
}
.view-range-selector a {
  font-size: 16px;
  display: block;
  padding: 9px 20px;
  color: #5D6D7C;
}
.view-range-selector a:hover {
  background-color: #F3F3F3;
  text-decoration: none;
}
a {
  text-transform: lowercase;
}
@media screen and (max-width: 768px) {
  .view-range-selector {
    top: 25px;
    padding: 7px 0;
    a {
      font-size: 13px;
      padding: 5px 15px;
    }
  }
}
</style>
