<template>
  <div class="pb-5">
    <div v-if="object && object.id">
      <b-row class="pt-2 document-pretable">
        <DocumentItemCol label="type"
                         :title="object.title"/>
        <DocumentItemCol label="warehouse"
                         :title="object.warehouse.title"/>
        <DocumentItemCol label="created"
                         v-if="object.user"
                         :title="object.user.full_name"/>
        <DocumentItemCol label="created"
                         :title="created_at"/>
        <DocumentItemCol label="status"
                         :title="$t(object.status)"/>
        <DocumentItemCol label="payment"
                         v-if="payMethod"
                         :title="payMethod"/>
        <DocumentItemCol label="comment"
                         v-if="object.comment"
                         :title="object.comment"/>
        <DocumentItemCol label="supplier"
                         v-if="object.supplier"
                         :title="object.supplier.title"/>
        <DocumentItemCol label="approved"
                         v-if="object.approved_user"
                         :title="object.approved_user.full_name"/>
        <DocumentItemCol label="approved"
                         v-if="approved_at"
                         :title="approved_at"/>
        <DocumentItemCol label="destination"
                         v-if="object.related && object.related.warehouse"
                         :title="object.related.warehouse.title"/>
      </b-row>
      <div class="data-table-wrapper pt-3">
        <table class="table data-table"
               v-if="object.items_with_materials.length && type != 'inventarization'">
          <thead>
          <tr>
            <th>Id</th>
            <th>{{ $t('title') }}</th>
            <th>{{ $t('barcode') }}</th>
            <th>{{ $t('artikul') }}</th>
            <th>{{ $t('unit_of_measure') }}</th>
            <th>{{ $t('amount') }}</th>
            <th>{{ $t('price') }}</th>
            <th>{{ $t('sum') }}</th>
            <th v-if="editable"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(i, ind) in object.items_with_materials"
              :key="`op-item-${ind}`">
            <td :data-title="$t('id')">{{ i.id }}</td>
            <td :data-title="$t('name')" class="order-mob-0">{{ i.material.name }}</td>
            <td :data-title="$t('barcode')">{{ i.material.barcode }}</td>
            <td :data-title="$t('artikul')">{{ i.material.artikul }}</td>
            <td :data-title="$t('unit_of_measure')">{{ i.material.unit ? i.material.unit.title : units[i.material.unit_id] }}</td>
            <td :data-title="$t('amount')">
              <input v-if="editable"
                     @blur="changeFinish()"
                     @change="inputChanged = true"
                     v-model="i.readable_amount"
                     class="form-control"/>
              <span v-else>{{ i.readable_amount }}</span>
            </td>
            <td :data-title="$t('price')">
              <input v-if="editable"
                     @blur="changeFinish()"
                     @change="inputChanged = true"
                     v-model="i.price"
                     class="form-control"/>
              <span v-else>{{ i.price | formatNumber }}</span>
            </td>
            <td :data-title="$t('total')">{{ (i.price * i.readable_amount) | formatCurrency }}</td>
            <td v-if="editable" :data-title="$t('delete')">
              <a class="pointer" @click="deleteItem(ind)"><DeleteSvg/></a>
            </td>
            <MobileMoreArrow/>
          </tr>
          <tr class="font-weight-bold">
            <td colspan="7">{{ $t('total') }}</td>
            <td :colspan="editable ? 2 : 1">{{ total_sum | formatCurrency }}</td>
          </tr>
          </tbody>
        </table>

        <table class="table data-table" v-if="inventarizationItems.length">
          <thead>
          <tr>
            <th>{{ $t('title') }}</th>
            <th>{{ $t('barcode') }}</th>
            <th>{{ $t('artikul') }}</th>
            <th>{{ $t('factual_amount') }}</th>
            <th>{{ $t('warehouse_amount') }}</th>
            <th>{{ $t('difference') }}</th>
            <th v-if="editable"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(i, ind) in inventarizationItems"
              :key="`inv-item-${ind}`">
            <td :data-title="$t('name')" class="order-mob-0">{{ i.material.name }}</td>
            <td :data-title="$t('barcode')">{{ i.material.barcode }}</td>
            <td :data-title="$t('artikul')">{{ i.material.artikul }}</td>
            <td :data-title="$t('factual_amount')">
              <input class="form-control"
                     type="number"
                     @change="save(false)"
                     :readonly="!editable"
                     v-model="i.counted_amount"/>
            </td>
            <td :data-title="$t('warehouse_amount')">{{ i.warehouse_amount }}</td>
            <td :data-title="$t('difference')"><span :class="{ 'text-danger': invDifference(i) != 0 }">{{ invDifference(i) }}</span></td>
            <td v-if="editable"
                :data-title="$t('delete')">
              <a class="pointer" @click="deleteInvItem(ind)"><DeleteSvg/></a>
            </td>
            <MobileMoreArrow/>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th>{{ $t('total') }}</th>
            <th colspan="2"></th>
            <th>{{ inventarizationTotals.counted_amount }}</th>
            <th>{{ inventarizationTotals.warehouse_amount }}</th>
            <th><span :class="{ 'text-danger': inventarizationTotalDifference != 0 }">{{ inventarizationTotalDifference }}</span></th>
            <th></th>
          </tr>
          </tfoot>
        </table>

        <div v-if="type === 'inventarization'"
             class="mb-3">
          <b-dropdown variant="link"
                      :text="$t('bulk_addition')"
                      toggle-class="btn-themed btn-themed-outline btn-themed-squared px-4 d-inline-block">
            <b-dropdown-item @click="addAllMaterialsForInventarization()"
                             link-class="font-weight-bold">
              {{ $t('add_all_materials_from_warehouse') }}
            </b-dropdown-item>
            <b-dropdown-divider/>
            <b-dropdown-group :header="$t('by_categories')">
              <b-dropdown-item v-for="category in categories"
                               :key="`cat-${category.id}`"
                               @click="addAllMaterialsForInventarization(category.id)">
                {{ category.title }}
              </b-dropdown-item>
            </b-dropdown-group>
          </b-dropdown>
        </div>
        <b-row v-if="editable" class="no-print row-narrow">
          <b-col col md="auto">
            <Autocomplete :placeholder="$t('enter_title_or_barcode')"
                          model="material"
                          class="autocomplete-operation-material"
                          v-model="autocompleteMaterial"/>
          </b-col>
          <b-col md cols="auto">
            <button @click="save"
                    :disabled="loading"
                    class="btn-themed btn-themed-outline btn-themed-squared d-inline-block">
              {{ $t('save') }}
            </button>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import DocumentItemCol from "@/components/parts/warehouse/DocumentItemCol"
import moment from "moment"
import Autocomplete from "@/components/parts/general/Autocomplete"
import DeleteSvg from '@/assets/svg/general/delete.svg'
import MobileMoreArrow from "@/components/parts/datatable/MobileMoreArrow"
import {WarehouseService} from "@/services/api.service"

export default {
  name: "DocumentItem",
  components: {
    MobileMoreArrow,
    Autocomplete,
    DocumentItemCol,
    DeleteSvg
  },
  metaInfo() {
    return {
      title: "Документ #"+(this.object ? this.object.id : ''),
      meta: []
    }
  },
  async created() {
    await this.$store.dispatch('getDocument', this.$route.params.id)
    if(this.type === 'inventarization') {
      this.categories = (await WarehouseService.getCategoriesWithMaterials())?.data || []
    }

  },
  data() {
    return {
      autocompleteMaterial: null,
      inputChanged: false,
      categories: []
    }
  },
  computed: {
    ...mapState({
      object: state => state.warehouseoperation.operation,
      loading: state => state.dom.loading,
      counter: state => state.datatable.dataTableCounter,
      units: state => state.auth.units,
      pay_methods: state => state.auth.pay_methods
    }),
    type() {
      return this.object?.operation_type || ''
    },
    inventarizationItems() {
      return this.object?.warehouse_inventarization_sheets || []
    },
    inventarizationTotals() {
      return {
        warehouse_amount: this.object?.warehouse_inventarization_sheets.reduce((acc, curr) => acc + Number(curr.warehouse_amount), 0),
        counted_amount: this.object?.warehouse_inventarization_sheets.reduce((acc, curr) => acc + Number(curr.counted_amount), 0),
      }
    },
    inventarizationTotalDifference() {
      return this.inventarizationTotals.counted_amount - this.inventarizationTotals.warehouse_amount
    },
    created_at() {
      return this.object.date_timezone ? moment(this.object.date_timezone).format('DD.MM.YYYY, HH:mm') : '';
    },
    approved_at() {
      return this.object.approved_timezone ? moment(this.object.approved_timezone).format('DD.MM.YYYY, HH:mm') : '';
    },
    editable() {
      return this.object && this.object.status == 'draft' && this.type != 'movement_to'
    },
    total_sum() {
      return this.object.items_with_materials.reduce((acc, curr) => acc + (curr.readable_amount * curr.price), 0)
    },
    payMethod() {
      if(!this.object.forma) return null
      const pm = this.pay_methods.find(p => p.id === Number(this.object.forma))
      return pm ? pm.title : null
    }
  },
  methods: {
    deleteItem(index) {
      this.$store.dispatch('deleteDocumentItem', index)
    },
    deleteInvItem(index) {
      this.$store.dispatch('deleteInventarizationItem', index)
    },
    async save(message = true) {
      if(this.loading) return
      await this.$store.dispatch('saveDocument')
      if(message) this.$noty.info(this.$t('success_message'))
      this.inputChanged = false
    },
    changeFinish() {
      if(this.inputChanged) {
        this.save(false)
      }
    },
    async addAllMaterialsForInventarization(categoryId = null) {
      await this.$store.dispatch('fillInventarization', { categoryId })
    },
    invDifference(i) {
      return Number(i.counted_amount) - Number(i.warehouse_amount)
    }
  },
  watch: {
    autocompleteMaterial(val) {
      if(val) {
        if(this.type === 'inventarization') {
          this.$store.dispatch('addInventarizationItem', {
            material_id: val.id,
          })
          this.autocompleteMaterial = null
          return
        }
        this.$store.dispatch('addDocumentItem', {
          material_id: val.id,
          price: val.price,
          amount: 1,
        })
      }
    },
    counter() {
      this.$store.dispatch('getDocument', this.$route.params.id)
    }
  },
  destroyed() {
    this.$store.commit('setOperation', null)
  }
}
</script>

<style scoped lang="scss">
.document-pretable {
  font-size: 14px;
}
.data-table-wrapper {
  input.form-control {
    max-width: 100px;
    height: 32px;
  }
  td, th {
    vertical-align: middle;
  }
}
.btn-themed {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
@media screen and (max-width: 768px) {
  .table th {
    border-top: none;
  }
}
</style>
