<template>
  <div class="mt-3 pb-4">
    <vue-excel-xlsx
        ref="excel"
        class="d-none"
        :data="excelData"
        :columns="columns"
        :filename="'report'"
        :sheetname="'report'">
    </vue-excel-xlsx>
    <div v-if="settings && loaded" class="mb-4">
      <div class="mb-3">
        <span v-if="isNonTechnicView">{{ $t('rate') }}: {{ settings.rate | formatCurrency }}</span>
        <div v-if="isAssistantView && settings.rate_per_day" class="d-inline-block px-4">|</div>
        <span v-if="isAssistantView && settings.rate_per_day">{{ $t('rate_per_day') }}: {{ settings.rate_per_day | formatCurrency }}</span>
        <div v-if="isNonTechnicView" class="d-inline-block px-4">|</div>
        {{ $t('general_percent') }}: {{ settings.percent | formatPercent }}
      </div>
      <b-row>
        <b-col cols="auto" v-if="isNonTechnicView">
          <div>
            <div class="text-muted">{{ $t('salary_accrual_for_personnel') }}</div>
            {{ $t(salaryTypes[settings.doctor_salaries_type].title) }}
          </div>
        </b-col>
        <b-col cols="auto">
          <div>
            <div class="text-muted">{{ $t('salary_accrual_for_technics') }}</div>
            {{ $t(salaryTypes[settings.technic_salaries_type].title) }}
          </div>
        </b-col>
        <b-col cols="auto" v-if="isNonTechnicView">
          <div>
            <div class="text-muted">{{ $t('percent_accrual_for_personnel') }}</div>
            {{ $t(percentTypes[settings.percent_accrual].title) }}
          </div>
        </b-col>
        <b-col cols="auto">
          <div>
            <div class="text-muted">{{ $t('salary_accrual') }}</div>
            {{ $t(salaryAccrualTypes[settings.salary_accrual].title) }}
          </div>
        </b-col>
        <b-col cols="auto" v-if="usesBonuses">
          <div>
            <div class="text-muted">{{ $t('bonus_payment') }}</div>
            {{ $t(bonusUseTypes[settings.use_bonuses_on_salary].title) }}
          </div>
        </b-col>
      </b-row>

    </div>
    <div v-if="loaded">
      <div v-for="(r, rind) in rows" :key="`sal-row-${rind}`">
        <div class="data-table-wrapper">
          <table class="table data-table more-button">
            <thead>
            <tr>
              <th>{{ $t('visit_date') }}</th>
              <th>{{ $t('patient') }}</th>
              <th>{{ $t('service') }}</th>
              <th>{{ $t('cost') }}</th>
              <th v-if="onlyPaid">{{ $t('payments') }}</th>
              <th v-if="onlyPaid && deductsAcquiringFromSalaries">{{ $t('acquiring_fee') }}</th>
              <th>{{ $t('technic_percent') }}</th>
              <th>{{ $t('technic_sum') }}</th>
              <th v-if="isDoctorView">{{ $t('doctor_percent') }}</th>
              <th v-if="isDoctorView">{{ $t('doctor_sum') }}</th>
              <th v-if="isAssistantView">{{ $t('assistant_percent') }}</th>
              <th v-if="isAssistantView">{{ $t('assistant_sum') }}</th>
              <th v-if="isAdministratorView">{{ $t('administrator_percent') }}</th>
              <th v-if="isAdministratorView">{{ $t('administrator_sum') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(s, sind) in r.services" :key="`service-${r.id}-${sind}`">
              <td v-if="sind === 0" :rowspan="r.services.length">{{ r.date }}</td>
              <td v-if="sind === 0" :rowspan="r.services.length">{{ r.patient }}</td>
              <td>{{ s.name }}</td>
              <td>{{ s.price | formatCurrency }}</td>
              <td v-if="sind === 0 && onlyPaid" :rowspan="r.services.length">
                <div v-for="(p, pind) in r.payments" :key="`pay-${p.id}`" :class="{'mt-3': pind > 0 }">
                  <div class="text-muted small">{{ p.date_timezone | formatDateTime }}</div>
                  <div>{{ p.paid | formatCurrency }}</div>
                </div>
                <div class="mt-2 font-weight-bold">
                  <div class="text-muted small">{{ $t('total') }}</div>
                  {{ sumPayments(r.payments) | formatCurrency }} ({{ getPayCovered(r) }}%)
                </div>
              </td>
              <td v-if="sind === 0 && onlyPaid && deductsAcquiringFromSalaries" :rowspan="r.services.length">
                {{ r.acquiring_fee | formatCurrency }}
              </td>
              <td>{{ s.technic_percent | formatPercent }}</td>
              <td>{{ s.technic_sum | formatCurrency }}</td>
              <td v-if="isNonTechnicView">{{ s.doctor_percent | formatPercent }}</td>
              <td v-if="isNonTechnicView">{{ s.doctor_sum | formatCurrency }}</td>
            </tr>
            <tr class="font-weight-bold">
              <td colspan="5"></td>
              <td v-if="onlyPaid" :colspan="deductsAcquiringFromSalaries ? 2 : 1"></td>
              <td>{{ r.total_technic | formatCurrency }}</td>
              <td v-if="isNonTechnicView"></td>
              <td v-if="isNonTechnicView">{{ r.total_doctor | formatCurrency }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="total > 0" class="font-weight-bold text-right">
        {{ $t('total') }}: {{ total | formatCurrency }}
      </div>
    </div>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import { salaryTypes, percentTypes, salaryAccrualTypes, bonusUseTypes } from "@/dictionaries/dictionary"
import {mapGetters, mapState} from "vuex"

export default {
  name: "ReportsSalaries",
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    oneDoctor: {
      type: Number
    },
    oneTechnic: {},
    oneAssistant: {},
    oneAdministrator: {},
    diagnoses: {
      type: Array
    },
    selectedBranches: {}
  },
  metaInfo() {
    return {
      title: this.$t('salaries'),
      meta: []
    }
  },
  data() {
    return {
      salaryTypes,
      percentTypes,
      salaryAccrualTypes,
      bonusUseTypes,
      onlyPaid: false,
      rows: [],
      settings: null,
      loaded: false,
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('salaries', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
        doctor: this.oneDoctor ? this.oneDoctor : null,
        technic: this.oneTechnic ? this.oneTechnic.id : null,
        assistant: this.oneAssistant ? this.oneAssistant.id : null,
        administrator: this.oneAdministrator ? this.oneAdministrator.id : null,
        view: this.view
      }).then(res => {
        this.rows = res.data.data
        this.settings = res.data.settings
        this.onlyPaid = res.data.onlyPaid
        this.loaded = true
      })
    },
    sumPayments(payments) {
      return payments.reduce((acc, cur) => acc + parseFloat(cur.paid), 0)
    },
    getPayCovered(r) {
      return r.record_price > 0 ? (Math.round((this.sumPayments(r.payments) / r.record_price) * 10000) / 100) : 0
    },
    excel() {
      this.$refs.excel.$el.click()
    }
  },
  computed: {
    ...mapGetters(['usesBonuses', 'deductsAcquiringFromSalaries']),
    ...mapState({
      dictionaryDoctors: state => state.auth.doctors,
    }),
    total() {
      let field = this.view === 'technic' ? 'total_technic' : 'total_doctor'
      return this.rows ? this.rows.reduce((acc, cur) => acc + cur[field], 0) : 0
    },
    view() {
      if(this.oneTechnic) return 'technic'
      if(this.oneAssistant) return 'assistant'
      if(this.oneAdministrator) return 'administrator'
      return 'doctor'
    },
    isDoctorView() {
      return this.view === 'doctor'
    },
    isAssistantView() {
      return this.view === 'assistant'
    },
    isAdministratorView() {
      return this.view === 'administrator'
    },
    isNonTechnicView() {
      return this.isDoctorView || this.isAssistantView || this.isAdministratorView
    },
    doctorModel() {
      if(!this.isDoctorView) return null
      return this.dictionaryDoctors.find(doctor => parseInt(doctor.id) === parseInt(this.oneDoctor))
    },
    selectedId() {
      if(this.isDoctorView) return this.oneDoctor
      if(this.isAssistantView) return this.oneAssistant.id
      if(this.isAdministratorView) return this.oneAdministrator.id
      return this.oneTechnic ? this.oneTechnic.id : null
    },
    columns() {
      let arr = [
        {
          field: 'date',
          label: this.$t('date'),
        },
        {
          field: 'name',
          label: this.$t('patient'),
        },
        {
          field: 'service',
          label: this.$t('service'),
        },
        {
          field: 'price',
          label: this.$t('price'),
        },
      ]
      if(this.onlyPaid) {
        arr.push({
          field: 'paid',
          label: this.$t('paid'),
        })
        arr.push({
          field: 'acquiring_fee',
          label: this.$t('acquiring_fee'),
        })
      }
      arr.push({
        field: 'technic_percent',
        label: this.$t('technic_percent'),
      })
      arr.push({
        field: 'technic_sum',
        label: this.$t('technic_sum'),
      })
      if(this.isDoctorView) {
        arr.push({
          field: 'doctor_percent',
          label: this.$t('doctor_percent'),
        })
        arr.push({
          field: 'doctor_sum',
          label: this.$t('doctor_sum'),
        })
        arr.push({
          field: 'doctor_name',
          label: this.$t('doctor_name'),
        })
      }
      if(this.isAssistantView) {
        arr.push({
          field: 'doctor_percent',
          label: this.$t('assistant_percent'),
        })
        arr.push({
          field: 'doctor_sum',
          label: this.$t('assistant_sum'),
        })
      }
      if(this.isAdministratorView) {
        arr.push({
          field: 'doctor_percent',
          label: this.$t('administrator_percent'),
        })
        arr.push({
          field: 'doctor_sum',
          label: this.$t('administrator_sum'),
        })
      }
      return arr
    },
    excelData() {
      let arr = []
      let totals = {
        paid: 0,
        price: 0,
        technic_sum: 0,
        doctor_sum: 0
      }
      if(!this.rows) return arr
      this.rows.forEach(row => {
        row.services.forEach(service => {
          let data = {
            date: row.date,
            name: row.patient,
            service: service.name,
            price: Number(service.price),
            technic_percent: service.technic_percent,
            technic_sum: service.technic_sum,
          }
          totals.technic_sum += service.technic_sum
          totals.price += Number(service.price)
          if(this.onlyPaid) {
            const paid = (this.getPayCovered(row) * Number(service.price)) / 100
            data.paid = paid
            totals.paid += paid
          }
          if(this.isNonTechnicView) {
            data.doctor_percent = service.doctor_percent
            data.doctor_sum = service.doctor_sum
            data.doctor_name = this.doctorModel?.full_name || ''
            totals.doctor_sum += service.doctor_sum
          }
          arr.push(data)
        })
      })
      arr.push(totals)
      return arr
    }
  },
  watch: {
    selectedId() {
      this.loaded = false
    }
  }
}
</script>
