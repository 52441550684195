<template>
  <span>
  <a :title="$t('movements')"
     v-b-tooltip.hover
     @click="openLink"
     class="tdclickable data-table-action">
    <BoxSvg/><span class="action-button-mob-title">{{ $t('movements') }}</span>
  </a>
    <a :title="$t('activate')"
       v-if="object.flag === 'not_active'"
       v-b-tooltip.hover
       @click="activate"
       class="tdclickable data-table-action">
    <ActivateSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('activate') }}</span>
  </a>
  </span>
</template>

<script>

import BoxSvg from '@/assets/svg/warehouse/box.svg'
import ActivateSvg from '@/assets/svg/form/tick.svg'
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"
import { EloquentService } from "@/services/api.service"
import { materialAdapter } from "@/adapters/warehouse/material"

export default {
  name: "WarehouseDatatableActionsMaterialStock",
  components: {
    BoxSvg,
    ActivateSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  methods: {
    openLink() {
      let warehouseId = ''
      try {
        const filters = JSON.parse(this.$route.query?.filters || "{}")
        if(filters.warehouse_id && filters.warehouse_id.type === 'equals') {
          warehouseId = filters.warehouse_id.condition
        }
      } catch (e) { console.log(e) }
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(`${link}/material/${this.object.material_id}?warehouseId=${warehouseId}`, '_blank')
    },
    activate() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, materialAdapter(this.object))
      formData.append('flag', 'active')
      EloquentService.update('materialstock',this.object.material_id, formData).then(() => {
        this.$store.commit('incrementDataTableCounter')
      })
    }
  },
}
</script>
