export default {
    accepted_by: "Kim tomonidan qabul qilindi",
    additional_income: "Qo‘shimcha daromad",
    additional_costs: "Xarajatlar",
    admin_statistics_future: "Kelgusi sanalar uchun yangi yozuvlar haqida administrator statistikasi",
    administrator_percent: "Administrator ulushi",
    administrator_sum: "Administrator miqdori",
    apply: "Qo‘llash",
    assistant_percent: "Yordamchi foiz",
    assistant_sum: "Yordamchi miqdori",
    average_cheque: "O‘rtacha chek",
    balance_amount: "Depozitdan to‘landi",
    balance_return: "Depozitni qaytarish",
    by_means: "To‘lov shakli bo‘yicha",
    by_services: "Xizmatlar bo‘yicha",
    came_among_patients: "Ulardan kelishdi",
    cancel_date: "Bekor qilish sanasi",
    cancel_reason: "Bekor qilish sababi",
    cancelled_visits: "Bekor qilingan tashriflar",
    cashdesk_report: "Kassa hisoboti",
    cash_flow_statement: "Pul mablag‘lari harakati to‘g‘risidagi hisobot",
    cos: "Tannarx",
    costs: "Xarajatlar",
    cost_of_materials: "Materiallar qiymati",
    crm_leads_analytics: "CRM arizalari analitikasi",
    daily_report: "Kun bo‘yicha hisobot",
    debt_payments: "Qarzlarni to‘lash",
    debt_writeoff: "Qarzlarni hisobdan chiqarish",
    debtor: "Qarzdor",
    debtors: "Qarzdorlar",
    debtors_statement: "Qarzdorlar to‘g‘risida hisobot",
    details_by_visits: "Tashriflar haqida tafsilotlar",
    did_not_come_among_patients: "Ulardan kelmadi",
    doctor_percent: "Shifokor foizi",
    doctor_load_percent: "Shifokor yuki %",
    doctor_name: "Shifokor",
    doctor_salary: "Shifokor ish haqi",
    doctor_statistics: "Shifokorlar bo‘yicha statistika",
    doctor_sum: "Shifokor summasi",
    doctors_load: "Shifokorlarni yuklash",
    duration: "Davomiylik",
    f057: "057 shakl",
    first_visit_date: "Birinchi tashrif sanasi",
    first_visit_doctor: "Birinchi tashrif shifokori",
    full_name: "Ismi",
    general_percent: "Umumiy foiz",
    hide_cancelled_visits: "Bekor qilingan tashriflarni yashirish",
    hidden_columns: "Yashirin ustunlar",
    income_by_doctors: "Shifokorlar bo‘yicha daromad",
    income_by_services: "Xizmatlar bo‘yicha daromad",
    initiator: "Tashabbuschi",
    invoiced: "Hisob-kitob qilish",
    last_visit_date: "Oxirgi tashrif sanasi",
    last_visit_doctor: "Oxirgi tashrif shifokori",
    last_visit_recommendation: "Oxirgi tashrif tavsiyalari",
    margins: "Yalpi daromad",
    next_visit_date: "Kelgusi tashrif sanasi",
    next_visit_doctor: "Kelgusi tashrif shifokori",
    operation_type: "Operasiya turi",
    orthodontist: "Ortodont",
    orthopedist: "Ortoped",
    paid_from_account: "Hisobdan to'langan",
    paid_invoices: "To‘langan hisoblar",
    patient_name: "Bemor",
    permanent_among_patients: "Ulardan doimiy bemorlar tashriflari",
    pl_statement: "Daromad va xarajatlar to‘g‘risidagi hisobot",
    plan_treatment_complexes: "Komplekslar",
    plan_treatments: "Davolanish rejasi",
    price_before_discount: "Chegirmagacha bo‘lgan summa",
    primary_among_patients: "Ulardan birinchi bemorlar tashriflari",
    primary_records: "Birinchi - yozilgan",
    primary_records_came: "Birinchi - kelgan",
    primary_records_further: "Birinchi - keyingi yozilgan",
    primary_records_revenue: "Birinchi - daromad",
    records: "Yozuvlar",
    records_count: "Tashriflar miqdori",
    repeated_records: "Takroriy - yozilgan",
    repeated_records_came: "Takroriy - kelgan",
    repeated_records_further: "Takroriy - keyingi yozilgan",
    repeated_records_revenue: "Takroriy - daromad",
    report_is_too_heavy_confirm: "Hisobot sahifada ko'rish uchun juda katta, hisobotni excel fayliga eksport qilasizmi?",
    revenue: "Daromad",
    revenue_per_hour: "Bir soatlik daromad",
    revenues: "Daromad",
    saldo: "Saldo",
    sales_funnel_applications: "Huni: arizalar va murojaatlar",
    sales_funnel_plans: "Huni: davolash rejalari",
    show_deleted_doctors: "Masofadagi shifokorlarni ko'rsating",
    show_only_new_patients: "Faqat yangi bemorlarni ko‘rsatish",
    technic_percent: "Tenika foizi",
    technic_sum: "Texnika summasi",
    therapist: "Terapevt",
    total_patients: "Ro‘yxatga olingan bemorlar soni",
    total_revenue: "Umumiy daromad",
    unpaid_invoices: "To‘lanmagan hisob-kitoblar",
    visit_id: "Yozuv Id",
    visit_statistics: "Tashriflar statistikasi",
    where_came_from: "Qayerdan kelgan",
    who_cancelled: "Kim bekor qildi",
    who_created: "Kim yaratgan",
    worked_hours: "Ishlangan soatlar",
}
