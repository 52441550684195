<template>
  <span>
    <a class="data-table-action"
       :title="$t('view')"
       v-b-tooltip.hover
       @click="show">
        <EyeSvg class="svg-light"/>
        <span class="action-button-mob-title">{{ $t('view') }}</span>
      </a>
    </span>
</template>

<script>

import EyeSvg from '@/assets/svg/form/eye.svg'
import {mapState} from "vuex"

export default {
  name: "CRMLeadDatatableActions",
  components: {
    EyeSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
  },
  methods: {
    show() {
      // let newObject = this.copyObject(this.object)
      this.$store.commit('setLead', this.copyObject(this.object))
      this.$bvModal.show('lead-show-modal')
      // this.$store.commit('setModalMode', 'show')
      // this.$store.commit('setViewModel', 'lead')
      // this.$store.commit('setModule', 'crm')
      // this.$store.commit('setSelectedModel', newObject)
      // this.$store.commit('setShowModal', true)
    },
  }
}
</script>

<style scoped>
.data-table-action svg {
  width: 16px;
  height: 16px;
}
</style>
