export default {
    add_task: "Добавить задачу",
    add_patient: "Добавить пациента",
    calls: "Звонки",
    clinic_id: "Id клиники",
    expenses: "Расходы",
    high: "Высокая",
    low: "Низкая",
    medium: "Средняя",
    no_notifications: "Уведомлений нет",
    tasks: "Задачи"
}
