<template>
  <a class="btn btn-plain" @click="addPatient">
    <AddSvg/>
    <span class="d-none d-xl-inline">{{ $t('add_patient') }}</span>
  </a>
</template>

<script>

import AddSvg from '@/assets/svg/header/adduser.svg'
import {mapState} from "vuex"

export default {
  name: "HeaderAddPatient",
  components: {
    AddSvg
  },
  computed: {
    ...mapState({
      branch: state => state.auth.branch,
    })
  },
  methods: {
    addPatient() {
      this.$store.commit('setViewModel', 'patient')
      this.$store.commit('setModule', 'patients')
      this.$store.commit('setSelectedModel', {
        branch_id: this.branch
      })
      this.$store.commit('setShowModal', true)
    }
  }
}
</script>

<style scoped>

</style>
