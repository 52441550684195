<template>
  <a @click="activateService"
     :title="$t('activate')"
     v-if="rights.includes('clinic_services')"
     v-b-tooltip.hover
     class="tdclickable data-table-action">
    <RefreshSvg/><span class="action-button-mob-title">{{ $t('activate') }}</span>
  </a>
</template>

<script>

import RefreshSvg from '@/assets/svg/general/refresh2.svg'
import  { EloquentService } from "@/services/api.service"
import { serviceAdapter } from '@/adapters/services/service'
import {mapState} from "vuex"

export default {
  name: "ServicesDatatableActionsArchive",
  components: {
    RefreshSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights
    })
  },
  methods: {
    activateService() {
      let formData = new FormData()
      this.object.visible = 1
      formData = this.appendFormdata(formData, serviceAdapter(this.object))
      EloquentService.update('service',this.object.id, formData).then(() => {
        this.$store.commit('incrementDataTableCounter')
      })
    }
  }
}
</script>

<style scoped>

</style>
