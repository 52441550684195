export default {
    accountingmonth: "Buxgalteriya oyi",
    accrual: "Hisoblangan summa",
    accruals: "Hisoblangan summa",
    AQSI_account: "AQSI hisob",
    balance_end: "Davr oxiridagi qoldiq",
    balance_start: "Davr boshidagi qoldiq",
    bonus_movement: "Bonus harakati",
    cash_movements: "Pul mablag‘lari harakati",
    cash_received: "Qabul qilingan pul mablag‘lari",
    closed: "Yopiq",
    communal: "Kommunal to‘lovlar",
    deduction: "Ushlab turish",
    deposit_balance: "Depozit qoldig‘i",
    deposit_balance_end: "Davr oxiridagi depozit qoldig‘i",
    deposit_balance_start: "Davr boshidagi depozit qoldig‘i",
    deposit_movement: "Depozit bo‘yicha harakatlar",
    employee: "Xodim",
    expense: "Xarajat",
    left_to_pay: "To‘lov uchun qolgan",
    manual_adjustments: "Qo'lda sozlash",
    materials_expense: "Sarflanadigan materiallar",
    open: "Ochish",
    other_expense: "Boshqa xarajatlar",
    other_income: "Boshqa daromadlar",
    pay_out: "To‘lash",
    payment_sum: "To‘lash uchun summa",
    profit: "Daromad",
    recalculate: "Qayta hisoblash",
    rent: "Ijara",
    salaries: "Ish haqi",
    unclosed: "Yopilmagan",
}
