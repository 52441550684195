import { render, staticRenderFns } from "./SuperAdminDatatableTDNewVersion.vue?vue&type=template&id=b3f56012&scoped=true&"
import script from "./SuperAdminDatatableTDNewVersion.vue?vue&type=script&lang=js&"
export * from "./SuperAdminDatatableTDNewVersion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3f56012",
  null
  
)

export default component.exports