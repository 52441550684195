import general from './ru_general'
import header from './ru_header'
import sidebar from './ru_sidebar'
import calendar from './ru_calendar'
import warehouses from './ru_warehouses'
import patients from "./ru_patients"
import settings from "./ru_settings"
import services from "./ru_services"
import staff from "./ru_staff"
import accounting from "./ru_accounting"
import reports from "./ru_reports"
import templates from "./ru_templates"
import cashdesk from "./ru_cashdesk"
import planning from "./ru_planning"
import crm from "./ru_crm"
import egisz from "./ru_egisz"
import marketing from "./ru_marketing"
import nontranslated from "./ru_nontranslated"

export default {
    ...general,
    ...header,
    ...sidebar,
    ...calendar,
    ...warehouses,
    ...patients,
    ...services,
    ...staff,
    ...accounting,
    ...reports,
    ...templates,
    ...cashdesk,
    ...planning,
    ...settings,
    ...crm,
    ...egisz,
    ...marketing,
    ...nontranslated
}
