<template>
<div>
  <b-modal id="popup-modal"
           v-if="popup"
           size="md"
           title=""
           hide-header
           centered>
    <div class="">
      <div class="text-right pb-1">
        <a class="close-svg" @click="$bvModal.hide('popup-modal')"><CloseSvg/></a>
      </div>
      <div v-if="popup.image" class="mb-3 text-center">
        <img :src="popup.image" class="popup-img" alt=""/>
      </div>
      <div v-if="popup.youtubeUrl" class="mt-3">
        <iframe :src="popup.youtubeUrl" allowfullscreen></iframe>
      </div>
      <article v-if="popup.text" class="mt-3">{{ popup.text }}</article>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons w-100">
        <b-row class="no-gutters align-items-center justify-content-end">
          <b-col sm="auto" cols="6" order-sm="0" order="1">
            <b-button class="cancel w-auto px-md-3 px-2 sec-button see-later"
                      variant="themed"
                      @click="close()">
              {{ $t('see_later') }}
            </b-button>    
          </b-col>
          <b-col sm="auto" cols="6" order-sm="1" order="2">
            <b-button class="cancel ml-sm-3 w-auto px-md-3 px-2 sec-button do-not-show"
                      variant="themed"
                      @click="doNotShowAnymore">
              {{ $t('do_not_show_anymore') }}
            </b-button>
          </b-col>
          <b-col sm="auto" cols="12" order-sm="2" order="0" class="mb-sm-0 mb-2"> 
            <a v-if="popup.url"
               :href="popup.url"
               @click="doNotShowAnymore"
               target="_blank"
               class="ml-sm-3 ml-0 btn-themed btn px-md-3 px-2 w-auto main-button">
              {{ popup.button_title ? popup.button_title : $t('follow') }}
            </a>
          </b-col>
        </b-row>
        
        
        
      </div>
    </template>
  </b-modal>
</div>
</template>

<script>
import {ClinicService} from "@/services/api.service"
import CloseSvg from '@/assets/svg/general/close.svg'

export default {
  name: "Popup",
  components: {
    CloseSvg
  },
  data() {
    return {
      popup: null  
    }
  },
  mounted() {
    this.checkPopup()
  },
  methods: {
    checkPopup() {
      ClinicService.getPopup().then(res => {
        if(res.data && res.data.id) {
          const alreadyWatched = window.localStorage.getItem(`viewedPopup${res.data.id}`) ?? false
          if(!alreadyWatched) {
            this.popup = res.data
            this.$nextTick(() => {
              this.$bvModal.show('popup-modal')
            })  
          }
        }
      })
    },
    doNotShowAnymore() {
      window.localStorage.setItem(`viewedPopup${this.popup.id}`, true)
      this.$bvModal.hide('popup-modal')
    }
  }
}
</script>

<style scoped lang="scss">
.popup-img {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
}
iframe {
  width: 100%;
  height: 350px;
}
.close-svg {
  svg {
    width: 16px;
    height: 16px;
    fill: $gray-text;
    cursor: pointer;
    opacity: 0.7;
  }
  &:hover {
    svg {
      opacity: 1;
    }
  }
}
@media screen and (max-width: 576px) {
  .main-button {
    width: 100% !important;
  }
  .sec-button {
    width: calc(100% - 5px) !important;
  }
  .see-later {
    margin-right: 5px;
  }
  .do-not-show {
    margin-left: 5px;
  }
}
</style>
