export default {
    apply_plan_treatment: "Davolash rejasini qo'llash",
    calls_planning: "Qo‘ng‘iroqlarni rejalashtirish",
    choose_criterias_for_fill: "To'ldirish uchun kerakli mezonlarni tanlang",
    comments: "Izohlar",
    grouped: "Guruhlangan",
    grouped_by_teeth: "Tishlar bo'yicha guruhlangan",
    last_update: "Oxirgi tahrirlangan",
    level_description: "Bosqich tavsifi",
    main_complaints: "Asosiy shikoyatlar",
    notification_date: "Bildirishnoma sanasi",
    plan: "Reja",
    plan_treatment_status: "Davolash rejasi holati",
    reset_all: "Hammasini tiklang",
    route_list: "Yo'nalish varaqasi",
    select_all: "Hammasini tanlang"
}
