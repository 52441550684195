<template>
  <span v-if="role === 'superadmin'">
    <router-link :title="$t('clinic')"
                 v-b-tooltip.hover
                 class="tdclickable data-table-action"
                 :to="{ name: 'SuperAdminClinicGeneral', params: { id: object.id } }">
      <ActivateSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('clinic') }}</span>
    </router-link>
    <a @click="changeStatus"
       :title="object.blocked ? $t('activate') : $t('block')"
       v-b-tooltip.hover
       class="tdclickable data-table-action">
    <RefreshSvg class="svg-light" v-if="object.blocked"/><BlockSvg v-if="!object.blocked"/><span class="action-button-mob-title">{{ $t('activate') }}</span>
  </a>
    </span>
</template>

<script>

import ActivateSvg from '@/assets/svg/header/note.svg'
import BlockSvg from '@/assets/svg/general/block.svg'
import RefreshSvg from '@/assets/svg/form/tick.svg'
import {SuperadminService} from "@/services/api.service"

export default {
  name: "SuperAdminClinicsActions",
  components: {
    ActivateSvg,
    RefreshSvg,
    BlockSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    role() {
      return this.$store.state.auth.user.role
    }
  },
  methods: {
    async changeStatus() {
      try {
        let res = await SuperadminService.toggleBlockedClinic(this.object.id, !this.object.blocked)
        if(res.data) this.$store.commit('incrementDataTableCounter')
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>

</style>
