<template>
  <div>
    {{  object.profession | oneLinerFromProperty('title') }}
  </div>
</template>

<script>

export default {
  name: "StaffDatatableTDProfession",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
