<template>
  <div>
    {{ admin ? admin.title : '' }}
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "CRMDatatableTDAdminName",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      admins: state => state.auth.admins,
    }),
    admin() {
      return this.admins.find(admin => admin.id === this.object.admin_id)
    }
  },
}
</script>
