<template>
  <div>
    <template v-if="object.clinic_tariff">
      <span v-if="object.clinic_tariff.isFree">Бесплатный</span>
      <span v-else-if="object.clinic_tariff.paid_till && object.clinic_tariff.paid_till >= today" class="text-primary">
        Оплачено до {{ object.clinic_tariff.paid_till | formatDate }}
      </span>
      <span v-else-if="object.clinic_tariff.paid_till && object.clinic_tariff.paid_till < today" class="text-warning">
        Оплата просрочена
      </span>
      <span v-else-if="!object.clinic_tariff.paid_till" class="text-danger">Не оплачен</span>
    </template>
    <span v-if="!object.clinic_tariff" class="text-muted">Не присвоен тариф</span>
  </div>
</template>

<script>

import moment from "moment/moment"

export default {
  name: "SuperAdminDatatableTDClinicPayStatus",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    today() {
      return moment().format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped>

</style>
