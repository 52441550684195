export const planTreatmentTemplateAdapter = (obj, servicesByTeeth = false) => ({
    title: obj.title ? obj.title : null,
    planned_treatment: obj.planned_treatment ? obj.planned_treatment : null,
    planned_result: obj.planned_result ? obj.planned_result : null,
    description: obj.description || null,
    date_to: obj.date_to || null,
    diagnoses: minimizeDiagnoses(obj.diagnoses),
    services: minimizeServices(obj.services, servicesByTeeth),
    complex_services: obj.complex_services ? minimizeDiagnoses(obj.complex_services) :  minimizeComplexServices(obj.complexServices),
})

function minimizeDiagnoses(diagnoses) {
    return diagnoses ? Object.values(diagnoses).map(({id}) => id) : []
}

function minimizeComplexServices(complexServices) {
    const arr = []
    if(!complexServices) return arr
    for(const tooth in complexServices) {
        complexServices[tooth].forEach(({id}) => {
            arr.push(id)
        })
    }
    return arr
}

function minimizeServices(services, servicesByTeeth = false) {
    if(!services) return []
    if(servicesByTeeth) {
        return Object.values(services).map(({id, teeth}) => {
            return {
                service_id: id,
                quantity: teeth.length,
            }
        })
    }
    services = [...new Map(services.map((item) => [item.id, item])).values()];
    return Object.values(services).map(({id, qty}) => {
        return {
            service_id: id,
            quantity: qty
        }
    })
}
