<template>
  <div class="form-group" ref="multiselect">
    <label class="form-label">
      <span>{{ label }}</span>
      <span v-if="required"> *</span>
    </label>
    <!--    <input-->
    <div class="position-relative">
      <div class="form-control tags-wrapper" :class="{ opened: dropdown }" @click="focus">
        <b-row no-gutters>
          <b-col>
        <span v-for="(o, oind) in byAmounts"
              class="tag"
              :key="`span-${oind}`"
              @dblclick="doubleClick(o)">
<!--          <span class="tag-title d-inline-block align-middle">{{ o.title }}</span>-->
          <span class="tag-title d-inline-block align-middle">{{ (o.cod ? o.cod+'. ' : '') + o.title }}</span>
            <div class="d-inline-block bg-white qty-input-wrapper">
              <input class="qty-input no-arrows-number-input"
                     type="number"
                     @click.stop
                     @blur="qtyChanged(o, o.qty)"
                     :key="`qty-${oind}-${version}`"
                     v-model="o.qty"/>
              <span class="text-black pr-2">{{ $t('un') }}</span>
            </div>
            <a class="pl-2 pointer d-inline-flex justify-content-center align-items-center align-middle" @click.stop="remove(o)">
              <CloseSvg/>
            </a>
          </span>
            <span v-if="!f || f.length === 0" class="text-muted opacity-50">{{ $t('search') }}</span>
          </b-col>
          <b-col cols="auto">
            <div class="pl-2 pr-1">
              <ArrowSvg class="arrow"/>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="dropdown"
           class="dropdown-search"
           v-click-outside="clickOutside">
        <input type="text"
               ref="searchinput"
               v-model="search"
               :class="{ noborder: !search && options.length === 0 }"
               :placeholder="$t('search')"/>
        <div class="scroll-part">
          <div v-for="(o, oind) in options"
               :key="`opt-${oind}`">
            <template v-if="model === 'service'">
            <div class="dropdown-category">{{ o.category }}</div>
            <div v-for="service in o.services"
                 class="dropdown-service"
                 :class="{ selected: f && f.find(x => x.id == service.id) }"
                 @click="select(service)"
                 :key="`opt-${oind}-${service.id}`">
              <!--              {{ service.title }}-->
              {{ (service.cod ? service.cod+'. ' : '') + service.title }}{{ service.price_p ? ` (${service.price_p})` : '' }}
            </div>
            </template>
            <template v-else>
              <div class="dropdown-service">{{ o.title }}</div>
            </template>
          </div>
          <div v-if="search && (!options || options.length === 0)" class="no-results text-muted">{{ $t('results_not_found') }}</div>
        </div>
      </div>
    </div>

    <!--    <multiselect :options="options"-->
    <!--                 :searchable="dbsearch"-->
    <!--                 v-model="f"-->
    <!--                 :label="title"-->
    <!--                 :placeholder="$t('search')"-->
    <!--                 selectLabel=""-->
    <!--                 selectedLabel=""-->
    <!--                 deselectLabel=""-->
    <!--                 :internalSearch="internalSearch"-->
    <!--                 :track-by="title"-->
    <!--                 @remove="remove"-->
    <!--                 :disabled="readonly"-->
    <!--                 @select="select"-->
    <!--                 :group-select="false"-->
    <!--                 :[groupLabel]="'category'"-->
    <!--                 :[groupValues]="'services'"-->
    <!--                 @open="serviceFind"-->
    <!--                 @search-change="serviceFind"-->
    <!--                 :multiple="multiple">-->
    <!--      <span slot="noResult">{{ $t('results_not_found') }}</span>-->
    <!--      <span slot="noOptions">{{ $t('results_not_found') }}</span>-->
    <!--    </multiselect>-->
  </div>
</template>

<script>

// import Multiselect from 'vue-multiselect'
import { EloquentService } from "@/services/api.service"
import {mapState} from "vuex"
import CloseSvg from '@/assets/svg/general/close_w.svg'
import ArrowSvg from '@/assets/svg/general/arrow_down.svg'

export default {
  name: "ServiceMultiSelectManual",
  components: {
    CloseSvg,
    ArrowSvg
    // Multiselect
  },
  props: {
    value: {},
    label: {},
    prepopulatedOptions: {},
    dbsearch: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: true
    },
    internalSearch: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: 'name'
    },
    model: {
      type: String,
      default: 'service'
    },
    dblclick: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      superadmin: state => state.auth.superadmin,
      clinic: state => state.auth.clinic,
      branch: state => state.auth.branch,
    }),
    groupLabel() {
      return this.model == 'service' ? 'group-label' : ''
    },
    groupValues() {
      return this.model == 'service' ? 'group-values' : ''
    },
    byAmounts() {
      if(!this.f) return []
      let arr = {}
      this.f.forEach(item => {
        if(arr[item.id]) {
          arr[item.id].qty++
          arr[item.id].qtyInit++
        } else {
          item.qty = 1
          item.qtyInit = 1
          arr[item.id] = item
        }
      })
      return Object.values(arr).sort((a, b) => {
        return a.order - b.order
      })
    }
  },
  data() {
    return {
      options: [],
      timer: null,
      f: [],
      dropdown: false,
      search: '',
      justOpened: false,
      version: 0
    }
  },
  methods: {
    serviceFind(query) {
      if(!this.dbsearch) return
      if(this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        EloquentService.autocomplete(this.model,
            query,
            40,
            true,
            this.superadmin, this.superadmin && this.clinic ? this.clinic.id : null,
            this.branch
        ).then(res => {
          this.options = res.data.result
        })
      }, 250)
    },
    // tagClick(e) {
    //   let node = e.target
    //   if(node && node.parentNode && node.parentNode.classList.contains('multiselect__tag')) {
    //     node = node.parentNode
    //     let parent = node.parentNode
    //     let index = Array.from(parent.children).indexOf(node)
    //     this.f.push(this.f[index])
    //     this.$emit('select',this.f[index])
    //   }
    // },
    remove(e) {
      this.$emit('remove',e)
    },
    select(e) {
      this.$emit('select',e)
    },
    focus() {
      if(this.dropdown) {
        this.dropdown = false
        return
      }
      this.justOpened = true
      this.dropdown = true
      this.search = ''
      this.$nextTick(() => {
        this.$refs.searchinput.focus()
        this.serviceFind('')
      })
      setTimeout(() => {
        this.justOpened = false
      }, 20)
    },
    clickOutside() {
      if(!this.justOpened) {
        this.dropdown = false
      }
    },
    doubleClick(e) {
      if(this.dblclick) {
        this.select(e)
      }
    },
    qtyChanged(service, qty) {
      let qInit = service.qtyInit
      qty = parseInt(qty)
      if(qty > qInit) {
        this.$emit('select', service, (qty - qInit))
        return
      }
      if(qInit > qty) {
        this.$emit('remove', service, (qInit - qty))
      }
    }
  },
  mounted() {
    this.f = this.value
    if(this.prepopulatedOptions) {
      this.options = this.prepopulatedOptions
    }
  },
  watch: {
    f(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.f = val
    },
    search(val) {
      this.serviceFind(val)
    }
  },
}
</script>

<style scoped lang="scss">
.tags-wrapper {
  padding: 7px 12px;
  min-height: 46px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.opened {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .arrow {
      transform: rotate(180deg);
    }
  }
  .arrow {
    position: relative;
    top: 1px;
    transition: all ease-out 0.15s;
  }
  .tag {
    margin-top: 2px;
    background: #0787c9;
    font-size: 13px;
    color: #fff;
    //padding: 4px 26px 4px 10px;
    padding: 2px 10px;
    border-radius: 5px;
    margin-right: 10px;
    display: inline-flex;
    margin-bottom: 2px;
    max-width: 100%;
    align-items: center;
    .qty-input-wrapper {
      margin-left: 5px;
    }
    .qty-input {
      width: 24px;
      border:none;
      height: 18px;
      text-align: center;
      outline: none;
      padding-right: 0;
    }
    .tag-title {
      white-space: nowrap;
      overflow: hidden;
      //max-width: calc(100% - 20px);
      max-width: 500px;
      text-overflow: ellipsis;
    }
  }
}
.dropdown-search {
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 100%;
  left: 0;
  background: #fff;
  border: 1px solid $gray-icon;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  .scroll-part {
    max-height: 40vh;
    overflow-y: scroll;
    .no-results {
      padding: 10px 10px;
    }
  }
  input {
    width: 100%;
    border: none;
    outline: none;
    padding: 10px 10px;
    border-bottom: 1px solid $gray-icon;
    &.noborder {
      border-bottom: none;
    }
  }
  .dropdown-category {
    padding: 3px 10px;
    background-color: $light-back;
    color: $gray-text;
    font-weight: bold;
  }
  .dropdown-service {
    padding: 3px 10px 3px 20px;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      background-color: $blue-color !important;
      color: #fff;
    }
    &.selected {
      //background-color: $light-back;
      font-weight: 600;
    }
  }
}

@media(max-width: 768px) {
  .tags-wrapper {
    padding: 2px 12px;
    min-height: 32px;
    .tag {
      font-size: 12px;
      padding: 1px 8px;
      .qty-input {
        width: 20px;
        margin-left: 4px;
        height: 15px;
      }
      .tag-title {
        max-width: 200px;
      }
    }
  }
}
@media(max-width: 575px) {
  .tags-wrapper {
    .tag {
      .tag-title {
        max-width: 136px;
      }
    }
  }
}
</style>
