export default {
    add_to_egisz: "Добавить в ЕГИСЗ",
    download_all: "Скачать все",
    egisz: "ЕГИСЗ",
    main_doctor: "Главный врач",
    patient_rejected_to_send_to_egisz: "Пациент отказался отправлять данные в ЕГИСЗ",
    requires_attention: "Требует внимания",
    representative_relation: "Кем является представитель пациенту",
    send_to_egisz: "Отправить в ЕГИСЗ",
    sent_to_egisz: "Отправлено в ЕГИСЗ",
    sign: "Подписать",
    sign_all: "Подписать все",
    signed_by_doctor: "Подписано врачом",
    signed_by_main_doctor: "Подписано глав. врачом",
    to_egisz_page: "На страницу ЕГИСЗ",
    to_rework: "На доработку",
    to_sign: "На подпись",
}
