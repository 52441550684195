<template>
  <div v-if="object.type">
    <WarningSvg/>
    <span class="ml-2 text-danger">{{ $t('important') }}</span>
  </div>
</template>

<script>

import WarningSvg from '@/assets/svg/general/warning.svg'

export default {
  name: "PatientsDatatableTDImportance",
  components: {
    WarningSvg
  },
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>