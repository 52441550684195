<template>
  <div>
    <AddObject module="settings"  btnclass="btn-themed-outline btn-themed-squared mt-2"/>
    <DataTable :options="options"
               class="mt-4"
               module="settings"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/settings/record_tag"
import AddObject from "@/components/parts/datatable/AddObject"

export default {
  name: "SettingsRecordTags",
  metaInfo() {
    return {
      title: this.$t('tags'),
      meta: []
    }
  },
  components: {
    AddObject,
    DataTable
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
