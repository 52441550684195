export const taskAdapter =
    ({
         id,
         title,
         description,
         importance,
         user,
         patient,
         deadline,
         start,
         files,
         phone,
         filesToDelete,
         task_type,
         group_id,
     }) => ({
        id: id ? id : null,
        title: title ?? null,
        description: description ?? null,
        importance: importance ?? null,
        user_id: user ? user.id : null,
        patient_id: patient ? patient.id : null,
        deadline: deadline ? deadline : null,
        start: start ? start : null,
        phone: phone ? phone : null,
        files: processFiles(files),
        filesToDelete: filesToDelete ?? null,
        task_type: task_type ? task_type : 'task',
        group_id: group_id ? group_id : null
    })


function processFiles(files) {
    let arr = []
    if(files) {
        files.forEach(file => {
            if(!file.id) {
                arr.push(file)
            }
        })
    }
    return arr
}

