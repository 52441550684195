import { fullTreatmentTemplateAdapter } from '@/adapters/templates/full_treatment_template'

export default {
    primaryKey: "id",
    model: 'full_treatment_template',
    order: 'id',
    sort: 'desc',
    modalsize: 'lg',
    adapter: fullTreatmentTemplateAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_template',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    formComponent: 'TemplateDatatableFormFullTreatmentTemplateForm',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'diagnoses',
            title: 'diagnoses',
            table: false,
            form: {
                component: "FullTreatmentTemplateDatatableFormDiagnoses"
            }
        },
    ]

}
