<template>
  <section id="api" class="api">
    <transition name="fade" mode="out-in">
      <div class="section-top-row" >
        <div class="row row-narrow align-items-center no-print position-relative">
          <div class="col-md col-12 order-md-0 order-0">
            <h1>{{ $t('api') }}</h1>
          </div>
        </div>
        <div style="max-width: 440px;">
          <div class="mt-4 mb-3">Cоздание доступа к партнерскому API. <a :href="apiDocumentationLink" target="_blank">Документация по API</a></div>
          <div v-if="!credentials">
            <InputGroup v-model="form.clinic_id"
                        type="number"
                        :label="$t('clinic_id')"/>
            <InputGroup v-model="form.login"
                        :label="$t('login')"/>
            <div class="mt-5">
              <button class="btn btn-themed btn-themed-squared"
                      :disabled="loading"
                      @click="generate">
                Создать
              </button>
            </div>
          </div>
          <div v-if="credentials">
            <div class="alert alert-warning mt-4">Скопируйте полученные данные, после закрытия страницы их невозможно будет просмотреть</div>
            <div>Логин: <span class="text-primary font-weight-bold">{{ credentials.login }}</span></div>
            <div>Пароль: <span class="text-primary font-weight-bold">{{ credentials.password }}</span></div>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import InputGroup from "@/components/form/InputGroup.vue"
import { SuperadminService } from "@/services/api.service"

export default {
  name: "SuperAdminAPI",
  components: {
    InputGroup
  },
  data() {
    return {
      form: {
        clinic_id: null,
        login: null,
        password: null
      },
      credentials: null,
      loading: false
    }
  },
  metaInfo() {
    return {
      title: this.$t('api'),
      meta: []
    }
  },
  computed: {
    apiDocumentationLink() {
      return `${process.env.VUE_APP_API_DOMAIN}/partner/docs`
    }
  },
  methods: {
    async generate() {
      this.loading = true
      try {
        const res = await SuperadminService.generateAPIAccess(this.form)
        this.credentials = res?.data || null
      } catch (e) {
        this.$noty.error(Object.values(e.response.data.errors).map(x => x[0]).join('\n'))
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

