import authorized from "@/middleware/authorized"
import paid from '@/middleware/paid'

import MarketingGeneral from "@/components/pages/marketing/MarketingGeneral"
import MarketingGroups from "@/components/pages/marketing/MarketingGroups"
import MarketingGroup from "@/components/pages/marketing/MarketingGroup"
import MarketingGroupAdd from "@/components/pages/marketing/MarketingGroupAdd"
import MarketingMailing from "@/components/pages/marketing/MarketingMailing"
import MarketingMailingItem from "@/components/pages/marketing/MarketingMailingItem"
import MarketingNotifications from "@/components/pages/marketing/MarketingNotifications"
import MarketingSettings from "@/components/pages/settings/SettingsMarketing"

export default {

    path: '/marketing',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['marketing'] },
    // component:() => import('../components/pages/cashdesk/CashdeskGeneral.vue'),
    component: MarketingGeneral,
    children: [
        { path: '', redirect: 'notifications' },
        {
            path: 'groups', name: "MarketingGroups",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['marketing'] },
            // component: () => import('../components/pages/settings/MarketingNotifications.vue')
            component: MarketingGroups
        },
        {
            path: 'groups/:id', name: "MarketingGroup",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['marketing'] },
            // component: () => import('../components/pages/settings/MarketingNotifications.vue')
            component: MarketingGroup
        },
        {
            path: 'groups/:id/add', name: "MarketingGroupAdd",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['marketing'] },
            // component: () => import('../components/pages/settings/MarketingNotifications.vue')
            component: MarketingGroupAdd
        },
        {
            path: 'mailing', name: "MarketingMailing",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['marketing'] },
            // component: () => import('../components/pages/settings/MarketingNotifications.vue')
            component: MarketingMailing
        },
        {
            path: 'mailing/:id', name: "MarketingMailingItem",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['marketing'] },
            // component: () => import('../components/pages/settings/MarketingNotifications.vue')
            component: MarketingMailingItem
        },
        {
            path: 'notifications', name: "MarketingNotifications",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['marketing'] },
            // component: () => import('../components/pages/settings/MarketingNotifications.vue')
            component: MarketingNotifications
        },
        {
            path: 'settings', name: "MarketingSettings",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['settings', 'marketing'] },
            component: MarketingSettings
        },
    ]

}
