<template>
  <b-modal id="plan-treatment-options-modal"
           size="lg"
           @hide="selectedOptions = {}"
           :title="$t('apply_plan_treatment')+':'"
           centered>
    <div v-if="complex">
      <h5 class="font-weight-bold text-capitalize">{{ complex.fullTitle }}</h5>
      <div class="mt-4">{{ $t('choose_criterias_for_fill') }}</div>
      <div class="mt-2">
        <a class="text-primary pointer"
           @click="selectAll">{{ $t('select_all') }}</a>
        <span class="text-muted opacity-50 mx-2">|</span>
        <a class="text-primary pointer"
           @click="resetAll">{{ $t('reset_all') }}</a>
      </div>
      <div>
        <template v-for="(c, cind) in complex.teethData">
          <div :key="`t-${cind}`"
               v-if="c.servicesQuantified && Object.keys(c.servicesQuantified).length"
               class="mt-4 border-bottom pb-3">
            <b-row>
              <b-col cols="auto">
                <ToothSvg/>
              </b-col>
              <b-col>
                <div>
                  <div class="checkbox form-group" :class="{ checked: selectedOptions[c.id] }">
                    <input type="checkbox"
                           :checked="selectedOptions[c.id]"
                           @input="toothToggle(c)"
                           :id="`tooth-${cind}`"/>
                    <label class="mb-0" :for="`tooth-${cind}`">{{ c.tooth }}</label>
                  </div>
                  <!--                <CheckboxGroup :fid="`tooth-${cind}`"-->
                  <!--                               :label="c.tooth"/>-->
                </div>
                <div>
                  <table class="table-blue">
                    <thead>
                    <tr>
                      <th>{{ $t('service') }}</th>
                      <th>{{ $t('plan') }}</th>
                      <th class="text-capitalize">{{ $t('applied') }}</th>
                      <th>{{ $t('apply') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(service, sind) in c.servicesQuantified" :key="`t-${cind}-${sind}`">
                      <td>
                        <div class="checkbox">
                          <input type="checkbox"
                                 :checked="selectedOptions[c.id] && selectedOptions[c.id].services[service.id]"
                                 :disabled="service.applied >= service.qty"
                                 @input="serviceToggle(c, service)"
                                 :id="`service-${cind}-${service.id}`"
                          />
                          <label class="mb-0 h-auto" 
                                 :for="`service-${cind}-${service.id}`">
                            {{ service.title }}
                          </label>
                        </div>
                      </td>
                      <td>{{ service.qty }}</td>
                      <td>{{ service.applied }}</td>
                      <td>
                        <SelectGroup :options="getNumberOptions((service.qty - service.applied))"
                                     class="sm mb-0"
                                     @input="amountSelected(c, service, ...arguments)"
                                     :translate="false"
                                     :value="selectedOptions[c.id] && selectedOptions[c.id].services[service.id] ? selectedOptions[c.id].services[service.id].amount : null"
                                     variant="white"/>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </div>
        </template>
      </div>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel mr-3 d-inline-block align-middle"
                  variant="themed"
                  @click="$parent.visit_id = $parent.object.visit_id; close()">
          {{ $t('cancel') }}
        </b-button>
        <button @click="save"
                :disabled="loading || Object.values(selectedOptions).length < 1"
                class="btn-themed align-middle btn-themed-squared">
          {{ $t('save') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import {mapState} from "vuex"
import SelectGroup from "@/components/form/SelectGroup"
import {PatientService} from "@/services/api.service"
import ToothSvg from '@/assets/svg/patients/tooth.svg'

export default {
  name: "PlanTreatmentOptionsModal",
  components: {
    SelectGroup,
    ToothSvg
  },
  props: {
    complex: {},
  },
  computed: {
    ...mapState({
      visit:  state => state.patient.visit,
      template:  state => state.patient.selectedTemplate,
      patient:  state => state.patient.patient,
      teeth:  state => state.teeth.recordTeeth,
      loading: state => state.dom.loading,
    })
  },
  data() {
    return {
      selectedOptions: {},
    }
  },
  methods: {
    async save() {
      await this.$parent.intermediateSave();
      // await this.$parent.save(false, false);
      let res = await PatientService.attachPlanWithOptions(this.patient.id, this.visit.id, this.complex.id, this.selectedOptions)
      this.$store.commit('setVisit', res.data)
      this.$bvModal.hide('plan-treatment-options-modal')
      this.$nextTick(() => {
        this.$emit('reload')
      })
    },
    amountSelected(tooth, service, amount) {
      if(!this.selectedOptions[tooth.id]) {
        this.$set(this.selectedOptions, tooth.id, {
          tooth: tooth,
          services: {},
        })
      }
      if(!amount || amount < 1) {
        this.removeService(tooth, service)
        return
      }
      this.$set(this.selectedOptions[tooth.id].services, service.id, {
        service: service,
        amount: amount
      })
    },
    selectAll() {
      this.resetAll()
      this.complex.teethData.forEach(tooth => {
        this.toothToggle(tooth)
      })
    },
    resetAll() {
      this.selectedOptions = {}
    },
    removeService(tooth, service) {
      this.$delete(this.selectedOptions[tooth.id].services, service.id)
      if(Object.keys(this.selectedOptions[tooth.id].services).length === 0) {
        this.$delete(this.selectedOptions, tooth.id)
      }
    },
    toothToggle(tooth) {
      if(this.selectedOptions[tooth.id]) {
        this.$delete(this.selectedOptions, tooth.id)
        return
      }
      for (const service of Object.values(tooth.servicesQuantified)) {
        this.serviceToggle(tooth, service)
      }
    },
    serviceToggle(tooth, service) {
      if(this.selectedOptions[tooth.id] && this.selectedOptions[tooth.id].services[service.id]) {
        this.removeService(tooth, service)
        return
      }
      this.amountSelected(tooth, service, (service.qty - service.applied))
    },
    getNumberOptions(num) {
      if(num <= 0) return []
      return [...Array(num).keys()].map((e,i) => {
        return { title: (i + 1), id: (i + 1) }
      });
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
h2 {

}
</style>
