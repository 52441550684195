<template>
  <header class="dentist-container header" id="header">
    <b-row no-gutters class="align-items-center">
      <b-col sm="4" md="4" lg="3" xl="3" class="d-none d-sm-block" order-sm="1" order-md="0">
        <SearchPatient class="ml-sm-3 ml-md-0"  v-if="!isPatient && isPaid"/>
      </b-col>
      <b-col sm="auto" cols="4" class="d-block d-md-none" order-md="1" order-sm="0">
        <MenuToggler view="header"/>
        <router-link to="/calendar">
          <img src="/images/general/logo_short.png" class="header__logo"/>
        </router-link>
      </b-col>
      <b-col sm md="8" lg="9" xl="9" cols="8" class="text-right" order="2">
        <div class="d-flex align-items-center justify-content-end pl-md-5">
          <HeaderAddPatient class="header__item" v-if="rights.includes('add_clients') && isPaid"/>
          <HeaderWaitingList class="header__item" v-if="rights.includes('waiting_list') && isPaid"/>
          <HeaderTasks class="header__item" v-if="!isPatient && isPaid && rights.includes('tasks')"/>
<!--          <HeaderCallPlanning class="header__item ml-xl-5 ml-md-3 ml-0" v-if="!isPatient && isPaid && rights.includes('call_planning')"/>-->
          <HeaderCRM class="header__item" v-if="!isPatient && isPaid && (rights.includes('tasks') || rights.includes('crm'))"/>
          <HeaderWazzup class="header__item" v-if="!isPatient && isPaid"/>
          <!--                    <HeaderCalls class="header__item ml-5"/>-->
          <HeaderBirthday class="header__item" v-if="!isPatient && isPaid && rights.includes('birthday')"/>
          <HeaderNotifications class="header__item" v-if="!isPatient && showNotifications && isPaid"/>
          <HeaderUser class="header__item"/>
        </div>
      </b-col>
    </b-row>
  </header>
</template>

<script>

import SearchPatient from "../parts/header/SearchPatient"
import HeaderUser from "../parts/header/HeaderUser"
import HeaderAddPatient from "../parts/header/HeaderAddPatient"
// import HeaderCalls from "../parts/header/HeaderCalls"
import HeaderTasks from "../parts/header/HeaderTasks"
import HeaderCRM from "../parts/header/HeaderCRM"
import HeaderNotifications from "../parts/header/HeaderNotifications"
import MenuToggler from "@/components/parts/header/MenuToggler"
import {mapGetters, mapState} from "vuex"
import HeaderWaitingList from "@/components/parts/header/HeaderWaitingList"
import HeaderBirthday from "@/components/parts/header/HeaderBirthday"
import {UsersService} from "@/services/api.service"
import HeaderWazzup from "@/components/parts/header/HeaderWazzup"
// import HeaderCallPlanning from "@/components/parts/header/HeaderCallPlanning"

export default {
  name: "Header",
  components: {
    HeaderWazzup,
    HeaderCRM,
    // HeaderCallPlanning,
    HeaderBirthday,
    HeaderWaitingList,
    MenuToggler,
    HeaderNotifications,
    HeaderTasks,
    // HeaderCalls,
    HeaderUser,
    SearchPatient,
    HeaderAddPatient
  },
  data() {
    return {
      showNotifications: true
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      headerUpdateCounter: state => state.auth.headerUpdateCounter,
    }),
    ...mapGetters(['isPaid', 'isLogged']),
    isPatient() {
      return this.$store.getters.isPatient
    }
  },
  methods: {
    getData() {
      if(!this.isLogged) return
      UsersService.counters().then(res => {
        let d = res.data
        this.$store.commit('setHeaderCounter', { counter: 'waiting', value: d.waiting } )
        this.$store.commit('setHeaderCounter', { counter: 'tasks', value: d.tasks } )
        this.$store.commit('setHeaderCounter', { counter: 'birthdays', value: d.birthdays } )
        this.$store.commit('setHeaderCounter', { counter: 'unread', value: d.unread } )
        // this.$store.commit('setHeaderCounter', { counter: 'notifications', value: d.notifications } )
      })
    }
  },
  mounted() {
    setTimeout(this.getData, 3000)
  },
  watch: {
    headerUpdateCounter() {
      this.getData()
    }
  }
}
</script>
