import VueI18n from 'vue-i18n'
import Vue from "vue"
Vue.use(VueI18n)
let lang = "ru"
import messages from '@/lang/lang'

const i18n = new VueI18n({
    locale: lang,
    messages,
})

export default i18n;
