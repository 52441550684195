export default {
    add_to_egisz: "EGISZga qo'shing",
    download_all: "Barchasini yuklab oling",
    egisz: "EGISZ",
    main_doctor: "Bosh shifokor",
    patient_rejected_to_send_to_egisz: "Bemor EGISZ ga ma'lumotlarni yuborishdan bo'shadi",
    requires_attention: "E'tibor kerak",
    representative_relation: "Bemorga vakil bo'lgan shaxs",
    send_to_egisz: "EGISZ ga yuboring",
    sent_to_egisz: "EGISZ ga yuborildi",
    sign: "Imzo",
    sign_all: "Barchasini imzolash",
    signed_by_doctor: "Shifokor tomonidan imzolangan",
    signed_by_main_doctor: "Imzolangan bo'lim. shifokor",
    to_egisz_page: "EGISZ sahifasiga",
    to_rework: "Qayta ko'rib chiqish uchun",
    to_sign: "Imzo yo'q",
}
