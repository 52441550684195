<template>
  <div>
    <DataTable :options="options"
               class="mt-4"
               :params="{ marketing_mailing_id: $route.params.id }"
               module="marketing"/>
    <NotificationInfoModal/>
  </div>
</template>

<script>

import {mapState} from "vuex"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/marketing/notification"
import {EloquentService} from "@/services/api.service"
import NotificationInfoModal from "@/components/parts/marketing/NotificationInfoModal"

export default {
  name: "MarketingMailingItem",
  components: {
    NotificationInfoModal,
    DataTable,
  },
  metaInfo() {
    return {
      title: this.$t('notifications'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    }),
  },
  data() {
    return {
      options: options
    }
  },
  methods: {
  },
  mounted() {
    EloquentService.show('marketing_mailing', this.$route.params.id).then(res => {
      this.$store.commit('setMarketingMailing', res.data)
      // this.group = res.data
    })
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
