<template>
  <div>
    <router-link class="text-capitalize" :to="{ name: 'AccountingMonthSummary', params: { id: object.id } }">
      {{ object.date | formatDate('MMMM, YYYY') }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "AccountingDatatableTDDate",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
