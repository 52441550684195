<template>
  <div class="mt-3 report-materials">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="date"
                   initialSort="desc"
                   :transferToExcel="transferToExcel"
                   :rows="rows"/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"

export default {
  name: "ReportsMaterials",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    diagnoses: {
      type: Array
    },
    selectedBranches: {}
  },
  metaInfo() {
    return {
      title: this.$t('materials'),
      meta: []
    }
  },
  data() {
    return {
      columns: [
        {
          id: 'date',
          field: 'date',
          label: this.$t('date'),
          sortable: true,
          format_datetime: true
        },
        {
          id: 'doctor_name',
          field: 'doctor_name',
          label: this.$t('doctor'),
          search_select: true,
          sortable: true
        },
        {
          id: 'patient_name',
          field: 'patient_name',
          label: this.$t('patient'),
          search_select: true,
          sortable: true
        },
        {
          id: 'warehouse_name',
          field: 'warehouse_name',
          label: this.$t('warehouse'),
          search_select: true,
          sortable: true
        },
        {
          id: 'materials_name',
          field: 'materials_name',
          label: this.$t('materials'),
          sortable: true,
          format_html: true,
        },
        {
          id: 'materials_price',
          field: 'materials_price',
          label: this.$t('price'),
          sortable: true,
          format_html: true,
        },
        {
          id: 'materials_amount',
          field: 'materials_amount',
          label: this.$t('amount'),
          sortable: true,
          format_html: true,
        },
        {
          id: 'materials_cost',
          field: 'materials_cost',
          label: this.$t('cost'),
          sortable: true,
          format_html: true,
        },
        // {
        //   id: 'price',
        //   field: 'price',
        //   label: this.$t('price'),
        //   sortable: true,
        //   format_number: true,
        //   totals: true
        // },
        // {
        //   id: 'paid',
        //   field: 'paid',
        //   label: this.$t('paid'),
        //   sortable: true,
        //   format_number: true,
        //   totals: true
        // },
        // {
        //   id: 'debt',
        //   field: 'debt',
        //   label: this.$t('debt'),
        //   sortable: true,
        //   format_number: true,
        //   totals: true
        // },

      ],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('materials', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
        doctors: this.doctors.map(a => a.id),
        diagnoses: this.diagnoses.map(a => a.id),
      }).then(res => {
        this.rows = res.data
      })
    },
    transferToExcel(data) {
      let excelRows = []
      if(data) {
        data.forEach(item => {
          const i = this.copyObject(item)
          Object.keys(i).forEach((key) => {
            i[key] = (i[key]).toString().replace(/<\/?[^>]+(>|$)/g, "\r\n")
          })
          excelRows.push(i)
        })
      }
      return excelRows
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>

<style scoped lang="scss">
.report-materials {
  ::v-deep .nowrap {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
