<template>
  <span>
  <a
      v-if="rights.includes('create_chairs')"
      @click="toggleChair"
      :title="object.is_active == 1 ? $t('block') : $t('activate')"
      v-b-tooltip.hover
      class="data-table-action">
    <BlockSvg v-if="object.is_active == 1"/><ActivateSvg class="svg-light" v-if="object.is_active == 0"/>
    <span class="action-button-mob-title">{{ object.is_active == 1 ? $t('block') : $t('activate') }}</span>
  </a>
    </span>
</template>

<script>

import BlockSvg from '@/assets/svg/general/block.svg'
import ActivateSvg from '@/assets/svg/form/tick.svg'
import { EloquentService } from "@/services/api.service"
import { chairAdapter } from '@/adapters/staff/chair'
import { mapState } from "vuex"

export default {
  name: "StaffDatatableChairActions",
  components: {
    BlockSvg,
    ActivateSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights
    })
  },
  methods: {
    toggleChair() {
      let formData = new FormData()
      let data = chairAdapter(this.object)
      data.is_active = this.object.is_active == 1 ? 0 : 1
      formData = this.appendFormdata(formData, data)
      EloquentService.update('chair', this.object.id, formData).then(() => {
         this.$store.commit('incrementDataTableCounter')
      })
    }
  }
}
</script>

<style scoped>

</style>
