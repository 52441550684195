<template>
  <div class="pb-4">
    <div style="max-width: 650px;">
      <FileGroup label="Excel"
                 :key="`file-c-${counter}`"
                 :fid="`file-doctor-photo`"
                 :deletefile="true"
                 filetype="excel"
                 @deleteFile="deleteFile"
                 v-model="file"/>
      <div v-if="analysis && file">
        <div class="alert" :class="{ 'alert-info': analysisValid, 'alert-danger': !analysisValid }">
          <div>Найдено записей: {{ analysis.records }}</div>
          <div>Колонки найдены: {{ analysis.columns_found.join(', ') }}</div>
        </div>
        <button v-if="analysisValid" :disabled="loading" class="btn-themed btn-themed-squared mb-4" @click="save">
          {{ $t('upload') }}
        </button>
      </div>

      <div v-if="!file">
        <b-button class="btn-collapse" v-b-toggle="'collapse-inst'">Инструкции</b-button>
        <b-collapse id="collapse-inst">
          <ul class="pt-4">
            <li>Файл должен быть в формате xlsx</li>
            <li>В файле должен быть только один лист</li>
            <li>Первая строка должна содержать названия колонок</li>
            <li>Последующие строки должны содержать значения пользователей (1 строка - 1 пользователь) - не объединять ячейки</li>
            <li>Не оставлять никаких значений в ячейках ниже основной таблицы (даже пробелы, закрашенные ячейки и тд)</li>
            <li>Принимаются следующие названия колонок: 'Фамилия', 'Имя', 'Отчество', 'Номер карточки пациента', 'Телефон', 'Дата рождения', 'Email', 'ИИН', 'Скидка%', 'Пол', 'Id', 'Адрес', 'Метка', 'Состояние', 'Номер паспорта', 'Серия паспорта', 'Кем выдан паспорт', 'Дата выдачи паспорта', 'Код подразделения', 'Снилс', 'ИНН', 'Откуда пришёл', 'Статус', 'Описание'</li>
            <li>Поле пол должно содержать значения <code>Муж</code> или <code>Жен</code></li>
            <li>Поле <code>Id пациента</code> должно содержать только цифровое значение</li>
            <li>Поле <code>Метка</code> должно содержать только цифровое значение (Id метки, Настройки -> Метки)</li>
            <li>Поле <code>Состояние</code> должно содержать только цифровое значение (Id состояния, Настройки -> Состояния пациентов)</li>
            <li>Поле <code>Статус</code> должно содержать только цифровое значение (2 - постоянный, 3 - первичный прием), однако в дальнейшем этот статус будет автоматически пересчитываться на основании наличия визитов в системе</li>
            <li>Поле <code>Откуда пришёл</code> должно содержать только цифровое значение (Id источника, Настройки -> Источники пациентов)</li>
            <li>Даты (дата рождения и дата выдачи паспорта) должна быть в формате 'дд.мм.гггг' - то есть, например 20 мая 2005 года - это <code>20.05.2005</code></li>
            <li>Если заполнено поле <code>Id</code>, то система будет пытаться обновить данные уже существующего пациента (если у клиники пациента с таким id нет, то новый создаваться не будет)</li>
            <li>Обязательные поля: Фамилия или Имя, остальные опциональны</li>
            <li>Загружать файлы с кол-вом пациентов не более 3 000. Если у клиники больше, то лучше разбить на несколько файлов (заголовки ставить на первую строку у каждого файла)</li>
            <li><a href="/patients_import_example.xlsx">Пример файла</a></li>
          </ul>
        </b-collapse>
      </div>

      <div class="mt-5" v-if="imports.length">
        <table class="table-blue services-table">
          <thead>
          <tr>
            <th>id</th>
            <th>{{ $t('records') }}</th>
            <th>{{ $t('created') }}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(i, ind) in imports" :key="`tr-i-${ind}`">
            <td>{{ i.id }}</td>
            <td>{{ i.records }}</td>
            <td>{{ i.created_at | formatDateTime }}</td>
            <td>
              <a class="pointer" @click="cancelImport(i.id)">
                <TrashSvg/>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex"
import FileGroup from "@/components/form/FileGroup"
import {SuperadminService} from "@/services/api.service"
import TrashSvg from "@/assets/svg/general/trash.svg"

export default {
  name: "SuperAdminClinicImport",
  components: {
    FileGroup,
    TrashSvg
  },
  metaInfo() {
    return {
      title: this.$t('import'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
    }),
    analysisValid() {
      return this.analysis && this.analysis.records > 0 && this.analysis.columns_found.length
    }
  },
  data() {
    return {
      counter: 0,
      file: null,
      analysis: null,
      imports: []
    }
  },
  async mounted() {
    await this.getImports()
  },
  methods: {
    async save() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, { file: this.file })
      let res = await SuperadminService.importPatientsFile(this.clinic.id, formData)
      this.analysis = null
      this.file = null
      this.$noty.info(`Создано ${res.data.records} пользователей`)
      this.counter++
      await this.getImports()
    },
    async getImports() {
      let res = await SuperadminService.getPatientImports(this.clinic.id)
      this.imports = res.data
    },
    async cancelImport(import_id) {
      if(confirm('Вы уверены, что хотите отменить этот импорт? Все импортированные пациенты будут удалены!')) {
        await SuperadminService.cancelImport(this.clinic.id, import_id)
        await this.getImports()
      }
    },
    deleteFile() {
      this.file = null
      this.counter++
    }
  },
  watch: {
    file(val) {
      if(val) {
        let formData = new FormData()
        formData = this.appendFormdata(formData, { file: val })
        SuperadminService.analyzeImportFile(this.clinic.id, formData).then(res => {
          this.analysis = res.data
        })
      }
    }
  },

}
</script>

<style scoped lang="scss">

</style>
