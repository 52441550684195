export default {
    apply_plan_treatment: "Застосувати план лікування",
    calls_planning: "Планування дзвінків",
    choose_criterias_for_fill: "Виберіть потрібні критерії для заповнення",
    comments: "Коментарі",
    grouped: "Згрупований",
    grouped_by_teeth: "Згрупований по зубах",
    last_update: "Остання зміна",
    level_description: "Опис етапу",
    main_complaints: "Основні скарги",
    notification_date: "Дата повідомлення",
    plan: "План",
    plan_treatment_status: "Статус плану лікування",
    reset_all: "Скинути все",
    route_list: "Маршрутний лист",
    select_all: "Вибрати все"
}
