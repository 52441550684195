import axios from "axios"
import JwtService from "@/services/jwt.service"
import store from '@/store'
import LangHelper from "@/services/lang.helper"
import Vue from 'vue'
import BranchHelper from "@/services/branch.helper"

const ApiService = {
  init(i18n, router) {
    axios.defaults.baseURL = process.env.VUE_APP_API_PROXY_URL
    // axios.defaults.baseURL = process.env.VUE_APP_API_URL
    axios.interceptors.request.use((config) => {
      config.headers['Accept-Language'] = LangHelper.getLowcaseLanguage()
      let token = JwtService.getToken()
      config.headers['Authorization'] = token ? `Bearer ${token}` : `${process.env.VUE_APP_API_TOKEN}`
      if(config.url === 'auth/login') {
        config.headers['Authorization'] = process.env.VUE_APP_API_TOKEN
      }
      let loader = config.loader
      store.commit('setLoading',typeof loader !== 'undefined' ? loader : true)
      config.headers['From'] = BranchHelper.getBranch()
      return config
    }, error => Promise.reject(error))

    axios.interceptors.response.use((response) => {
      store.commit('setLoading',false)
      const appVersion = response.headers.app_version
      if(appVersion) JwtService.saveAppVersion(appVersion)
      return response
    }, function (error) {
      store.commit('setLoading',false)
      let status = error.response ? error.response.status : 500
      console.log(error.response)
      if(status === 422) {
        console.log(error.response.data)
        if(error.response.data.errors.show_message) {
          Vue.prototype.$noty.error(error.response.data.errors.show_message[0])
        }
        store.commit('setValidationErrors', error.response.data.errors)
      }
      if(status === 405 || status === 403) {
        Vue.prototype.$noty.error(i18n.tc('not_enough_rights_for_this_operation'))
      }
      if(status === 406) {
        Vue.prototype.$noty.error(i18n.tc('your_tariff_plan_is_not_paid'))
      }
      if(status === 409) {
        Vue.prototype.$noty.error(i18n.tc('clinic_is_blocked'))
      }
      if(status === 500) {
        Vue.prototype.$noty.error(i18n.tc('server_error'))
      }
      if(status === 503) {
        router.push('/maintenance')
      }
      if(status === 400 && error.response.data.message) {
        Vue.prototype.$noty.error(error.response.data.message)
      }
      return Promise.reject(error)
    })
  },

  get(resource, params = {}, headers = {}) {
    let p = { params: params }
    p = {...p, ...headers }
    return axios.get(resource, p)
  },

  post(resource, params = {}, headers = {}, config = {}) {
    headers['Accept'] = 'application/json'
    config.headers = headers
    return axios.post(`${resource}`, params, config)
  },

  delete(resource, headers = {}, config = {}) {
    headers['Accept'] = 'application/json'
    config.headers = headers
    return axios.delete(`${resource}`, config)
  },
};

export default ApiService;


/// *** Accounting Service *** ///

export const AccountingService = {
  salaries(id) {
    return ApiService.get(`accounting/${id}/salaries`)
  },
  materials(id) {
    return ApiService.get(`accounting/${id}/materials`)
  },
  clearSalaryPayments(month_id, user_id) {
    return ApiService.delete(`accounting/salary/clean/${user_id}/${month_id}`)
  },
  closeMonth(month_id) {
    return ApiService.post(`accounting/${month_id}/close`)
  },
  openMonth(month_id) {
    return ApiService.post(`accounting/${month_id}/open`)
  },
  salaryAdjustment(month_id, params) {
    return ApiService.post(`accounting/${month_id}/salary/adjustment`, params)
  }
}

/// *** Calendar Service *** ///

export const CalendarService = {
  calendarDictionaries() {
    return ApiService.get("calendar/dictionaries")
  },
  get(params) {
    return ApiService.get("records", params, { loader: params.loader })
  },
  doctors(branch_id) {
    return ApiService.get(`doctors/${branch_id}`)
  },
  createOrUpdateRecord(record) {
    return ApiService.post(`records/create`, record)
  },
  updateRecord(record) {
    return ApiService.post(`records/${record.id}/update`, record)
  },
  cancelRecord(record_id, reason) {
    return ApiService.post(`records/${record_id}/cancel`, { reason: reason })
  },
  getAdditionalInfo(record_id) {
    return ApiService.get(`records/${record_id}/additional/info`)
  },
  recordAdditionalInfo(record_id, info) {
    return ApiService.post(`records/${record_id}/additional/record`, info)
  },
  recoverRecord(record_id) {
    return ApiService.post(`records/${record_id}/recover`)
  },
  copyRecord(record_id, params) {
    return ApiService.post(`records/${record_id}/copy`, params, {'Content-Type': 'multipart/form-data'})
  },
  updateRecordTeeth(record_id, teeth) {
    return ApiService.post(`records/${record_id}/update/teeth`, teeth)
  },
  updatePlanTreatment(treatment_id, params) {
    return ApiService.post(`plan_treatments/${treatment_id}/update`, params)
  },
  duplicatePlanTreatment(treatment_id) {
    return ApiService.post(`plan_treatments/${treatment_id}/duplicate`)
  },
  updatePlanTreatmentPrices(treatment_id) {
    return ApiService.post(`plan_treatments/${treatment_id}/prices/update`)
  },
  finishTask(id) {
    return ApiService.post(`task/${id}/finish`)
  },
  finishTaskBulk(ids) {
    return ApiService.post(`task/finish/bulk`, { ids })
  },
  setRecordTiming(record_id, params) {
    return ApiService.post(`records/${record_id}/timing`, params)
  },
  initiateEGIZS(record_id) {
    return ApiService.post(`records/${record_id}/egisz/initiate`)
  },
  getEGISZList() {
    return ApiService.get(`egisz/list`)
  },
  getEGISZDictionaries() {
    return ApiService.get(`records/egisz/dictionaries`)
  },
  EGISZNextStep(record_id) {
    return ApiService.post(`records/${record_id}/egisz/nextStep`)
  },
  resetEGISZ(record_id) {
    return ApiService.post(`records/${record_id}/egisz/reset`)
  },
  validateEGISZ(record_id) {
    return ApiService.post(`records/${record_id}/egisz/validate`)
  },
  getEgiszCDA(record_id) {
    return ApiService.get(`records/${record_id}/egisz/cda`)
  },
  getEgiszXML(record_id) {
    return ApiService.get(`records/${record_id}/egisz/xml`)
  },
  signCDA(record_id, params) {
    return ApiService.post(`records/${record_id}/egisz/signature`, params)
  }
}

/// *** Clinic Service *** ///

export  const ClinicService = {
  saveData(params) {
    return ApiService.post(`clinic/data/save`, params, {'Content-Type': 'multipart/form-data'})
  },
  saveCurrencyRates(rates) {
    return ApiService.post(`clinic/currencies/save`, rates)
  },
  recountServicesExchangeRate(currencyRate) {
    return ApiService.post(`clinic/currencies/${currencyRate.id}/recount`)
  },
  getContactInfo() {
    return ApiService.get("clinic/info")
  },
  updateBranchContacts(data) {
    return ApiService.post(`clinic/info/branch`, data)
  },
  saveSMSSettings(settings, superadmin = false, clinic_id = '') {
    return ApiService.post(`${superadmin ? 'superadmin/' : ''}clinic/sms/settings?clinic_id=${clinic_id}`, { settings })
  },
  savePPOAttribute(attrs, vals, superadmin = false, clinic_id = '') {
    return ApiService.post(`${superadmin ? 'superadmin/' : ''}clinic/integrations/save?clinic_id=${clinic_id}`, { attrs, vals })
  },
  importStandardServices() {
    return ApiService.post(`import/services/standard`)
  },
  disableStandardServices() {
    return ApiService.post(`import/services/standard/disable`)
  },
  getUsersList(users, superadmin = false) {
    return ApiService.get(`${superadmin ? 'superadmin/' : ''}clinic/binotel/users`, { users: users } )
  },
  getPopup() {
    return ApiService.get(`clinic/popup`)
  },
  getAdmins() {
    return ApiService.get(`clinic/admins`)
  }
}

/// *** Cashdesk Service *** ///

export const CashdeskService = {
  getData() {
    return ApiService.get("cashdesk/settings")
  },
  saveSettings(settings) {
    return ApiService.post(`cashdesk/settings`, { settings })
  },
  openShift() {
    return ApiService.post(`cashdesk/shift/open`)
  },
  closeShift() {
    return ApiService.post(`cashdesk/shift/close`)
  },
  createXReport() {
    return ApiService.post(`cashdesk/xreport`)
  },
  webkassaData(type) {
    return ApiService.get(`webkassa/data/${type}`)
  },
  aqsiData(type) {
    return ApiService.get(`aqsi/data/${type}`)
  }
}

/// *** Doctor Service *** ///

export const DoctorService = {
  doctorsSchedule(doctor_id, branch_id) {
    return ApiService.get(`doctor/${doctor_id}/schedule?branch_id=${branch_id}`)
  },
  doctorsAvailabilityForDay(doctor_id, branch_id, day) {
    return ApiService.get(`doctor/${doctor_id}/schedule/${day}?branch_id=${branch_id}`)
  },
  saveDoctorScheduleDay(doctor_id, branch_id, params) {
    return ApiService.post(`doctor/${doctor_id}/schedule/day?branch_id=${branch_id}`, params)
  },
  deleteDoctorScheduleDay(doctor_id, branch_id, day_id) {
    return ApiService.delete(`doctor/${doctor_id}/schedule/day/${day_id}?branch_id=${branch_id}`)
  },
  monthAvailability(doctor_id, branch_id, month) {
    return ApiService.get(`doctor/${doctor_id}/schedule/month/${month}?branch_id=${branch_id}`, {}, { loader: false })
  }

}

/// *** Eloquent Service *** ///

export const EloquentService = {
  autocomplete(model, q, pagination = null, loader = true, superadmin = false, clinic_id = null, branch_id = null) {
    return ApiService.get(`${superadmin ? 'superadmin/' : ''}autocomplete`, { q, model, pagination, clinic_id, branch_id },{ loader })
  },
  prepare(model, params = {}, superadmin = false) {
    return ApiService.get(`${superadmin ? 'superadmin/' : ''}eloquent/prepare`, { model: model, params: params })
  },
  show(model, id, superadmin = false, loader = true) {
    return ApiService.get(`${superadmin ? 'superadmin/' : ''}eloquent/show`, { model: model, id: id }, { loader })
  },
  create(model, params, superadmin = false) {
    return ApiService.post(`${superadmin ? 'superadmin/' : ''}eloquent/${model}/create`, params, {'Content-Type': 'multipart/form-data'})
  },
  update(model, id, params, superadmin = false, loader = true) {
    return ApiService.post(`${superadmin ? 'superadmin/' : ''}eloquent/${model}/update/${id}`, params, {'Content-Type': 'multipart/form-data'}, { loader })
  },
  delete(model, id, superadmin = false) {
    return ApiService.delete(`${superadmin ? 'superadmin/' : ''}eloquent/${model}/delete/${id}`)
  },
  deleteBulk(model, ids, superadmin = false) {
    return ApiService.post(`${superadmin ? 'superadmin/' : ''}eloquent/${model}/deletebulk`, { ids })
  },
  restore(model, id, superadmin = false) {
    return ApiService.post(`${superadmin ? 'superadmin/' : ''}eloquent/${model}/restore/${id}`)
  },
  dataTable(model, page, params, loader = true, superadmin = false) {
    return ApiService.get(`${superadmin ? 'superadmin/' : ''}datatable`, { model: model, page: page, ...params }, {loader: loader})
  },
  excel(model, conditions = {}, filters = {}, superadmin = false) {
    let params = { excel: true }
    params.condition = conditions
    params.filters = filters
    return ApiService.get(`${superadmin ? 'superadmin/' : ''}datatable`, { model: model, ...params })
  }
}

/// *** Users Service *** ///

export const UsersService = {
  login(params) {
    return ApiService.post("auth/login", params)
  },
  init(superadmin = false) {
    return ApiService.get((superadmin ? "superadmin/" : "")+"initial")
  },
  counters() {
    return ApiService.get("counters", {}, { loader: false })
  },
  getProfile() {
    return ApiService.get("profile")
  },
  fcmToken(params) {
    return ApiService.post("token/fcm", params)
  }
}

/// *** Warehouse Service *** ///

export const WarehouseService = {
  getDocument(id) {
    return ApiService.get(`warehouse/document/${id}`)
  },
  addDocumentItem(document_id, params) {
    return ApiService.post(`warehouse/document/${document_id}/operation/add`, params)
  },
  addInventarizationItem(document_id, params) {
    return ApiService.post(`warehouse/document/${document_id}/operation/add/inventarization`, params)
  },
  autoFillInventarization(document_id, params) {
    return ApiService.post(`warehouse/document/${document_id}/operation/fill`, params)
  },
  saveDocument(document_id, items, inventarization = false) {
    return ApiService.post(`warehouse/document/${document_id}/operation/sync${inventarization ? '/inventarization' : '' }`, { items })
  },
  approveDocument(document_id) {
    return ApiService.post(`warehouse/document/${document_id}/approve`)
  },
  cancelDocument(document_id) {
    return ApiService.post(`warehouse/document/${document_id}/cancel`)
  },
  deleteDocumentItem(document_id, item_id) {
    return ApiService.post(`warehouse/document/${document_id}/operation/${item_id}/delete`)
  },
  deleteInventarizationItem(document_id, item_id) {
    return ApiService.post(`warehouse/document/${document_id}/operation/${item_id}/delete/inventarization`)
  },
  materialStocksExcel(material_id) {
    return ApiService.post(`warehouse/material/${material_id}/stocks/excel`)
  },
  getCategoriesWithMaterials() {
    return ApiService.get(`warehouse/categories_with_materials`)
  }
}


/// *** Patient Service *** ///

export const PatientService = {
  getPatient(id) {
    return ApiService.get(`patient/${id}`)
  },
  getClientTeeth(id) {
    return ApiService.get(`patient/${id}/teeth`)
  },
  getClientAnketa(id) {
    return ApiService.get(`patient/${id}/anketa`)
  },
  getBirthdays() {
    return ApiService.get(`patients/birthdays`)
  },
  sendBirthdayGreetings(params) {
    return ApiService.post(`patients/birthdays`, params)
  },
  saveClientAnketa(id, params) {
    return ApiService.post(`patient/${id}/anketa`, params)
  },
  updateClientTeeth(patient_id, tooth_id, params) {
    return ApiService.post(`patient/${patient_id}/teeth/${tooth_id}/update`, params)
  },
  getVisitDictionaries(patient_id, visit_id = null) {
    return ApiService.get(`patient/${patient_id}/visits/dictionaries`, { visit_id: visit_id })
  },
  attachPlan(patient_id, visit_id, plan_visit_id) {
    return ApiService.post(`patient/${patient_id}/visit/${visit_id}/plan/${plan_visit_id}/attach`)
  },
  attachPlanWithOptions(patient_id, visit_id, plan_visit_id, options) {
    return ApiService.post(`patient/${patient_id}/visit/${visit_id}/plan/${plan_visit_id}/options/attach`, { options })
  },
  attachTemplate(patient_id, visit_id, template_id, options = [], teeth = [], allTeeth = []) {
    return ApiService.post(`patient/${patient_id}/visit/${visit_id}/template/${template_id}/attach`, { options: options, teeth: teeth, allTeeth: allTeeth })
  },
  getClientVisit(patient_id, visit_id) {
    return ApiService.get(`patient/${patient_id}/visit/${visit_id}`)
  },
  getClientPlan(patient_id, plan_id) {
    return ApiService.get(`patient/${patient_id}/plan/${plan_id}`)
  },
  getUnpaidServices(patient_id) {
    return ApiService.get(`patient/${patient_id}/visits/unpaid`)
  },
  payForVisit(patient_id, visit_id, params) {
    return ApiService.post(`patient/${patient_id}/visit/${visit_id}/pay`, params)
  },
  toggleTag(patient_id, tag_id) {
    return ApiService.post(`patient/${patient_id}/tag/${tag_id}/toggle`)
  },
  syncFamilyAccount(patient_id, user_ids = []) {
    return ApiService.post(`patient/${patient_id}/family_account/`, { user_ids: user_ids })
  },
  checkIfPhoneExists(patient_id, phone) {
    return ApiService.get(`patient/phone/exists`, { patient_id: patient_id, phone: phone }, { loader: false })
  },
  unifyCard(patient_id, destination_user_id) {
    return ApiService.post(`patient/${patient_id}/unify/`, { destination_user_id: destination_user_id })
  },
  sendFormulaImage(patient_id, data) {
    return ApiService.post(`patient/${patient_id}/formula/image/upload`, { img: data })
  },
  getClientSources() {
    return ApiService.get(`patients/sources`)
  },
}


/// *** Send Service *** ///

export const SendService = {
  sendSMS(params) {
    return ApiService.post(`sms`, params)
  },
  sendMail(params) {
    return ApiService.post(`mail`, params)
  },
  sendBulkMessage(params) {
    return ApiService.post(`message/bulk`, params)
  }
}

/// *** Documents Service *** ///

export const DocumentsService = {
  getDocumentTemplate(type, custom_id = null) {
    return ApiService.get(`document/${type}/template`, { custom_id: custom_id })
  },
  saveDocumentTemplate(type, text, custom_id = null) {
    return ApiService.post(`document/${type}/template`, { text26: text, custom_id: custom_id })
  },
  getAgreementsList(type) {
    return ApiService.get(`agreements/list`, { type: type })
  },
  getGeneratedDocuments(clientId) {
    return ApiService.get(`document/client/${clientId}/generated`)
  },
  generateDocument(clientId, doc) {
    return ApiService.post(`document/client/${clientId}/generate`, { doc })
  },
  signGeneratedDocument(clientId, documentId) {
    return ApiService.post(`document/client/${clientId}/generated/${documentId}/sign`)
  },
  uploadToGeneratedDocument(clientId, params) {
    return ApiService.post(`document/client/${clientId}/generated/upload`, params)
  }
}


/// *** Reports Service *** ///

export const ReportsService = {
  loadReport(type, params) {
    return ApiService.get(`report/${type}`, params)
  },
  getCashDetailsByVisit(type, from, to) {
    return ApiService.get(`report/visits/${type}`, { from: from, to: to })
  },
  getF057Mapping() {
    return ApiService.get(`report/f057/mapping`)
  },
  saveF057Mapping(params) {
    return ApiService.post(`report/f057/mapping`, params)
  }
}


/// *** Billing Service *** ///

export const BillingService = {
  getTariffInfo() {
    return ApiService.get(`clinic/tariff`)
  },
  payImitation() {
    return ApiService.post(`clinic/tariff/pay`)
  },
  pay(params) {
    return ApiService.post(`clinic/tariff/pay/init`, params)
  },
  paymentStatus(payment_id, superadmin = false) {
    return ApiService.get(`${superadmin ? 'superadmin/' : ''}clinic/tariff/pay/status/${payment_id}`)
  },
  unblockTemporarily() {
    return ApiService.post(`clinic/tariff/unblock/temporary`)
  },
  refundPay(payment_id) {
    return ApiService.post(`superadmin/clinic/tariff/pay/refund/${payment_id}`)
  },
  changeTariff(tariff_id) {
    return ApiService.post(`clinic/tariff/change`, { tariff_id: tariff_id })
  }
}


/// *** Marketing Service *** ///

export const MarketingService = {
  addToGroup(group_id, patient_ids) {
    return ApiService.post(`marketing/group/${group_id}/add`, { patient_ids: patient_ids })
  },
  removeFromGroup(group_id, patient_ids) {
    return ApiService.post(`marketing/group/${group_id}/remove`, { patient_ids: patient_ids })
  },
  launchSending(marketing_mailing_id) {
    return ApiService.post(`marketing/mailing/${marketing_mailing_id}/start`)
  },
  stopSending(marketing_mailing_id) {
    return ApiService.post(`marketing/mailing/${marketing_mailing_id}/stop`)
  },
  cloneMailing(marketing_mailing_id) {
    return ApiService.post(`marketing/mailing/${marketing_mailing_id}/clone`)
  },
  resendNotification(notification_id) {
    return ApiService.post(`marketing/notification/${notification_id}/resend`)
  },
  createStandardNotifications() {
    return ApiService.post(`marketing/notification/standard/copy`)
  },
  readNotifications(ids) {
    return ApiService.post(`marketing/notification/read`, { ids: ids }, {}, { loader: false })
  },
  getNotificationChannels() {
    return ApiService.get(`marketing/notification/channels`)
  },
  sendDeferredMessage(data) {
    return ApiService.post(`marketing/notification/deferred/send`, data)
  }
}

/// *** Superadmin Service *** ///

export const SuperadminService = {
  loadRights(clinic_id) {
    return ApiService.get(`superadmin/clinic/${clinic_id}/rights`)
  },
  toggleRight(clinic_id, params) {
    return ApiService.post(`superadmin/clinic/${clinic_id}/rights`, params)
  },
  tariffFunctionality(tariff_id) {
    return ApiService.get(`superadmin/tariff/${tariff_id}/functionality`)
  },
  updateTariffFunctionality(tariff_id, params) {
    return ApiService.post(`superadmin/tariff/${tariff_id}/functionality`, params)
  },
  tariffsForClinic(clinic_id) {
    return ApiService.get(`superadmin/clinic/${clinic_id}/tariffs`)
  },
  saveTariffsForClinic(clinic_id, params) {
    return ApiService.post(`superadmin/clinic/${clinic_id}/tariffs`, params)
  },
  nullifyPayment(clinic_id) {
    return ApiService.delete(`superadmin/clinic/${clinic_id}/tariffs/nullify`)
  },
  analyzeImportFile(clinic_id, params) {
    return ApiService.post(`superadmin/import/patients/${clinic_id}/action/analyze`, params)
  },
  analyzeServicesImportFile(clinic_id, params) {
    return ApiService.post(`superadmin/import/services/${clinic_id}/action/analyze`, params)
  },
  importPatientsFile(clinic_id, params) {
    return ApiService.post(`superadmin/import/patients/${clinic_id}/action/import`, params)
  },
  importServicesFile(clinic_id, params) {
    return ApiService.post(`superadmin/import/services/${clinic_id}/action/import`, params)
  },
  getPatientImports(clinic_id) {
    return ApiService.get(`superadmin/import/patients/${clinic_id}/imports`)
  },
  getServicesImports(clinic_id) {
    return ApiService.get(`superadmin/import/services/${clinic_id}/imports`)
  },
  cancelImport(clinic_id, import_id) {
    return ApiService.post(`superadmin/import/patients/${clinic_id}/import/${import_id}/cancel`)
  },
  importServices(clinic_id) {
    return ApiService.post(`superadmin/import/services/${clinic_id}/standard`)
  },
  toggleBlockedClinic(clinic_id, status) {
    return ApiService.post(`superadmin/clinic/${clinic_id}/block`, { blocked: status })
  },
  sendPush(params, message) {
    return ApiService.post(`superadmin/push/send`, { params: params, message: message })
  },
  restoreUser(userId) {
    return ApiService.post(`superadmin/user/${userId}/restore`)
  },
  generateAPIAccess(params) {
    return ApiService.post(`superadmin/api/access`, params)
  }
}

/// *** Whatsapp Service *** ///

export const WhatsappService = {
  getStatus(superadmin = false) {
    return ApiService.get(`${superadmin ? 'superadmin/' : ''}radist/status`)
  },
  getWazzupStatus(superadmin = false) {
    return ApiService.get(`${superadmin ? 'superadmin/' : ''}wazzup/status`)
  },
  getJetAPIStatus(superadmin = false, clinic_id = '') {
    return ApiService.get(`${superadmin ? 'superadmin/' : ''}jetapi/status?clinic_id=${clinic_id}`)
  },
  createJetAPIInstance(params) {
    return ApiService.post(`jetapi/instance`, params)
  },
  breakJetAPIConnection(superadmin = false, clinic_id = '') {
    return ApiService.post(`${superadmin ? 'superadmin/' : ''}jetapi/instance/break?clinic_id=${clinic_id}`)
  },
  getWazzupSocketCredentials() {
    return ApiService.get(`wazzup/socket`, {}, {loader: false})
  },
  createIframe(user_id = null, type = 'whatsapp') {
    return ApiService.post(`wazzup/iframe`, { user_id, type })
  },
  takeover() {
    return ApiService.post(`radist/takeover`)
  }
}

/// *** Binotel Service *** ///

export const BinotelService = {
  initiateCall(phone) {
    return ApiService.post(`binotel/call/initiate`, { phone })
  },
  getCallRecording(id) {
    return ApiService.post(`binotel/call/recording`, { id })
  },
  getUISCallRecording(id) {
    return ApiService.post(`uis/call/recording`, { id })
  },
  getUISEmployees() {
    return ApiService.get(`uis/employees`)
  },
  generateTildaKey() {
    return ApiService.post(`tilda/key/generate`)
  }
}

/// *** Prodoctorov Service *** ///

export const ProdoctorovService = {
  syncData(superadmin = false, clinic_id = '') {
    return ApiService.post(`${superadmin ? 'superadmin/' : ''}prodoctorov/sync?clinic_id=${clinic_id}`)
  },
}

/// *** CRM Service *** ///

export const CRMService = {
  getPlanTreatments(filters) {
    return ApiService.get(`crm/plan_treatments`, { filters })
  },
  initiateSipuniCall(phone) {
    return ApiService.post(`sipuni/call/initiate`, { phone })
  },
  initiateUISCall(phone) {
    return ApiService.post(`uis/call/initiate`, { phone })
  }
}


export const OnlineBookingService = {
  getOnlineBookingServices() {
    return ApiService.get(`online_booking/services`)
  },
  saveOnlineBookingServices(params) {
    return ApiService.post(`online_booking/services`, params)
  },
}

export const TestService = {
  hetznerTest() {
    return ApiService.get(`https://api3.dentist-plus.com/api/test?t=${Date.now()}`)
  },
  yandexTest() {
    return ApiService.get(`https://api.dentist-plus.com/api/test?t=${Date.now()}`)
  },
}
