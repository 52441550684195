<template>
  <div>
    <DataTableFilter :options="options" class="mt-3"/>

    <div style="overflow-x: scroll" class="pt-3">

      <b-row class="flex-nowrap" no-gutters>
        <b-col v-for="(l, lind) in leadStatuses"
               :key="`lead-${lind}`">
          <div class="lead-wrap h-100"
               :class="{ 'ml-3': lind > 0 }">
            <div>
              {{ $t(l.title) }} | <span :class="{ 'text-danger': !!leads[l.id] }">{{ leads[l.id] ? leads[l.id].length : 0 }}</span>
              <div class="separator w-100 mt-2 mb-3" :style="`background-color: ${l.color}`"/>
            </div>

            <draggable class="list-group h-100"
                       :list="leads[l.id]"
                       group="leads"
                       :data-status="l.id"
                       :move="leadMoved">
              <div v-for="lead in leads[l.id]"
                   class="lead-card card p-md-3 p-2 mb-3 pointer"
                   @click="showLeadModal(lead)"
                   :data-id="lead.id"
                   :key="`lead-id-${lead.id}`">
                <div class="font-weight-bold pr-4">
                  {{ lead.patient_name }}
                  <span v-if="lead.urgent" class="notification-bubble mr-md-3 mt-md-3 mr-2 mt-2"></span>
                </div>
                <div>{{ lead.phone }}</div>
                <div v-html="lead.comment"/>
                <div>{{ lead.reason }}</div>
                <div>{{ $t('date_add') }}: {{ lead.created_at | formatDate }}</div>
                <div v-if="lead.call_date" class="text-danger">{{ $t('recall') }}: {{ lead.call_date | formatDate }}</div>
                <div v-if="lead.responsible">{{ $t('responsible') }}: {{ lead.responsible.full_name }}</div>
              </div>
            </draggable>
          </div>
        </b-col>
      </b-row>

      <LeadShowModal/>

    </div>
  </div>
</template>

<script>

import { leadStatuses } from "@/dictionaries/dictionary"
import { EloquentService } from "@/services/api.service"
import LeadShowModal from "@/components/parts/crm/LeadShowModal"
import { mapState } from "vuex"
import options from "@/data/crm/lead"
import draggable from 'vuedraggable'
import DataTableFilter from "@/components/parts/datatable/DataTableFilter"
import moment from "moment/moment"

export default {
  name: "CRMSalesFunnel",
  components: {
    DataTableFilter,
    LeadShowModal,
    draggable
  },
  metaInfo() {
    return {
      title: this.$t('sales_funnel'),
      meta: []
    }
  },
  data() {
    return {
      leadStatuses: leadStatuses,
      leads: {},
      options
    }
  },
  computed: {
    ...mapState({
      counter: state => state.datatable.dataTableCounter,
      filters: state => state.datatable.filters,
    }),
  },
  methods: {
    async loadData() {
      try {
        this.initialLeadsState()
        let filters = this.copyObject(this.filters)
        if(Object.keys(filters).length === 0) {
          filters = {
            created_at: {
              id: 'created_at',
              type: 'more',
              condition: moment().subtract(30, 'days').format('YYYY-MM-DD')
            }
          }
        }
        let data = await EloquentService.dataTable('lead', 1, {
          pagination: 1000,
          filters: !Object.keys(this.filters).length ? { custom: { id: 'custom', condition: true } } : this.filters,
          order: 'updated_at',
          sort: 'desc'
        })
        data.data.result.data.forEach(lead => {
          if(!this.leads[lead.status]) {
            this.$set(this.leads, lead.status, [])
          }
          this.leads[lead.status].push(lead)
        })
      } catch (e) {
        console.log(e)
      }
    },
    showLeadModal(lead) {
      this.$store.commit('setLead', this.copyObject(lead))
      this.$bvModal.show('lead-show-modal')
    },
    leadMoved(e) {
      const leadId = String(e.dragged.getAttribute('data-id'))
      const newStatus = e.to.getAttribute('data-status')
      const oldStatus = e.from.getAttribute('data-status')
      const lead = this.leads[oldStatus].find(x => String(x.id) === leadId)
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        status: newStatus,
        source: lead.source,
        fname: lead.fname,
        phone: lead.phone,
      })
      EloquentService.update('lead', leadId, formData, false, false)
    },
    initialLeadsState() {
      this.leads = {}
      this.leadStatuses.forEach(status => {
        this.$set(this.leads, status.id, [])
      })
    },
  },
  mounted() {
    this.$store.commit("setViewModel", 'lead')
    this.$store.commit('setEloquentOptions', options)
    this.loadData()
  },
  watch: {
    counter() {
      this.loadData()
    },
    $route() {
      this.loadData()
    },
  },
  beforeDestroy() {
    this.$store.commit('setFilters', {})
  }
}
</script>

<style scoped lang="scss">
.separator {
  height: 2px;
}
.lead-wrap {
  min-width: 220px;
}
.lead-card {
  border-radius: 8px;
  &:hover {
    background-color: $light-blue;
  }
}
@media screen and (max-width: 768px) {
  .lead-wrap {
    min-width: 150px;
  }
}
</style>
