<template>
  <div v-if="patientsEnabledLogin">
    <b-row>
      <b-col md="4" cols="12">
        <InputGroup :label="$t('login')"
                    type="text"
                    v-model="object.username"/>
        <ValidationError validationId="username"/>
      </b-col>
      <b-col md="4" cols="12">
        <InputGroup type="password"
                    autocomplete="new-password"
                    :label="$t('password')"
                    v-model="object.password"/>
        <ValidationError validationId="password"/>
      </b-col>
      <b-col md="4" cols="12">
        <InputGroup type="password"
                    autocomplete="new-password"
                    :label="$t('confirm_password')"
                    v-model="object.password_confirmation"/>
        <ValidationError validationId="confirm_password"/>
        <div class="text-danger small" v-if="object.password && object.password != object.password_confirmation">{{ $t('password_does_not_match_confirmation') }}</div>
      </b-col>
    </b-row>
    <div v-if="object.password && object.email && rights.includes('password_send')">
      <CheckboxGroup :label="$t('send_password_to_patient')"
                     class="mt-4"
                     :fid="`technics-check`"
                     v-model="object.sendPassword"/>
    </div>
  </div>
</template>

<script>

import InputGroup from "@/components/form/InputGroup"
import ValidationError from "@/components/form/ValidationError"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import {mapState} from "vuex"
// import  { EloquentService } from "@/services/api.service"

export default {
  name: "PatientsDatatableFormLoginForm",
  components: {
    CheckboxGroup,
    InputGroup,
    ValidationError
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  data() {
    return {
      sendPassword: false,
      patientsEnabledLogin: true
    }
  },
  methods: {

  },
  mounted() {
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
    pass() {
      return this.object.password
    }
  },
  watch: {
    pass(val) {
      if(!val) {
        this.object.sendPassword = false
      }
    }
  }
}
</script>

<style scoped>

</style>
