<template>
  <div class="clinic-egisz-data page pb-5 pt-4" style="max-width: 450px;">
    <SelectGroup :options="doctors"
                 title="full_name"
                 variant="white"
                 :showErrors="true"
                 validationId="doctor_id"
                 :label="$t('main_doctor')"
                 :showlabel="true"
                 v-model="form.main_doctor_id"/>
    <InputGroup :label="$t('oid_ministry_rf')" v-model="form.oid_ministry_rf"/>
    <div class="mt-5">
      <button class="btn btn-themed btn-themed-squared" @click="save">
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import { EloquentService } from "@/services/api.service"
import SelectGroup from "@/components/form/SelectGroup.vue"
import InputGroup from "@/components/form/InputGroup.vue"

export default {
  name: "SettingsEGISZ",
  metaInfo() {
    return {
      title: this.$t('clinic_data'),
      meta: []
    }
  },
  components: {
    InputGroup,
    SelectGroup,
  },
  data() {
    return {
      form: {},
    }
  },
  computed: {
    ...mapState({
      clinic: state     => state.auth.clinic,
      superadmin: state => state.auth.superadmin,
      doctors: state => state.auth.doctors,
    }),
    additional() {
      return this.clinic.clinic_additional
    },
  },
  methods: {
    async save() {
      this.$store.commit('cleanValidationErrors')
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.form)
      await EloquentService.update('clinic_additional', this.clinic.id, formData)
      await this.$store.dispatch('init')
      this.$noty.info(this.$t('success_message'))
    },
  },
  mounted() {
    this.form = this.copyObject(this.additional)
  }
}
</script>
