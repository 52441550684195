<template>
  <div>
    <div class="template-group data-table-wrapper">
      <table class="table-blue" v-if="visit && Object.keys(diagnoses).length > 0">
        <thead>
        <tr>
          <th>{{ $t('diagnosis') }}</th>
          <th>{{ $t('mkb_code') }}</th>
          <th v-for="t in teeth" :key="`tt-${t}`">
            {{ t | filterTeeth }}
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(d, id) in diagnoses" class="expanded position-relative" :key="`diagnosis-tr-${id}`">
          <td :data-title="$t('diagnosis')" class="order-mob-0">
            {{ d.title }}
          </td>
          <td :data-title="$t('mkb_code')">
            {{ d.mkb_code }}
          </td>
          <td :data-title="t" 
              class="diagnosis-tooth-td"
              v-for="t in teeth" 
              :key="`d-t-${id}-${t}`">
            <input type="checkbox"
                   :value="t"
                   @change="$emit('versionChange'); emitDiagnoses()"
                   v-model="d.teeth"/>
          </td>
          <td class="diagnosis-delete-td">
            <a @click="deleteDiagnosis(id)"><TrashSvg/></a>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="mt-4">
        <div v-if="teeth.length > 0">
          <label class="form-label">{{ $t('add_diagnosis') }}</label>
          <Autocomplete :placeholder="$t('search')+'...'"
                        model="diagnosis_template"
                        class="template-like"
                        @input="newDiagnosisFound"/>
          <div class="text-right mt-3">
            <a class="btn-themed btn-themed-outline btn-themed-squared btn" @click="createDiagnosis">
              {{ $t('create_diagnosis') }}
            </a>
          </div>
        </div>
        <div v-if="teeth.length < 1">{{ $t('to_add_diagnoses_you_need_to_select_teeth') }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import Autocomplete from "@/components/parts/general/Autocomplete"
import TrashSvg from '@/assets/svg/general/trash.svg'
import { mapState } from "vuex"

export default {
  name: "VisitDiagnoses",
  components: {
    Autocomplete,
    TrashSvg
  },
  props: {
    visit: {
      type: Object
    },
    view: {
      type: String,
      default: "visit"
    },
    readonly: {
      type: Boolean,
      default: false
    },
    versionCount: {
      type: Number
    },
    // diagnosesPassed: {}
  },
  computed: {
    ...mapState({
      teeth: state => state.teeth.recordTeeth,
      diagnoses: state => state.treatment.diagnoses,
      newEloquentObject: state => state.datatable.newEloquentObject,
    }),
  },
  data() {
    return {
      // diagnoses: {},
      addingNew: false
    }
  },
  methods: {
    parseDiagnoses() {
      this.$store.commit('parseDiagnoses', { view: this.view, visit: this.visit })
      // let arr = {}
      // let visit_teeth = this.view === 'plan' ? 'teeth' : 'record_teeth'
      // let tooth_teeth = this.view === 'plan' ? 'tooth' : 'teeth'
      // if(!this.visit || !this.visit[visit_teeth]) {
      //   this.$store.commit('setDiagnoses', arr)
      //   return
      // }
      // this.visit[visit_teeth].forEach(tooth => {
      //   tooth.diagnoses.forEach(diagnose => {
      //     if(!arr[diagnose.id]) {
      //       diagnose.teeth = [tooth[tooth_teeth]]
      //       arr[diagnose.id] = diagnose
      //     } else {
      //       arr[diagnose.id].teeth.push(tooth[tooth_teeth])
      //     }
      //   })
      // })
      // this.$store.commit('setDiagnoses', arr)
    },
    newDiagnosisFound(diagnosis) {
      this.$store.commit('newDiagnosisFound', { diagnosis, teeth: this.copyObject(this.teeth) })
      // if(!this.diagnoses[diagnosis.id]) {
      //   diagnosis.teeth = this.copyObject(this.teeth)
      //   this.$set(this.diagnoses, diagnosis.id, diagnosis)
      // }
    },
    deleteDiagnosis(id) {
      this.$store.commit('deleteDiagnosis', id)
      // this.$delete(this.diagnoses, id)
    },
    createDiagnosis() {
      this.$store.commit('setViewModel', 'general_template')
      this.$store.commit('setModule', 'templates')
      this.$store.commit('setSelectedModel', {
        template_type: 'diagnosis'
      })
      this.$store.commit('setShowModal', true)
    },
    emitDiagnoses() {
      this.$store.commit('setDiagnoses', this.copyObject(this.diagnoses))
      // this.$emit('parseDiagnoses', this.diagnoses)
    }
  },
  mounted() {
    this.parseDiagnoses()
    if(this.newEloquentObject && this.newEloquentObject.model_name == "general_template") {
      this.newDiagnosisFound(this.copyObject(this.newEloquentObject))
    }
  },
  watch: {
    // diagnoses: {
    //   deep: true,
    //   handler() {
        // this.emitDiagnoses()
      // }
    // },
    newEloquentObject: {
      deep: true,
      immediate: true,
      handler(val) {
          if(val && val.model_name == "general_template") {
            this.newDiagnosisFound(val)
          }
      }
    }
  }
}
</script>

<style scoped>

</style>
