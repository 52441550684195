<template>
  <b-modal id="add-new-visit-modal"
           size="lg"
           @show="onShow"
           @hide="onHide"
           :title="modalTitle"
           centered>
    <b-tabs nav-class="sub-menu"
            content-class="pt-md-3 pt-2"
            v-if="visit && loaded">
      <b-tab active :title="$t('description')">
        <VisitModalDescriptionPart :visit="visit"
                                   ref="descriptionPart"
                                   @dateIsInSchedule="dateIsInSchedule"
                                   @conflictingRecord="setConflictingRecord"
                                   @changedPatient="patientSet"/>
      </b-tab>
      <b-tab :title="$t('additionally')" v-if="!editBlock">
        <VisitModalAdditionallyPart :visit="visit"
                                    :counter="visitChangeCounter"/>
      </b-tab>
      <b-tab v-if="rights.includes('notifications') && enableNotifications"
             :title="$t('patient_notifications')">
        <VisitModalNotificationsPart :visit="visit"/>
      </b-tab>
      <b-tab v-if="rights.includes('tasks') && enableTasks"
             :title="$t('related_tasks')">
        <VisitModalTasksPart :visit="visit"/>
      </b-tab>
      <b-tab :title="$t('info')"
             v-if="visit.id && !editBlock">
        <VisitModalInfoPart :visit="visit"/>
      </b-tab>
    </b-tabs>
    <div v-if="conflictingRecord" class="text-danger small">
      {{ $t('record_conflict') }}:
      <span v-if="conflictingRecord.patient">{{ conflictingRecord.patient.full_name }}| </span>
      {{ conflictingRecord.readableStart }}
      <span v-if="conflictingRecordDoctor">| {{ conflictingRecordDoctor.full_name }}</span>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="ml-auto ml-sm-0 cancel d-inline-block d-sm-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('cancel') }}
        </b-button>
        <button :disabled="loading"
                @click="save"
                class="ml-3 btn-themed align-middle d-inline-block d-sm-inline-block btn-themed-squared">
          {{ $t('save') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState, mapActions } from "vuex"
import { CalendarService } from "@/services/api.service"
import VisitModalDescriptionPart from "@/components/parts/calendar/visitmodal/VisitModalDescriptionPart"
import VisitModalAdditionallyPart from "@/components/parts/calendar/visitmodal/VisitModalAdditionallyPart"
import VisitModalNotificationsPart from "@/components/parts/calendar/visitmodal/VisitModalNotificationsPart"
import VisitModalTasksPart from "@/components/parts/calendar/visitmodal/VisitModalTasksPart"
import VisitModalInfoPart from "@/components/parts/calendar/visitmodal/VisitModalInfoPart"

export default {
  name: "AddNewVisitModal",
  components: {
    VisitModalInfoPart,
    VisitModalTasksPart,
    VisitModalNotificationsPart,
    VisitModalAdditionallyPart,
    VisitModalDescriptionPart,
  },
  data() {
    return {
      visit: null,
      loaded: false,
      visitChangeCounter: 0,
      inSchedule: true,
      conflictingRecord: null,
      enableNotifications: false,
      enableTasks: false
    }
  },
  computed: {
    ...mapState({
      errors: state => state.datatable.validationErrors,
      loading: state => state.dom.loading,
      newVisitDoctor: state => state.calendar.newVisitDoctor,
      newVisitChair: state => state.calendar.newVisitChair,
      newVisitId: state => state.calendar.newVisitId,
      newVisitPatientId: state => state.calendar.newVisitPatientId,
      newVisitDurationMinutes: state => state.calendar.newVisitDurationMinutes,
      newVisitLeadId: state => state.calendar.newVisitLeadId,
      patientFromCall: state => state.calendar.patientFromCall,
      phoneFromCall: state => state.calendar.phoneFromCall,
      leadForRecord: state => state.calendar.leadForRecord,
      rights: state => state.auth.rights,
      branch: state => state.auth.branch,
      doctorsFromCalendar: state => state.calendar.doctors,
      clientSources: state => state.auth.clientSources
    }),
    conflictingRecordDoctor() {
      if(!this.conflictingRecord || !this.conflictingRecord.doctor_id) return null
      return this.doctorsFromCalendar.find(x => x.id == this.conflictingRecord.doctor_id)
    },
    editBlock() {
      return this.visit?.id && this.visit?.record_type === 'block'
    },
    modalTitle() {
      if(this.editBlock) return this.$t('blocked')
      return this.visit && this.visit.id ? (this.$t('visit')+' #'+this.visit.id) : this.$t('new_visit')
    }
  },
  methods: {
    ...mapActions(['getClientVisit']),
    async save() {
      if(this.rights.includes('ignore_schedule') && !this.inSchedule) {
        let res = confirm(this.$t('doctor_is_not_working_on_this_date_continue'))
        if(!res) return
      }
      this.$store.commit('cleanValidationErrors')
      if(this.visit.record_type === 'online' && this.visit.client_id) this.visit.record_type = 'treatment'
      await CalendarService.createOrUpdateRecord(this.visit)
      this.$noty.info(this.$t('success_message'))
      this.$bvModal.hide('add-new-visit-modal')
      this.$store.commit('incrementDataTableCounter')
    },
    initVisit() {
      this.visit = {
        doctor_id: null,
        chair_id: null,
        branch_id: this.branch,
        start: null,
        records_statuses_id: 1,
        record_type: 'treatment',
        planned_services: [],
        tasks: [],
        record_notifications: [],
      }
    },
    patientSet(patient_id) {
      this.visitChangeCounter = patient_id
    },
    dateIsInSchedule(val) {
      this.inSchedule = val
    },
    setConflictingRecord(val) {
      this.conflictingRecord = val
    },
    async onShow() {
      if(this.newVisitId) {
        this.visit = await this.getClientVisit({
          id: this.newVisitPatientId,
          visit_id: this.newVisitId
        })
        if(!this.visit.chair_id && this.newVisitChair) {
          this.$set(this.visit, 'chair_id', this.newVisitChair.id)
        }
        if(!this.visit.client_id && this.newVisitPatientId) {
          this.$set(this.visit, 'client_id', this.newVisitPatientId.id)
          this.$set(this.visit, 'patient', this.newVisitPatientId)
        }
      } else {
        this.initVisit()
        this.$set(this.visit, 'doctor_id', this.newVisitDoctor ? this.newVisitDoctor.id : null)
        this.$set(this.visit, 'chair_id', this.newVisitChair ? this.newVisitChair.id : null)
        this.$set(this.visit, 'lead_id', this.newVisitLeadId ? this.newVisitLeadId : null)
        this.$set(this.visit, 'start', this.newVisitTime)
        if(this.newVisitPatientId) {
          this.$set(this.visit, 'patient', this.newVisitPatientId)
          this.$set(this.visit, 'client_id', this.newVisitPatientId.id)
        } else {
          if(this.patientFromCall) {
            this.$set(this.visit, 'patient', this.patientFromCall)
            this.$set(this.visit, 'client_id', this.patientFromCall.id)
          }
        }
        if(this.leadForRecord) {
          this.$set(this.visit, 'lead_id', this.leadForRecord.id)
          this.$set(this.visit, 'source_id', this.leadForRecord.source_id)
        }
        if(this.newVisitDurationMinutes) {
          this.$set(this.visit, 'durationMinutes', this.newVisitDurationMinutes)
        }
      }
      if(this.leadForRecord && !this.visit.source_id) {
        this.$set(this.visit, 'source_id', this.leadForRecord.source_id)
      }
      await this.loadPrepare()
      this.loaded = true
      await this.$nextTick()
    },
    onHide() {
      this.initVisit()
      this.loaded = false
      this.visitChangeCounter = 0
      this.doctorAvailableTimes = null
      this.$store.commit('cleanValidationErrors')
      this.$store.commit('setPatientFromCall', null)
      this.$store.commit('setLeadForRecord', null)
      this.$store.commit('setPhoneFromCall', null)
    },
    fixOnUser() {
      this.$refs.descriptionPart.openPatientDropdown()
    },
    async loadPrepare() {
      if(!this.clientSources.length) {
        await this.$store.dispatch('getClientSources')
      }
    }
  },
}
</script>
