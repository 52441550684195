export default function auth({ next, router, to }) {
    if(!router.app.$store || !router.app.$store.state.auth.isLogged) {
        router.push('/login').catch(()=>{})
        return
    }
    if(to.meta.rights && Array.isArray(to.meta.rights)) {
        to.meta.rights.forEach(item => {
            if(!router.app.$store.state.auth.rights || !router.app.$store.state.auth.rights.includes(item)) {
                router.push('/login').catch(()=>{})
                return
            }
        })
    }
    return next()
}
