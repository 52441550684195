export default {
    cashdesk: "Kassa",
    cash_operation: "Naqd pul muomalasi",
    cheque: "Chek",
    cheques: "Cheklar",
    closing_balance: "Kun oxiridagi balans",
    close_shift: "Smenani yopish",
    data_is_invalid: "Ma’lumotlar noto‘g‘ri",
    data_is_valid: "Ma’lumotlar to‘g‘ri",
    injection: "To'ldirish",
    name_of_service_in_cheque: "Chiptadagi xizmat nomi",
    not_subject_to_vat: "QQSga soliq qo‘yilmaydi",
    opening_balance: "Kunning boshida muvozanat",
    open_shift: "Smenani ochish",
    opened_at: "Ochilish sanasi",
    sales: "Sotuvlar",
    send_services_in_cheque: "Xizmatlarni chiptada yuborish",
    shift: "Smena",
    shifts: "Smenalar",
    shift_number: "Smena raqami",
    vat_rate: "QQS stavkasi",
    withdrawal: "Olib tashlash",
    xreport: "X-hisobot",
    xreports: "X-hisobotlar"
}
