<template>
  <a v-if="user">
    <b-dropdown right variant="link" no-caret>
      <template #button-content>
        <UserSvg class="user-svg"/>
        <span class="user-name d-none d-xl-inline">{{ user.fname }}</span>
      </template>
      <b-dropdown-item v-if="clinic && !superadmin">{{ $t('clinic_id') }}: {{ clinic.id }}</b-dropdown-item>
      <b-dropdown-item v-if="clinic && !superadmin" @click="editMyInfo">{{ $t('edit') }}</b-dropdown-item>
      <b-dropdown-item @click="logout">{{ $t('logout') }}</b-dropdown-item>
      <b-dropdown-divider v-if="clinic.id === 1843"></b-dropdown-divider>
      <b-dropdown-item link-class="text-danger" v-if="clinic.id === 1843" @click="deleteProfile">{{ $t('delete') }}</b-dropdown-item>
    </b-dropdown>
  </a>
</template>

<script>

import { mapState } from "vuex"
import UserSvg from '@/assets/svg/header/user.svg'
import { roles } from "@/dictionaries/dictionary"

export default {
  name: "HeaderUser",
  components: {
    UserSvg
  },
  computed: {
    ...mapState({
      user:  state => state.auth.user,
      clinic:  state => state.auth.clinic,
      superadmin:  state => state.auth.superadmin,
      newEloquentObject: state => state.datatable.newEloquentObject,
    }),
    viewModel() {
      return roles[this.user.role_id]
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
      this.$router.push('/login').catch(()=>{})
    },
    editMyInfo() {
      this.$store.commit('setViewModel', this.viewModel)
      this.$store.commit('setModule', 'staff')
      this.$store.commit('setSelectedModel', this.copyObject(this.user))
      this.$store.commit('setShowModal', true)
    },
    async deleteProfile() {
      this.$store.commit('setLoading', true)
      await new Promise(resolve => setTimeout(resolve, 500))
      this.$noty.info('Ваш аккаунт будет удалён через 24 часа')
      this.$store.commit('setLoading', false)
    }
  },
  watch: {
    newEloquentObject: {
      deep: true,
      handler(val) {
        if(val && val.id == this.user.id && val.model_name == this.viewModel) {
          this.$store.commit('setUser', this.copyObject(val))
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.dropdown-toggle {
  margin-top: -2px;
}
.user-name {
  top: -1px;
  position: relative;
}
.user-svg {
  top: -2px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .user-svg {
    margin-right: 0;
  }
}
</style>
