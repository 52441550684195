import Vue from "vue"
import { doctorAdapter } from "@/adapters/staff/doctor"

import options from "@/data/staff/doctor"
let administrator_options = Vue.prototype.copyObject(options)
administrator_options.model = 'administrator'
administrator_options.fields.forEach(item => {
    // if(['percent','rate','warehouse_id'].includes(item.id)) { delete item.form }
    if(['warehouse_id','doctors_profession_id'].includes(item.id)) { delete item.form }
    if(['full_name'].includes(item.id)) { item.tdcomponent = 'StaffDatatableTDStaffName' }
    if(item.form && item.form.type === 'separator') { delete item.form }
})
administrator_options.actionscomponent = 'StaffDatatableAdministratorActions'
administrator_options.adapter = doctorAdapter
export default administrator_options
