<template>
  <div class="mb-4" v-if="hasRight">
    <b-button v-b-toggle.prodoctorov-c class="btn-collapse">Prodoctorov</b-button>
    <b-collapse id="prodoctorov-c" class="form-group-standard">
      <div class="mt-3 pb-2">
        <InputGroup v-model="prodoctorov_token"
                    :label="$t('api_token')"/>
        <button class="btn-themed btn-themed-squared d-inline-block mt-3"
                :disabled="loading"
                @click="save">
          {{ $t('save') }}
        </button>
        <button v-if="prodoctorov_token"
                class="btn-themed btn-themed-squared btn-themed-outline d-inline-block ml-2"
                @click="syncData"
                :disabled="loading">
          {{ $t('sync_data') }}
        </button>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { ClinicService, ProdoctorovService } from "@/services/api.service"
import InputGroup from "@/components/form/InputGroup"

export default {
  name: "ProdoctorovIntegration",
  components: {
    InputGroup,
  },
  data() {
    return {
      prodoctorov_token: '',
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      superadmin: state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        prodoctorov_token: '',
      }
    },
    hasRight() {
      if(this.superadmin) return this.clinic.rights.includes('prodoctorov')
      return this.rights.includes('prodoctorov')
    }
  },
  async mounted() {
    this.prodoctorov_token = this.ppoSettings.prodoctorov_token
  },
  methods: {
    async save() {
      await ClinicService.savePPOAttribute([
        'prodoctorov_token',
      ], [
        this.prodoctorov_token,
      ], this.superadmin, this.clinic.id)
      this.$store.dispatch('init')
      this.$noty.info(this.$t('success_message'))
    },
    async syncData() {
      let res = await ProdoctorovService.syncData(this.superadmin, this.clinic.id)
      if(res && res.data) {
        this.$noty.info(this.$t('success_message'))
      }
    }
  }
}
</script>
