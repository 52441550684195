<template>
  <div v-if="hasWebkassaRight" class="pb-3">
    <b-button class="btn-collapse" v-b-toggle="`collapse-webkassa`">Webkassa</b-button>
    <b-collapse id="collapse-webkassa" class="pt-4">
      <div class="alert alert-info" v-if="!ppoData.error">{{ $t('data_is_valid') }}</div>
      <div class="alert alert-danger" v-if="ppoData.error">{{ $t('data_is_invalid') }}</div>
      <InputGroup v-model="webkassa.webkassa_email"
                  class="mb-3"
                  :label="$t('email')"/>
      <InputGroup v-model="webkassa.webkassa_password"
                  class="mb-3"
                  :label="$t('password')"/>
      <SelectGroup :label="$t('cashdesk')"
                   class="mb-3"
                   v-if="cashdesks.length"
                   :options="cashdesks"
                   variant="white"
                   title="UniqueNumber"
                   valuekey="UniqueNumber"
                   :showlabel="true"
                   :null-option="false"
                   v-model="webkassa.webkassa_kassa"/>
      <InputGroup v-model="webkassa.webkassa_service"
                  v-if="cashdesks.length"
                  class="mb-3"
                  :label="$t('name_of_service_in_cheque')"/>
      <ServiceMultiSelect :label="$t('pay_methods_cash')"
                          :dblclick="false"
                          model="pay_method"
                          title="translatedTitle"
                          class="mb-3"
                          :dbsearch="false"
                          :prepopulatedOptions="pay_methods"
                          v-model="webkassa.webkassa_cashmethods"/>
      <InputGroup v-model="webkassa.webkassa_vat"
                  class="mb-3"
                  type="number"
                  :label="$t('vat_rate')+ ' (%)'"/>
      <div class="mt-4">
        <button class="btn btn-themed btn-themed-squared"
                :disabled="loading"
                @click="saveWebkassa">
          {{ $t('save') }}
        </button>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { ClinicService, CashdeskService } from "@/services/api.service"
import { mapState } from "vuex"
import InputGroup from "@/components/form/InputGroup.vue"
import SelectGroup from "@/components/form/SelectGroup"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"

export default {
  name: "WebkassaIntegration",
  components: {
    ServiceMultiSelect,
    InputGroup,
    SelectGroup
  },
  computed: {
    ...mapState({
      clinic: state   => state.auth.clinic,
      superadmin: state => state.auth.superadmin,
      rights: state => state.auth.rights,
      loading: state => state.dom.loading,
      ppoData: state => state.ppo.ppoData,
      pay_methods: state => state.auth.pay_methods,
      branch: state => state.auth.branch
    }),
    standardSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        [`webkassa_email${this.branch}`]: '',
        [`webkassa_password${this.branch}`]: '',
        [`webkassa_kassa${this.branch}`]: '',
        [`webkassa_service${this.branch}`]: '',
        [`webkassa_cashmethods${this.branch}`]: [],
        [`webkassa_vat${this.branch}`]: '',
      }
    },
    hasWebkassaRight() {
      return this.rights.includes('webkassa')
    }
  },
  data() {
    return {
      webkassa: {
        webkassa_email: '',
        webkassa_password: '',
        webkassa_kassa: '',
        webkassa_service: '',
        webkassa_cashmethods: '',
        webkassa_vat: '',
      },
      cashdesks: []
    }
  },
  methods: {
    async saveWebkassa() {
      await ClinicService.savePPOAttribute([
        `webkassa_email${this.branch}`,
        `webkassa_password${this.branch}`,
        `webkassa_kassa${this.branch}`,
        `webkassa_service${this.branch}`,
        `webkassa_cashmethods${this.branch}`,
        `webkassa_vat${this.branch}`,
      ], [
        this.webkassa.webkassa_email,
        this.webkassa.webkassa_password,
        this.webkassa.webkassa_kassa,
        this.webkassa.webkassa_service,
        this.webkassa.webkassa_cashmethods.map(x => x.id),
        this.webkassa.webkassa_vat,
      ], this.superadmin, this.clinic.id)
      this.$noty.info(this.$t('success_message'))
      await this.getCashdesks()
      this.$emit('update')
    },
    async getCashdesks() {
      const res = await CashdeskService.webkassaData('cashdesks')
      this.cashdesks = res.data
    }
  },
  async mounted() {
    await this.getCashdesks()
    this.webkassa.webkassa_email = this.standardSettings[`webkassa_email${this.branch}`]
    this.webkassa.webkassa_password = this.standardSettings[`webkassa_password${this.branch}`]
    this.webkassa.webkassa_kassa = this.standardSettings[`webkassa_kassa${this.branch}`]
    this.webkassa.webkassa_service = this.standardSettings[`webkassa_service${this.branch}`]
    this.webkassa.webkassa_vat = this.standardSettings[`webkassa_vat${this.branch}`]
    this.webkassa.webkassa_cashmethods = []
    let cashMethods = this.standardSettings[`webkassa_cashmethods${this.branch}`]
    if(cashMethods) this.webkassa.webkassa_cashmethods = this.pay_methods.filter(p => cashMethods.includes(p.id))
  }
}
</script>
