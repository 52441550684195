<template>
  <div class="form-group">
    <div class="select-wrapper">
      <label class="form-label"
             :data-content="label"
             v-if="showlabel">
        <span class="">{{ label }}</span>
        <span v-if="required"> *</span>
        <span v-if="infoHint">
          <a :title="infoHint"
             class="info ml-3"
             v-b-tooltip.hover>
      <InfoSvg class="info-svg"/>
    </a>
        </span>
      </label>
      <select class="form-control"
              :disabled="readonly"
              :class="{ 'gray-input': variant === 'gray' }"
              @input="handleInput"
              :value="value">
        <option v-if="nullOption" :value="null">-</option>
        <option v-for="o in options" :value="valuekey ? o[valuekey] : o.id" :key="'opt-'+o.id+'-'+label">
          {{ title ? t(o,title) : (translate && $te(o.title) ? $t(o.title) : o.title) }}
        </option>
      </select>
    </div>
    <ValidationError class="mt-1" v-if="showErrors" :validationId="validationId"/>
  </div>
</template>

<script>

import ValidationError from "@/components/form/ValidationError"
import InfoSvg from '@/assets/svg/general/question.svg'

export default {
  name: "SelectGroup",
  components: {
    ValidationError,
    InfoSvg
  },
  data() {
    return {
      content: this.value
    }
  },
  props: {
    label: {
      type: String
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    validationId: {},
    value: {},
    valuekey: {},
    options: {
      type: Array,
      required: true
    },
    showlabel: {
      type: Boolean,
      default: false
    },
    translate: {
      type: Boolean,
      default: true
    },
    title: {
      type: String
    },
    variant: {
      type: String,
      default: 'gray'
    },
    nullOption: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    infoHint: {
      type: String,
      default: ''
    },
    readonly: {},
  },
  mounted() {
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>
