<template>
  <span>
<!--  <a class="data-table-action"-->
<!--     @click="openReceipt"-->
<!--     v-b-tooltip.hover-->
<!--     :title="$t('receipt')"-->
<!--  >-->
<!--    <PrintSvg/><span class="action-button-mob-title">{{ $t('receipt') }}</span>-->
<!--  </a>-->

    <a class="data-table-action"
       v-b-tooltip.hover
       :title="$t('print')">
      <b-dropdown right variant="link" no-caret>
        <template #button-content>
          <PrintSvg/><span class="action-button-mob-title">{{ $t('print') }}</span>
        </template>
        <a class="dropdown-item pointer" @click="openReceipt">
          {{ $t('receipt') }}
        </a>
        <a class="dropdown-item pointer" @click="openCheque">
          {{ $t('cheque') }}
        </a>
      </b-dropdown>
    </a>

<!--    <a class="data-table-action"-->
<!--       v-b-tooltip.hover-->
<!--       :title="$t('edit')">-->
<!--      <EditSvg/><span class="action-button-mob-title">{{ $t('edit') }}</span>-->
<!--    </a>-->



<!--    <a class="data-table-action"-->
<!--       v-if="object.treatment_id"-->
<!--       @click="openAkt"-->
<!--       v-b-tooltip.hover-->
<!--       :title="$t('akt_print')"-->
<!--    >-->
<!--    <NoteSvg/><span class="action-button-mob-title">{{ $t('akt_print') }}</span>-->
<!--  </a>-->
    </span>

</template>

<script>

import PrintSvg from '@/assets/svg/general/print2.svg'
// import EditSvg from '@/assets/svg/general/edit.svg'
// import NoteSvg from '@/assets/svg/header/note.svg'
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"

export default {
  name: "PaymentDatatableActions",
  components: {
    PrintSvg,
    // EditSvg
    // NoteSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  methods: {
    openReceipt() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/receipt/'+this.object.id, '_blank')
    },
    openCheque() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/cheque/'+this.object.id, '_blank')
    },
    openAkt() {
      let signer = 'manager'
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/akt/'+this.object.treatment_id+'?signer='+signer, '_blank')
    }
  }
}
</script>

<style scoped>

</style>
