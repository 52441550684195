<template>
  <router-link class="position-relative btn btn-plain" :to="{name: 'WaitingList'}">
    <ClockSvg/>
    <span class="d-none d-xl-inline">{{ $t('waiting_list') }}</span>
    <span class="notification-bubble" v-if="hasWaiting"></span>
  </router-link>
</template>

<script>

import {mapState} from "vuex"
import ClockSvg from '@/assets/svg/header/clock3.svg'

export default {
  name: "HeaderWaitingList",
  components: {
    ClockSvg
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
    hasWaiting() {
      return this.$store.state.auth.headerCounters.waiting > 0
    }
  },
}
</script>

<style scoped lang="scss">
.notification-bubble {
  top: 0;
  left: 10px;
}
</style>
