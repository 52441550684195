<template>
  <div>
    <div class="mb-3">
      <div class="d-inline-block mr-4" 
           v-for="r in roles" 
           :key="`role-chbx-${r}`">
        <CheckboxGroup :label="$t(r)"
                       :fid="`role-${r}`"
                       :checkboxvalue="r"
                       class="mt-4 sm"
                       v-model="selectedRoles"/>
      </div>
    </div>
    <DataTableHtml :columns="columns"
                   primaryKey="none"
                   :params="{ role: selectedRolesTranslated }"
                   initialOrder="name"
                   initialSort="asc"
                   :rows="rows"/>
    <AccountingSalaryModal @selectRow="selectRow"/>
  </div>
</template>

<script>

import { AccountingService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import AccountingSalaryModal from "@/components/parts/accounting/AccountingSalaryModal"
import {mapState} from "vuex"

export default {
  name: "AccountingMonthSalaries",
  components: {
    AccountingSalaryModal,
    DataTableHtml,
    CheckboxGroup
  },
  metaInfo() {
    return {
      title: this.$t('salaries'),
      meta: []
    }
  },
  data() {
    return {
      roles: [
        'doctor','technic','manager','assistant'
      ],
      selectedRoles: [
        'doctor','technic','manager','assistant'
      ],
      columns: [
        {
          id: 'name',
          field: 'name',
          label: this.$t('name'),
          tabletdclass: "order-mob-0",
          sortable: true
        },
        {
          id: 'role',
          field: 'role',
          label: this.$t('role'),
          search_select: true,
          sortable: true
        },
        {
          id: 'fixed',
          field: 'fixed',
          label: this.$t('rate_per_month'),
          format_number: true,
          sortable: true,
          totals: true,
        },
        {
          id: 'daily_rate',
          field: 'daily_rate',
          label: this.$t('rate_per_day'),
          format_number: true,
          sortable: true,
          totals: true,
        },
        {
          id: 'percent',
          field: 'percent',
          label: this.$t('percent'),
          format_number: true,
          sortable: true,
          totals: true,
        },
        {
          id: 'adjustments',
          field: 'adjustments',
          label: this.$t('adjustment'),
          format_number: true,
          sortable: true,
          totals: true,
        },
        {
          id: 'sum',
          field: 'sum',
          label: this.$t('sum'),
          format_number: true,
          sortable: true,
          totals: true,
        },
        {
          id: 'paid',
          field: 'paid',
          label: this.$t('paid'),
          format_number: true,
          sortable: true,
          totals: true,
        },
        {
          id: 'left_to_pay',
          field: 'left_to_pay',
          label: this.$t('left_to_pay'),
          format_number: true,
          sortable: true,
          totals: true,
        },
        {
          id: 'action',
          field: 'action',
          hide_search: true,
          tdcomponent: 'AccountingMonthDatatableTDSalariesAction',
          label: this.$t('actions'),
        },
      ],
      rows: [],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      const res = await AccountingService.salaries(this.$route.params.id)
      this.rows = res.data
    },
    async selectRow(row) {
      await this.load()
      this.$store.commit('setSalaryRow', this.copyObject(this.rows.find(x => x.user_id === row.user_id)))
    }
  },
  computed: {
    selectedRolesTranslated() {
      return this.selectedRoles.map(obj => this.$t(obj))
    },
    ...mapState({
      counter: state => state.datatable.dataTableCounter,
    }),
  },
  watch: {
    counter() {
      this.load()
    }
  }
}
</script>

<style scoped>

</style>
