<template>
  <a v-if="rights.includes('payments') && !isPatient" @click="pay" class="pointer">
    <DollarSvg class="svg-light"/>
  </a>
</template>

<script>

import {mapState} from "vuex"
import DollarSvg from '@/assets/svg/patients/dollar.svg'

export default {
  name: "PatientsDatatableTDDebtorPay",
  components: {
    DollarSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
    isPatient() {
      return this.$store.getters.isPatient
    }
  },
  methods: {
    pay() {
      this.$store.commit('setPatient', this.object)
      this.$nextTick(() => {
        this.$bvModal.show('patient-topay-modal')
      })
    }
  }
}
</script>

<style scoped>

</style>
