<template>
  <div>
    <template v-for="(v, vind) in validationIds">
      <div class="validation-error"  v-if="errors[v]" :key="`val-id-${vind}`">
        {{ errors[v].join(', ') }}
      </div>
    </template>
  </div>
</template>

<script>
import {mapState} from "vuex"

export default {
  name: "ValidationError",
  props: {
    validationId: {

    },
  },
  computed: {
    ...mapState({
      errors: state => state.datatable.validationErrors,
    }),
    validationIds() {
      if(!this.validationId) return []
      return Array.isArray(this.validationId) ? this.validationId : [this.validationId]
    }
  }
}
</script>

<style scoped>

</style>
