<template>
  <div class="notification-teaser" :class="{ unread: !notification.is_read }">
    <div class="notification-text pr-4 pb-1">
      {{ notification.text }}
    </div>
    <div class="text-right text-muted small">
      {{ notification.sent_at }}
    </div>
    <a class="position-absolute notification-delete pointer" @click="deleteNotification">
      <TrashSvg class="svg-light mr-0"/>
    </a>
  </div>
</template>

<script>

import TrashSvg from '@/assets/svg/general/trash_tr.svg'
import {EloquentService} from "@/services/api.service"

export default {
  name: "NotificationTeaser",
  components: {
    TrashSvg
  },
  props: {
    notification: Object
  },
  methods: {
    deleteNotification() {
      // this.$emit('onDelete')
      let formData = new FormData()
      formData = this.appendFormdata(formData, { show_in_header: 0 })
      EloquentService.update('notification', this.notification.id, formData).then(() => {
        this.$emit('onDelete')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.notification-teaser {
  padding-bottom: 5px;
  position: relative;
  &.unread {
    font-weight: bold;
  }
  .notification-text {
    white-space: break-spaces;
  }
  .notification-delete {
    right: 0;
    display: none;
    top: 0;
  }
  &:hover {
    .notification-delete {
      display: block;
      opacity: 0.5;
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>
