<template>
  <div class="pt-3 form-narrow">
<!--    <div class="radio-inline">-->
<!--      <RadioGroup :options="periodViews"-->
<!--                  :label="$t('period_by_default')"-->
<!--                  :showlabel="true"-->
<!--                  v-model="clinic.calendar_period"-->
<!--                  validationId="periods"/>-->
<!--    </div>-->
<!--    <hr>-->
<!--    <div class="radio-inline">-->
<!--      <RadioGroup :options="calendarViews"-->
<!--                  :label="$t('calendar_view_by_default')"-->
<!--                  :showlabel="true"-->
<!--                  v-model="clinic.chairs"-->
<!--                  validationId="chairs"/>-->
<!--    </div>-->
    <div style="max-width: 500px;">
      <b-row class="align-items-center">
        <b-col>
          <InputGroup v-model="clinic.time_start" :label="$t('work_time_from')"/>
          <ValidationError validation-id="time_start"/>
        </b-col>
        <b-col cols="auto">-</b-col>
        <b-col>
          <InputGroup v-model="clinic.time_end" :label="$t('work_time_to')"/>
          <ValidationError validation-id="time_end"/>
        </b-col>
      </b-row>
    </div>
    <hr>
    <div style="max-width: 300px;">
      <SelectGroup :options="timeSteps"
                   variant="white"
                   :showErrors="true"
                   :translate="false"
                   validationId="calendar_step"
                   :label="$t('time_interval_value')"
                   :showlabel="true"
                   v-model="clinic.calendar_step"/>
    </div>
    <div style="max-width: 300px;">
      <InputGroup v-model="clinic.calendar_color" type="color" :label="$t('calendar_text_color')"/>
    </div>
    <div class="pt-5">
      <a class="btn btn-themed btn-themed-squared" @click="save">{{ $t('save') }}</a>
    </div>
  </div>
</template>

<script>

// import RadioGroup from "@/components/form/RadioGroup"
import { mapState } from "vuex"
import InputGroup from "@/components/form/InputGroup"
import ValidationError from "@/components/form/ValidationError"
import SelectGroup from "@/components/form/SelectGroup"

export default {
  name: "SettingsCalendar",
  components: {
    SelectGroup,
    ValidationError,
    // RadioGroup,
    InputGroup
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  metaInfo() {
    return {
      title: this.$t('calendar'),
      meta: []
    }
  },
  data() {
    return {
      calendarViews: [
        { id: '0', title: 'by_doctors' },
        { id: '1', title: 'by_chairs' },
      ],
      periodViews: [
        { id: 'day', title: 'day' },
        { id: 'week', title: 'week' },
        { id: 'month', title: 'month' },
      ],
      timeSteps: [
        { id: 5, title: '5 '+this.$t('min') },
        { id: 10, title: '10 '+this.$t('min') },
        { id: 15, title: '15 '+this.$t('min') },
        { id: 20, title: '20 '+this.$t('min') },
        { id: 30, title: '30 '+this.$t('min') },
        { id: 60, title: '60 '+this.$t('min') },
      ]
    }
  },
  methods: {
    save() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.clinic)
      this.$store.commit('cleanValidationErrors')
      this.$store.dispatch('saveData', formData).then(() => {
        this.$noty.info(this.$t('success_message'))
      })
    }
  }
}
</script>

<style scoped>

</style>
