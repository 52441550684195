<template>
  <a @click="modal" class="pointer position-relative btn btn-plain">
    <TaskSvg/>
    <span class="d-none d-xl-inline">{{ $t('tasks') }}</span>
    <TasksModal v-if="show"/>
    <TaskShowModal/>
    <span class="notification-bubble" v-if="tasksCounter > 0">
      {{ tasksCounter }}
    </span>
  </a>
</template>

<script>

import TaskSvg from '@/assets/svg/header/note.svg'
import TasksModal from "@/components/parts/header/TasksModal"
import { mapState } from "vuex"
import TaskShowModal from "@/components/parts/crm/TaskShowModal"

export default {
  name: "HeaderTasks",
  components: {
    TaskShowModal,
    TasksModal,
    TaskSvg
  },
  data() {
    return {
      show: false
    }
  },
  computed: {
    ...mapState({
      headerCounters: state => state.auth.headerCounters
    }),
    tasksCounter() {
      return this.headerCounters.tasks
    }
  },
  methods: {
    modal() {
      this.show = true
      this.$nextTick(() => {
        this.$bvModal.show('tasks-modal')
      })
    }
  },
  mounted() {
    // setTimeout(this.getTasks, 3000)
    // this.$echo.channel(`clinic`)
    //     .listen('.clinic', (e) => {
    //       console.log(e)
    //     }).error(err => console.log(err))
  }
}
</script>

<style scoped>
.notification-bubble {
  top: 0;
  left: 8px;
}
</style>
