export default {
    primaryKey: "id",
    model: 'general_template',
    order: 'id',
    sort: 'desc',
    deletemessage: 'are_you_sure_you_want_to_delete_template',
    prepareParams: ['id', 'template_type'],
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'mkb_code',
            title: 'mkb_code',
            form: {
                type: "text",
                component: "GeneralTemplateMKBInput"
            }
        },
        {
            id: 'template_type',
            title: 'type',
            table: true,
            sortable: true,
            format_translate: true,
            filter: true,
            filter_list_select: true,
            form: {
                type: "select",
                select_type: "list",
                disabled_on_edit: true,
                options: {
                    'complaints_templates': { id: 'complaints_templates', title: 'complaints_templates' },
                    'diagnosis': { id: 'diagnosis', title: 'diagnosis' },
                    'bite_templates': { id: 'bite_templates', title: 'bite_templates' },
                    'development_templates': { id: 'development_templates', title: 'development_templates' },
                    'diseases_templates': { id: 'diseases_templates', title: 'diseases_templates' },
                    'inspection_templates': { id: 'inspection_templates', title: 'inspection_templates' },
                    'medication_templates': { id: 'medication_templates', title: 'medication_templates' },
                    'objectively_templates': { id: 'objectively_templates', title: 'objectively_templates' },
                    'plansurvey_templates': { id: 'plansurvey_templates', title: 'plansurvey_templates' },
                    'recommendations_templates': { id: 'recommendations_templates', title: 'recommendations_templates' },
                    'research_templates': { id: 'research_templates', title: 'research_templates' },
                    'subjectively_templates': { id: 'subjectively_templates', title: 'subjectively_templates' }
                },
                required: true
            }
        },
    ]

}
