<template>
  <div>
    <DataTable :options="options"
               :params="{ branch_id: branch }"
               module="staff"/>
    <DoctorScheduleModal/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/staff/doctor"
import DoctorScheduleModal from "@/components/parts/staff/DoctorScheduleModal"
import { mapState } from "vuex"

export default {
  name: "Doctors",
  metaInfo() {
    return {
      title: this.$t('doctors'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      branch: state => state.auth.branch
    })
  },
  components: {
    DataTable,
    DoctorScheduleModal
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    this.$store.commit('setAdditionalParams', { branch_id: this.branch })
  },
  beforeDestroy() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>

<style scoped>

</style>
