<template>
  <div>
    <SelectGroup :options="plan_visits"
                 variant="white"
                 v-if="dictionariesLoaded && rights.includes('planning')"
                 :label="$t('treatment_plan')"
                 :translate="false"
                 :showlabel="true"
                 :readonly="!canBeEdited"
                 v-model="visit.visit_id"/>
    <SelectGroup :options="assistants"
                 v-if="assistantsLoaded"
                 title="full_name"
                 variant="white"
                 :showErrors="true"
                 :readonly="assistantEditDisabled"
                 validationId="assistant_id"
                 :label="$t('assistant')"
                 :showlabel="true"
                 v-model="visit.assistant_id"/>
    <ServiceMultiSelect :label="$t('planned_services')"
                        :dblclick="true"
                        :readonly="!canBeEdited"
                        v-model="visit.planned_services"/>
<!--    <AutosizeTextArea v-model="visit.description"-->
<!--                      :label="$t('description')"/>-->
  </div>
</template>

<script>

// import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import SelectGroup from "@/components/form/SelectGroup"
import {EloquentService, PatientService} from "@/services/api.service"
import {mapState} from "vuex"

export default {
  name: "VisitModalAdditionallyPart",
  components: {
    // AutosizeTextArea,
    ServiceMultiSelect,
    SelectGroup
  },
  props: {
    visit: {},
    counter: {}
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
    patient_id() {
      return this.counter ? this.counter : (this.visit.client_id ?? null)
    },
    canBeEdited() {
      return this.visit && this.visit.is_paid !== '1'
    },
    assistantEditDisabled() {
      return !this.visit || (this.visit.price > 0 && (this.visit.debt == 0 || (this.visit.payments && this.visit.payments.length)))
    }
  },
  data() {
    return {
      plan_visits: [],
      assistants: [],
      dictionariesLoaded: false,
      assistantsLoaded: false
    }
  },
  methods: {
    loadDictionaries() {
      this.dictionariesLoaded = false
      if(!this.patient_id) return
      PatientService.getVisitDictionaries(this.patient_id, this.visit ? this.visit.visit_id : null).then(res => {
        this.plan_visits = res.data.plan_visits
        // this.assistants = res.data.assistants
        this.dictionariesLoaded = true
      })
    },
    loadAssistants() {
      EloquentService.dataTable('assistant', 1, { pagination: 999 }).then(res => {
        this.assistants = res.data.result.data
        this.assistantsLoaded = true
      })
    }
  },
  mounted() {
    this.loadAssistants()
  },
  watch: {
    patient_id() {
      this.loadDictionaries()
    },
    counter() {
      this.loadDictionaries()
    }
  }
}
</script>

<style scoped>

</style>
