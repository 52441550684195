export default {
    cashdesk: "Касса",
    cash_operation: "Кассовая операция",
    cheque: "Чек",
    cheques: "Чеки",
    closing_balance: "Остаток на конец дня",
    close_shift: "Закрыть смену",
    data_is_invalid: "Данные невалидны",
    data_is_valid: "Данные валидны",
    injection: "Пополнение",
    name_of_service_in_cheque: "Название услуги в чеке",
    not_subject_to_vat: "Не облагается НДС",
    opening_balance: "Остаток на начало дня",
    open_shift: "Открыть смену",
    opened_at: "Дата открытия",
    sales: "Продажи",
    send_services_in_cheque: "Передавать услуги в чеке",
    shift: "Смена",
    shifts: "Смены",
    shift_number: "Номер смены",
    vat_rate: "Ставка НДС",
    withdrawal: "Изъятие",
    xreport: "X-отчет",
    xreports: "X-отчеты"
}
