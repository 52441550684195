<template>
  <div class="d-calendar__column-time position-relative w-100">
    <template v-for="(schedule, sind) in schedules">
      <div :key="`sched-${sind}`"
           class="d-calendar__schedule-slot"
           :class="{ customColor: scheduleColor(schedule) !== defaultScheduleColor }"
           :style="{'height': scheduleHeight(schedule) + '%', 'top': scheduleY(schedule) + '%', 'backgroundColor': scheduleColor(schedule) }">
      </div>
    </template>
    <!--    <div v-if="schedule"-->
    <!--         class="d-calendar__schedule-slot"-->
    <!--         :style="{'height': scheduleHeight + '%', 'top': scheduleY + '%'}">-->
    <!--    </div>-->
    <DTimeTracker v-if="item && item.isTodayDate" />
    <DTimeCell v-for="it in timeRows"
               :key="it"
               :date="item ? item.date.format('YYYY-MM-DD') : null"
               :time="it"
               :doctor="doctor_id"
               :chair="chair_id"
               :show-time="isFirst"
               :class="{ blank: !item }"
               :highlight="item && item.isTodayDate"
               :time-height="timeHeight"
               @click.native="addRecord(item, it)"
    />

    <template v-if="item">
      <DRecord ref="d-records"
               v-for="rec in getDateRecords(item)"
               :key="`${rec.id}`"
               @mouseover.native="onMouseOverRecord(rec)"
               @mouseleave.native="onMouseLeaveRecord(rec)"
               @touchstart.native="onTouchStart(rec)"
               @touchend.native="onTouchEnd(rec)"
               @touchmove.native="onTouchMove"
               :record="rec"
               :class="{ touchActive: rec.id === touchRecordId }"
               :pos="getPosition(rec)"
               :doctor="getDoctor(rec.doctor_id)"/>
    </template>
  </div>
</template>

<script>

import ColumnData from "@/components/pages/calendar/calendar/classes/ColumnData"
import { mapGetters, mapState } from "vuex"
import DTimeCell from "@/components/pages/calendar/calendar/DTimeCell"
import DRecord from "@/components/pages/calendar/calendar/DRecord"
import DTimeTracker from "@/components/pages/calendar/calendar/DTimeTracker"
import moment from 'moment'
import { EloquentService } from "@/services/api.service"
import { ViewType } from "@/components/pages/calendar/calendar/const/CalendarConst"

export default {
  name: "DColumn",
  components: {
    DTimeTracker,
    DRecord,
    DTimeCell
  },
  props: {
    item: ColumnData,
    isFirst: Boolean,
    timeOver: Function,
    day: {}
  },
  data() {
    return {
      touchTimer: null,
      touchRecordId: null,
      defaultScheduleColor: '#0787C9'
    }
  },
  computed: {
    ...mapState({
      timeHeight: state => state.calendar.timeHeight,
      doctors: state => state.calendar.doctors,
      chairs:     state => state.auth.branchChairs,
      contextMenuVisible: state => state.calendar.contextMenuVisible,
      curView: state => state.calendar.curView,
      curRange: state => state.calendar.curRange,
      doctorSchedules: state => state.calendar.doctorSchedules,
      timeToHour: state => state.calendar.timeToHour,
      timeStep: state => state.calendar.timeStep,
      rights: state => state.auth.rights,
      justClosedContext: state => state.calendar.justClosedContext,
      recordToMove: state => state.calendar.recordToMove,
      recordToCopy: state => state.calendar.recordToCopy,
    }),
    ...mapGetters([
      'timeRows',
      'getDoctor',
      'getPosition',
      'getDateRecords',
      'getColumnMinutes'
    ]),
    isInVisible(time) {
      return !time.includes(':00') && !time.includes(':30')
    },
    doctor_id() {
      return this.item && this.item.doctor ? this.item.doctor.id : null
    },
    chair_id() {
      return this.item && this.item.chair ? this.item.chair.id : null
    },
    columnMinutes() {
      return (this.timeRows.length) * this.timeStep
    },
    dayFormatted() {
      return moment(this.day).format('YYYY-MM-DD')
    },
    schedules() {
      if(this.curView === 'DOCTOR' && this.curRange === 'DAY' && this.doctor_id) {
        if(this.doctorSchedules[this.doctor_id]) {
          return this.doctorSchedules[this.doctor_id].filter(x => x.day == this.dayFormatted)
        }
      }
      if(this.curView === 'CHAIR' && this.curRange === 'DAY' && this.chair_id) {
        return Object.values(this.doctorSchedules).flat().filter(x => {
          if(x.day != this.dayFormatted) return false;
          if(x.chair_id == this.chair_id) return true;
          if(!x.chair_id && this.item.chair.doctors.find(d => d.id == x.doctor_id)) return true;
          return false;
        })
      }
      return null
    },
  },
  methods: {
    onMouseOverRecord(record) {
      const resizableComponent = this.$children.find(item => record === item.record)
      this.$store.commit('setResizableComponent', resizableComponent)
      this.$store.commit('setResizableRecord', record)
    },
    onMouseLeaveRecord(record) {
      this.$store.commit('unsetResizableRecord', record)
    },
    onTouchStart(record) {
      this.touchTimer = setTimeout(() => {
        this.touchRecordId = record.id
        document.documentElement.style.overflow = 'hidden'
        this.touchTimer = null
      }, 300)
      this.onMouseOverRecord(record)
    },
    onTouchEnd(record) {
      if(this.touchTimer) {
        clearTimeout(this.touchTimer)
      }
      document.documentElement.style.overflow = 'auto'
      this.touchRecordId = null
      this.onMouseLeaveRecord(record)
    },
    onTouchMove() {
      if(this.touchTimer) {
        clearTimeout(this.touchTimer)
      }
    },
    addRecord(colItem, time) {
      if(!colItem) colItem = { doctor: null, chair: null, date: this.day }
      if(!this.rights.includes('add_records')) return
      const clickedTs = `${colItem.date.format('YYYY-MM-DD')} ${time}:00`
      if(this.recordToMove || this.recordToCopy) {
        const end = moment(clickedTs).add(this.recordToMove ? this.recordToMove.durationMinutes : this.recordToCopy.durationMinutes, 'minute').format('YYYY-MM-DD HH:mm:ss')
        let formData = new FormData()
        formData.append('start', clickedTs)
        formData.append('end', end)
        if(colItem.doctor) formData.append('doctor_id', colItem.doctor.id)
        if(colItem.chair) formData.append('chair_id', colItem.chair.id)
        if(this.recordToMove) {
          EloquentService.update('record', this.recordToMove.id, formData).then(() => {
            this.$store.commit('incrementDataTableCounter')
            this.$noty.info(this.$t('success_message'))
          }).catch(() => {})
        }
        if(this.recordToCopy) {
          console.log(this.recordToCopy)
          // this.$store.commit('setNewVisitPatientId', this.recordToCopy.patient)
          this.openAddRecordPopup(clickedTs, colItem.doctor, colItem.chair, {
            client_id: this.recordToCopy.patient,
            durationMinutes: this.recordToCopy.durationMinutes
          })
        }
        this.$store.commit('setRecordToMove', null)
        this.$store.commit('setRecordToCopy', null)
        return
      }
      this.openAddRecordPopup(clickedTs, colItem.doctor, colItem.chair)
    },
    openAddRecordPopup(ts, doctor, chair, additionalParams = {}) {
      if(this.justClosedContext) {
        this.$store.commit('setContextMenuVisible', false)
        return
      }
      if(ts < moment().format('YYYY-MM-DD HH:mm:ss')) {
        let today = moment(ts).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
        if(!this.rights.includes('edit_history') && !(today && this.rights.includes('records_during_day'))) {
          this.$noty.warning(this.$t('time_has_already_passed'))
          return
        }
      }
      const time = ts.slice(-8)
      if(!chair && doctor) { // trying to get chair from schedule
        const schedules = this.doctorSchedules[doctor.id] || []
        const schedule = schedules.find(s => {
          return s.time_from <= time && s.time_to >= time
        })
        if(schedule && schedule.chair_id) chair = this.chairs.find(c => c.id === Number(schedule.chair_id))
      }
      if(!doctor && chair) { // trying to infer doctor from chair
        const scheduleClicked = this.schedules.find(s => s.time_from <= time && s.time_to > time)
        if(scheduleClicked) doctor = this.doctors.find(d => d.id === Number(scheduleClicked.doctor_id))
        if(!doctor) {
          const schedules = this.doctorSchedules
          const doctorsWithSchedules = chair.doctors.filter(doctor => schedules && schedules[doctor.id]);
          if(doctorsWithSchedules.length === 1) {
            doctor = doctorsWithSchedules[0]
          }
        }
      }
      this.$store.commit('setNewVisit', {
        time: ts,
        doctor,
        chair,
        ...additionalParams
      })
      this.$nextTick(() => {
        this.$bvModal.show('add-new-visit-modal')
      })
    },
    scheduleY(schedule) {
      if(!schedule || !schedule.minutes) return 0
      const diff = moment.duration(moment(schedule.time_from.substr(0, 5), "HH:mm")
          .diff(moment(this.timeRows[0], "HH:mm"))).asMinutes()
      if(this.columnMinutes <= 0) return 0
      return (diff / this.columnMinutes) * 100
    },
    scheduleHeight(schedule) {
      if(!schedule) return 0
      if(this.columnMinutes <= 0) return 0
      return (schedule.minutes / this.columnMinutes) * 100
    },
    scheduleColor(schedule) {
      if(this.curView === ViewType.DOCTOR) return this.defaultScheduleColor
      const doctor = this.doctors.find(d => d.id === schedule.doctor_id)
      return doctor && doctor.color ? doctor.color : this.defaultScheduleColor
    }
  }
}
</script>

<style scoped lang="scss">
.d-calendar__column-time {
  border-left: 1px dashed #0787C9;
  font-size: 14px;
}
.d-calendar__column-time:last-child {
  border-right: 1px solid #E5E5E5;
}
.d-calendar__schedule-slot {
  background: #0787C9;
  opacity: 0.18;
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  user-select: none;
  &.customColor {
    opacity: 0.09;
  }
}
</style>
