<template>
  <div>
    <div v-if="ppoData.vendor === 'checkbox'">
      <div class="mb-3">
        <button class="btn btn-themed btn-themed-squared btn-themed-outline" @click="xReport">
          {{ $t('xreport') }}
        </button>
      </div>
      <DataTableHtml :columns="columns"
                     primaryKey="none"
                     initialOrder="created_at"
                     initialSort="desc"
                     :rows="reports"/>
    </div>
    <div v-if="ppoData.vendor === 'webkassa'">
      <div v-if="webkassaXReport">
        <div v-for="(c, ind) in webkassaColumns" :key="`webkassa-${ind}`">
          <template v-if="webkassaXReport[c.id]">
            <span v-if="c.title" class="mr-3">{{ c.title }}</span>
            <span v-if="c.id === 'Sell'">Сумма: {{ webkassaXReport[c.id].Taken }} | Кол-во: {{ webkassaXReport[c.id].Count }}</span>
            <span v-else>{{ webkassaXReport[c.id] }}</span>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DataTableHtml from "@/components/parts/general/DataTableHtml"
import { mapState } from "vuex"
import { CashdeskService } from "@/services/api.service"

export default {
  name: "CashdeskXReports",
  components: {
    DataTableHtml
  },
  metaInfo() {
    return {
      title: this.$t('xreports'),
      meta: []
    }
  },
  computed: {
    ...mapState({
      ppoData: state => state.ppo.ppoData
    }),
    reports() {
      if(!this.ppoData || !this.ppoData.reports) return []
      return this.ppoData.reports.results
    }
  },
  data() {
    return {
      webkassaXReport: null,
      columns: [
        {
          id: 'id',
          field: 'id',
          label: 'ID',
          sortable: true,
        },
        {
          id: 'created_at',
          field: 'created_at',
          label: this.$t('date'),
          format_datetime: true,
          sortable: true
        },
        {
          id: 'sell_receipts_count',
          field: 'sell_receipts_count',
          label: this.$t('sales'),
          format_number: true,
          sortable: true
        },
        {
          id: 'cash',
          field: 'cash',
          tdcomponent: 'CashdeskDatatableTDXReportCash',
          label: this.$t('cash'),
        },
        {
          id: 'card',
          field: 'card',
          tdcomponent: 'CashdeskDatatableTDXReportCard',
          label: this.$t('cashless'),
        },
        {
          id: 'actions',
          field: 'actions',
          tdcomponent: 'CashdeskDatatableTDXReportView',
          label: this.$t('actions'),
        },
      ],
      webkassaColumns: [
        { id: 'TaxPayerName', title: '' },
        { id: 'TaxPayerIN', title: 'БИН' },
        { id: 'CashboxSN', title: 'Касса' },
        { id: 'ShiftNumber', title: 'Смена' },
        { id: 'StartOn', title: 'Начало' },
        { id: 'Sell', title: 'Продажи' },
        { id: 'SumInCashbox', title: 'В кассе' },
      ]
    }
  },
  methods: {
    xReport() {
      CashdeskService.createXReport().then(res => {
        this.$store.commit('setXReports', res.data.reports)
      })
    }
  },
  mounted() {
    if(this.ppoData.vendor === 'webkassa') {
      CashdeskService.webkassaData('xreport').then(res => {
        this.webkassaXReport = res.data
      })
    }
  }
}
</script>