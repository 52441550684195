<template>
  <div>
    <DataTable :options="options"
               :params="{ clinic_id: this.clinic.id, document_type: 'agreement' }"
               module="templates"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/templates/agreement_template"
import { mapState } from "vuex"

export default {
  name: "AgreementTemplates",
  metaInfo() {
    return {
      title: this.$t('agreements'),
      meta: []
    }
  },
  components: {
    DataTable
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
