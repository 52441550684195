<template>
  <div v-if="object.id" class="mt-4">
    <AutosizeTextArea v-model="comment"
                      labelclass="black"
                      :label="$t('leave_comment')"/>
    <div class="text-right">
      <button class="btn-themed btn-themed-squared"
              @click="saveComment"
              :disabled="loading || !comment.length">
        {{ $t('send') }}
      </button>
    </div>
    <div class="mt-md-2 mt-3">
      <div v-for="c in comments"
           :key="`comment-${c.id}`"
           class="comment">
        <div>
          <span class="comment-author text-primary">
            {{ c.author ? c.author.full_name : '-' }}
          </span>
          <span class="comment-date text-muted ml-2">
            {{ c.created_at | formatDateTime }}
          </span>
        </div>
        <div class="comment-block">{{ c.text }}</div>
      </div>
    </div>
  </div>
</template>

<script>

import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import { EloquentService } from "@/services/api.service"
import { mapState } from "vuex"

export default {
  name: "GeneralCommentsBlock",
  components: {
    AutosizeTextArea
  },
  props: {
    model: String,
    parentColumn: String,
    object: {}
  },
  data() {
    return {
      comment: '',
      comments: []
    }
  },
  computed: {
    ...mapState({
      loading: state => state.dom.loading
    }),
  },
  methods: {
    saveComment() {
      if(!this.comment) return
      if(!this.object.id) return
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        [this.parentColumn]: this.object.id,
        text: this.comment
      })
      EloquentService.create(this.model, formData).then(() => {
        this.comment = ''
        this.getComments()
      })
    },
    getComments() {
      if(this.object.id) {
        EloquentService.dataTable(this.model, 1, {
          condition: { [this.parentColumn]: this.object.id },
        }).then(res => {
          this.comments = res.data.result.data
        })
      }
    }
  },
  mounted() {
    this.getComments()
  }

}
</script>

<style scoped lang="scss">
.comment-block {
  background: #F8FAFC;
  padding: 9px 13px;
}
.comment {
  //font-size:  14px;
  margin-bottom: 15px;
  &-author {
    font-size: 15px;
  }
  &-date {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .comment {
    &-author {
      font-size: 13px;
    }
    &-date {
      font-size: 12px;
    }
  }
}
</style>
