<template>
  <span>
    {{ (object.send_time ? object.send_time : object.created_at) | formatDateTime }}
  </span>
</template>

<script>
export default {
  name: "MarketingDatatableTDMailingDate",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped lang="scss">

</style>
